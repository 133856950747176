import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Camera, CameraResultType } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import {
  IonAvatar,
  IonButton,
  IonInput,
  IonLabel,
  IonSpinner
} from "@ionic/react";

import { userActions } from "../../../api/UserActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import AuthContext from "../../../context/AuthContext";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { ExceptionDto } from "../../../types/schema";
import { DataURIToBlob } from "../../../utils/globalFunctions";

import styles from "./UserPages.module.scss";

const ProfilePageForm: React.FC = () => {
  const { t } = useTranslation();
  const { nameCtx, mailCtx, updateProfileAvatarCtx, profileAvatarCtx } =
    useContext(AuthContext);
  const { layout } = useWindowSize();
  const [email, setEmail] = useState(mailCtx);
  const [name, setName] = useState(nameCtx);
  const [isLoading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [wasModified, setWasModified] = useState(false);

  useEffect(() => {
    if (profileAvatarCtx) {
      setSelectedImage(profileAvatarCtx);
    }
  }, []);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");

        reader.onload = async (readerEvent) => {
          setLoading(true);
          try {
            setSelectedImage(readerEvent.target.result as string);
            updateProfileAvatarCtx(readerEvent.target.result as string);
            setWasModified(true);
          } catch (e) {
            console.log(e);
          } finally {
            setLoading(false);
          }
        };
      });
    },
    [isLoading]
  );

  const mobileCondition = () => {
    return !(Capacitor.isNativePlatform() && layout === "phone") ? (
      <div className={styles.avatarImageWrapper}>
        <IonAvatar
          data-testid="profile-avatar"
          {...getRootProps()}
          className={styles.avatarImage}
        >
          <input {...getInputProps()} />
          <img
            alt="Silhouette of a person's head"
            src={
              selectedImage
                ? selectedImage
                : `${process.env.REACT_APP_API_URL}/img/avatar.png`
            }
          />
        </IonAvatar>
        <IonLabel {...getRootProps()} className={styles.avatarLabel}>
          <input {...getInputProps()} />
          {t("profileAvatar.imageChange")}
        </IonLabel>
        <IonLabel
          onClick={() => {
            setSelectedImage(`${process.env.REACT_APP_API_URL}/img/avatar.png`);
            updateProfileAvatarCtx(
              `${process.env.REACT_APP_API_URL}/img/avatar.png`
            );
            setWasModified(true);
          }}
          className={styles.avatarLabelCancel}
        >
          {t("profileAvatar.imageCancel")}
        </IonLabel>
      </div>
    ) : (
      <div className={styles.avatarImageWrapper}>
        <IonAvatar
          data-testid="profile-avatar"
          onClick={() => takePictureNative()}
          className={styles.avatarImage}
        >
          <img
            alt="Silhouette of a person's head"
            src={
              selectedImage
                ? selectedImage
                : `${process.env.REACT_APP_API_URL}/img/avatar.png`
            }
          />
        </IonAvatar>
        <IonLabel
          onClick={() => takePictureNative()}
          className={styles.avatarLabel}
        >
          {t("profileAvatar.imageChange")}
        </IonLabel>
        <IonLabel
          onClick={() => {
            setSelectedImage(`${process.env.REACT_APP_API_URL}/img/avatar.png`);
            updateProfileAvatarCtx(
              `${process.env.REACT_APP_API_URL}/img/avatar.png`
            );
            setWasModified(true);
          }}
          className={styles.avatarLabelCancel}
        >
          {t("profileAvatar.imageCancel")}
        </IonLabel>
      </div>
    );
  };

  const takePictureNative = async () => {
    setLoading(true);
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        resultType: CameraResultType.DataUrl
      });
      setSelectedImage(image.dataUrl);
      setWasModified(true);
      updateProfileAvatarCtx(image.dataUrl as string);
    } catch (err) {
      if (
        err.message === "User cancelled photos app" ||
        err.message === "No image picked"
      ) {
        return;
      }
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"]
    }
  });

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      const blobImage = DataURIToBlob(selectedImage);
      const data = new FormData();

      const imageIsDefaultAvatar =
        selectedImage === `${process.env.REACT_APP_API_URL}/img/avatar.png`;
      if (imageIsDefaultAvatar) {
        data.append("avatar", "null");
        data.append("name", name);
        data.append("imageWasModified", `${wasModified}`);
      } else if (!wasModified) {
        data.append("avatar", "null");
        data.append("name", name);
        data.append("imageWasModified", `${wasModified}`);
      } else if (wasModified) {
        data.append("avatar", blobImage);
        data.append("name", name);
        data.append("imageWasModified", `${wasModified}`);
      }

      await userActions.updateUserProfile(data);
      toastAdd(t("profile.saved"), "success");
    } catch (err: any) {
      const ex = err as ExceptionDto;
      if (ex) {
        toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <form onSubmit={submitHandler} className={styles.form}>
      <IonLabel>{t("profile.email")}</IonLabel>
      <IonInput
        onIonChange={(e) => setEmail(e.target.value as string)}
        value={email}
        type="text"
        inputmode="email"
        autocomplete="off"
        disabled={true}
        placeholder={t("profile.email")}
      />

      <IonLabel>{t("profile.name")}</IonLabel>
      <IonInput
        onIonChange={(e) => setName(e.target.value as string)}
        value={name}
        type="text"
        inputmode="text"
        autocomplete="off"
        placeholder={t("profile.name")}
      />

      {mobileCondition()}

      <IonButton type="submit" expand="block" fill="solid" disabled={isLoading}>
        {isLoading ? (
          <>
            <IonSpinner name="circles" /> {t("saving")}
          </>
        ) : (
          t("save")
        )}
      </IonButton>

      <input type="submit" className="submit-enter" />
    </form>
  );
};
export default ProfilePageForm;
