import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Redirect, Route, Switch } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import {
  IonApp,
  IonItem,
  IonLabel,
  IonSpinner,
  setupIonicReact
} from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";

import { aqmeterActions } from "./api/AqmeterActions";
import { CookieAccept } from "./components/CookieAccept";
import { NotificationsListener } from "./components/NotificationsListener";
import AuthContext from "./context/AuthContext";
import { useSettings } from "./context/SettingsContext";
import UserContext from "./context/UserContext";
import useRefreshToken from "./hooks/useRefreshToken";
import ConfirmNewPasswordPage from "./pages/0.Authentication/ForgotPassword/ConfirmNewPasswordPage";
import ForgotPasswordPage from "./pages/0.Authentication/ForgotPassword/ForgotPasswordPage";
import LoginPage from "./pages/0.Authentication/Login/LoginPage";
import SSOFinish from "./pages/0.Authentication/Login/SSO/SSOFinish";
import SSOStart from "./pages/0.Authentication/Login/SSO/SSOStart";
import AccountConfirmationPage from "./pages/0.Authentication/Register/AccountConfirmationPage";
import RegisterPage from "./pages/0.Authentication/Register/RegisterPage";
import { PayPage } from "./pages/1.Invoices/Payments/Common/PayPage";
import { TransactionResultPage } from "./pages/1.Invoices/Payments/TransactionResultPage";
import ContactPage from "./pages/5.More/Contact/ContactPage";
import { CookiesPage } from "./pages/5.More/Help/Cookies/CookiesPage";
import { FaqPage } from "./pages/5.More/Help/FAQ/FaqPage";
import HelpPage from "./pages/5.More/Help/HelpPage";
import { PrivacyPage } from "./pages/5.More/Help/Privacy/PrivacyPage";
import { TermsPage } from "./pages/5.More/Help/Terms/TermsPage";
import EmailIsValidPage from "./pages/Others/EmailIsValidPage";
import MaintenancePage from "./pages/Others/MaintenancePage";
import { UpdatePage } from "./pages/Others/UpdatePage";
import UserTabs from "./pages/UserTabs";
import type { AppStatus } from "./types/responseTypes";
import AppUrlListener from "./AppUrlListener";
import { startFirebase } from "./firebaseInit";

import "./App.scss";

setupIonicReact();

const App: React.FC = () => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const refresh = useRefreshToken();
  const { isLoggedIn, accessTokenCtx, logoutCtx } = useContext(AuthContext);
  const { updateCompaniesCtx } = useContext(UserContext);
  const isMobile = Capacitor.isNativePlatform();

  const [fcmToken, setFcmToken] = useState<string>(localStorage.getItem("fcm"));
  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<AppStatus>({
    health: null,
    version: null
  });
  const [mobileVersion, setMobileVersion] = useState(null);
  const [checkingAuth, setCheckingAuth] = useState<boolean>(true);

  const appIsDown = useMemo(() => status?.health === "DOWN", [status]);
  const mobileNeedsUpdate = useMemo(
    () => isMobile && mobileVersion < status?.version,
    [status]
  );

  useEffect(() => {
    if (!fcmToken) {
      startFirebase().then((currentToken) => setFcmToken(currentToken));
    }

    aqmeterActions
      .getMobileVersion()
      .then((version) => setMobileVersion(version));

    aqmeterActions
      .getAppStatus()
      .then((status) => setStatus(status))
      .catch((err) => {
        // consider app is DOWN when backend returns error or is unavailable
        if (err.message === "Request aborted") return;
        setStatus({ health: "DOWN", version: settings.version });
      });

    aqmeterActions
      .getCompanies()
      .then((companies) => updateCompaniesCtx(companies));
  }, []);

  useEffect(() => {
    if (!appIsDown && !mobileNeedsUpdate && !accessTokenCtx) {
      setIsLoading(true);
      // if the app is UP and UP TO DATE, check if there is a valid refresh token,
      // then perform a refresh request to get a new access token and keep the user logged in
      refresh({ fcmToken })
        .then(() => setIsLoading(false))
        .catch(() => {
          // there is no valid refresh token, so the user needs to be logged out
          setIsLoading(false);
          logoutCtx();
        });
    }
    setCheckingAuth(false);
  }, []);

  return (
    <>
      {!checkingAuth ? (
        <>
          {appIsDown ? (
            <MaintenancePage />
          ) : mobileNeedsUpdate ? (
            <UpdatePage />
          ) : isLoading ? (
            <div className="main-spinner">
              <IonItem lines="none">
                <IonSpinner slot="start" color="primary" />
                <IonLabel color="medium">{t("suspense.loading")}</IonLabel>
              </IonItem>
            </div>
          ) : (
            <IonApp>
              {isLoggedIn ? (
                <IonReactRouter>
                  <AppUrlListener />
                  <NotificationsListener />
                  <UserTabs />
                </IonReactRouter>
              ) : (
                <IonReactRouter>
                  <AppUrlListener />
                  <NotificationsListener />
                  <Switch>
                    <Route exact path="/login" component={LoginPage} />
                    <Route
                      exact
                      path="/login/:returnUrl"
                      component={LoginPage}
                    />
                    <Route
                      exact
                      path="/login/sso/finish"
                      component={SSOFinish}
                    />
                    <Route
                      exact
                      path="/login/sso/:provider"
                      component={SSOStart}
                    />
                    <Route exact path="/register" component={RegisterPage} />
                    <Route
                      exact
                      path="/confirm-account/:token"
                      component={AccountConfirmationPage}
                    />
                    <Route
                      exact
                      path="/forgot-password"
                      component={ForgotPasswordPage}
                    />
                    <Route
                      exact
                      path="/password-recovery/:token"
                      component={ConfirmNewPasswordPage}
                    />

                    <Route
                      exact
                      path="/validation"
                      component={EmailIsValidPage}
                    />

                    <Route exact path="/help" component={HelpPage} />
                    <Route exact path="/FAQ" component={FaqPage} />
                    <Route exact path="/contact" component={ContactPage} />
                    <Route exact path="/terms" component={TermsPage} />
                    <Route exact path="/privacy" component={PrivacyPage} />
                    <Route exact path="/cookies" component={CookiesPage} />
                    <Route
                      exact
                      path="/pay/:contractId/:paymentData"
                      component={PayPage}
                    />
                    <Route
                      exact
                      path="/payment/transaction/result"
                      component={TransactionResultPage}
                    />

                    <Route
                      path="/"
                      exact
                      render={() => <Redirect to="/login" />}
                    />
                    <Route render={() => <Redirect to="/login" />} />
                  </Switch>
                </IonReactRouter>
              )}
              {!isMobile && <CookieAccept />}
            </IonApp>
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};
export default App;
