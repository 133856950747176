import type {
  CustomPaymentRequest,
  InvoicesPaymentRequest
} from "../types/requestTypes";
import type { PaymentData } from "../types/schema";

import { axiosPrivate } from "./customAxios";

class PaymentActions {
  async invoicesPayment(payData: InvoicesPaymentRequest): Promise<PaymentData> {
    try {
      const response = await axiosPrivate.post("/payments", payData);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async customPayment(payData: CustomPaymentRequest): Promise<PaymentData> {
    try {
      const response = await axiosPrivate.post("/payments/custom", payData);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export const paymentActions = new PaymentActions();
