import React from "react";
import { useTranslation } from "react-i18next";

import { IonInput, IonLabel } from "@ionic/react";

import styles from "./EditContractPage.module.scss";

interface EditContractContactInfoProps {
  cannotModifyContractEmail: boolean;
  contactMail: string;
  contactPhone: string;
  setContactMail: (value: string) => void;
  setContactPhone: (value: string) => void;
}

const EditContractContactInfo: React.FC<EditContractContactInfoProps> = ({
  cannotModifyContractEmail,
  contactMail,
  contactPhone,
  setContactMail,
  setContactPhone
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.contactInfo}>
      <h3>{t("contract.edit.title")}</h3>

      <IonLabel>{t("contract.edit.email")}</IonLabel>
      <IonInput
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onIonChange={(e) => setContactMail(e.target.value as string)}
        value={contactMail}
        type="text"
        inputmode="text"
        autocomplete="off"
        disabled={cannotModifyContractEmail}
        placeholder={t("email")}
      />

      <IonLabel>{t("contract.edit.phone")}</IonLabel>
      <IonInput
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            e.preventDefault();
          }
        }}
        onIonChange={(e) => setContactPhone(e.target.value as string)}
        value={contactPhone}
        type="tel"
        inputmode="tel"
        autocomplete="off"
        placeholder={t("phone")}
      />
    </div>
  );
};

export default EditContractContactInfo;
