import React, { useEffect, useRef, useState } from "react";
import {
  Autoplay,
  EffectFade,
  Keyboard,
  Navigation,
  Pagination
} from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import type { InfoSliderProps } from "../../../types/otherTypes";
import type { Appinfo } from "../../../types/responseTypes";

import SliderItem from "./SliderItem/SliderItem";

import styles from "../DashboardPage.module.scss";

import "swiper/scss";
import "swiper/scss/autoplay";
import "swiper/scss/effect-fade";
import "swiper/scss/keyboard";
import "swiper/scss/navigation";
import "swiper/scss/pagination";

const InfoSlider: React.FC<InfoSliderProps> = ({ info }) => {
  const [slides, setSlides] = useState([]);
  const swiperRef = useRef<any>();

  useEffect(() => {
    const backendProcessedData = slides;
    if (!backendProcessedData) return;
    refreshState(backendProcessedData);
  }, []);

  useEffect(() => {
    if (info) {
      refreshState(info);
    }
  }, [info]);

  // Local Storage init + kept

  function deleteSlide(id) {
    const readIds = getLocalStorageReadIds();
    readIds.push(id);

    localStorage.setItem("readIds", JSON.stringify(readIds));
    setTimeout(() => {
      refreshState(slides);
    }, 600);
  }

  function getLocalStorageReadIds() {
    let readIds: number[] = JSON.parse(localStorage.getItem("readIds"));
    if (!readIds) readIds = [];
    return readIds;
  }

  function refreshState(data: Appinfo[]) {
    const readIds = getLocalStorageReadIds();

    const unreadInfos = [...data].filter((info) => {
      const id = info.id;
      return !readIds.includes(id);
    });

    setSlides(unreadInfos);
  }

  return (
    <div className={styles.infoContainer}>
      <Swiper
        spaceBetween={100}
        slidesPerView={1}
        pagination={{
          dynamicBullets: true
        }}
        loop={slides.length > 1 ? true : false}
        modules={[Navigation, EffectFade, Pagination, Autoplay, Keyboard]}
        keyboard={true}
        speed={500}
        className={styles.swiper}
        autoplay={{
          delay: 6000,
          disableOnInteraction: false,
          stopOnLastSlide: true
        }}
        ref={swiperRef}
      >
        {slides.map((item) => {
          return (
            <SwiperSlide key={item.id}>
              <SliderItem deleteSlide={deleteSlide} id={item.id}>
                {item.body}
              </SliderItem>
            </SwiperSlide>
          );
        })}
      </Swiper>
    </div>
  );
};
export default InfoSlider;
