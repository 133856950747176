import React from "react";
import { useTranslation } from "react-i18next";

import { IonHeader, IonLabel, IonPage, IonToolbar } from "@ionic/react";

import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../components/UI/BackBtn";
import { useWindowSize } from "../../../hooks/useWindowSize";

import ForgotPasswordForm from "./ForgotPasswordForm";

import styles from "./ForgotPasswordPage.module.scss";

export default function ForgotPasswordPage() {
  const { t } = useTranslation();
  const { layout } = useWindowSize();

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <BackBtn />
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout layoutType={"back"}>
        <section className={styles.forgotPassWrapper}>
          <img
            src="/assets/img/aqmeter-small.png"
            alt="Aqmeter"
            className={styles.logo}
          />
          <IonLabel className={styles.title}>
            {t("login.forgotPassword")}
          </IonLabel>

          <ForgotPasswordForm />
        </section>
      </SidebarLayout>
    </IonPage>
  );
}
