import React from "react";
import { t } from "i18next";

import StandardInput from "../StandardInput";

import styles from "../../CreateContractPage.module.scss";

interface AddressProps {
  setAddress: (address) => void;
  address?: any;
  errorMsg?: string;
}
const Address: React.FC<AddressProps> = ({ setAddress, address, errorMsg }) => {
  return (
    <>
      <div className={styles.spacedInputs}>
        <StandardInput
          inputValue={address?.county}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              county: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.county")}
          errorMsg={errorMsg}
        />
        <StandardInput
          inputValue={address?.city}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              city: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.city")}
          errorMsg={errorMsg}
        />
        <StandardInput
          inputValue={address?.street}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              street: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.street")}
          errorMsg={errorMsg}
        />
        <StandardInput
          inputValue={address?.number}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              number: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.number")}
          errorMsg={errorMsg}
        />
        <StandardInput
          inputValue={address?.building}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              building: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.building")}
          notRequired={true}
        />

        <StandardInput
          inputValue={address?.entrance}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              entrance: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.entrance")}
          notRequired={true}
        />
        <StandardInput
          inputValue={address?.floor}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              floor: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.floor")}
          notRequired={true}
        />
        <StandardInput
          inputValue={address?.ap}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              ap: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.address.ap")}
          notRequired={true}
        />
      </div>
    </>
  );
};

export default Address;
