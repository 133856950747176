import type React from "react";
import { useParams } from "react-router";

import type { SSOProvider } from "../../../../types/otherTypes";
import { getValueOfSearchedUrlQueryParameter } from "../../../../utils/globalFunctions";

const SSOStart: React.FC = () => {
  const { provider } = useParams() as { provider: SSOProvider };
  const isMobile = getValueOfSearchedUrlQueryParameter("isMobile") as string;

  localStorage.setItem("isMobile", isMobile);
  localStorage.setItem("provider", provider);

  const API_URL = process.env.REACT_APP_API_URL;

  const redirect = () => {
    if (!provider) return;
    window.location.replace(
      API_URL + `/oauth2/custom/authorization/${provider}`
    );
  };

  redirect();

  return null;
};

export default SSOStart;
