import React from "react";
import { sadOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonSkeletonText,
  IonThumbnail
} from "@ionic/react";

import Card from "../UI/Card";

import styles from "./SkeletonList.module.scss";

interface SkeletonListProps {
  loading: boolean;
  error?: any;
  type?: "simple" | "list" | "circle";
}

const SkeletonList: React.FC<SkeletonListProps> = ({
  loading,
  error,
  type
}) => {
  const { t } = useTranslation();

  return (
    <>
      {error ? (
        <Card>
          <div className={styles.error}>
            <IonIcon icon={sadOutline} />
            <p>{t("suspense.error")}</p>
          </div>
        </Card>
      ) : loading ? (
        type === "simple" ? (
          <IonItem lines="none" className={styles.simple}>
            <IonLabel>
              <IonSkeletonText animated={true} />
              <IonSkeletonText animated={true} />
              <IonSkeletonText animated={true} />
              <IonSkeletonText animated={true} />
            </IonLabel>
          </IonItem>
        ) : type === "circle" ? (
          <IonItem lines="none" className={styles.circle}>
            <IonLabel>
              <IonSkeletonText animated={true} />
              <IonSkeletonText animated={true} />
              <IonSkeletonText animated={true} />
              <IonSkeletonText animated={true} />
            </IonLabel>
          </IonItem>
        ) : (
          <IonList className={styles.list}>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true} />
              </IonThumbnail>
              <IonLabel>
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
              </IonLabel>
            </IonItem>
            <IonItem>
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true} />
              </IonThumbnail>
              <IonLabel>
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true} />
              </IonThumbnail>
              <IonLabel>
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
              </IonLabel>
            </IonItem>
            <IonItem lines="none">
              <IonThumbnail slot="start">
                <IonSkeletonText animated={true} />
              </IonThumbnail>
              <IonLabel>
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
                <IonSkeletonText animated={true} />
              </IonLabel>
            </IonItem>
          </IonList>
        )
      ) : (
        ""
      )}
    </>
  );
};

export default SkeletonList;
