import React from "react";
import { useTranslation } from "react-i18next";

import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";

import { axiosPrivate } from "../api/customAxios";
import type { PaidInvoiceInfo } from "../types/responseTypes";

import { toastAdd } from "./CustomToast/CustomToasts";

interface DownloadPdfBtnProps {
  invoice: PaidInvoiceInfo;
}

const DownloadPdfBtn: React.FC<DownloadPdfBtnProps> = ({ invoice }) => {
  const { t } = useTranslation();

  const isMobile = Capacitor.isNativePlatform();

  const openPdfInNewTab = async (invoice: PaidInvoiceInfo) => {
    try {
      const response = await axiosPrivate.get(invoice.pdfLink);
      if (response?.status === 200) {
        if (isMobile) {
          await Browser.open({ url: invoice.pdfLink });
        } else {
          const newWindow = window.open(
            invoice.pdfLink,
            "_blank",
            "noopener,noreferrer"
          );
          if (newWindow) newWindow.opener = null;
        }
      } else {
        throw { response };
      }
    } catch (err) {
      toastAdd(t([`err.PDF_INVOICE_NOT_FOUND`, "err.__"]), "error");
    }
  };

  return (
    <span>
      <a
        className="cursor-hover"
        onClick={async () => await openPdfInNewTab(invoice)}
      >
        {t("invoice.download")}
      </a>
    </span>
  );
};

export default DownloadPdfBtn;
