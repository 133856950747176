import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import { IonItem, IonList } from "@ionic/react";

import InfoBox from "../../../components/UI/InfoBox";
import type { NotPaidInvoices } from "../../../types/otherTypes";
import type { Contract, Invoice } from "../../../types/responseTypes";

import NotPaidInvoicesItem from "./NotPaidInvoicesItem";

interface NotPaidInvoicesListProps {
  contract: Contract;
  invoiceSummary: NotPaidInvoices;
  selectedInvoices: number[];
  dispatch: (value: any) => void;
}

const NotPaidInvoicesList: React.FC<NotPaidInvoicesListProps> = ({
  contract,
  invoiceSummary,
  selectedInvoices,
  dispatch
}: NotPaidInvoicesListProps) => {
  const { t } = useTranslation();

  const isRecentlyAdded: boolean =
    contract?.isTotalsoftAndRecentlyAdded ?? false;
  const invoices: Invoice[] = invoiceSummary?.invoices;

  const initialSelectionIds: number[] =
    invoices?.map((invoice: Invoice): number => invoice?.id) ?? [];

  const invoiceLocationProduction = (invoice: Invoice): boolean =>
    invoice.location !== "PS" &&
    invoice.location !== "FP" &&
    invoice.location !== "ST";

  const selectAll = (invoices: Invoice[]) => {
    if (invoices) {
      if (
        !invoiceSummary?.invoicesProcessingNr &&
        !invoiceSummary?.invoicesInitializedNr
      ) {
        const allSelectedInvoices: number[] = invoices
          .filter((invoice: Invoice): boolean =>
            invoiceLocationProduction(invoice)
          )
          .map((invoice: Invoice): number => invoice.id);
        dispatch({ type: "updateSelection", payload: allSelectedInvoices });
      }
    }
  };

  useEffect(() => {
    if (invoices) selectAll(invoices);
  }, [invoices]);

  const updateSelectionList = (clickedInvoiceId: number) => {
    const clickedIndex: number = initialSelectionIds.indexOf(clickedInvoiceId);
    const clickedInvoice: Invoice = invoices.find(
      (invoice: Invoice): boolean => invoice.id === clickedInvoiceId
    );
    let newSelection: number[] = [];

    if (clickedInvoice.location === "PP") {
      if (selectedInvoices.slice(-1)[0] === clickedInvoiceId) {
        // daca factura pe care s-a dat click exista deja in selectie si este ultima
        newSelection = initialSelectionIds
          .slice(0, clickedIndex)
          .filter(
            (invoiceId: number): boolean =>
              invoices.find(
                (invoice: Invoice): boolean => invoice.id === invoiceId
              )?.location === "PP"
          );
      } else {
        newSelection = initialSelectionIds
          .slice(0, clickedIndex + 1)
          .filter(
            (invoiceId: number): boolean =>
              invoices.find(
                (invoice: Invoice): boolean => invoice.id === invoiceId
              )?.location === "PP"
          );
      }

      dispatch({ type: "updateSelection", payload: newSelection });
    } else if (
      clickedInvoice.location === "PS" ||
      clickedInvoice.location === "FP" ||
      clickedInvoice.location === "ST"
    ) {
      // deselecteaza toate facturile de productie
      const newSelection: number[] = initialSelectionIds.filter(
        (invoiceId: number): boolean => {
          return invoiceId === clickedInvoiceId;
        }
      );

      dispatch({ type: "updateSelection", payload: newSelection });
    } else {
      // daca nu avem tipuri de facutri speciale
      if (selectedInvoices.slice(-1)[0] === clickedInvoiceId) {
        // daca factura pe care s-a dat click exista deja in selectie si este ultima
        newSelection = initialSelectionIds.slice(0, clickedIndex);
      } else {
        newSelection = initialSelectionIds.slice(0, clickedIndex + 1);
      }

      dispatch({ type: "updateSelection", payload: newSelection });
    }
  };

  const isAquaCaras: boolean = contract.companyId === 6;

  return (
    <>
      {invoices && (
        <IonList className="invoice-list" lines="none">
          {invoices && isRecentlyAdded && (
            <IonItem>
              <p className="card-widget-list-item center balance-sync">
                <span className="ag-body-text warning-color">
                  {t("balanceSyncLong")}
                </span>
              </p>
            </IonItem>
          )}
          {invoices && !isRecentlyAdded && invoices.length === 0 && (
            <IonItem>
              <p className="card-widget-list-item center">
                <span className="ag-body-text">{t("noInvoicePayments")}</span>
              </p>
            </IonItem>
          )}
          {isAquaCaras &&
            invoices.some(
              (invoice: Invoice): boolean => invoice.location === "PP"
            ) &&
            invoices.some(
              (invoice: Invoice): boolean => invoice.location !== "PP"
            ) && <h3>{t("invoice.payments.notPaid.title.water")}</h3>}
          {invoices &&
            !isRecentlyAdded &&
            invoices.length > 0 &&
            invoices
              .filter((invoice: Invoice): boolean => invoice.location === "PP")
              .map(
                (invoice: Invoice): JSX.Element => (
                  <NotPaidInvoicesItem
                    key={invoice.id}
                    invoice={invoice}
                    updateSelectionList={updateSelectionList}
                    checked={selectedInvoices.includes(invoice.id)}
                    disabled={
                      invoiceSummary?.invoicesProcessingNr > 0 ||
                      invoiceSummary?.invoicesInitializedNr > 0
                    }
                  />
                )
              )}
          {isAquaCaras &&
            invoices.some(
              (invoice: Invoice): boolean => invoice.location !== "PP"
            ) && <h3>{t("invoice.payments.notPaid.title.services")}</h3>}
          {invoices &&
            !isRecentlyAdded &&
            invoices.length > 0 &&
            invoices
              .filter((invoice: Invoice): boolean => invoice.location !== "PP")
              .map(
                (invoice: Invoice): JSX.Element => (
                  <NotPaidInvoicesItem
                    key={invoice.id}
                    invoice={invoice}
                    updateSelectionList={updateSelectionList}
                    checked={selectedInvoices.includes(invoice.id)}
                    disabled={
                      invoiceSummary?.invoicesProcessingNr > 0 ||
                      invoiceSummary?.invoicesInitializedNr > 0
                    }
                  />
                )
              )}
          {isAquaCaras &&
            invoices.some(
              (invoice: Invoice): boolean => invoice.location !== "PP"
            ) && <InfoBox color="warning">{t("invoice.pay.warning")}</InfoBox>}
        </IonList>
      )}
    </>
  );
};
export default NotPaidInvoicesList;
