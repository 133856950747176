import React from "react";
import CookieBanner from "react-cookie-banner";
import { useTranslation } from "react-i18next";

export const CookieAccept: React.FC = () => {
  const { t } = useTranslation();

  return (
    <CookieBanner
      message={t("cookie.text")}
      buttonMessage="+"
      cookie="user-has-accepted-cookies"
      link={<a href="/cookies">{t("cookie.link")}</a>}
    />
  );
};
