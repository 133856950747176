import type {
  EuplatescData,
  MobilPayData,
  PayUData
} from "../../../../types/schema";
import { euplatescModule } from "../Providers/euplatesc";
import { mobilPayModule } from "../Providers/mobilPay";
import { payuModule } from "../Providers/payU";

const RenderPaymentForms = ({ paymentData }) => {
  const renderFormMobilPay = (data: MobilPayData) => {
    const mobilPayModuleInstance = mobilPayModule(
      document.querySelector("#mobilPayForm"),
      data
    );
    mobilPayModuleInstance.submitToMobilPay();
  };
  const renderFormPayU = (data: PayUData) => {
    const payuModuleInstance = payuModule(
      document.querySelector("#payuForm"),
      data
    );
    payuModuleInstance.submitToPayU();
  };
  const renderFormEuplatesc = (data: EuplatescData) => {
    const _form = document.querySelector("#euplatescForm");
    if (!_form) throw new Error("Didn't find euplatesc form!");
    const euplatescModuleInstance = euplatescModule(_form, data);
    euplatescModuleInstance.submitForm();
  };

  return (
    <>
      <div>
        {paymentData && false && (
          <pre>{JSON.stringify(paymentData, null, 4)}</pre>
        )}
      </div>

      {paymentData?.mobilPay && renderFormMobilPay(paymentData.mobilPay)}
      {paymentData?.payU && renderFormPayU(paymentData.payU)}
      {paymentData?.euplatesc && renderFormEuplatesc(paymentData.euplatesc)}
    </>
  );
};

export default RenderPaymentForms;
