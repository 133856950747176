import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
//import fsBackend from "i18next-fs-backend";
import httpBackend from "i18next-http-backend";
import { initReactI18next } from "react-i18next";

i18n
  // load translation using http -> see /public/locales
  // learn more: https://github.com/i18next/i18next-http-backend
  .use(httpBackend)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    //resources,
    // lng: "ro", // language to use, more information here:
    // https://www.i18next.com/overview/configuration-options#languages-namespaces-resources
    // you can use the i18n.changeLanguage function to change the language manually: https://www.i18next.com/overview/api#changelanguage
    // if you're using a language detector, do not define the lng option
    supportedLngs: ["ro", "en"],
    fallbackLng: "ro",
    interpolation: {
      escapeValue: false // react already safes from xss
    },
    //saveMissing: true,
    backend: {
      loadPath: "/locales/i18n-{{lng}}.json",
      addPath: "/locales/v2/i18n-{{lng}}.missing.json"
    },
    react: {
      useSuspense: false
    }
  });

export default i18n;
