import React, { useState } from "react";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import { AqNotificationsBell } from "../../../../components/AqNotificationsBell";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../../components/UI/BackBtn";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { Company } from "../../../../types/responseTypes";

import PickCompanies from "./PickCompanies";

interface PickCompanyPageProps {
  prop: string;
}

const PickCompanyPage: React.FC<PickCompanyPageProps> = () => {
  const { layout } = useWindowSize();
  const [selectedCompany, setSelectedCompany] = useState<Company>(null);

  console.log("PickCompanyPage", selectedCompany);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"demands"}
        menuSelected={"item2"}
      >
        <PickCompanies setSelectedCompany={setSelectedCompany} />
      </SidebarLayout>
    </IonPage>
  );
};

export default PickCompanyPage;
