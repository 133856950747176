import React, { useState } from "react";
import { chevronDown, warningOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon, IonItem } from "@ionic/react";

import type { DashboardContract } from "../../../../types/responseTypes";

import styles from "../PaymentCard.module.scss";

interface ContractsWithUpdatingBalanceProps {
  contractsWithUpdatingBalance: DashboardContract[];
}

const ContractsWithUpdatingBalance: React.FC<
  ContractsWithUpdatingBalanceProps
> = ({ contractsWithUpdatingBalance }) => {
  const { t } = useTranslation();

  const [isSyncOpen, setIsSyncOpen] = useState(false);

  return (
    <div className={`${styles.contractInfo} ${isSyncOpen ? styles.open : ""}`}>
      <IonItem
        className={styles.contractDetails}
        lines="none"
        detail={false}
        onClick={() => setIsSyncOpen((prevState) => !prevState)}
      >
        <IonIcon icon={warningOutline} color="warning" slot="start" />
        <span>
          {t("balanceSyncSummaryInfo", {
            count: contractsWithUpdatingBalance?.length
          })}
        </span>
        <IonIcon icon={chevronDown} color="medium" slot="end" />
      </IonItem>
      <ul>
        {contractsWithUpdatingBalance.map((contract) => (
          <li key={contract?.number}>
            {contract?.companyShortName} {contract?.number}
            {" - "}
            {contract?.name}
            {" ("}
            {contract?.deliveryAddress}
            {")"}
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContractsWithUpdatingBalance;
