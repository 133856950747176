import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonLabel,
  IonPage,
  IonRow,
  IonText,
  IonTitle,
  IonToolbar,
  useIonModal
} from "@ionic/react";

import { AqNotificationsBell } from "../../../../components/AqNotificationsBell";
import CompanyFilter from "../../../../components/Filters/CompanyFilter";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../../components/UI/BackBtn";
import DemandContext from "../../../../context/DemandContext";
import UserContext from "../../../../context/UserContext";
import useFetchCaching from "../../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { Demand } from "../../../../types/contractDemandsTypes";
import { filterDemands } from "../../../../utils/globalFunctions";

import ListItemDemands from "./ListItemDemands";

import styles from "./ListDemands.module.scss";

const ListDemands: React.FC = () => {
  const { layout } = useWindowSize();
  const history = useHistory();
  const { t } = useTranslation();

  const ModalExample = ({
    onDismiss
  }: {
    onDismiss: (
      data?: string | null | undefined | number,
      role?: string
    ) => void;
  }) => {
    return (
      <IonPage>
        <IonHeader>
          <IonToolbar>
            <IonTitle style={{ textAlign: "center" }}>
              {t("demands.list.modal.title")}
            </IonTitle>
          </IonToolbar>
        </IonHeader>

        <IonContent>
          <IonText className="confirm-deletion">
            {t("demands.list.modal.description")}
          </IonText>

          <IonGrid>
            <IonRow>
              <IonCol>
                <IonButton
                  color="tertiary"
                  expand="block"
                  onClick={() => {
                    onDismiss(null, "cancel");
                    history.push("/demands/create");
                  }}
                >
                  {t("demands.list.modal.buttonLabel.continue")}
                </IonButton>
              </IonCol>
              <IonCol>
                <IonButton
                  color="danger"
                  expand="block"
                  onClick={() => {
                    sessionStorage.removeItem("step");
                    sessionStorage.removeItem("enableStep3&4");
                    onDismiss(null, "cancel");
                    history.push("/demands/list");
                  }}
                >
                  {t("demands.list.modal.buttonLabel.reset")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>
        </IonContent>
      </IonPage>
    );
  };
  const [present, dismiss] = useIonModal(ModalExample, {
    onDismiss: (data: string, role: string) => dismiss(data, role)
  });

  const { data: listDemandsEndpoint } =
    useFetchCaching<Demand[]>("/contract-demands");

  const { companiesCtx } = useContext(UserContext);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [companyFilter, setCompanyFilter] = useState(0);
  const { updateNumberOfDemands } = useContext(DemandContext);

  useEffect(() => {
    if (listDemandsEndpoint) {
      updateNumberOfDemands(listDemandsEndpoint.length);
    }
  }, [listDemandsEndpoint]);

  const getCompany = (searchedCompanyId, allCompanies) => {
    if (!searchedCompanyId || !allCompanies) return null;

    for (const element of allCompanies) {
      if (searchedCompanyId === element.id) {
        return element;
      }
    }
    return null;
  };

  const filteredDemandsEndpoint = filterDemands(
    listDemandsEndpoint,
    companyFilter
  );

  const companyFilterChangeHandler = (selectedCompany) => {
    setCompanyFilter(selectedCompany);
  };

  useEffect(() => {
    setTimeout(() => {
      if (JSON.parse(sessionStorage.getItem("enableStep3&4"))) {
        console.log(JSON.parse(sessionStorage.getItem("enableStep3&4")));
        present();
      }
    }, 2);

    return () => {
      dismiss();
    };
  }, []);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"demands"}
        menuSelected={"item1"}
      >
        <SkeletonList loading={!listDemandsEndpoint} />
        {/* <SkeletonList loading={!demands} /> */}
        <div className={styles.listWrapper}>
          {listDemandsEndpoint ? (
            <>
              <CompanyFilter
                type="demands"
                onChangeCompanyFilter={companyFilterChangeHandler}
                demands={listDemandsEndpoint}
              />
              {filteredDemandsEndpoint.map((demand, index) => {
                return (
                  <ListItemDemands
                    key={demand.number}
                    id={index}
                    showModal={showDeleteModal}
                    updateShowModal={setShowDeleteModal}
                    statusText={demand.statusText}
                    clientType={demand.clientType}
                    surname={`${demand.firstName} ${demand.lastName}`}
                    address={{
                      place: demand.city,
                      street: demand.street,
                      nr: demand.number
                    }}
                    company={getCompany(demand.companyId, companiesCtx)}
                    status={demand.status}
                    // onDelete={() => deleteDemand(index)}
                    files={demand.files}
                  />
                );
              })}
            </>
          ) : (
            <IonLabel className={styles.noDemandText}>
              Nu există nicio cerere.
            </IonLabel>
          )}
        </div>

        <div className={styles.createDemandButtonWrapper}>
          <IonButton
            className={styles.createDemandButton}
            onClick={() => history.push("/demands/company")}
          >
            {t("demands.list.addDemandButton")}
          </IonButton>
        </div>
      </SidebarLayout>
    </IonPage>
  );
};

export default ListDemands;
