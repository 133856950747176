import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import { IonCol, IonRow } from "@ionic/react";

import DownloadPdfBtn from "../../../components/DownloadPdfBtn";
import type { Invoice } from "../../../types/responseTypes";

interface NotPaidInvoicesItemActionsProps {
  invoice: Invoice;
}

const NotPaidInvoicesItemActions: React.FC<NotPaidInvoicesItemActionsProps> = ({
  invoice
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const location = useLocation().pathname;

  return (
    <IonRow onClick={(event) => event.stopPropagation()}>
      <IonCol className="invoice-actions">
        {invoice?.isPdfDownloadAllowed ? (
          <DownloadPdfBtn invoice={invoice} />
        ) : (
          ""
        )}
        <span>
          <a
            className="cursor-hover"
            onClick={() =>
              history.push({
                pathname: `/invoices/detail/${invoice?.id}`,
                state: { from: location }
              })
            }
          >
            {t("invoice.details")}
          </a>
        </span>
      </IonCol>
    </IonRow>
  );
};

export default NotPaidInvoicesItemActions;
