import React, { useContext, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import { contractActions } from "../../../api/ContractActions";
import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import { CompanyHeader } from "../../../components/CompanyHeader";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../components/UI/BackBtn";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type {
  Company,
  Contract,
  ContractDetails
} from "../../../types/responseTypes";

import EditContractDetails from "./EditContractDetails";
import EditContractForm from "./EditContractForm";

const EditContractPage: React.FC = (): JSX.Element => {
  const { layout } = useWindowSize();
  const { contractsCtx, companiesCtx, updateContractsCtx } =
    useContext(UserContext);
  const { id }: { id: string } = useParams();
  const location = useLocation<{ from: string }>();

  const [from, setFrom] = useState<string>();
  const [contractDetails, setContractDetails] = useState<ContractDetails>();
  const [successfulUpdate, setSuccessfulUpdate] = useState<number>(0);

  if (location.state && !from) {
    setFrom(location.state.from);
  }

  const contract: Contract = useMemo(
    () => contractsCtx?.filter((contract: Contract) => contract.id === +id)[0],
    [contractsCtx]
  );

  const company: Company = useMemo(
    () =>
      companiesCtx?.filter(
        (company: Company) => company?.id === contract?.companyId
      )[0],
    [companiesCtx, contract]
  );

  const { data, error } = useFetchCaching<ContractDetails>(`/contracts/${id}`);

  useEffect(() => {
    if (data) {
      setContractDetails(data);
    }
  }, [data]);

  useEffect(() => {
    if (successfulUpdate) {
      contractActions
        .getInfo(+id)
        .then((data: ContractDetails) => setContractDetails(data));
      contractActions
        .getAllUserContracts()
        .then((data: Contract[]) => updateContractsCtx(data));
    }
  }, [successfulUpdate]);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn url={from || "/contracts"} />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout layoutType={"back"} url={from || "/contracts"}>
        <CompanyHeader company={company} />
        <EditContractDetails contract={contract} />
        <SkeletonList
          loading={!contractDetails}
          error={error}
          type={"simple"}
        />
        {!error && contractDetails ? (
          <EditContractForm
            contract={contract}
            contractDetails={contractDetails}
            setSuccessfulUpdate={setSuccessfulUpdate}
          />
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};

export default EditContractPage;
