import React from "react";
import { useTranslation } from "react-i18next";

import { IonItem, IonList } from "@ionic/react";

import type { Contract, ReadingSummary } from "../../../types/responseTypes";

import ReadingsListItem from "./ReadingsListItem";

type mergedReadingsContract = Contract & ReadingSummary;

interface ReadingsListProps {
  filteredContracts: mergedReadingsContract[];
}

const ReadingsList: React.FC<ReadingsListProps> = ({ filteredContracts }) => {
  const { t } = useTranslation();

  return (
    <IonList lines="none">
      {filteredContracts.length === 0 && (
        <IonItem className="card-widget-list-item ion-text-center ag-body-text">
          {t("noReadIndexContract")}
        </IonItem>
      )}
      {filteredContracts.map((contract) => (
        <ReadingsListItem key={contract.id} contract={contract} />
      ))}
    </IonList>
  );
};

export default ReadingsList;
