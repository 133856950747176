import React, { useContext, useMemo, useState } from "react";
import { ellipse } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonButton, IonIcon } from "@ionic/react";

import UserContext from "../../../context/UserContext";
import type {
  Company,
  Contract,
  DashboardCompany,
  DashboardContract,
  DashboardInvoices
} from "../../../types/responseTypes";

import Chart from "./PaymentCard/Chart";
import ContractsWithNegativeBalance from "./PaymentCard/ContractsWithNegativeBalance";
import ContractsWithUpdatingBalance from "./PaymentCard/ContractsWithUpdatingBalance";

import classes from "../DashboardPage.module.scss";
import styles from "./PaymentCard.module.scss";

const getContractDetails = (
  contracts: Contract[],
  companies: Company[]
): DashboardContract[] => {
  if (!contracts?.length || !companies?.length) {
    return [];
  }
  return contracts.map((contract) => {
    const currentCompany = companies.find(
      (company) => company.id === contract.companyId
    );
    return {
      ...contract,
      companyShortName: currentCompany.shortName,
      isPartialPaymentAllowed: currentCompany.isPartialPaymentAllowed,
      isAdvancePaymentAllowed: currentCompany.isAdvancePaymentAllowed
    };
  });
};

const getCompanyDetails = (
  companyDueAmounts: DashboardInvoices[],
  companies: Company[]
): DashboardCompany[] => {
  if (!companyDueAmounts?.length || !companies?.length) {
    return [];
  }
  return companyDueAmounts.map((item) => {
    const currentCompany = companies.find(
      (company) => company.id === item.companyId
    );
    return {
      ...item,
      companyShortName: currentCompany.shortName
    };
  });
};

interface PaymentWidgetProps {
  companyDueAmounts: DashboardInvoices[];
}

const PaymentCard: React.FC<PaymentWidgetProps> = ({ companyDueAmounts }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { contractsCtx, companiesCtx } = useContext(UserContext);

  const [filteredCompany, setFilteredCompany] = useState(0);

  const dashboardContracts = getContractDetails(contractsCtx, companiesCtx);
  const dashboardCompanies = getCompanyDetails(companyDueAmounts, companiesCtx);
  const numberOfContracts = useMemo(() => contractsCtx?.length, [contractsCtx]);

  const contractsWithNegativeBalance = useMemo(
    () =>
      dashboardContracts?.filter(
        (contract) =>
          (contract.isPartialPaymentAllowed ||
            contract.isAdvancePaymentAllowed) &&
          contract.balance < 0
      ),
    [dashboardContracts]
  );

  const contractsWithUpdatingBalance = useMemo(
    () =>
      dashboardContracts?.filter(
        (contract) => contract.isTotalsoftAndRecentlyAdded
      ),
    [dashboardContracts]
  );

  return (
    <div className={classes.paymentCard}>
      {/* GRAFIC */}
      <Chart
        dashboardCompanies={dashboardCompanies}
        filteredCompany={filteredCompany}
      />

      {/* CHART LEGEND */}
      <div className={styles.wrapper}>
        {dashboardCompanies.length > 0 && (
          <div className={styles.list}>
            <IonButton
              fill={"outline"}
              key={0}
              className={` ${filteredCompany !== 0 ? styles.inactive : ""}`}
              onClick={() => setFilteredCompany(0)}
            >
              {t("dashboardChart.total")}
            </IonButton>
            {dashboardCompanies.map((company) => (
              <IonButton
                key={company.companyId}
                fill={"outline"}
                className={`${
                  filteredCompany !== company.companyId ? styles.inactive : ""
                }`}
                onClick={() => setFilteredCompany(company.companyId)}
              >
                <IonIcon slot="start" icon={ellipse}></IonIcon>
                {company.companyShortName}
              </IonButton>
            ))}
          </div>
        )}
      </div>
      {/* BUTON */}

      {companyDueAmounts.length > 0 && (
        <IonButton
          className="ag-cta"
          color="primary"
          expand="block"
          onClick={() => history.replace("/invoices")}
        >
          {t("pay.btnNow")}
        </IonButton>
      )}

      {numberOfContracts !== 0 &&
        !companyDueAmounts.length &&
        (numberOfContracts === 0 ||
          (numberOfContracts > 0 &&
            contractsWithUpdatingBalance.length === 0) ||
          (numberOfContracts > 0 &&
            contractsWithUpdatingBalance.length > 0 &&
            numberOfContracts !== contractsWithUpdatingBalance.length)) && (
          <IonButton
            className="ag-cta"
            color="primary"
            disabled={true}
            expand="block"
          >
            {t("pay.btnNothingToPay")}
          </IonButton>
        )}

      {numberOfContracts === 0 && (
        <IonButton
          className="ag-cta"
          color="primary"
          onClick={() => history.push("/contracts/add")}
          expand="block"
        >
          {t("contract.addContract")}
        </IonButton>
      )}

      {/* UPDATING BALANCE INFO */}
      {contractsWithUpdatingBalance.length > 0 && (
        <ContractsWithUpdatingBalance
          contractsWithUpdatingBalance={contractsWithUpdatingBalance}
        />
      )}

      {/* NEGATIVE BALANCE INFO */}
      {contractsWithNegativeBalance.length > 0 && (
        <ContractsWithNegativeBalance
          contractsWithNegativeBalance={contractsWithNegativeBalance}
        />
      )}
    </div>
  );
};
export default PaymentCard;
