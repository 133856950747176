import React from "react";
import { chevronBackOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonButton, IonIcon, IonLabel } from "@ionic/react";

import styles from "./BackBtn.module.scss";

interface BackBtnProps {
  url?: string;
  clickAction?: () => void;
}

const BackBtn: React.FC<BackBtnProps> = ({ url, clickAction }) => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <IonButton
      className={styles.backBtn}
      data-testid="back-button"
      onClick={() =>
        clickAction ? clickAction() : url ? history.push(url) : history.goBack()
      }
    >
      <IonIcon slot="start" icon={chevronBackOutline} />
      <IonLabel>
        {history.location.pathname === "/validation"
          ? t("backToApp")
          : t("back")}
      </IonLabel>
    </IonButton>
  );
};

export default BackBtn;
