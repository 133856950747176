import React, { createContext, useState } from "react";
import { jwtDecode } from "jwt-decode";
import { useTranslation } from "react-i18next";

import type { Language, Role } from "../types/otherTypes";
import type { LoginResponse, Profile } from "../types/responseTypes";

type AuthContextValue = {
  isLoggedIn: boolean;
  nameCtx: string;
  profileAvatarCtx: string;
  mailCtx: string;
  langCtx: Language;
  roleCtx: Role;
  accessTokenCtx: string;
  userId: number;
  updateAuthCtx: (requestData: LoginResponse) => void;
  logoutCtx: () => void;
  updateLangCtx: (newLang: Language) => void;
  updateNameCtx: (newName: string) => void;
  updateProfileCtx: (data: Profile) => void;
  updateProfileAvatarCtx: (data: string) => void;
};

const AuthContext = createContext<AuthContextValue>({
  isLoggedIn: false,
  nameCtx: null,
  profileAvatarCtx: null,
  mailCtx: null,
  langCtx: null,
  roleCtx: null,
  accessTokenCtx: null,
  userId: null,
  updateAuthCtx: () => {},
  logoutCtx: () => {},
  updateLangCtx: () => {},
  updateNameCtx: () => {},
  updateProfileCtx: () => {},
  updateProfileAvatarCtx: () => {}
});

type AuthProviderProps = {
  children: React.ReactNode;
};

export const AuthProvider: React.FC<AuthProviderProps> = ({
  children
}: AuthProviderProps): JSX.Element => {
  const { i18n } = useTranslation();

  // example of DECODED ACCESS TOKEN:
  // {
  //   "exp": 1666874357,
  //   "iat": 1666872557,
  //   "jti": "6892fe13-ad1f-4a42-bf44-9a566b43c272",
  //   "iss": "http://localhost:8180/auth/realms/master",
  //   "aud": "aqmeter",
  //   "sub": "f:1cbd39e1-7b14-4b5a-8d2d-c79ff4ef2432:andr@setmobile.ro",
  //   "typ": "Bearer",
  //   "azp": "aqmeter",
  //   "session_state": "4d7ff36b-c7e7-49cb-a888-b28b6126711c",
  //   "acr": "1",
  //   "scope": "read",
  //   "user_name": "andr@setmobile.ro",
  //   "authorities": [
  //   "ROLE_PAY_INVOICE",
  //   "ROLE_READ_OWN_CONTRACT",
  //   "ROLE_UPDATE_CONTRACT",
  //   "ROLE_CREATE_READING",
  //   "ROLE_PASSWORD_RESET",
  //   "ROLE_CREATE_OWN_READING_REQUEST",
  //   "ROLE_USER",
  //   "ROLE_CREATE_OWN_READING",
  //   "ROLE_READ_OWN_READING",
  //   "ROLE_READ_OWN_SUBSCRIPTION",
  //   "ROLE_READ_NEWS_WIDGET",
  //   "ROLE_READ_OWN_INVOICE"
  // ]
  // }

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [nameCtx, setNameCtx] = useState<string>(null);
  const [profileAvatarCtx, setProfileAvatarCtx] = useState<string>(null);
  const [mailCtx, setMailCtx] = useState<string>(null);
  const [langCtx, setLangCtx] = useState<Language>(
    i18n.resolvedLanguage as Language
  );
  const [roleCtx, setRoleCtx] = useState<Role>(null);
  const [accessTokenCtx, setAccessTokenCtx] = useState<string>(null);
  const [userId, setUserId] = useState<number>(null);

  const updateAuthCtx = (requestData: LoginResponse) => {
    const accessToken = requestData?.accessToken;
    const { authorities: userRoles } = jwtDecode(accessToken) as {
      authorities: string[];
    };

    if (userRoles.includes("ROLE_USER")) {
      setRoleCtx("user");
    }

    setAccessTokenCtx(accessToken);
    setIsLoggedIn(true);
    setNameCtx(requestData.name);
    setMailCtx(requestData.email);
    updateLangCtx(requestData.language);
    updateProfileAvatarCtx(requestData.avatarUrl);
  };

  const logoutCtx = () => {
    setIsLoggedIn(false);
    setNameCtx(null);
    setMailCtx(null);
    setRoleCtx(null);
    setLangCtx(i18n.resolvedLanguage as Language);
    setAccessTokenCtx(null);
  };

  const updateLangCtx = (newLang: Language) => {
    i18n.changeLanguage(newLang).then(() => {
      setLangCtx(newLang);
    });
  };

  const updateNameCtx = (newName: string) => {
    setNameCtx(newName);
  };

  const updateProfileAvatarCtx = (newAvatarUrl: string) => {
    setProfileAvatarCtx(newAvatarUrl);
  };

  const updateProfileCtx = (data: Profile) => {
    setNameCtx(data.name);
    setMailCtx(data.email);
    setUserId(data.userId);
  };

  const contextValue: AuthContextValue = {
    isLoggedIn,
    nameCtx,
    profileAvatarCtx,
    mailCtx,
    langCtx,
    roleCtx,
    accessTokenCtx,
    userId,
    updateAuthCtx,
    logoutCtx,
    updateLangCtx,
    updateNameCtx,
    updateProfileCtx,
    updateProfileAvatarCtx
  };

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export default AuthContext;
