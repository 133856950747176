import React from "react";
import { checkmarkCircleOutline, closeCircleOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon, IonItem, IonLabel, IonToggle } from "@ionic/react";

import styles from "./UserPages.module.scss";

function Toggle2FA({
  has2FA,
  toggle2FA,
  isDisabled,
  isCheckIconShown,
  isXIconShown
}) {
  const { t } = useTranslation();
  return (
    <IonItem>
      <IonLabel color="medium">{t("2FA.title")}</IonLabel>
      <IonToggle
        data-testid="2fa-toggle"
        checked={has2FA}
        slot="end"
        color="tertiary"
        onClick={toggle2FA}
        disabled={isDisabled}
      />
      {isCheckIconShown || isXIconShown ? (
        <IonIcon
          className={styles.checkmark}
          icon={isCheckIconShown ? checkmarkCircleOutline : closeCircleOutline}
          size="medium"
          color={isCheckIconShown ? "primary" : "warning"}
        />
      ) : (
        ""
      )}
    </IonItem>
  );
}

export default Toggle2FA;
