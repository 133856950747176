import React from "react";
import { useTranslation } from "react-i18next";

import { IonItem, IonLabel, IonSpinner } from "@ionic/react";

interface ResponseSpinnerProps {
  isLoading: boolean;
  children: React.ReactNode;
}

const ResponseSpinner: React.FC<ResponseSpinnerProps> = ({
  isLoading,
  children
}) => {
  const { t } = useTranslation();
  return (
    <div className={"loader-parent"}>
      {isLoading ? (
        <div className="overlay-spinner">
          <IonItem lines="none">
            <IonSpinner slot="start" color="primary" />
            <IonLabel color="medium">{t("suspense.loading")}</IonLabel>
          </IonItem>
        </div>
      ) : (
        ""
      )}
      {children}
    </div>
  );
};

export default ResponseSpinner;
