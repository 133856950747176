import React, { useState } from "react";
import { closeOutline } from "ionicons/icons";
import type { MouseEvent } from "react";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";
import { useParams } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { axiosPrivate } from "../../../api/customAxios";
import FormFeedback from "../../../components/UI/FormFeedback";
import type { ElectronicInvoiceEmails } from "../../../types/requestTypes";

import styles from "./EditContractPage.module.scss";

interface EditContractValidateEmailModalProps {
  electronicInvoice: ElectronicInvoiceEmails;
  showModal: boolean;
  updateShowModal: (value: boolean) => void;
  electronicInvoiceEmails: ElectronicInvoiceEmails[];
  setElectronicInvoiceEmails: (value: ElectronicInvoiceEmails[]) => void;
}

const EditContractValidateEmailModal: React.FC<
  EditContractValidateEmailModalProps
> = ({
  electronicInvoice,
  showModal,
  updateShowModal,
  electronicInvoiceEmails,
  setElectronicInvoiceEmails
}: EditContractValidateEmailModalProps) => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [alertVisible, setAlertVisible] = useState<boolean>(false);
  const [resent, setResent] = useState<boolean>(false);
  const [progressBarAppearance, setProgressBarAppearance] =
    useState<boolean>(true);
  const [pin, setPin] = useState<string>("");
  let pinRef: PinInput;

  const resendHandler = async (e: MouseEvent) => {
    e.preventDefault();
    pinRef.clear();
    setPin("");

    try {
      await axiosPrivate.post("/confirm-contract-email/send-validation", {
        contractId: id,
        email: electronicInvoice?.email,
        isConfirmedEmail: electronicInvoice?.confirmedEmail
      });
    } catch (err) {
      setErrorMessage(`err.${err.response?.data.message}`);
      setAlertVisible(true);
      setPin("");
    } finally {
      setResent(true);
      setTimeout(() => setProgressBarAppearance(false), 1);
      setTimeout(() => setProgressBarAppearance(true), 2);
      setTimeout(() => setResent(false), 6000);
      setAlertVisible(false);
    }
  };

  const validateHandler = async (event: MouseEvent) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      await axiosPrivate
        .post("/confirm-contract-email/confirm-with-pin", {
          contractId: id,
          email: electronicInvoice?.email,
          pin: pin
        })
        .then(() => {
          const updatedElectronicInvoiceEmails = electronicInvoiceEmails.map(
            (electronicInvoiceEmail) => {
              if (electronicInvoiceEmail.email === electronicInvoice.email) {
                return {
                  ...electronicInvoiceEmail,
                  confirmedEmail: true
                };
              }
              return electronicInvoiceEmail;
            }
          );

          setElectronicInvoiceEmails(updatedElectronicInvoiceEmails);

          setIsLoading(false);
          updateShowModal(false);
        })
        .catch((err) => {
          setIsLoading(false);
          if (err.response?.data.message === "INCORRECT_PIN") {
            setErrorMessage("err.INCORRECT_PIN");
            setAlertVisible(true);
            setPin("");
          } else if (err.response?.data.message === "EXPIRED_PIN") {
            setAlertVisible(true);
            setErrorMessage("err.EXPIRED_PIN");
            setPin("");
          } else {
            setAlertVisible(true);
            setErrorMessage("err.GENERIC_ERROR");
            setPin("");
          }
        });
    } catch (err) {
      setIsLoading(false);
      if (err.response?.data.message === "INCORRECT_PIN") {
        setErrorMessage("err.INCORRECT_PIN");
        setAlertVisible(true);
        setPin("");
      } else if (err.response?.data.message === "EXPIRED_PIN") {
        setAlertVisible(true);
        setErrorMessage("err.EXPIRED_PIN");
        setPin("");
      } else {
        setAlertVisible(true);
        setErrorMessage("err.GENERIC_ERROR");
        setPin("");
      }
    }
  };

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => updateShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("confirmation")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => updateShowModal(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonGrid>
          <IonRow>
            <IonCol>
              <div className={styles.twoFactorInputs}>
                <PinInput
                  length={4}
                  initialValue=""
                  type="numeric"
                  inputMode="number"
                  style={{
                    padding: "10px",
                    width: "100%",
                    textAlign: "center",
                    fontWeight: "500"
                  }}
                  inputStyle={{ borderColor: "red" }}
                  inputFocusStyle={{ borderColor: "blue" }}
                  autoSelect={true}
                  regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
                  onChange={(value: string) => {
                    setResent(false);
                    setPin(value);
                    setAlertVisible(false);
                  }}
                  onComplete={(value: string) => {
                    setPin(value);
                  }}
                  focus={true}
                  ref={(value: PinInput) => {
                    pinRef = value;
                    return pinRef;
                  }}
                />

                {progressBarAppearance && (
                  <div className={styles.loaderWrapper}>
                    <div className={styles["loader-progressBar"]}></div>
                  </div>
                )}

                <div className={` ${styles.loader}`}>
                  <div className={styles.loaderText}>
                    <p className={styles.validityText}>{t("2FA.validity")}</p>
                    <IonButton
                      className={styles.linkResend}
                      fill="clear"
                      color="primary"
                      size="small"
                      onClick={(e: MouseEvent) => resendHandler(e)}
                    >
                      {t("2FA.resend")}
                    </IonButton>
                  </div>
                </div>

                <IonButton
                  className="ion-margin-top"
                  type="submit"
                  onClick={validateHandler}
                  color="primary"
                  expand="block"
                  strong={true}
                  disabled={isLoading || pin.length <= 3}
                >
                  {isLoading ? (
                    <>
                      <IonSpinner name="circles" /> {t("2FA.loading")}
                    </>
                  ) : (
                    <> {t("2FA.buttonText")}</>
                  )}
                </IonButton>
              </div>
              {alertVisible && (
                <FormFeedback type={"error"}>
                  {t(`${errorMessage}`)}
                </FormFeedback>
              )}
              {resent && (
                <FormFeedback type={"success"}>
                  {t("2FA.resend.message")}
                </FormFeedback>
              )}

              <input
                type="submit"
                className="submit-enter"
                disabled={isLoading || pin.length <= 3}
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default EditContractValidateEmailModal;
