import { useContext, useEffect, useState } from "react";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";

import { axiosPrivate } from "../api/customAxios";
import AuthContext from "../context/AuthContext";
import { startFirebase } from "../firebaseInit";

import useRefreshToken from "./useRefreshToken";

const useAxiosPrivate = () => {
  const refresh = useRefreshToken();
  const history = useHistory();
  const { accessTokenCtx, logoutCtx } = useContext(AuthContext);
  const location = useLocation();
  const currentPath = location?.pathname;

  const [fcmToken, setFcmToken] = useState<string>(localStorage.getItem("fcm"));

  useEffect(() => {
    if (!fcmToken) {
      startFirebase().then((currentToken) => setFcmToken(currentToken));
    }
  }, []);

  let refreshTokenPromise;
  // this holds any in-progress token refresh requests

  useEffect(() => {
    const requestIntercept = axiosPrivate.interceptors.request.use(
      (config) => {
        if (!config.headers["Authorization"]) {
          // when authorization header doesn't exist, we know it's not a retry
          config.headers["Authorization"] = `Bearer ${accessTokenCtx}`;
        }
        return config;
      },
      (error) => Promise.reject(error)
    );

    const responseIntercept = axiosPrivate.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.config && error.response && error.response.status === 401) {
          if (!refreshTokenPromise) {
            // check for an existing in-progress request
            // if nothing is in-progress, start a new refresh request
            refreshTokenPromise = refresh({ fcmToken }).then((newToken) => {
              refreshTokenPromise = null; // clear state
              return newToken; // resolve with the new token
            });
          }

          return refreshTokenPromise
            .then((newAccessToken) => {
              // after receiving the new access token, resend your initial request
              // using the same data stored in error.config, but with the new token in the header
              error.config.headers["Authorization"] =
                `Bearer ${newAccessToken}`;
              return axiosPrivate(error.config);
            })
            .catch((err) => {
              // if the refresh token expired (response 400), the user must be logged out
              logoutCtx();
              if (!currentPath.includes("/pay")) {
                history.replace("/login");
              }
              return Promise.reject(err);
            });
        }

        return Promise.reject(error);
      }
    );

    return () => {
      //remove interceptors at cleanup, so they don't pile forever
      axiosPrivate.interceptors.request.eject(requestIntercept);
      axiosPrivate.interceptors.response.eject(responseIntercept);
    };
  }, [accessTokenCtx, refresh]);

  return axiosPrivate;
};

export default useAxiosPrivate;
