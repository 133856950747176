import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import AuthContext from "../../../../context/AuthContext";
import type { Invoice } from "../../../../types/responseTypes";

interface InvoicesPaymentListItemProps {
  invoice: Invoice;
}

const InvoicesPaymentListItem: React.FC<InvoicesPaymentListItemProps> = ({
  invoice
}) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  return (
    <div className="invoice-payment-row">
      <span>
        <span className="gray">{t("invoice.no")}</span>
        <span>
          {invoice?.serial} {invoice?.number}
        </span>
      </span>
      <span>
        <span className="gray">{t("invoice.issuedAt2")}</span>
        <span>
          {new Date(invoice?.invoiceDate).toLocaleDateString(langCtx)}
        </span>
      </span>
      <span>
        <span className="gray">{t("invoice.value")}</span>
        <span>
          {invoice?.hasPartialPayment
            ? invoice?.amount.toLocaleString(langCtx)
            : invoice?.dueAmount.toLocaleString(langCtx)}{" "}
          {t("currency")}
        </span>
      </span>
      {invoice?.hasPartialPayment && (
        <span>
          <span className="gray">{t("invoice.difference")}</span>
          <span>
            {invoice?.dueAmount.toLocaleString(langCtx)} {t("currency")}
          </span>
        </span>
      )}
    </div>
  );
};

export default InvoicesPaymentListItem;
