import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonButton, IonCol, IonGrid, IonRow } from "@ionic/react";

import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import type { NotPaidInvoices } from "../../../types/otherTypes";
import type { Contract } from "../../../types/responseTypes";

import styles from "./NotPaidInvoicesPage.module.scss";

interface NotPaidInvoicesButtonsProps {
  contract: Contract;
  selectedInvoices: number[];
  invoiceSummary: NotPaidInvoices;
  contractDetails: {
    btnValue: number;
    hasPendingPayments: boolean;
    isPayAmountTooSmall: boolean;
  };
}

const NotPaidInvoicesButtons: React.FC<NotPaidInvoicesButtonsProps> = ({
  contract,
  selectedInvoices,
  invoiceSummary,
  contractDetails
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companiesCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);

  const company = useMemo(
    () =>
      companiesCtx?.filter((company) => company?.id === contract?.companyId)[0],
    [companiesCtx, contract]
  );

  const btnValue = contractDetails?.btnValue;
  const hasPendingPayments = contractDetails?.hasPendingPayments;
  const isPayAmountTooSmall = contractDetails?.isPayAmountTooSmall;
  const totalDueAmount = invoiceSummary?.totalDueAmount;
  const hasCustomPaymentInitialized =
    invoiceSummary?.hasCustomPaymentInitialized;
  const advancePaymentAllowed = company?.isAdvancePaymentAllowed;
  const partialPaymentAllowed = company?.isPartialPaymentAllowed;
  const minInvoicePaymentAmount = company?.minPaymentAmount;
  const isRecentlyAdded = contract?.isTotalsoftAndRecentlyAdded;
  const invoices = invoiceSummary?.invoices;

  return (
    <IonGrid className={styles.buttonsGrid}>
      {invoiceSummary && invoices?.length > 0 ? (
        <>
          {/* BUTON PLATA FACTURI */}
          <IonRow>
            <IonCol>
              <IonButton
                className="ag-cta invoice-pay-btn"
                expand="block"
                disabled={
                  hasPendingPayments ||
                  isPayAmountTooSmall ||
                  selectedInvoices.length === 0
                }
                onClick={() =>
                  history.push({
                    pathname: "/payment/invoices/",
                    state: { contract, invoices, selectedInvoices }
                  })
                }
              >
                <span>
                  {t("pay.btn")}{" "}
                  {btnValue > 0 && (
                    <>
                      {btnValue.toLocaleString(langCtx)} {t("currency")}
                    </>
                  )}
                </span>
              </IonButton>
            </IonCol>
          </IonRow>

          {/* BUTON PLATA PARTIALA */}
          {partialPaymentAllowed && !hasPendingPayments ? (
            isPayAmountTooSmall &&
            (invoices.length === 1 ||
              totalDueAmount < minInvoicePaymentAmount) ? (
              <IonRow>
                <IonCol className="ion-text-center">
                  <span
                    className="gray cursor-hover"
                    onClick={() =>
                      history.push({
                        pathname: `/payment/custom/${contract?.id}`,
                        state: { payType: "partial" }
                      })
                    }
                  >
                    {t("pay.advance")}
                  </span>
                </IonCol>
              </IonRow>
            ) : (
              <IonRow>
                <IonCol className="ion-text-center">
                  <span
                    className="gray cursor-hover"
                    onClick={() =>
                      history.push({
                        pathname: `/payment/custom/${contract?.id}`,
                        state: { payType: "partial" }
                      })
                    }
                  >
                    {t("pay.partial")}
                  </span>
                </IonCol>
              </IonRow>
            )
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}

      {/* BUTON PLATA AVANS */}
      {invoiceSummary &&
      !invoices?.length &&
      advancePaymentAllowed &&
      !isRecentlyAdded ? (
        <IonRow>
          <IonCol>
            <IonButton
              fill="solid"
              color="tertiary"
              expand="block"
              disabled={hasCustomPaymentInitialized}
              onClick={() =>
                history.push({
                  pathname: `/payment/custom/${contract?.id}`,
                  state: { payType: "advance" }
                })
              }
            >
              <span>{t("pay.advance")}</span>
            </IonButton>
          </IonCol>
        </IonRow>
      ) : (
        ""
      )}
    </IonGrid>
  );
};
export default NotPaidInvoicesButtons;
