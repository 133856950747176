import React from "react";
import { useTranslation } from "react-i18next";

import { IonCheckbox, IonItem, IonLabel } from "@ionic/react";

import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import type { NotificationSettingType } from "../../../types/otherTypes";
import type { ContractNotificationSettings } from "../../../types/responseTypes";

import styles from "./EditContractPage.module.scss";

interface EditContractNotificationSettingsRowProps {
  title: string;
  setting: NotificationSettingType;
  isElectronicInvoice?: boolean;
  notificationSettings: ContractNotificationSettings;
  setNotificationSettings: (value) => void;
  isDAE: boolean;
}

const EditContractNotificationSettingsRow: React.FC<
  EditContractNotificationSettingsRowProps
> = ({
  title,
  setting,
  isElectronicInvoice,
  notificationSettings,
  setNotificationSettings,
  isDAE
}) => {
  const { t } = useTranslation();

  // array cu cele 3 chei (email, sms, push) care includ settingul (randul) curent
  const currentSettings = Object.keys(notificationSettings).filter((key) =>
    key.toLowerCase().includes(setting.toLowerCase())
  );

  const updateSettingHandler = (setting) => {
    setNotificationSettings((prevState) => {
      return { ...prevState, [setting]: !prevState[setting] };
    });
  };

  const readOnlyHandler = () => {
    if (setting === "generateInvoice" && !isDAE) {
      toastAdd(
        t([`contract.edit.notifications.issue.readOnly`, "err.__"]),
        "error"
      );
    } else if (setting === "payInvoice") {
      toastAdd(t("contract.edit.notifications.payment.readOnly"), "info");
    } else return;
  };

  const emailCheck = isElectronicInvoice
    ? isElectronicInvoice
    : setting === "payInvoice"
      ? true
      : notificationSettings[currentSettings[0]] ?? false;

  return (
    <div className={styles.settingsRow}>
      <h4>{title}</h4>
      <div className={styles.settingsRowContent}>
        <div onClick={readOnlyHandler}>
          <IonItem lines="none">
            <IonCheckbox
              data-testid="email-checkbox"
              slot="start"
              mode="md"
              color="tertiary"
              disabled={
                setting === "payInvoice" || setting === "generateInvoice"
              }
              checked={emailCheck}
              onClick={() => updateSettingHandler(currentSettings[0])}
            />
            <IonLabel>{t("contract.edit.notifications.email")}</IonLabel>
          </IonItem>
        </div>

        <IonItem lines="none">
          <IonCheckbox
            data-testid="sms-checkbox"
            slot="start"
            mode="md"
            color="tertiary"
            checked={notificationSettings[currentSettings[1]] ?? false}
            onClick={() => updateSettingHandler(currentSettings[1])}
          />
          <IonLabel>{t("contract.edit.notifications.sms")}</IonLabel>
        </IonItem>

        <IonItem lines="none">
          <IonCheckbox
            data-testid="push-checkbox"
            slot="start"
            mode="md"
            color="tertiary"
            checked={notificationSettings[currentSettings[2]] ?? false}
            onClick={() => updateSettingHandler(currentSettings[2])}
          />
          <IonLabel>{t("contract.edit.notifications.push")}</IonLabel>
        </IonItem>
      </div>
    </div>
  );
};

export default EditContractNotificationSettingsRow;
