import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IonCol, IonGrid, IonItem, IonLabel, IonRow } from "@ionic/react";

import DownloadPdfBtn from "../../../components/DownloadPdfBtn";
import AuthContext from "../../../context/AuthContext";
import type { InvoiceDetails } from "../../../types/responseTypes";

import styles from "./InvoiceDetailsPage.module.scss";

interface InvoiceDetailsSummaryProps {
  invoice: InvoiceDetails;
}

const InvoiceDetailsSummary: React.FC<InvoiceDetailsSummaryProps> = ({
  invoice
}) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  return (
    <IonItem
      className="card-widget contract-invoices-list invoice-details-view"
      lines="none"
    >
      <IonGrid>
        {(invoice?.isInProcessing || invoice?.hasPaymentInitialized) && (
          <>
            {invoice?.isInProcessing && (
              <div key="isInProcessing">
                <IonLabel className="ag-number-description invoice-flag processing">
                  {t("paymentProcessing")}
                </IonLabel>
              </div>
            )}
            {invoice?.hasPaymentInitialized && (
              <div key="hasOnePaymentInitialized">
                <IonLabel className="ag-number-description invoice-flag pay-init">
                  {t("hasOnePaymentInitialized")}
                </IonLabel>
              </div>
            )}
          </>
        )}

        {/* daca obiectul de invoice nu contine proprietatea due date, inseamna ca e factura platita */}
        {!invoice?.dueDate && (
          <span className={styles.statusFlag}>
            {t("invoice.payments.paid")}
          </span>
        )}

        <IonRow>
          <IonCol className="invoice-information">
            {(invoice?.isInProcessing || invoice?.hasPaymentInitialized) && (
              <span className="extra-spacing"></span>
            )}
            <span className="ag-number-description gray">
              {t("invoice.title")}
            </span>
            <span className="ag-number-description black">
              <span>
                {invoice?.serial} {invoice?.number}{" "}
              </span>
              <span className="lowercase">
                {t("invoice.issuedAt")}{" "}
                {new Date(invoice?.invoiceDate).toLocaleDateString(langCtx)}
              </span>
            </span>
            <span>
              <span className="ag-number-description gray">
                {t("invoice.value")}
              </span>
              <span className="ag-number-description black">
                {!invoice?.hasPartialPayment
                  ? invoice?.dueAmount
                    ? invoice?.dueAmount.toLocaleString(langCtx)
                    : invoice?.amount.toLocaleString(langCtx)
                  : invoice?.amount.toLocaleString(langCtx)}{" "}
                {t("currency")}
              </span>
            </span>
            {invoice?.hasPartialPayment && (
              <span>
                <span className="ag-number-description gray">
                  {t("invoice.difference")}
                </span>
                <span className="ag-number-description black">
                  {invoice?.dueAmount.toLocaleString(langCtx)} {t("currency")}
                </span>
              </span>
            )}
            {invoice?.dueDate && (
              <span>
                <span className="ag-number-description gray">
                  {t("invoice.dueDate")}
                </span>
                <span className="ag-number-description black">
                  {new Date(invoice?.dueDate).toLocaleDateString(langCtx)}
                </span>
              </span>
            )}
          </IonCol>
        </IonRow>

        {invoice?.isPdfDownloadAllowed && (
          <IonRow>
            <IonCol className="invoice-actions">
              <DownloadPdfBtn invoice={invoice} />
            </IonCol>
          </IonRow>
        )}
      </IonGrid>
    </IonItem>
  );
};

export default InvoiceDetailsSummary;
