import React, { createContext, useCallback, useState } from "react";

type DemandContextValue = {
  enabled: boolean;
  numberOfDemands: any;
  updateNumberOfDemands: (demands: any) => void;
  updateEnabled: (status: boolean) => void;
  isInsideDemands: boolean;
  updateIsInsideDemands: (status: boolean) => void;
  useLocalStorageNonString: (key: any, initialState: any) => void;
};

const DemandContext = createContext<DemandContextValue>({
  enabled: false,
  updateEnabled: () => {},
  isInsideDemands: false,
  updateIsInsideDemands: () => {},
  numberOfDemands: [],
  updateNumberOfDemands: () => {},
  useLocalStorageNonString: () => {}
});

type DemandProviderProps = {
  children: React.ReactNode;
};

export const DemandProvider: React.FC<DemandProviderProps> = ({ children }) => {
  const [enabled, setEnabled] = useState<boolean>(null);
  const [numberOfDemands, setNumberDemands] = useState<any>([]);
  const [isInsideDemands, setIsInsideDemands] = useState<boolean>(null);

  const updateEnabled = (status: boolean): void => {
    setEnabled(status);
  };

  const updateIsInsideDemands = (status: boolean): void => {
    setIsInsideDemands(status);
  };
  const updateNumberOfDemands = (demands: any): void => {
    setNumberDemands(demands);
  };

  function useLocalStorageNonString(key, initialState) {
    const serializedInitialState = JSON.stringify(initialState);
    let storageValue = initialState;
    try {
      storageValue = JSON.parse(localStorage.getItem(key)) ?? initialState;
    } catch {
      localStorage.setItem(key, serializedInitialState);
    }
    const [value, setValue] = useState(storageValue);
    const updatedSetValue = useCallback(
      (newValue) => {
        const serializedNewValue = JSON.stringify(newValue);
        if (
          serializedNewValue === serializedInitialState ||
          typeof newValue === "undefined"
        ) {
          localStorage.removeItem(key);
        } else {
          localStorage.setItem(key, serializedNewValue);
        }
        setValue(newValue ?? initialState);
      },
      [initialState, serializedInitialState, key]
    );
    return [value, updatedSetValue];
  }

  const contextValue: DemandContextValue = {
    enabled,
    updateEnabled,
    isInsideDemands,
    updateIsInsideDemands,
    numberOfDemands,
    updateNumberOfDemands,
    useLocalStorageNonString
  };

  return (
    <DemandContext.Provider value={contextValue}>
      {children}
    </DemandContext.Provider>
  );
};

export default DemandContext;
