import React, { useContext, useMemo } from "react";
import { chevronForward, warningOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonAvatar, IonIcon, IonItem, IonLabel } from "@ionic/react";

import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import type { Contract, InvoiceSummary } from "../../../types/responseTypes";

import styles from "./InvoicesPage.module.scss";

interface InvoicesContractListItemProps {
  contract: Contract & InvoiceSummary;
}

const InvoicesContractListItem: React.FC<InvoicesContractListItemProps> = ({
  contract
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companiesCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);

  const company = useMemo(
    () =>
      companiesCtx?.filter((company) => company?.id === contract?.companyId)[0],
    [companiesCtx, contract]
  );

  const hasPaymentsInitialized =
    contract?.hasCustomPaymentInitialized ||
    contract?.hasInvoicePaymentInitialized;
  const hasPaymentsProcessing =
    contract?.hasInvoicePaymentProcessing ||
    contract?.customPaymentInProcessingAmount > 0;
  const customPayProcessingAmount = contract?.customPaymentInProcessingAmount;
  const hasCustomPaymentsAllowed =
    company?.isAdvancePaymentAllowed || company?.isPartialPaymentAllowed;
  const balance = contract?.balance;

  return (
    <IonItem
      className="card-widget-list-item invoice-view cursor-hover readings-list"
      onClick={() => history.push(`/invoices/${contract?.id}`)}
    >
      <IonAvatar slot="start">
        <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
      </IonAvatar>

      <IonLabel>
        <span className="ag-body-text ellipse">{company?.shortName}</span>

        {contract?.isTotalsoftAndRecentlyAdded && (
          <span className="ag-number-description warning-color">
            {t("balanceSyncShort")}
          </span>
        )}

        <span className="ag-number-description capitalize ellipse">
          {contract?.name.toLowerCase()}
        </span>
        <span className="ag-number-description ellipse">
          {contract?.deliveryAddress}
        </span>

        {/* RED ROW */}
        {contract?.numberOfUnpaidInvoices > 0 && (
          <span className="ag-cta invoice-group-total ellipse">
            <span className="small-device">
              {t("invoiceCountShort", {
                count: contract?.numberOfUnpaidInvoices
              })}
            </span>
            <span className="large-device">
              {t("invoiceCount", { count: contract?.numberOfUnpaidInvoices })}
            </span>
            <span>
              {contract?.totalDueAmount.toLocaleString(langCtx)} {t("currency")}
            </span>
          </span>
        )}

        {/* GREEN ROW */}
        {customPayProcessingAmount > 0 && (
          <span className="ag-cta invoice-group-total ellipse green">
            {!(contract?.numberOfUnpaidInvoices > 0) ? (
              <span>{t("payment.advance")}</span>
            ) : contract?.totalDueAmount < customPayProcessingAmount ? (
              <span>{t("payment.advance")}</span>
            ) : (
              <span>{t("payment.partial")}</span>
            )}
            <span>
              {customPayProcessingAmount?.toLocaleString(langCtx)}{" "}
              {t("currency")}
            </span>
          </span>
        )}

        {/* BLUE ROW */}
        {hasCustomPaymentsAllowed && balance < 0 && (
          <span className="ag-cta invoice-group-total ellipse blue">
            <span>{t("invoiceBalance")}</span>
            <span>
              {balance?.toLocaleString(langCtx)} {t("currency")}
            </span>
          </span>
        )}

        {/* FLAGS */}
        <div className={styles.paymentFlagBox}>
          {hasPaymentsInitialized && (
            <span className={`${styles.flagItem} ${styles.initialized}`}>
              {t("hasOnePaymentInitialized")}
            </span>
          )}
          {hasPaymentsProcessing && (
            <span className={`${styles.flagItem} ${styles.processing}`}>
              {t("paymentProcessing")}
            </span>
          )}
        </div>
      </IonLabel>

      <IonIcon className="chevron" icon={chevronForward} />

      <div className="readings-list-icon-details">
        {contract?.isTotalsoftAndRecentlyAdded && (
          <IonIcon icon={warningOutline} color="warning" />
        )}
      </div>
    </IonItem>
  );
};

export default InvoicesContractListItem;
