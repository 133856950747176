import React, { useContext } from "react";
import { lockClosed, lockOpen } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon } from "@ionic/react";

import AuthContext from "../../../context/AuthContext";
import type { Reading } from "../../../types/responseTypes";

import styles from "./AddReadingPage.module.scss";

interface AddReadingPastReadingsRowProps {
  reading: Reading;
  isEditing: Reading;
  setIsEditing: (value) => void;
}

const AddReadingPastReadingsRow: React.FC<AddReadingPastReadingsRowProps> = ({
  reading,
  isEditing,
  setIsEditing
}) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  const editHandler = () => {
    if (!reading?.sentToErp) {
      if (!isEditing) {
        setIsEditing(reading);
      } else {
        setIsEditing(null);
      }
    } else {
      return;
    }
  };

  return (
    <>
      <div
        className={`${styles.readingLine} ${!reading?.sentToErp ? styles.canEdit : ""}`}
        onClick={editHandler}
      >
        <span>
          {reading?.value}{" "}
          {reading?.unit === "mc" ? (
            <>
              m<sup>3</sup>
            </>
          ) : (
            reading?.unit
          )}
        </span>
        <span className={styles.readingDate}>
          {t("readingDateLabelShort")}
          {new Date(reading?.readingDate).toLocaleDateString(langCtx)}
          {!reading?.sentToErp ? (
            <IonIcon icon={lockOpen} />
          ) : (
            <IonIcon icon={lockClosed} />
          )}
        </span>
      </div>
      {!reading?.sentToErp ? (
        <p className={styles.editInfo}>{t("index.editInfo")}</p>
      ) : (
        ""
      )}
    </>
  );
};

export default AddReadingPastReadingsRow;
