import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import CompanyAndContractFilters from "../../../components/Filters/CompanyAndContractFilters";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type {
  Company,
  Contract,
  Invoice,
  PaidInvoiceInfo,
  PaidOrNotPaidInvoices
} from "../../../types/responseTypes";
import {
  extractHistoryFilters,
  filterHistoryItems
} from "../../../utils/globalFunctions";

import PaidInvoicesList from "./PaidInvoicesList";

const getInvoiceDetails = (
  invoices: Invoice[],
  contracts: Contract[],
  companies: Company[]
): PaidInvoiceInfo[] => {
  if (!invoices?.length || !contracts?.length || !companies?.length) {
    return [];
  }
  return invoices.map((invoice) => {
    const currentContract = contracts.find(
      (contract) => contract.id === invoice.contractId
    );
    const currentCompany = companies.find(
      (company) => company.id === currentContract.companyId
    );
    return {
      ...invoice,
      companyId: currentContract.companyId,
      contractNumber: currentContract.number,
      contractName: currentContract.name,
      address: currentContract.deliveryAddress,
      companyShortName: currentCompany.shortName
    };
  });
};

const PaidInvoicesPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { menuCtx, contractsCtx, companiesCtx } = useContext(UserContext);

  const [companyFilter, setCompanyFilter] = useState(0);
  const [contractFilter, setContractFilter] = useState(0);

  const { data: paidInvoices, error } = useFetchCaching<PaidOrNotPaidInvoices>(
    "/invoices?paid=true"
  );

  const notPaidInvoices = menuCtx?.totalUnpaidInvoices;
  const invoices = getInvoiceDetails(
    paidInvoices?.invoices,
    contractsCtx,
    companiesCtx
  );

  const companyFilterChangeHandler = (selectedCompany) => {
    setCompanyFilter(selectedCompany);
  };
  const contractFilterChangeHandler = (selectedContract) => {
    setContractFilter(selectedContract);
  };

  const filteredInvoices = filterHistoryItems(
    invoices,
    companyFilter,
    contractFilter
  );
  const extractedFilters = extractHistoryFilters(invoices);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" style={{ flexDirection: "column" }}>
              <IonLabel className="ag-h2 black">{t("tabs.invoices")}</IonLabel>
              {notPaidInvoices === 0 ? (
                <IonLabel className="ag-body-text gray">
                  {t("invoiceToPayCountZero")}
                </IonLabel>
              ) : (
                <IonLabel className="ag-body-text gray">
                  {t("invoiceToPayCount", {
                    count: notPaidInvoices
                  })}
                </IonLabel>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        className="invoice-history-page"
        layoutType={"submenu"}
        menuType={"invoices"}
        menuSelected={"item2"}
      >
        <SkeletonList loading={!paidInvoices} error={error} />

        {!error && paidInvoices && filteredInvoices ? (
          <>
            <CompanyAndContractFilters
              filters={extractedFilters}
              selectedContract={contractFilter}
              onChangeCompanyFilter={companyFilterChangeHandler}
              onChangeContractFilter={contractFilterChangeHandler}
            />
            <PaidInvoicesList invoices={filteredInvoices} />
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default PaidInvoicesPage;
