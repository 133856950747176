import React, { useMemo } from "react";

import FormStep from "./FormStep";

import styles from "./FormSteps.module.scss";

type FormStepsProps = {
  steps: number;
  currentStep: number;
  setCurrentStep: (value: number) => void;
};

const FormSteps: React.FC<FormStepsProps> = ({
  steps,
  currentStep,
  setCurrentStep
}) => {
  const stepsArray = useMemo(() => {
    const array = [];
    for (let i = 1; i <= steps; i++) {
      array.push(i);
    }
    return array;
  }, [steps]);

  return (
    <div className={styles.stepsWrapper} data-testid={"form-step"}>
      {stepsArray.map((step) => (
        <FormStep
          key={step}
          step={step}
          active={step === currentStep}
          disabled={currentStep <= step}
          setCurrentStep={setCurrentStep}
        />
      ))}
    </div>
  );
};

export default FormSteps;
