import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IonButton } from "@ionic/react";

import { userActions } from "../api/UserActions";
import AuthContext from "../context/AuthContext";
import type { Language } from "../types/otherTypes";

import styles from "./LanguageSwitcher.module.scss";

const LanguageSwitcher: React.FC = () => {
  const { i18n } = useTranslation();
  const { isLoggedIn, langCtx } = useContext(AuthContext);
  const { updateLangCtx } = useContext(AuthContext);

  const [language, setLanguage] = useState<Language>(
    i18n.resolvedLanguage as Language
  );

  // ATENTIE !!!
  // la lansarea aplicatiei pentru prima data, aplicatia detecteaza automat LIMBA DEVICEULUI sau BROWSERULUI!

  useEffect(() => {
    if (isLoggedIn) {
      setLanguage(langCtx);
    }
  }, [isLoggedIn]);

  const changeLanguage = async (clickedLang: Language) => {
    if (language === clickedLang) return;

    if (isLoggedIn) {
      await userActions
        .changeUserSettings({
          language: clickedLang
        })
        .then(() => {
          updateLangCtx(clickedLang);
          setLanguage(clickedLang);
        });
    } else {
      i18n.changeLanguage(clickedLang).then(() => {
        setLanguage(clickedLang);
      });
    }
  };

  return (
    <div className={styles.wrapper}>
      <IonButton
        data-testid="lang-switcher-ro"
        fill={"outline"}
        mode={"md"}
        className={language !== "ro" ? styles.inactive : ""}
        onClick={() => changeLanguage("ro")}
      >
        <img src="assets/img/ro.png" alt="Romanian Flag" />
        <span>RO</span>
      </IonButton>
      <IonButton
        data-testid="lang-switcher-en"
        fill={"outline"}
        mode={"md"}
        className={language !== "en" ? styles.inactive : ""}
        onClick={() => changeLanguage("en")}
      >
        <img src="assets/img/en.png" alt="English Flag" />
        <span>EN</span>
      </IonButton>
    </div>
  );
};

export default LanguageSwitcher;
