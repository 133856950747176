import React, { useContext, useEffect, useState } from "react";
import { timerOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonButton,
  IonChip,
  IonIcon,
  IonInput,
  IonLabel,
  IonSpinner
} from "@ionic/react";

import { axiosPrivate } from "../../../../api/customAxios";
import { updateContextActions } from "../../../../api/UpdateContextActions";
import FormFeedback from "../../../../components/UI/FormFeedback";
import UserContext from "../../../../context/UserContext";
import type {
  AddNewReading,
  EditNewReading
} from "../../../../types/requestTypes";
import type {
  Contract,
  ContractMeters,
  Meter,
  Reading
} from "../../../../types/responseTypes";
import type { ExceptionDto } from "../../../../types/schema";

import styles from "../AddReadingPage.module.scss";

interface AddReadingFormProps {
  contract: Contract;
  selectedMeter: Meter;
  setSelectedMeter: (value: Meter) => void;
  setContractMeters: (value: ContractMeters) => void;
  isEditing: Reading;
  setIsEditing: (value: Reading) => void;
}

const AddReadingForm: React.FC<AddReadingFormProps> = ({
  contract,
  selectedMeter,
  setSelectedMeter,
  setContractMeters,
  isEditing,
  setIsEditing
}) => {
  const { t } = useTranslation();
  const { updateMenuCtx } = useContext(UserContext);

  const [readingInput, setReadingInput] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState<"success" | "error">();
  const [errorMsg, setErrorMsg] = useState<string>();

  const onSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);

    const editData: EditNewReading = {
      value: +readingInput
    };

    const addData: AddNewReading = {
      meterId: selectedMeter.meterId,
      value: +readingInput
    };

    try {
      if (isEditing) {
        // edit new readingInput logic

        const response = await axiosPrivate.patch(
          `/readings/${isEditing?.id}`,
          editData
        );
        if (response.status === 200) {
          setLoading(false);
          setIsEditing(null);
          updateContextActions.getContractMeters(contract?.id).then((data) => {
            setContractMeters(data);
            setSelectedMeter(
              data.contractMeterData.filter(
                (meter) => meter.meterId === selectedMeter.meterId
              )[0]
            );
          });
        }
      } else {
        // add new readingInput logic

        const response = await axiosPrivate.post("/readings", addData);
        if (response.status === 200) {
          setLoading(false);
          setSubmitted("success");
          updateContextActions
            .getMenuDetails()
            .then((data) => updateMenuCtx(data));
          updateContextActions.getContractMeters(contract?.id).then((data) => {
            setContractMeters(data);
            setSelectedMeter(
              data.contractMeterData.filter(
                (meter) => meter.meterId === selectedMeter.meterId
              )[0]
            );
          });
        }
      }
    } catch (err: any) {
      setLoading(false);
      setSubmitted("error");
      const ex: ExceptionDto = err.response.data;
      setErrorMsg(t([`err.${ex.message}`, "err.__"]));
    }
  };

  useEffect(() => {
    if (submitted) {
      setSubmitted(null);
      setReadingInput(null);
    }
    if (errorMsg) {
      setErrorMsg(null);
    }
  }, [readingInput, selectedMeter]);

  return (
    <>
      <IonChip
        outline={true}
        color="success"
        className={styles.readingInterval}
      >
        <IonIcon icon={timerOutline} color="primary" />
        <IonLabel data-testid={"reading-interval"}>
          {t("index.readingInterval")}
          {contract?.monthlyReadingPeriod.startDate}
          {" - "}
          {contract?.monthlyReadingPeriod.endDate} {t("index.ofMonth")}
        </IonLabel>
      </IonChip>

      {!submitted || submitted === "error" ? (
        <form onSubmit={onSubmit} className={styles.addReading}>
          <IonLabel>
            {isEditing
              ? t("index.editNewReadingValue")
              : t("index.addNewReadingValue")}
          </IonLabel>
          <IonInput
            onIonChange={(e) => setReadingInput(e.target.value as number)}
            value={readingInput}
            type="number"
            inputmode="numeric"
            autocomplete="off"
            placeholder={isEditing ? isEditing?.value.toString() : "0000"}
            className={`${errorMsg ? "error" : ""} ${styles.edit}`}
          />

          <IonButton
            strong={true}
            type="submit"
            fill="solid"
            expand="block"
            disabled={!readingInput || !selectedMeter}
          >
            {isEditing ? (
              isLoading ? (
                <>
                  <IonSpinner name="circles" /> {t("index.editingReading")}
                </>
              ) : (
                t("index.editReading")
              )
            ) : isLoading ? (
              <>
                <IonSpinner name="circles" /> {t("sending")}
              </>
            ) : (
              t("index.addReading")
            )}
          </IonButton>

          {submitted === "error" ? (
            <FormFeedback type={"error"}>{errorMsg}</FormFeedback>
          ) : (
            ""
          )}
          <input type="submit" className="submit-enter" />
        </form>
      ) : (
        <FormFeedback type={"success"}>{t("newIndexSaved")}</FormFeedback>
      )}
    </>
  );
};

export default AddReadingForm;
