import React from "react";
import { useTranslation } from "react-i18next";

import { IonLabel } from "@ionic/react";

import type { Meter, Reading } from "../../../types/responseTypes";

import AddReadingPastReadingsRow from "./AddReadingPastReadingsRow";

import styles from "./AddReadingPage.module.scss";

interface AddReadingPastReadingsProps {
  selectedMeter: Meter;
  isEditing: Reading;
  setIsEditing: (value: Reading) => void;
}

const AddReadingPastReadings: React.FC<AddReadingPastReadingsProps> = ({
  selectedMeter,
  isEditing,
  setIsEditing
}) => {
  const { t } = useTranslation();

  return (
    <div className={styles.lastReadingsWrapper}>
      {!selectedMeter ? (
        <IonLabel className={styles.lastReadingsEmpty}>
          {t("index.noSelection")}
        </IonLabel>
      ) : (
        <>
          <IonLabel className={styles.lastReadingsTitle}>
            {t("index.last3Readings")}
          </IonLabel>
          {!selectedMeter?.readings.length ? (
            <IonLabel className={styles.lastReadingsEmpty}>
              {t("index.noReadings")} {selectedMeter?.meterSerial}
              {"."}
            </IonLabel>
          ) : (
            selectedMeter?.readings.map((reading) => (
              <AddReadingPastReadingsRow
                key={reading?.id}
                reading={reading}
                isEditing={isEditing}
                setIsEditing={setIsEditing}
              />
            ))
          )}
        </>
      )}
    </div>
  );
};

export default AddReadingPastReadings;
