import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import CompanyFilter from "../../../components/Filters/CompanyFilter";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { Contract } from "../../../types/responseTypes";
import { filterItems } from "../../../utils/globalFunctions";

import ContractsList from "./ContractsList";

const ContractsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const history = useHistory();
  const { updateContractsCtx } = useContext(UserContext);

  const [companyFilter, setCompanyFilter] = useState(0);

  const { data: contracts, error } = useFetchCaching<Contract[]>("/contracts");

  useEffect(() => {
    if (contracts) {
      updateContractsCtx(contracts);
    }
  }, [contracts]);

  const filteredContracts = filterItems(contracts, companyFilter);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout == "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" style={{ flexDirection: "column" }}>
              <IonLabel className="ag-h2 black">{t("tabs.contracts")}</IonLabel>
              {contracts ? (
                <IonLabel className="ag-body-text gray">
                  {t("contractsCount", { count: contracts?.length })}
                </IonLabel>
              ) : (
                ""
              )}
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout layoutType={"back"}>
        <CompanyFilter onChangeCompanyFilter={setCompanyFilter} />
        <SkeletonList loading={!contracts} error={error} />
        {!error && contracts ? (
          <>
            <ContractsList filteredContracts={filteredContracts} />
            <IonButton
              className="ag-cta"
              color="primary"
              expand="block"
              onClick={() => history.push("/contracts/add")}
            >
              {t("contract.addContract")}
            </IonButton>
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};

export default ContractsPage;
