import { useSettings } from "../../../../context/SettingsContext";

const PaymentForms = () => {
  const { settings } = useSettings();

  return (
    <>
      <form
        id="mobilPayForm"
        name="mobilPayForm"
        method="POST"
        action={
          settings.env === "production"
            ? "https://secure.mobilpay.ro"
            : "http://sandboxsecure.mobilpay.ro"
        }
      ></form>
      <form
        id="payuForm"
        name="payuForm"
        method="post"
        action={
          settings.env === "production"
            ? "https://secure.payu.ro/order/lu.php"
            : "https://sandbox.payu.ro/order/lu.php"
        }
      ></form>
      <form
        id="euplatescForm"
        name="euplatescForm"
        method="post"
        action={"https://secure.euplatesc.ro/tdsprocess/tranzactd.php"}
      ></form>
    </>
  );
};
export default PaymentForms;
