import React from "react";
import { useTranslation } from "react-i18next";

import { IonLabel, IonList } from "@ionic/react";

import type { InvoiceService } from "../../../types/responseTypes";

import InvoiceDetailsServicesListItem from "./InvoiceDetailsServicesListItem";

interface InvoiceDetailsServicesListProps {
  details: InvoiceService[];
}

const InvoiceDetailsServicesList: React.FC<InvoiceDetailsServicesListProps> = ({
  details
}) => {
  const { t } = useTranslation();

  return (
    <>
      {details?.length > 0 && (
        <div className="card-widget">
          <IonLabel className="ag-h2">{t("invoice.details.title")}</IonLabel>
          <IonList lines="full">
            {details?.map((service, idx) => (
              <InvoiceDetailsServicesListItem key={idx} service={service} />
            ))}
          </IonList>
        </div>
      )}
    </>
  );
};

export default InvoiceDetailsServicesList;
