import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonLabel, IonText } from "@ionic/react";

import { notificationActions } from "../../../api/NotificationActions";
import { updateContextActions } from "../../../api/UpdateContextActions";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import type { Notification } from "../../../types/responseTypes";
import { shortenText } from "../../../utils/globalFunctions";

import styles from "./RecentNotifications.module.scss";

interface RecentNotificationsItemProps {
  notification: Notification;
}

const RecentNotificationsItem: React.FC<RecentNotificationsItemProps> = ({
  notification
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateMenuCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);

  const notificationClickHandler = async () => {
    history.push({
      pathname: `/notification/${notification?.id}`,
      state: notification
    });
    if (!notification?.readAt) {
      await notificationActions.markNotificationsAsRead([notification?.id]);
      await updateContextActions
        .getMenuDetails()
        .then((data) => updateMenuCtx(data));
    }
  };

  return (
    <div
      className={styles.notification}
      key={notification?.id}
      onClick={notificationClickHandler}
    >
      <IonLabel
        className={`${styles.notificationTitle} ${notification?.readAt ? styles.read : ""}`}
      >
        {notification?.subject
          ? notification?.subject
          : t("notification.noSubject")}
      </IonLabel>
      <IonText
        className={`${styles.notificationBody} ${notification?.readAt ? styles.read : ""}`}
      >
        {shortenText(notification.content, 120)}
      </IonText>
      <IonText
        className={`${styles.notificationBody} ${notification?.readAt ? styles.read : ""}`}
      >
        {notification?.createdAt
          ? new Date(
              notification?.createdAt.replace(" ", "T")
            ).toLocaleDateString(langCtx)
          : "-"}
      </IonText>
    </div>
  );
};

export default RecentNotificationsItem;
