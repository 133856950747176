import React, { useRef } from "react";
import { close, informationCircle } from "ionicons/icons";

import { IonIcon } from "@ionic/react";

interface SliderItemProps {
  deleteSlide: (id: number) => void;
  id: number;
  children: React.ReactNode;
}

const SliderItem: React.FC<SliderItemProps> = ({
  deleteSlide,
  id,
  children
}) => {
  const sliderItemRef = useRef(null);
  const handleToggleAnimation = (sliderItemRef) => {
    if (!sliderItemRef.current) {
      return;
    }

    if (!sliderItemRef.current.classList.contains("fadeout")) {
      sliderItemRef.current.classList.add("fadeout");
    } else {
      sliderItemRef.current.classList.remove("fadeout");
      sliderItemRef.current = null;
    }
  };

  return (
    <div className="toast-wrapper info slider" ref={sliderItemRef}>
      <div className="toast-icon-bg">
        <IonIcon icon={informationCircle} />
      </div>
      <div className="toast-text">{children}</div>
      <div className="toast-close-btn">
        <IonIcon
          icon={close}
          onClick={(event) => {
            handleToggleAnimation(sliderItemRef);
            event.stopPropagation();
            sliderItemRef.current = event.target;
            handleToggleAnimation(sliderItemRef);
            deleteSlide(id);
          }}
        />
      </div>
    </div>
  );
};

export default SliderItem;
