import React, { useContext, useMemo, useState } from "react";
import { informationCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";
import { Link } from "react-router-dom";

import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import { ContractHeader } from "../../../components/ContractHeader";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../components/UI/BackBtn";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { InvoiceDetails } from "../../../types/responseTypes";

import InvoiceDetailsPaymentsList from "./InvoiceDetailsPaymentsList";
import InvoiceDetailsServicesList from "./InvoiceDetailsServicesList";
import InvoiceDetailsSummary from "./InvoiceDetailsSummary";

const InvoiceDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { contractsCtx } = useContext(UserContext);
  const { invoiceId } = useParams<{ invoiceId: string }>();
  const location = useLocation<{ from: string }>();

  const [from, setFrom] = useState<string>();

  if (location.state && !from) {
    setFrom(location.state.from);
  }

  const { data: invoice, error } = useFetchCaching<InvoiceDetails>(
    `/invoices/${invoiceId}/details`
  );

  const contract = useMemo(
    () =>
      contractsCtx?.filter(
        (contract) => contract?.id === invoice?.contractId
      )[0],
    [contractsCtx, invoice]
  );

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn url={from || "/invoices"} />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout layoutType={"back"} url={from ? from : "/invoices"}>
        <SkeletonList loading={!invoice} error={error} />
        {!error && invoice ? (
          <>
            <ContractHeader contract={contract} />
            <InvoiceDetailsSummary invoice={invoice} />

            {!invoice.isPdfDownloadAllowed && (
              <div className="invoice password-hint-wrapper">
                <p>
                  {t("invoice.download.disabled.t1")}{" "}
                  <Link
                    to={`/contracts/${contract?.id}`}
                    className="cursor-hover"
                  >
                    {t("invoice.download.disabled.t2")}
                  </Link>{" "}
                  {t("invoice.download.disabled.t3")}
                </p>
                <IonIcon icon={informationCircle} />
              </div>
            )}

            <InvoiceDetailsServicesList details={invoice?.details} />
            <InvoiceDetailsPaymentsList
              invoicePayments={invoice?.invoicePayments}
            />
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default InvoiceDetailsPage;
