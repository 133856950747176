import React from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../components/UI/BackBtn";
import { useWindowSize } from "../../../hooks/useWindowSize";

import ChangePasswordPageForm from "./ChangePasswordPageForm";

import styles from "./UserPages.module.scss";

const ChangePasswordPage: React.FC = () => {
  const { t } = useTranslation();

  const { layout } = useWindowSize();

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        url={"/more"}
        menuType={"profile"}
        menuSelected={"item2"}
      >
        <IonLabel data-testid={"changePass"} className={styles.pageTitle}>
          {t("profile.changePass")}
        </IonLabel>
        <ChangePasswordPageForm />
      </SidebarLayout>
    </IonPage>
  );
};

export default ChangePasswordPage;
