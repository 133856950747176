import React, { useEffect, useState } from "react";
import { informationCircle } from "ionicons/icons";

import { IonIcon } from "@ionic/react";

import styles from "./InfoBox.module.scss";

interface InfoBoxProps {
  color: "primary" | "secondary" | "tertiary" | "medium" | "warning" | "danger";
  noMargin?: boolean;
  children: React.ReactNode;
}

const InfoBox: React.FC<InfoBoxProps> = ({ color, noMargin, children }) => {
  const [cssClass, setCssClass] = useState("");

  useEffect(() => {
    if (color === "primary") {
      setCssClass(`${styles.infoBox} ${styles.primary}`);
    }
    if (color === "secondary") {
      setCssClass(`${styles.infoBox} ${styles.secondary}`);
    }
    if (color === "tertiary") {
      setCssClass(`${styles.infoBox} ${styles.tertiary}`);
    }
    if (color === "medium") {
      setCssClass(`${styles.infoBox} ${styles.medium}`);
    }
    if (color === "warning") {
      setCssClass(`${styles.infoBox} ${styles.warning}`);
    }
    if (color === "danger") {
      setCssClass(`${styles.infoBox} ${styles.danger}`);
    }
  }, [color]);

  return (
    <div className={`${cssClass} ${noMargin ? styles.noMargin : ""}`}>
      <IonIcon icon={informationCircle} />
      <div>{children}</div>
    </div>
  );
};

export default InfoBox;
