import React from "react";
import { logoApple, logoFacebook, logoGoogle } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { IonIcon } from "@ionic/react";

import type { SSOProvider } from "../../../types/otherTypes";

import styles from "./Login.module.scss";

const LoginSocialButtons: React.FC = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const isMobile = Capacitor.isNativePlatform();
  const BASE_URL = process.env.REACT_APP_DOMAIN;

  const SSOHandler = async (provider: SSOProvider) => {
    if (isMobile) {
      await Browser.open({
        url: BASE_URL + `/login/sso/${provider}?isMobile=true`
      });
    } else {
      history.push(`/login/sso/${provider}?isMobile=false`);
    }
  };

  return (
    <>
      <p className={styles.ssoTitle}>{t("login.alternative-social")}</p>
      <div className={styles.ssoLogos}>
        <IonIcon
          data-testid="facebookButton"
          icon={logoFacebook}
          onClick={() => SSOHandler("facebook")}
        />
        <IonIcon
          data-testid="googleButton"
          icon={logoGoogle}
          onClick={() => SSOHandler("google")}
        />
        <IonIcon
          data-testid="appleButton"
          icon={logoApple}
          onClick={() => SSOHandler("apple")}
        />
        {/*<IonIcon icon={logoLinkedin} onClick={() => SSOHandler('linkedin')} />*/}
      </div>
    </>
  );
};

export default LoginSocialButtons;
