import React from "react";
import { helpCircleOutline, mailOpenOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonIcon } from "@ionic/react";

import styles from "./PublicHelpMenu.module.scss";

const PublicHelpMenu: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  return (
    <div className={styles.publicMenu}>
      <div onClick={() => history.push("/contact")}>
        <IonIcon icon={mailOpenOutline} />
        <span>{t("contact")}</span>
      </div>
      <div onClick={() => history.push("/help")}>
        <IonIcon icon={helpCircleOutline} />
        <span>{t("help")}</span>
      </div>
    </div>
  );
};

export default PublicHelpMenu;
