import React from "react";
import moment from "moment";

import type { ReadingHistoryInfo } from "../../../types/responseTypes";

import ReadingsHistoryGroup from "./ReadingsHistoryGroup";

interface ReadingsHistoryListProps {
  filteredReadings: ReadingHistoryInfo[];
}

const ReadingsHistoryList: React.FC<ReadingsHistoryListProps> = ({
  filteredReadings
}) => {
  const groupReadingsByDate = (readings) => {
    return readings.reduce((accum, elem) => {
      const currentMonth = moment(elem.readingDate).month();
      const currentYear = moment(elem.readingDate).year();
      const currentGroup = accum.find(
        (group) => group.month === currentMonth && group.year === currentYear
      );

      if (!currentGroup) {
        return [
          ...accum,
          { month: currentMonth, year: currentYear, readingsList: [elem] }
        ];
      } else {
        const newAccum = accum.filter(
          (group) =>
            !(group.month === currentMonth && group.year === currentYear)
        );
        return [
          ...newAccum,
          {
            month: currentMonth,
            year: currentYear,
            readingsList: [...currentGroup.readingsList, elem]
          }
        ];
      }
    }, []);
  };

  const groupedReadings = groupReadingsByDate(filteredReadings);

  return (
    <>
      {groupedReadings.map((group) => (
        <ReadingsHistoryGroup
          key={`${group.month} ${group.year}`}
          month={group.month}
          year={group.year}
          readings={group.readingsList}
        />
      ))}
    </>
  );
};

export default ReadingsHistoryList;
