import React, { useMemo } from "react";

import { useWindowSize } from "../../../hooks/useWindowSize";

import FormStep from "./FormStep";

import styles from "./FormSteps.module.scss";

type FormStepsProps = {
  steps: number;
  currentStep: number;
  setCurrentStep: (value: number) => void;
  disableNavText?: boolean;
  formStepsType: "demand" | "signature";
  scenario?: string;
};

const FormSteps: React.FC<FormStepsProps> = ({
  steps,
  currentStep,
  setCurrentStep,
  formStepsType,
  scenario
}) => {
  const stepsArray = useMemo(() => {
    const array = [];
    for (let i = 1; i <= steps; i++) {
      array.push(i);
    }
    return array;
  }, [steps]);
  const { layout } = useWindowSize();
  const labelDemand = [scenario, "GDPR", "Identificare", "Cerere", "Sumar"];

  const labelSignature = ["Semnătură", "Sumar"];

  return (
    <div className={styles.stepsWrapper}>
      {stepsArray.map((step, i) => {
        return (
          <div key={step}>
            {layout !== "phone" ? (
              <div className={styles.stepLabel}>
                <p
                  className={` ${
                    step === currentStep ? styles.activeText : ""
                  } ${currentStep + 1 <= step ? styles.activeDisabled : ""}`}
                >
                  {formStepsType === "demand"
                    ? labelDemand[i]
                    : formStepsType === "signature"
                      ? labelSignature[i]
                      : null}
                </p>
                <FormStep
                  step={step}
                  active={step === currentStep}
                  disabled={currentStep <= step}
                  setCurrentStep={setCurrentStep}
                />
              </div>
            ) : (
              <FormStep
                key={step}
                step={step}
                active={step === currentStep}
                disabled={currentStep <= step}
                setCurrentStep={setCurrentStep}
              />
            )}
          </div>
        );
      })}
    </div>
  );
};

export default FormSteps;
