import React, { useContext } from "react";
import { notificationsOutline } from "ionicons/icons";
import { useHistory } from "react-router";

import { IonAvatar, IonIcon } from "@ionic/react";

import UserContext from "../context/UserContext";

export const AqNotificationsBell: React.FC = () => {
  const history = useHistory();
  const { menuCtx } = useContext(UserContext);

  const unreadNotifs = menuCtx?.totalUnreadNotifications;

  return (
    <React.Fragment>
      <IonAvatar
        className="cursor-hover mobile-header-bell-btn"
        onClick={() => history.replace("/notifications")}
      >
        {unreadNotifs > 0 ? <div className="new-notification"></div> : ""}
        <IonIcon icon={notificationsOutline} />
      </IonAvatar>
    </React.Fragment>
  );
};
