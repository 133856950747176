/* eslint-disable @typescript-eslint/prefer-for-of */
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import UserContext from "../../context/UserContext";
import type { Contract, ReadingSummary } from "../../types/responseTypes";

import styles from "./Filters.module.scss";

const getUserCompanies = (userContracts) => {
  if (!userContracts?.length) {
    return [];
  }
  const userCompanies = [];
  for (let i = 0; i < userContracts.length; i++) {
    if (!userCompanies.includes(userContracts[i].companyId)) {
      userCompanies.push(userContracts[i].companyId);
    }
  }
  return userCompanies;
};

const getUserCompaniesFromDemandsList = (userDemandsList) => {
  if (!userDemandsList?.length) {
    return [];
  }
  const userCompanies = [];
  for (let i = 0; i < userDemandsList.length; i++) {
    // if (!userCompanies.includes(userDemandsList[i].company.id)) {
    //   userCompanies.push(userDemandsList[i].company.id);
    // }
    // pentru localstorage
    if (!userCompanies.includes(userDemandsList[i].companyId)) {
      userCompanies.push(userDemandsList[i].companyId);
    }
  }
  return userCompanies;
};

const getCompanyFilters = (userCompanies, allCompanies) => {
  if (!userCompanies || !allCompanies) {
    return [];
  }
  const filters = [];
  for (let i = 0; i < userCompanies.length; i++) {
    for (let j = 0; j < allCompanies.length; j++) {
      if (userCompanies[i] === allCompanies[j].id) {
        filters.push({
          identifier: allCompanies[j].id,
          displayName: allCompanies[j].shortName
        });
      }
    }
  }
  return filters;
};

type mergedReadingsContract = Contract & ReadingSummary;

interface CompanyFilterProps {
  onChangeCompanyFilter?: (value: number) => void;
  type?: "invoices" | "readings" | "demands";
  readingContracts?: mergedReadingsContract[];
  demands?: any;
}

const CompanyFilter: React.FC<CompanyFilterProps> = ({
  onChangeCompanyFilter,
  type,
  readingContracts,
  demands
}) => {
  const { t } = useTranslation();
  const { companiesCtx, contractsCtx } = useContext(UserContext);

  const [filteredCompany, setFilteredCompany] = useState(0);

  let userCompanies;
  if (type === "readings") {
    userCompanies = getUserCompanies(readingContracts);
  } else if (type === "demands") {
    userCompanies = getUserCompaniesFromDemandsList(demands);
  } else {
    userCompanies = getUserCompanies(contractsCtx);
  }

  const companyFilters = getCompanyFilters(userCompanies, companiesCtx);

  const companyFilterHandler = (value) => {
    setFilteredCompany(value);
    onChangeCompanyFilter(value);
  };

  return (
    <div className={styles.wrapper}>
      {companyFilters.length > 0 && (
        <>
          <div className={styles.list}>
            <span
              key={0}
              className={`${styles.item} ${
                filteredCompany === 0 && styles.active
              }`}
              onClick={() => companyFilterHandler(0)}
            >
              {t("filter.allCompanies")}
            </span>
            {companyFilters.map((company) => (
              <span
                key={company.identifier}
                className={`${styles.item} ${
                  filteredCompany === company.identifier && styles.active
                }`}
                onClick={() => companyFilterHandler(company.identifier)}
              >
                {company.displayName}
              </span>
            ))}
          </div>
        </>
      )}
    </div>
  );
};
export default CompanyFilter;
