import { useEffect } from "react";
import type React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { Capacitor } from "@capacitor/core";
import type {
  ActionPerformed,
  PushNotificationSchema,
  Token
} from "@capacitor/push-notifications";
import { PushNotifications } from "@capacitor/push-notifications";

import { onMessageListener } from "../firebaseInit";
import type { ReceivedNotificationData } from "../types/schema";

import { toastAdd } from "./CustomToast/CustomToasts";

export const NotificationsListener: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();

  useEffect(() => {
    const isPushNotificationsAvailable =
      Capacitor.isPluginAvailable("PushNotifications");
    if (isPushNotificationsAvailable) {
      initFirebase();
    }
  }, []);

  const initFirebase = () => {
    PushNotifications.checkPermissions().then((res) => {
      if (res.receive !== "granted") {
        PushNotifications.requestPermissions().then((res) => {
          // Request permission to receive push notifications.

          //On Android it does not prompt for permission because you can always receive push notifications.

          //On iOS, the first time you use the function, it will prompt the user for push notification
          // permission and return granted or denied based on the user selection. On following calls it
          // will get the status of the permission without prompting again.

          if (res.receive !== "denied") {
            toastAdd(t("pushNotifications.permissionGranted"), "info");
            registerListeners();
          }
        });
      } else {
        registerListeners();
      }
    });
  };

  const registerListeners = async () => {
    await PushNotifications.register();

    await PushNotifications.addListener("registration", (token: Token) => {
      console.log("registration: ", token);
      const storedFCM = localStorage.getItem("fcm");
      if (token.value === storedFCM) return;
      localStorage.setItem("fcm", token.value);
    });

    await PushNotifications.addListener("registrationError", (error: any) => {
      console.log("Push notification registration error!", error);
    });

    await PushNotifications.addListener(
      "pushNotificationReceived",
      (data: PushNotificationSchema) => {
        console.log("pushNotificationReceived: ", data);
      }
    );

    await PushNotifications.addListener(
      "pushNotificationActionPerformed",
      (data: ActionPerformed) => {
        console.log("pushNotificationActionPerformed: ", data);

        const notificationData: ReceivedNotificationData =
          data.notification.data;
        const leftAction = notificationData.buttonLeftAction;
        const userNotificationId = notificationData.userNotificationId;
        const baseAction = userNotificationId
          ? `/notifications/${userNotificationId}`
          : "/notifications";

        if (leftAction) {
          history.push(leftAction);
        } else {
          history.push(baseAction);
        }
      }
    );
  };

  // Invoked when the browser is in the foreground
  onMessageListener()
    .then(() => {})
    .catch((err) => console.log("failed: ", err));

  return null;
};
