// using Ionic Community HTTP plugin for IOS, instead of Axios
// because Axios does not handle cookies ok for IOS

import type { HttpOptions } from "@capacitor/core";
import { CapacitorHttp } from "@capacitor/core";

const BASE_URL = process.env.REACT_APP_API_URL;

export const doMobilePost = async (url: string, data: any) => {
  const options: HttpOptions = {
    url: BASE_URL + url,
    headers: { "Content-Type": "application/json" },
    data: data,
    webFetchExtra: { credentials: "include" }
  };

  return CapacitorHttp.post(options).then((response) => {
    if (response?.status !== 200) {
      throw { response };
    } else {
      return response;
    }
  });
};

export const doMobileGet = async (url: string) => {
  const options: HttpOptions = {
    url: BASE_URL + url,
    headers: { "Content-Type": "application/json" },
    webFetchExtra: { credentials: "include" }
  };

  return CapacitorHttp.get(options).then((response) => {
    if (response?.status !== 200) {
      throw { response };
    } else {
      return response;
    }
  });
};
