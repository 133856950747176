import React from "react";
import { Link } from "react-router-dom";

const PrivacyContentEN: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <p className="help-last-update">Last modified: 5 April 2018</p>

      <ol className="help-nested-list">
        <li>
          <span className="help-strong">General Provisions</span>

          <span className="help-block">
            The Privacy Policy provides information regarding the use, storage
            and transmission/transfer of personal data by Set Mobile S.R.L., a
            limited liability company, incorporated and operating under the laws
            of Romania, having its registered office in Bucharest, str. Rovine,
            nr.3, Sc.A, Ap.21, registration number in the Trade Register
            J40/16520/2003, unique identification code RO15967256 (hereinafter
            referred to as <span className="help-strong">"Aqmeter"</span>) of
            personal data obtained through the application having the domain
            aqmeter.com and the online application for mobile phones available
            on Google Store and Apple App Store. By using the web application
            with the domain aqmeter.com (
            <span className="help-strong">"Platform"</span>) and the application
            available on the Google Store and Apple App Store (
            <span className="help-strong">"Application"</span>), the services
            available on or through the Platform/Application (
            <span className="help-strong">"Services"</span>), you agree to the
            rules set forth in this Privacy Policy.
          </span>
          <span className="help-block">
            The Privacy Policy should be read in conjunction with and forms part
            of the platform's <Link to="/terms">Terms and Conditions</Link> and{" "}
            <Link to="/cookies">Cookies Policy</Link>. All capitalized Terms not
            otherwise defined in this Privacy Policy, unless the context
            otherwise requires, shall have the meanings set forth in the Terms
            of Use.
          </span>
        </li>

        <li>
          <span className="help-strong">Definitions. Purpose</span>
          <span className="help-block">
            The following capitalized terms shall have the meanings set forth
            below unless otherwise expressly provided:
          </span>

          <span className="definitions">
            <label>"Aqmeter App" or "App" </label>
            <span>
              means the software application developed by the Company and
              marketed by the Company under this name, through which Users can
              record water meter self-readings and make online payments for
              utility bills provided by the Utility Operator, accessible at the
              website described in the preamble and possibly for download by
              Users through the Apple App Store and Google Play.
            </span>
          </span>
          <span className="definitions">
            <label>"Company" or "Provider" </label>
            <span>
              means Set Mobile S.R.L., a limited liability company, incorporated
              and operating in accordance with the laws of Romania, having its
              registered office in Bucharest, str. Rovine, nr.3, Sc.A, Ap.21,
              registration number in the Trade Register J40/16520/2003, unique
              identification code RO15967256.
            </span>
          </span>
          <span className="definitions">
            <label>"Account" </label>
            <span>
              means the section of the Platform accessible to the User through
              the use of an e- mail address and password for identification
              purposes, which allows the User to submit water meter readings and
              pay a service bill to the Operator and which contains information
              about the User and the User's history on the Platform (e.g.,
              previous readings, tax bills, etc.).
            </span>
          </span>
          <span className="definitions">
            <label>"Customer" </label>
            <span>
              means the User/Agreed Supplier who benefits from the services of
              the Application/Platform for a fee.
            </span>
          </span>
          <span className="definitions">
            <label>"Personal Data" </label>
            <span>
              means any information relating to an identified or identifiable
              natural person; an identifiable person is one who can be
              identified, directly or indirectly, in particular by reference to
              an identification number or to one or more factors specific to his
              or her physical, physiological, mental, economic, cultural or
              social identity.
            </span>
          </span>
          <span className="definitions">
            <label>"Agreed Supplier" </label>
            <span>
              means a legal entity included in the Aqmeter Application or the
              aqmeter.com website as a result of a contract between one or more
              water suppliers and the Company.
            </span>
          </span>
          <span className="definitions">
            <label>"Services" </label>
            <span>
              means any services that are offered to the User for purchase,
              whether for a fee or free of charge via the Platform/App.
            </span>
          </span>
          <span className="definitions">
            <label>"User" </label>
            <span>
              means the natural person who accesses the Platform, registers an
              Account and uses the Applications and Services offered by the
              Platform.
            </span>
          </span>
        </li>

        <li>
          <span className="help-strong">Personal Data</span>
          <span className="help-block">
            As a result of the consent expressly granted by each User at the
            time of creating an account in the Aqmeter Application/Platform, the
            following Personal Data of the User is processed by the Company for
            the purpose of operating the Aqmeter Application/Platform:
          </span>
          <ol>
            <li>
              User information such as: first name, last name, e-mail address,
              phone number, username and password;
            </li>
            <li>
              information necessary for the User to make payment transactions,
              such as: addresses of consumption locations, billing information,
              water meter information, water index information, electronically
              uploaded telephone numbers, User's bank account information;
              <span className="help-block">and</span>
            </li>
            <li>
              information about the use of the Aqmeter Application/Platform:
              time spent by the User on certain instances of the Aqmeter
              Application/Platform, frequency of use of the Aqmeter
              Application/Platform and the Services.
            </li>
          </ol>
          <span className="help-block">
            The User may withdraw his/her consent to the processing of his/her
            Personal Data at any time. In this case, at least some or all of the
            functionalities of the Aqmeter Application/Platform will no longer
            be usable.
          </span>
        </li>

        <li>
          <span className="help-strong">Data collection and use</span>
          <span className="help-block">
            You can use the Platform without disclosing your personal data.
            However, if you wish to take advantage of our Services, you will be
            asked to provide your Data during a registration process. The
            requested data may include: full name, ID card details, telephone
            number, email address, home and/or delivery address and debit or
            credit card information, as applicable, to be used for (hereinafter
            "<span className="help-strong">Processing Purposes</span>"):
          </span>
          <ol>
            <li>
              register an Account in the dedicated section of the Aqmeter
              Platform/App;
            </li>
            <li>
              placing an Order through the Platform for the purpose of
              purchasing services ("
              <span className="help-strong">Products</span>");
            </li>
            <li>Order processing;</li>
            <li>
              to execute a Contract for the sale or purchase of one or more
              Services on the Platform, by placing an Order by the User and
              accepting it by Aqmeter, in compliance with the legal provisions
              and the terms and conditions for the online sale of Aqmeter's
              services and products, or to take action, at your request, before
              entering into a Contract;
            </li>
            <li>Transaction processing;</li>
            <li>
              processing any requests you submit via the Contact section of the
              Aqmeter Platform/App;
            </li>
            <li>
              to constantly improve the functionalities of the Aqmeter
              Application/Platform and the Services offered to Users;
            </li>
            <li>
              creating a profile for each User in order to improve the user
              experience of the Aqmeter App, to send offers, advertising and
              marketing messages.
            </li>
          </ol>
          <span className="help-block">
            Any personal data communicated during the registration process will
            be processed by Aqmeter, as personal data controller, in accordance
            with the Law for the protection of individuals with regard to the
            processing of personal data and the free movement of such data (Law
            no. 677/2001) or any other legal act supplementing, amending or
            replacing this Law.
          </span>
          <span className="help-block">
            Your data will only be used for the Processing Purposes for which it
            was originally collected. Data may only be processed for a
            legitimate interest other than the Processing Purposes if such
            legitimate interest is closely related to the Processing Purposes.
            In the event that we intend to further process the Data for purposes
            other than the Processing Purposes set out in this Privacy Policy,
            we will provide you, prior to such further processing, with relevant
            and detailed information on the intended processing.
          </span>
          <span className="help-block">
            You are obliged to provide Aqmeter with accurate and complete data.
            Failure to do so constitutes a breach of the Terms and Conditions
            and the Privacy Policy, which may result in immediate termination by
            Aqmeter of (a) your Platform/Application Account or (b) the
            provision of any Services ordered. In addition, you expressly agree
            to keep your registration information up to date and ensure that it
            is accurate and complete at all times.
          </span>
          <span className="help-block">
            It is our policy that such Data will not be disclosed, transmitted,
            sold or rented to third parties other than as expressly provided in
            this Privacy Policy.
          </span>
          <span className="help-block">
            If a User does not wish to receive notifications and information of
            a commercial nature via the Aqmeter Application/Platform or by email
            in connection with the Aqmeter Application/Platform, he/she may
            unsubscribe by clicking on the "Unsubscribe" or "Unsubscribe" link
            present in any message sent by the Company to the User.
          </span>
        </li>

        <li>
          <span className="help-strong">User account registration</span>

          <span className="help-block">
            Placing an Order through the Platform is done by registering an
            Account in the dedicated section of the Platform, which will contain
            information about the Customer and the history of the Customer's
            activity on the Platform (e.g., previous Orders, tax invoices,
            etc.). The registration of the Account is done by using, as
            identification, an e-mail address and a password, full name,
            telephone number, home address. While we make every effort to
            protect your personal information, you are solely responsible for
            maintaining the confidentiality of any passwords or other Account
            information.
          </span>
          <span className="help-block">
            Refusal to complete the registration process by failing to provide
            all requested, accurate and complete Data will prevent the
            completion of the Account registration process and access to your
            activity history on the Platform.
          </span>
          <span className="help-block">
            We also offer the possibility to register on the Aqmeter Platform
            via Facebook or Google social media. If you choose one of these
            options, you will be redirected to a page administered by Facebook
            or Google, where you will be informed about what data will be
            transferred to Aqmeter. For more details, you can check
            <Link
              to="https://www.facebook.com/privacy/policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Facebook{" "}
            </Link>
            or
            <Link
              to="https://policies.google.com/privacy"
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              Google
            </Link>
            's own Privacy Policies.
          </span>
        </li>

        <li>
          <span className="help-strong">
            Product/Service Delivery and Transaction Processing
          </span>

          <span className="help-block">
            Placing an Order via the Platform is done by using, for
            identification purposes, an e-mail address, full name,
            identification data from the ID card, telephone number, home address
            and/or delivery address. If you opt to pay for the Services online,
            Aqmeter may request Debit or Credit Card information and may ask you
            to enter such information on the Platform solely for the purpose of
            processing Transactions (the payment transaction made by the
            Customer, i.e. the collection by Aqmeter as the Provider, of a sum
            of money as a result of the sale by Aqmeter, i.e. the purchase by
            the Customer, of one or more Services).
          </span>
          <span className="help-block">
            Refusal to complete the registration process by failing to provide
            all requested, accurate and complete Data will prevent the
            completion of the Order placement process or, as the case may be,
            the processing of Transactions (making payments), resulting in
            Aqmeter ceasing to supply any Products ordered.
          </span>
          <span className="help-block">
            Relationships arising between Aqmeter and each Client using the
            Platform for, on the occasion of or after placing an Order are
            subject to the Terms and Conditions of Use and this Privacy Policy.
          </span>
        </li>

        <li>
          <span className="help-strong">
            Use of Cookies and Usage Statistics via Google Analytics
          </span>

          <span className="help-block">
            We use cookies on our Platform to provide you with a faster browsing
            experience for statistical purposes and to improve the Platform. The
            use of a cookie is in no way linked to personally identifiable
            information during a visit to the Platform.
          </span>
          <span className="help-block">
            We also use Google Analytics to analyze information collected from
            visitors to the Platform. This information allows Aqmeter to better
            understand the users of the Platform and how it is used. No
            personally identifiable information is included in this type of
            reporting.
          </span>
          <span className="help-block">
            We do not use cookies to collect Personal Data or any technology
            that links information collected through cookies to Platform User
            Data.
          </span>
          <span className="help-block">
            For detailed information on the use of cookies and usage statistics
            through Google Analytics on the Platform, as well as how you can opt
            out of certain types of processing, disable the storage of cookies,
            or remove cookies that have been registered, please visit the{" "}
            <Link to="/cookies" target="_blank">
              Cookie Policy
            </Link>{" "}
            .
          </span>
        </li>

        <li>
          <span className="help-strong">Links</span>

          <span className="help-block">
            From time to time, the Platform/Application may contain links to
            other sites or resources on the Internet. Please note that these
            other sites or resources are not controlled by Aqmeter and you
            acknowledge and agree that Aqmeter is in no way responsible or
            liable, directly or indirectly, for the privacy practices or actions
            of these sites or their operators.
          </span>
          <span className="help-block">
            The inclusion of any such link does not imply endorsement by Aqmeter
            and no association with its operators. We encourage you to exercise
            caution when leaving the Platform and to read the privacy statements
            of each site that collects Personal Data. This Privacy Policy
            applies only to information collected and processed by Aqmeter for
            the stated Processing Purposes.
          </span>
        </li>

        <li>
          <span className="help-strong">Recipients and Data Transfer</span>

          <span className="help-block">
            Aqmeter may transfer your Data, by disclosing or granting remote
            access rights, only through secure applications, to third parties,
            such as affiliated entities and other business partners of Aqmeter,
            acting as processors, processing personal data for and on behalf of
            Aqmeter (e.g., cloud server storage of Data, legal and financial
            consultants, technical service providers or shipping support
            providers), with whom Aqmeter has entered into the necessary
            contractual agreements in accordance with EU and national
            regulations.
          </span>
          <span className="help-block">
            We will transfer Data to third parties only to the extent necessary
            to fulfill the applicable Processing Purposes for which your Data is
            collected and processed.
          </span>
          <span className="help-block">
            Personal Data collected and processed for the Processing Purposes
            described in this Privacy Policy may be stored on servers located
            abroad or transferred to affiliated entities based outside the
            territory of the European Union. In the event of transfer of Data to
            third countries, Aqmeter will inform you of the intention of
            transfer as well as the third countries concerned, the purpose of
            the transfer and the request for consent, where such consent is
            required under applicable legal provisions.
          </span>
          <span className="help-block">
            Aqmeter may disclose the Data to comply with legal requirements or
            in response to a request from a court or other public authority.
            Aqmeter may also disclose the Information if it believes in good
            faith that it is necessary to prevent or deter fraud or limit its
            consequences, to protect Aqmeter and its employees, or to comply
            with the Terms of Use.
          </span>
        </li>

        <li>
          <span className="help-strong">Data Storage Duration</span>

          <span className="help-block">
            Personal data collected and used for the provision of the Services
            by Aqmeter will be stored for a period of 5 years after termination
            of the contractual relationship or any longer period required by
            law, applicable regulations or rules regarding record keeping
            obligations or requests by public authorities.
          </span>
          <span className="help-block">
            Immediately after the end of the applicable storage period, the data
            will be:
          </span>
          <ol>
            <li>
              deleted or destroyed
              <span className="help-block">or</span>
            </li>
            <li>
              anonymised; <span className="help-block">or</span>
            </li>
            <li>
              transferred to an archive (unless prohibited by applicable law or
              regulation on record retention).
            </li>
          </ol>
          <span className="help-block">
            Personal data collected and used for the creation of your Account
            will be deleted immediately if you close your account.
          </span>
          <span className="help-block">
            To ensure that Data is not kept longer than necessary, Aqmeter will
            periodically review the Data and, if necessary, delete it.
          </span>
        </li>

        <li>
          <span className="help-strong">Security</span>

          <span className="help-block">
            As part of the administration of the Platform, we have taken
            technical and organisational measures to ensure a level of security
            appropriate to the risks involved in processing the Data, in
            particular through misuse, accidental destruction, illegal or
            unauthorised access, loss, alteration, disclosure, intentional or
            accidental manipulation, third-party access, deletion and
            modification. To this end, we have developed and implemented data
            security policies and other privacy practices. In addition, our
            security procedures are continually reviewed based on new
            technological developments.
          </span>
          <span className="help-block">
            For more information about our security practices, please fill out
            the contact form in the Contact section of the Platform.
          </span>
          <span className="help-block">
            You will be notified of a data breach within a reasonable period of
            time after the discovery of such a breach, unless an authorized
            public body determines that notification would impede a criminal
            investigation or harm national security. In such case, notification
            will be delayed as directed by such body. We will respond promptly
            to your questions regarding such a data breach.
          </span>
        </li>

        <li>
          <span className="help-strong">User Rights</span>

          <span className="help-block">
            You have the right to access and rectify your Data, as well as the
            right to transfer and request their deletion (if the processing is
            carried out on the basis of consent and there is no other legal
            basis for the processing, under the conditions laid down by the
            applicable legal regulations).
          </span>
          <span className="help-block">
            We will communicate any rectification or erasure of Personal Data or
            restriction of processing carried out in accordance with applicable
            law to each recipient to whom the Data has been disclosed, unless
            this proves impossible or involves disproportionate efforts.
          </span>
          <span className="help-block">
            Where Aqmeter has made Personal Data public, we will take reasonable
            steps, taking into account available technology and the cost of
            implementation, to inform the controllers processing the Personal
            Data of your request to remove any links or to copy or replicate
            that Data. In any event, you will receive prior notice and a request
            for consent (unless such processing is necessary to fulfill a legal
            obligation) of Aqmeter's intention to make any of your Personal Data
            public.
          </span>
          <span className="help-block">
            You may contact us if you believe that your Personal Data has been
            provided without consent by sending a written and dated request
            accompanied by proof of your identity to Aqmeter at the contact
            details in the <span className="help-strong">Contact Details</span>{" "}
            section.
          </span>
          <span className="help-block">
            In addition to the above, you have the right not to be subject to a
            decision based solely on automated processing of personal data,
            including the collection of information, which produces legal
            effects or significantly affects you in a similar way, unless it is
            necessary for the conclusion or performance of a Contract between
            you and Aqmeter.
          </span>
        </li>

        <li>
          <span className="help-strong">Amendment of the Privacy Policy</span>

          <span className="help-block">
            Aqmeter reserves the right to change the Privacy Policy at any time
            in its sole discretion. Any changes will be posted on the Platform
            so that you will always have access to the information we collect,
            how we use it, and under what circumstances we disclose it, if any.
            Any such change, material or otherwise, will be effective
            immediately upon posting on the Platform.
          </span>
          <span className="help-block">
            If at any time we decide to use Personal Data for a purpose other
            than that for which it was collected, we will inform you in advance
            by e-mail. You will have the choice whether we can use your data for
            the new intended purposes. Otherwise, if possible, we will use the
            Data in accordance with the Privacy Policy in force at the time of
            collection of the Data.
          </span>
        </li>

        <li>
          <span className="help-strong">Contact Details</span>

          <span className="help-block">
            If you have further questions about how we process personal
            information or if you wish to exercise any of your rights under this
            Privacy Policy, please send us a written and dated request,
            accompanied by proof of your identity, using the following contact
            details or the contact form in the Contact section of the Platform:{" "}
            <Link to="mailto:contact@aqmeter.ro">contact@aqmeter.ro</Link> .
          </span>
          <span className="help-block">
            Requests for access, rectification, restriction of processing or
            deletion of Data may only be made in writing and are subject to the
            applicable legal restrictions.
          </span>
          <span className="help-block">
            We will ensure that appropriate steps are taken to respond to your
            request without undue delay and, in any case, within one month of
            receipt. Information shall be provided electronically where
            possible, unless you expressly request otherwise in writing.
          </span>
        </li>
      </ol>
    </div>
  );
};

export default PrivacyContentEN;
