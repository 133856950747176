import React, { useContext, useState } from "react";
import { eye, eyeOff } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonSpinner
} from "@ionic/react";

import { userActions } from "../../../api/UserActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import PasswordValidationTooltip from "../../../components/PasswordComponents/PasswordValidationTooltip";
import AuthContext from "../../../context/AuthContext";
import type { PasswordValidationType } from "../../../types/otherTypes";
import type { ChangePassword } from "../../../types/requestTypes";
import type { ExceptionDto } from "../../../types/schema";
import { passMinLength, regex } from "../../../utils/globalVariables";

import styles from "./UserPages.module.scss";

const ChangePasswordPageForm: React.FC = () => {
  const { t } = useTranslation();
  const { mailCtx } = useContext(AuthContext);

  const [pass1, setPass1] = useState<string>("");
  const [pass2, setPass2] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showValidationState, setShowValidationState] =
    useState<PasswordValidationType>({
      minLength: false,
      specialChar: false,
      lowerChar: false,
      upperChar: false,
      digit: false
    });

  const validatePass1 = (e) => {
    setPass1(e.target.value);
    const inputValue = e.detail.value;
    setShowValidationState({
      minLength: inputValue.length >= passMinLength,
      specialChar: regex.specialChar.test(inputValue),
      lowerChar: regex.lowerChar.test(inputValue),
      upperChar: regex.upperChar.test(inputValue),
      digit: regex.digit.test(inputValue)
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();
    setLoading(true);
    try {
      if (pass1.length < passMinLength) {
        setLoading(false);
        toastAdd(t("err.PASSWORD_MIN_8_CHARACTERS"), "error");
        return;
      }
      if (!regex.upperChar.test(pass1)) {
        setLoading(false);
        toastAdd(t("err.PASSWORD_MIN_1_UPPER"), "error");
        return;
      }
      if (!regex.lowerChar.test(pass1)) {
        setLoading(false);
        toastAdd(t("err.PASSWORD_MIN_1_AZ"), "error");
        return;
      }
      if (!regex.digit.test(pass1)) {
        setLoading(false);
        toastAdd(t("err.PASSWORD_MIN_1_09"), "error");
        return;
      }
      if (!regex.specialChar.test(pass1)) {
        setLoading(false);
        toastAdd(t("err.PASSWORD_MIN_1_SPECIAL_CHAR"), "error");
        return;
      }
      if (pass1 !== pass2) {
        setLoading(false);
        toastAdd(t("err.PASSWORD_IDENTIC_PASSWORD2"), "error");
        return;
      }

      const data: ChangePassword = {
        email: mailCtx,
        password: pass1
      };

      await userActions.changeUserPass(data);
      setLoading(false);
      toastAdd(t("profile.savedPass"), "success ");
    } catch (err: any) {
      setLoading(false);
      const ex = err.response.data as ExceptionDto;
      if (ex) {
        toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
      }
    }
  };

  return (
    <form onSubmit={submitHandler} className={styles.form} data-testid={"form"}>
      <div className={styles.passWrapper}>
        <IonLabel>{t("profile.pass")}</IonLabel>
        <IonInput
          className={styles.passInput}
          onIonChange={(e) => validatePass1(e)}
          onIonBlur={() => setShowTooltip(false)}
          onIonFocus={() => setShowTooltip(true)}
          clearOnEdit={false}
          value={pass1}
          type={showPassword ? "text" : "password"}
          inputmode="text"
          autocomplete="off"
          placeholder={t("profile.pass")}
        />
        <IonIcon
          onClick={() => setShowPassword((prevState) => !prevState)}
          icon={showPassword ? eyeOff : eye}
          className={showPassword ? styles.off : styles.on}
        />
      </div>

      <div className={styles.passWrapper}>
        <IonLabel>{t("profile.repeatPass")}</IonLabel>
        <IonInput
          className={styles.passInput}
          onIonChange={(e) => setPass2(e.target.value as string)}
          value={pass2}
          type={showPassword2 ? "text" : "password"}
          clearOnEdit={false}
          inputmode="text"
          autocomplete="off"
          placeholder={t("profile.repeatPass")}
        />
        <IonIcon
          onClick={() => setShowPassword2((prevState) => !prevState)}
          icon={showPassword2 ? eyeOff : eye}
          className={showPassword2 ? styles.off : styles.on}
        />
      </div>

      <PasswordValidationTooltip
        showTooltip={showTooltip}
        showValidationState={showValidationState}
        position={"bottom"}
      />

      <IonButton type="submit" expand="block" fill="solid" disabled={isLoading}>
        {isLoading ? (
          <>
            <IonSpinner name="circles" /> {t("saving")}
          </>
        ) : (
          t("save")
        )}
      </IonButton>

      <input type="submit" className="submit-enter" />
    </form>
  );
};

export default ChangePasswordPageForm;
