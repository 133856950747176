import type { ContractMeters, DashboardMenu } from "../types/responseTypes";

import { axiosPrivate } from "./customAxios";

class UpdateContextActions {
  async getMenuDetails(): Promise<DashboardMenu> {
    try {
      const response = await axiosPrivate.get("/dashboard/menu");
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getContractMeters(contractId): Promise<ContractMeters> {
    try {
      const response = await axiosPrivate.get(
        `/meters?contractId=${contractId}`
      );
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export const updateContextActions = new UpdateContextActions();
