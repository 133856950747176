import {
  bagAddOutline,
  banOutline,
  calendarOutline,
  cashOutline,
  documentAttachOutline,
  handLeftOutline,
  homeOutline,
  informationCircleOutline,
  mailOutline,
  speedometerOutline,
  warningOutline
} from "ionicons/icons";
import moment from "moment";

import { Browser } from "@capacitor/browser";

import type { PaymentData } from "../types/schema";

export const shortenText = (text, maxLength) => {
  let returnedText = text;
  if (returnedText.length > maxLength) {
    returnedText = returnedText.substring(0, maxLength - 1) + " ...";
  }
  return returnedText;
};

export const getNotificationIcon = (notification) => {
  let iconName;
  switch (notification.type) {
    case "BONUS":
      iconName = bagAddOutline;
      break;
    case "CUSTOM_PAYMENT_CONFIRMATION":
      iconName = cashOutline;
      break;
    case "CUSTOM_PAYMENT_FAILURE":
      iconName = warningOutline;
      break;
    case "DI_EXCEPTION":
      iconName = banOutline;
      break;
    case "DUE_INVOICE":
      iconName = calendarOutline;
      break;
    case "E_INVOICE_ACTIVATION":
      iconName = mailOutline;
      break;
    case "E_INVOICE_DEACTIVATION":
      iconName = homeOutline;
      break;
    case "INDEX_PERIOD_ENTERING":
      iconName = speedometerOutline;
      break;
    case "METER_READING":
      iconName = speedometerOutline;
      break;
    case "NEW_INVOICE":
      iconName = documentAttachOutline;
      break;
    case "PAYMENT_CONFIRMATION":
      iconName = cashOutline;
      break;
    case "PAYMENT_FAILURE":
      iconName = warningOutline;
      break;
    case "SERVICE_INTERRUPTION":
      iconName = handLeftOutline;
      break;
    case "TIP":
      iconName = informationCircleOutline;
      break;
    default:
      iconName = informationCircleOutline;
      break;
  }
  return iconName;
};

export const getValueOfSearchedUrlQueryParameter = (searchedParameterKey) => {
  const currentUrl = window.location.search.substring(1);
  const urlParametersList = currentUrl.split("&");
  for (const element of urlParametersList) {
    const parameterPair = element.split("=");
    const parameterKey = parameterPair[0];
    const parameterValue = parameterPair[1];
    if (parameterKey === searchedParameterKey) {
      return parameterValue;
    }
  }
  return null;
};

export const isContractActive = (contract) => {
  if (!contract) return;
  if (contract.endDate === null) {
    return true;
  } else {
    return moment(contract.endDate).isSameOrAfter(moment());
  }
};

export const extractListFilters = (contractsList) => {
  if (!contractsList) {
    return { companies: [] };
  }
  return contractsList.reduce(
    (accum, elem) => {
      const companies = [...accum.companies];

      if (
        !accum.companies.find(
          (company) => company.identifier === elem.company.id
        )
      ) {
        companies.push({
          identifier: elem.company.id,
          displayName: elem.company.companyShortName
        });
      }
      return { companies };
    },
    { companies: [] }
  );
};

export const extractHistoryFilters = (itemsList) => {
  if (!itemsList) {
    return { companies: [], contracts: [] };
  }
  return itemsList.reduce(
    (accum, elem) => {
      const companies = [...accum.companies];
      const contracts = [...accum.contracts];

      if (
        !accum.companies.find(
          (company) => company.identifier === elem.companyId
        )
      ) {
        companies.push({
          identifier: elem.companyId,
          displayName: elem.companyShortName
        });
      }
      if (
        !accum.contracts.find(
          (contract) => contract.identifier === elem.contractNumber
        )
      ) {
        contracts.push({
          identifier: elem.contractNumber,
          company: elem.companyId,
          displayName: `${elem.contractNumber} - ${elem.address}`
        });
      }
      return { companies, contracts };
    },
    { companies: [], contracts: [] }
  );
};

export const filterItems = (contracts, companyFilter) => {
  if (!contracts) {
    return [];
  }
  return contracts.filter((item) => {
    if (!companyFilter) {
      return item;
    }
    return item.companyId === companyFilter;
  });
};

export const filterDemands = (demands, companyFilter) => {
  if (!demands) {
    return [];
  }
  return demands.filter((item) => {
    if (!companyFilter) {
      return item;
    }
    return item.companyId === companyFilter;
  });
};

export const filterHistoryItems = (
  itemsList,
  companyFilter,
  contractFilter
) => {
  if (!itemsList) {
    return [];
  }
  return itemsList.filter((item) => {
    if (!companyFilter && !contractFilter) {
      return item;
    }
    if (!companyFilter) {
      return item.contractNumber === contractFilter;
    }
    if (!contractFilter) {
      return item.companyId === companyFilter;
    }
    return (
      item.companyId === companyFilter && item.contractNumber === contractFilter
    );
  });
};

export const validatePaymentPhone = (phone) => {
  const validation = /^(\+4)?07\d{8}$/;
  return validation.test(phone);
};

export const validatePaymentAmount = (amount) => {
  if (amount < 1) {
    return false;
  } else {
    const validation = /^\d*(\.\d{0,2})?$/;
    return validation.test(amount);
  }
};

export const cleanContractPhone = (phone) => {
  let cleanedPhoneNo = "";
  if (!phone) {
    return "";
  } else {
    for (const character of phone) {
      if (/^-?[0-9]+$/.test(character)) {
        cleanedPhoneNo += character;
      }
    }
    return cleanedPhoneNo;
  }
};

export const openPaymentPageForMobile = async (
  domains: string,
  paymentResponse: PaymentData,
  contractId: number
) => {
  await Browser.open({
    url: `${domains}/pay/${contractId}/${encodeURIComponent(
      btoa(JSON.stringify(paymentResponse))
    )}`
  });
};

export function DataURIToBlob(dataURI: string) {
  const splitDataURI = dataURI.split(",");
  const byteString =
    splitDataURI[0].indexOf("base64") >= 0
      ? atob(splitDataURI[1])
      : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(":")[1].split(";")[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) ia[i] = byteString.charCodeAt(i);

  return new Blob([ia], { type: mimeString });
}
