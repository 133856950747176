import React from "react";
import { useTranslation } from "react-i18next";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from "../../components/UI/BackBtn";
import { useWindowSize } from "../../hooks/useWindowSize";
import ImageCardLayout from "../../layout/ImageCardLayout/ImageCardLayout";

const EmailIsValidPage: React.FC = () => {
  const { layout } = useWindowSize();
  const { t } = useTranslation();

  return (
    <IonPage className={`layout--${layout}`}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <BackBtn />
          </IonButtons>
          <IonButtons slot={"end"}>
            <LanguageSwitcher />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ImageCardLayout
        title={t("validation.title")}
        imgUrl={"./assets/img/404.jpg"}
        logo={true}
      >
        <p>{t("validation.desc")}</p>
      </ImageCardLayout>
    </IonPage>
  );
};

export default EmailIsValidPage;
