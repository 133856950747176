import React from "react";

import { IonContent } from "@ionic/react";

import { useWindowSize } from "../../hooks/useWindowSize";
import type {
  SecondaryMenuItemsType,
  SecondaryMenuType
} from "../../types/otherTypes";
import BackBtn from "../UI/BackBtn";
import Card from "../UI/Card";

import SecondaryMenu from "./SecondaryMenu/SecondaryMenu";

import styles from "./SidebarLayout.module.scss";

interface SidebarLayoutProps {
  className?: string;
  layoutType: "submenu" | "back";
  menuType?: SecondaryMenuType;
  menuSelected?: SecondaryMenuItemsType;
  url?: string;
  children: React.ReactNode;
}

const SidebarLayout: React.FC<SidebarLayoutProps> = ({
  className,
  layoutType,
  menuType,
  menuSelected,
  url,
  children
}) => {
  const { layout } = useWindowSize();

  return (
    <IonContent className={`${className ? className : ""}`} fullscreen>
      {layout === "phone" ? (
        <section className={styles.wrapperPhone}>
          {layoutType === "submenu" ? (
            <SecondaryMenu type={menuType} selected={menuSelected} />
          ) : (
            ""
          )}
          {children}
        </section>
      ) : (
        <section className={styles.wrapper}>
          {/*tablet or desktop*/}
          <aside>
            {/*daca layoutType e submenu, se afiseaza meniul secundar in stanga*/}
            {/*daca layoutType e back, se afiseaza btn inapoi in stanga  */}
            {layoutType === "submenu" ? (
              <SecondaryMenu type={menuType} selected={menuSelected} />
            ) : url ? (
              <BackBtn url={url} />
            ) : (
              <BackBtn />
            )}
          </aside>

          <Card className={styles.main}>{children}</Card>
        </section>
      )}
    </IonContent>
  );
};

export default SidebarLayout;
