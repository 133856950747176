import { App } from "@capacitor/app";
import { Capacitor } from "@capacitor/core";

import type { AppStatus, Company } from "../types/responseTypes";

import axiosBasic from "./customAxios";

class AqmeterActions {
  async getAppStatus(): Promise<AppStatus> {
    try {
      const response = await axiosBasic.get("/app/status");
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getMobileVersion() {
    try {
      if (Capacitor.isNativePlatform()) {
        const mobileAppInfo = await App.getInfo();
        return mobileAppInfo?.version;
      }
    } catch (error) {
      console.log(error);
    }
  }

  async getCompanies(): Promise<Company[]> {
    try {
      const response = await axiosBasic.get("/app/companies");
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export const aqmeterActions = new AqmeterActions();
