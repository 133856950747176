import React, { useContext, useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  IonButtons,
  IonHeader,
  IonItem,
  IonPage,
  IonSelect,
  IonSelectOption,
  IonToolbar
} from "@ionic/react";

import { updateContextActions } from "../../../api/UpdateContextActions";
import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import { ContractHeader } from "../../../components/ContractHeader";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../components/UI/BackBtn";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type {
  ContractMeters,
  Meter,
  Reading
} from "../../../types/responseTypes";

import AddReadingForm from "./AddReadingContent/AddReadingForm";
import LastReadingNotInCurrentMonth from "./AddReadingContent/LastReadingInCurrentMonth";
import MaxReadingsProtocol from "./AddReadingContent/MaxReadingsProtocol";
import MissingReadingInterval from "./AddReadingContent/MissingReadingInterval";
import OutsideReadingInterval from "./AddReadingContent/OutsideReadingInterval";
import AddReadingPastReadings from "./AddReadingPastReadings";

import styles from "./AddReadingPage.module.scss";

const compareDates = (meter, language) => {
  const currentMonth = new Date().toLocaleString(language, {
    month: "numeric"
  });
  const currentYear = new Date().toLocaleString(language, { year: "numeric" });

  const lastReadingMonth = new Date(
    meter?.readings[0]?.readingDate
  ).toLocaleString(language, { month: "numeric" });
  const lastReadingYear = new Date(
    meter?.readings[0]?.readingDate
  ).toLocaleString(language, { year: "numeric" });

  if (lastReadingMonth !== currentMonth) {
    return true;
  } else
    return lastReadingMonth === currentMonth && lastReadingYear !== currentYear;
};

const AddReadingPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { contractsCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);
  const { id: contractId } = useParams<{ id: string }>();

  const [contractMeters, setContractMeters] = useState<ContractMeters>();
  const [selectedMeter, setSelectedMeter] = useState<Meter>();
  const [isEditing, setIsEditing] = useState<Reading>();

  const { data, error } = useFetchCaching<ContractMeters>(
    `/meters?contractId=${contractId}`
  );

  const contract = useMemo(
    () => contractsCtx?.filter((contract) => contract?.id === +contractId)[0],
    [contractsCtx, contractId]
  );

  const lastReadingNotInCurrentMonth = compareDates(selectedMeter, langCtx);

  useEffect(() => {
    if (data) {
      setContractMeters(data);
    }
  }, [data]);

  useEffect(() => {
    if (contractMeters) {
      if (contractMeters?.contractMeterData.length === 1) {
        // daca e un singur contor pe contract, il pre-selecteaza
        setSelectedMeter(contractMeters?.contractMeterData[0]);
      }
    }
  }, [contractMeters]);

  useEffect(() => {
    if (contractMeters?.contractMeterData.length > 1) {
      // daca sunt cel putin 2 contoare pe contract, reface requestul catre BE
      // la fiecare schimbare a contorului selectat (din dropdown)
      updateContextActions
        .getContractMeters(contractId)
        .then((data) => setContractMeters(data));
    }
  }, [selectedMeter]);

  const selectionHandler = (e) => {
    const chosenOption = e.target.value;
    const meter = contractMeters?.contractMeterData.filter(
      (meter) => meter.meterId === chosenOption
    )[0];
    setSelectedMeter(meter);
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn url={"/readings"} />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout layoutType={"back"} url={"/readings"}>
        <SkeletonList loading={!data} error={error} />
        {!error && data && contractMeters ? (
          <>
            <ContractHeader contract={contract} />
            <AddReadingPastReadings
              selectedMeter={selectedMeter}
              isEditing={isEditing}
              setIsEditing={setIsEditing}
            />

            <IonItem className={styles.meterSelector}>
              <IonSelect
                placeholder={t("index.chooseMeter")}
                value={selectedMeter?.meterId}
                onIonChange={selectionHandler}
                okText={t("placeholder.choose")}
                cancelText={t("cancel")}
                disabled={contractMeters?.contractMeterData.length === 1}
              >
                {contractMeters?.contractMeterData.map((meter) => (
                  <IonSelectOption key={meter?.meterId} value={meter?.meterId}>
                    {`${meter?.meterSerial} - ${meter?.meterAddress}`}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonItem>

            {selectedMeter && (
              <>
                {!contract?.hasSelfReadingEnabled ? (
                  <>
                    {/* 1 - USER NOT ALLOWED TO ADD INDEX OR MISSING READING INTERVAL */}
                    <MissingReadingInterval contract={contract} />
                  </>
                ) : !contract?.inSelfReadingPeriod ? (
                  <>
                    {/* 2 - OUTSIDE READING INTERVAL */}
                    <OutsideReadingInterval contract={contract} />
                  </>
                ) : !lastReadingNotInCurrentMonth && !isEditing ? (
                  <>
                    {/* 3 - READING ALREADY SENT */}
                    <LastReadingNotInCurrentMonth
                      contract={contract}
                      selectedMeter={selectedMeter}
                    />
                  </>
                ) : !selectedMeter?.nextSelfReadingAllowed ? (
                  <>
                    {/* 4 - CRAB MAX 2 SELF READINGS PROTOCOL - NOT USED ANYMORE!!! */}
                    <MaxReadingsProtocol contract={contract} />
                  </>
                ) : (
                  <>
                    {/* 5 - USER CAN ADD OR EDIT NEW READING */}
                    <AddReadingForm
                      contract={contract}
                      selectedMeter={selectedMeter}
                      setSelectedMeter={setSelectedMeter}
                      setContractMeters={setContractMeters}
                      isEditing={isEditing}
                      setIsEditing={setIsEditing}
                    />
                  </>
                )}
              </>
            )}
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default AddReadingPage;
