import type { NotificationsPagination } from "../types/responseTypes";

import { axiosPrivate } from "./customAxios";

class NotificationActions {
  async getAllNotifications(): Promise<NotificationsPagination> {
    try {
      const response = await axiosPrivate.get("/notifications");
      return response?.data;
    } catch (error) {
      console.log(error);
    }
  }

  async markNotificationsAsRead(notificationIds: number[]) {
    try {
      const response = await axiosPrivate.post(
        "/notifications/read",
        notificationIds
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async deleteNotifications(notificationIds: number[]): Promise<void> {
    try {
      await axiosPrivate.delete("/notifications/delete", {
        data: notificationIds
      });
    } catch (error) {
      console.log(error);
    }
  }
}

export const notificationActions = new NotificationActions();
