/* eslint-disable @typescript-eslint/ban-ts-comment */
import { useCallback, useState } from "react";
import { cameraOutline } from "ionicons/icons";
import { useDropzone } from "react-dropzone";
import { useTranslation } from "react-i18next";

import { Camera, CameraResultType } from "@capacitor/camera";
import { Capacitor } from "@capacitor/core";
import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon
} from "@ionic/react";

import { userActions } from "../../../../../api/UserActions";
import ResponseSpinner from "../../../../../components/UI/ResponseSpinner";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import type { Company } from "../../../../../types/responseTypes";
import { DataURIToBlob } from "../../../../../utils/globalFunctions";
import WizardMenu from "../../WizardMenu";

import styles from "../CreateContractPage.module.scss";

const CIPage = ({ step, setStep }) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [fileType, setFileType] = useState("image/jpeg");
  const { t } = useTranslation();
  const { layout } = useWindowSize();

  const onDrop = useCallback(
    async (acceptedFiles) => {
      // Do something with the files

      acceptedFiles.forEach((file) => {
        const reader = new FileReader();

        reader.readAsDataURL(file);
        reader.onabort = () => console.log("file reading was aborted");
        reader.onerror = () => console.log("file reading has failed");

        // cea mai importantă parte

        reader.onload = async (readerEvent) => {
          setIsLoading(true);
          try {
            const blobImageWeb = DataURIToBlob(
              readerEvent.target.result as string
            );
            const data = new FormData();
            data.append("ciFile", blobImageWeb, file.name);
            const companyId: Company = JSON.parse(
              sessionStorage.getItem("selectedCompanyFromLocationObj")
            );
            data.append("companyId", `${companyId.id}`);
            const response = await userActions.uploadCiFile(data);
            if (response) {
              setSelectedFile(readerEvent.target.result);
              sessionStorage.setItem("CI_DATA", JSON.stringify(response));
              sessionStorage.setItem(
                "ciFileName",
                JSON.stringify({
                  //@ts-ignore
                  ciFileName: response?.storedFileName as string
                })
              );
            }
          } catch (e) {
            console.log(e);
          } finally {
            setIsLoading(false);
          }
        };
      });
    },
    [isLoading]
  );
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      "image/*": [".jpeg", ".jpg", ".png"]
    }
  });

  const takePictureNative = async () => {
    setIsLoading(true);
    try {
      const image = await Camera.getPhoto({
        quality: 100,
        resultType: CameraResultType.DataUrl
      });

      // image.webPath will contain a path that can be set as an image src.
      // You can access the original file using image.path, which can be
      // passed to the Filesystem API to read the raw data of the image,
      // if desired (or pass resultType: CameraResultType.Base64 to getPhoto)

      const blobImageCamera = DataURIToBlob(image.dataUrl);
      const data = new FormData();
      data.append("ciFile", blobImageCamera, `dummy.${image.format} `);
      const companyId: Company = JSON.parse(
        sessionStorage.getItem("selectedCompanyFromLocationObj")
      );
      data.append("companyId", `${companyId.id}`);

      // console.log(file)

      // const data = new FormData()
      // data.append('file', image.dataUrl)
      const response = await userActions.uploadCiFile(data);
      if (response) {
        setSelectedFile(image.dataUrl);
        sessionStorage.setItem("CI_DATA", JSON.stringify(response));

        sessionStorage.setItem(
          "ciFileName",
          //@ts-ignore
          JSON.stringify({ ciFileName: response?.storedFileName as string })
        );
      }
    } catch (err) {
      if (
        err.message === "User cancelled photos app" ||
        err.message === "No image picked"
      ) {
        return;
      }
      console.error(err);
    } finally {
      setIsLoading(false);
    }

    // Can be set to the src of an image now
  };

  function isValidWebUpload() {
    return (
      selectedFile &&
      (fileType === "image/jpeg" ||
        fileType === "image/jpg" ||
        fileType === "image/png")
    );
  }

  function accessNativeOrWebTemplateIncludingCameraIcon() {
    return Capacitor.isNativePlatform() && layout === "phone" ? (
      <div className={styles.iconImage}>
        <IonIcon
          icon={cameraOutline}
          onClick={() => {
            takePictureNative();
          }}
        />
        <IonCardContent className={styles.uploadText}>
          Încarcă fotografie
        </IonCardContent>
      </div>
    ) : (
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        {!isDragActive ? (
          <div className={styles.iconImage}>
            <IonIcon
              icon={cameraOutline}
              // onClick={(e) => {
              //     return (
              //         filePickerRef.current && filePickerRef.current.click()
              //     );
              // }}
            />
            <IonCardContent className={styles.uploadText}>
              {t("demands.cardIdentityPage.upload")}
            </IonCardContent>
          </div>
        ) : (
          <div className={styles.iconImage}>
            <IonIcon
              icon={cameraOutline}
              // onClick={(e) => {
              //     return (
              //         filePickerRef.current && filePickerRef.current.click()
              //     );
              // }}
            />
            <IonCardContent className={styles.uploadText}>
              {t("demands.cardIdentityPage.drag")}
            </IonCardContent>
          </div>
        )}
      </div>
    );
  }

  return (
    <div>
      {/*<IonLoading cssClass={"custom-loading"} isOpen={isLoading} message={"Se încarcă"} />*/}

      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={styles.title}>
            {t("demands.cardIdentityPage.title")}
          </IonCardTitle>
        </IonCardHeader>
        {isValidWebUpload() ? (
          <div>
            <div className={styles.imgWrapper}>
              <img
                src={selectedFile}
                alt=""
                className={styles.imgCI}
                onClick={() => {
                  setSelectedFile(null);
                  setFileType("image/jpeg");
                }}
              />
            </div>
            <IonCardContent
              className={`${styles.textAligned} ${styles.textCentered}`}
            >
              {t("demands.cardIdentityPage.hintDelete")}
            </IonCardContent>
          </div>
        ) : (
          <div>
            <IonCardContent className={styles.textAligned}>
              {`\xa0\xa0  ${t("demands.cardIdentityPage.description")}`}
            </IonCardContent>
            <ResponseSpinner isLoading={isLoading}>
              {accessNativeOrWebTemplateIncludingCameraIcon()}
            </ResponseSpinner>

            <IonCardContent>
              {t("demands.cardIdentityPage.hintDataType")}
            </IonCardContent>
          </div>
        )}
      </IonCard>

      <WizardMenu disabled={!selectedFile} setStep={setStep} step={step} />
    </div>
  );
};

export default CIPage;
