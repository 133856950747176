import React from "react";
import { useTranslation } from "react-i18next";

import { IonText } from "@ionic/react";

import type { Notification } from "../../../../types/responseTypes";

import NotificationsListItem from "./NotificationsListItem";

import styles from "../Notifications.module.scss";

interface NotificationsListProps {
  filteredNotifications: Notification[];
  selectAll: boolean;
  checkedItems: number[];
  checkboxesHandler: (value: number) => void;
}

const NotificationsList: React.FC<NotificationsListProps> = ({
  filteredNotifications,
  selectAll,
  checkedItems,
  checkboxesHandler
}) => {
  const { t } = useTranslation();

  return (
    <>
      {filteredNotifications?.length === 0 ? (
        <div className={`${styles.notificationCard} ${styles.empty}`}>
          <IonText>{t("notif.empty")}</IonText>
        </div>
      ) : (
        filteredNotifications.map((notification) => (
          <NotificationsListItem
            key={notification?.id}
            notification={notification}
            selectAll={selectAll}
            checkedItems={checkedItems}
            checkboxesHandler={checkboxesHandler}
          />
        ))
      )}
    </>
  );
};

export default NotificationsList;
