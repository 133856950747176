import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonContent,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../components/AqNotificationsBell";
import SkeletonList from "../../components/Skeleton/SkeletonList";
import AuthContext from "../../context/AuthContext";
import UserContext from "../../context/UserContext";
import useFetchCaching from "../../hooks/useFetchCaching";
import { useWindowSize } from "../../hooks/useWindowSize";
import type { Dashboard } from "../../types/responseTypes";

import PaymentCard from "./Content/PaymentCard";
import QuickActionsCard from "./Content/QuickActionsCard";
import InfoSlider from "./InfoSlider/InfoSlider";
import RecentNotifications from "./RecentNotifications/RecentNotifications";
import DashboardHeader from "./DashboardHeader";

import styles from "./DashboardPage.module.scss";

const DashboardPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { nameCtx } = useContext(AuthContext);
  const { menuCtx } = useContext(UserContext);

  const { data: dashboard, error } = useFetchCaching<Dashboard>("/dashboard");

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonItem lines="none" className="ion-no-padding">
              <IonLabel>
                {nameCtx ? (
                  <span className="ag-h2 black capitalize">
                    {t("hiUser", { name: nameCtx?.toLowerCase() })}
                  </span>
                ) : (
                  <span className="ag-h2 black">{t("hiNoUser")}</span>
                )}
                <br />
                {menuCtx.hasWaterContracts ? (
                  !menuCtx?.totalUnpaidInvoices ? (
                    <span className="ag-body-text gray">
                      {t("invoiceToPayCountZero")}
                    </span>
                  ) : (
                    <span className="ag-body-text gray">
                      {t("invoiceToPayCount", {
                        count: menuCtx?.totalUnpaidInvoices
                      })}
                    </span>
                  )
                ) : (
                  <span className="ag-body-text gray">
                    {t("dashboard.header.noContract")}
                  </span>
                )}
              </IonLabel>
            </IonItem>

            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <IonContent fullscreen className={styles.pageWrapper}>
        {layout !== "phone" ? (
          <DashboardHeader info={dashboard?.info} />
        ) : !error && dashboard?.info.length ? (
          <InfoSlider info={dashboard?.info} />
        ) : (
          ""
        )}

        <div className={styles.pageContent}>
          <SkeletonList loading={!dashboard} error={error} type={"circle"} />
          {!error && dashboard ? (
            <PaymentCard companyDueAmounts={dashboard?.notPaidInvoices} />
          ) : (
            ""
          )}
          <QuickActionsCard />
        </div>

        {!error && dashboard ? (
          <RecentNotifications
            recentNotifications={dashboard?.recentNotifications}
          />
        ) : (
          ""
        )}
      </IonContent>
    </IonPage>
  );
};
export default DashboardPage;
