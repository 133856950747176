import React, { useContext, useMemo } from "react";
import { timerOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";

import { IonChip, IonIcon, IonLabel } from "@ionic/react";

import InfoBox from "../../../../components/UI/InfoBox";
import UserContext from "../../../../context/UserContext";
import type { Contract } from "../../../../types/responseTypes";

import styles from "../AddReadingPage.module.scss";

interface MissingReadingIntervalProps {
  contract: Contract;
}

const MissingReadingInterval: React.FC<MissingReadingIntervalProps> = ({
  contract
}) => {
  const { t } = useTranslation();
  const { companiesCtx } = useContext(UserContext);

  const company = useMemo(
    () =>
      companiesCtx?.filter((company) => company?.id === contract?.companyId)[0],
    [companiesCtx, contract]
  );

  return (
    <>
      <IonChip outline={true} color="danger" className={styles.readingInterval}>
        <IonIcon icon={timerOutline} color="danger" />
        <IonLabel>{t("index.noPeriod")}</IonLabel>
      </IonChip>

      <InfoBox color="warning">
        {t("index.noPeriodInfo")}
        {company?.shortName}
        {t("index.noPeriodAt")}
        <Link to={`mailto: ${company?.enableReadingsCompanyEmail}`}>
          {company?.enableReadingsCompanyEmail}
        </Link>
        {" ."}
      </InfoBox>
    </>
  );
};

export default MissingReadingInterval;
