const CookiesContentEN: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <h5>Cookies and unique identification</h5>

      <p className="help-last-update">Last modified: 5 April 2018</p>

      <p className="indentation">
        You agree that Aqmeter has the right to use any comments, information or
        ideas contained in any communication you send to Set Mobile or its
        partners without any compensation, acknowledgment or payment to you for
        any purpose whatsoever, including but not limited to developing,
        producing, marketing products and services and creating or improving the
        Aqmeter App or other products and services. In order to provide you with
        the best browsing experience on the aqmeter.com website, we use an
        Internet browser feature called a "cookie". Cookies are small files that
        the browser loads onto the user's hard drive. These cookies are used to
        remember user names, passwords and preferences, to monitor site
        navigation, and to personalize pages to the visitor. You can set your
        browser to reject cookies. If you choose this option, a negative
        influence on the use of the Aqmeter platform may occur.
      </p>
      <p className="indentation">
        We use cookies on our Platform to provide you with a faster browsing
        experience for statistical purposes and to improve the Platform. The use
        of a cookie is in no way linked to personally identifiable information
        during a visit to the Platform.
      </p>
      <p className="indentation">
        We also use Google Analytics to analyze information collected from
        visitors to the Platform. This information allows Aqmeter to better
        understand the users of the Platform and how it is used. No personally
        identifiable information is included in this type of reporting.
      </p>
      <p className="indentation">
        We do not use cookies to collect Personal Data or any technology that
        links information collected through cookies to Platform User Data.
      </p>
    </div>
  );
};

export default CookiesContentEN;
