import React, { useContext, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { notificationActions } from "../../../api/NotificationActions";
import { updateContextActions } from "../../../api/UpdateContextActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import UserContext from "../../../context/UserContext";

interface ConfirmActionModalProps {
  view: "list" | "detail";
  action: "delete" | "read";
  notificationIds: number[];
  showModal: boolean;
  updateShowModal: (value: boolean) => void;
  setSuccessfulAction?: (value) => void;
}

const ConfirmActionModal: React.FC<ConfirmActionModalProps> = ({
  view,
  action,
  notificationIds,
  showModal,
  updateShowModal,
  setSuccessfulAction
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateMenuCtx } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const deleteNotificationHandler = async () => {
    setIsLoading(true);
    try {
      await notificationActions.deleteNotifications(notificationIds);
      setIsLoading(false);
      toastAdd(
        t("notificationDeleted", { count: notificationIds?.length }),
        "success"
      );
      updateShowModal(false);
      if (view === "detail") {
        updateContextActions
          .getMenuDetails()
          .then((data) => updateMenuCtx(data));
        history.replace("/notifications");
      } else {
        setSuccessfulAction((prevState) => prevState + 1);
      }
    } catch (err) {
      setIsLoading(false);
      if (err) {
        toastAdd(t([`err.${err.message}`, "err.__"]), "error");
      }
    }
  };

  const markAsReadHandler = async () => {
    setIsLoading(true);
    try {
      await notificationActions.markNotificationsAsRead(notificationIds);
      setIsLoading(false);
      toastAdd(
        t("notificationRead", { count: notificationIds?.length }),
        "success"
      );
      updateShowModal(false);
      if (view === "detail") {
        history.replace("/notifications");
      } else {
        setSuccessfulAction((prevState) => prevState + 1);
      }
    } catch (err) {
      setIsLoading(false);
      if (err) {
        toastAdd(t([`err.${err.message}`, "err.__"]), "error");
      }
    }
  };

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => updateShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>
            {action === "delete" ? t("removeNotifTitle") : t("readNotifTitle")}
          </IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => updateShowModal(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonText className="confirm-deletion">
          {action === "delete"
            ? t("removeNotifDetails", {
                count: notificationIds?.length
              })
            : t("readNotifDetails", {
                count: notificationIds?.length
              })}
        </IonText>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                fill="clear"
                color="dark"
                expand="block"
                onClick={() => updateShowModal(false)}
              >
                {t("cancel")}
              </IonButton>
            </IonCol>
            <IonCol>
              {action === "delete" ? (
                <IonButton
                  color="danger"
                  expand="block"
                  onClick={deleteNotificationHandler}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <IonSpinner name="circles" /> {t("deleting")}
                    </>
                  ) : (
                    t("delete")
                  )}
                </IonButton>
              ) : (
                <IonButton
                  color="danger"
                  expand="block"
                  onClick={markAsReadHandler}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <>
                      <IonSpinner name="circles" /> {t("marking")}
                    </>
                  ) : (
                    t("mark")
                  )}
                </IonButton>
              )}
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default ConfirmActionModal;
