import React, { useEffect, useState } from "react";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../components/UI/BackBtn";
import FormSteps from "../../../components/UI/Forms/FormSteps";
import AddContractChooseCompany from "../../../features/AddContract/AddContractChooseCompany";
import AddContractSubmit from "../../../features/AddContract/AddContractSubmit";
import AddContractVerifyDetails from "../../../features/AddContract/AddContractVerifyDetails";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type {
  Company,
  ValidateContractResponse
} from "../../../types/responseTypes";

const AddContractPage: React.FC = () => {
  const { layout } = useWindowSize();

  const [step, setStep] = useState<number>(1);
  const [selectedCompany, setSelectedCompany] = useState<Company>(null);
  const [contractNumber, setContractNumber] = useState<string>();
  const [invoiceNumber, setInvoiceNumber] = useState<string>();
  const [contractDetails, setContractDetails] =
    useState<ValidateContractResponse>(null);

  useEffect(() => {
    setContractNumber(null);
    setInvoiceNumber(null);
    setContractDetails(null);
  }, [selectedCompany]);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn url={"/contracts"} />
            </IonButtons>
            {step >= 1 && step <= 3 ? (
              <IonButtons slot="end">
                <FormSteps
                  steps={3}
                  currentStep={step}
                  setCurrentStep={setStep}
                />
              </IonButtons>
            ) : (
              ""
            )}
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout layoutType={"back"} url={"/contracts"}>
        {layout !== "phone" && step >= 1 && step <= 3 ? (
          <FormSteps steps={3} currentStep={step} setCurrentStep={setStep} />
        ) : (
          ""
        )}

        {step === 1 ? (
          <AddContractChooseCompany
            setSelectedCompany={setSelectedCompany}
            setStep={setStep}
          />
        ) : step === 2 ? (
          <AddContractVerifyDetails
            selectedCompany={selectedCompany}
            contractNumber={contractNumber}
            setContractNumber={setContractNumber}
            invoiceNumber={invoiceNumber}
            setInvoiceNumber={setInvoiceNumber}
            setContractDetails={setContractDetails}
            setStep={setStep}
          />
        ) : (
          <AddContractSubmit
            source={"contracts"}
            selectedCompany={selectedCompany}
            contractNumber={contractNumber}
            invoiceNumber={invoiceNumber}
            contractDetails={contractDetails}
            setContractDetails={setContractDetails}
          />
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default AddContractPage;
