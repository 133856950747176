import React from "react";
import { informationCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon } from "@ionic/react";

import type { PasswordValidationType } from "../../types/otherTypes";

import styles from "./PasswordValidationTooltip.module.scss";

interface PasswordValidationTooltipProps {
  showTooltip: boolean;
  showValidationState: PasswordValidationType;
  position?: "top" | "bottom";
}

const PasswordValidationTooltip: React.FC<PasswordValidationTooltipProps> = ({
  showTooltip,
  showValidationState,
  position
}) => {
  const { t } = useTranslation();

  return (
    <div
      data-testid={"tooltip"}
      className={`${styles.tooltipWrapper} ${showTooltip ? styles.show : ""} ${position === "bottom" ? styles.bottom : ""}`}
    >
      <div className={styles.tooltipContent}>
        <IonIcon icon={informationCircle} />
        <p>{t("passHint.text")}</p>
        <ul>
          <li className={showValidationState.minLength ? styles.valid : ""}>
            {t("passHint.cond1")}
          </li>
          <li className={showValidationState.lowerChar ? styles.valid : ""}>
            {t("passHint.cond2")}
          </li>
          <li className={showValidationState.upperChar ? styles.valid : ""}>
            {t("passHint.cond3")}
          </li>
          <li className={showValidationState.digit ? styles.valid : ""}>
            {t("passHint.cond4")}
          </li>
          <li className={showValidationState.specialChar ? styles.valid : ""}>
            {t("passHint.cond5")}
          </li>
        </ul>
      </div>
    </div>
  );
};

export default PasswordValidationTooltip;
