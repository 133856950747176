import { Capacitor } from "@capacitor/core";

import axiosBasic from "../api/customAxios";
import { doMobilePost } from "../api/customHttp";
import type { LoginRequest } from "../types/requestTypes";
import type { LoginResponse } from "../types/responseTypes";

const useLogin = () => {
  const isMobile = Capacitor.isNativePlatform();

  return async (data: LoginRequest): Promise<LoginResponse> => {
    let response: LoginResponse;
    if (isMobile) {
      const mobileResponse = await doMobilePost("/auth/login", data);
      response = mobileResponse?.data;
    } else {
      const webResponse = await axiosBasic.post("/auth/login", data, {
        withCredentials: true
      });
      response = webResponse?.data;
    }

    return response;
  };
};

export default useLogin;
