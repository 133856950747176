import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButton,
  IonButtons,
  IonFooter,
  IonHeader,
  IonPage,
  IonSpinner,
  IonToolbar
} from "@ionic/react";

import { userActions } from "../../../api/UserActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../components/UI/BackBtn";
import FormSteps from "../../../components/UI/Forms/FormSteps";
import AddContractChooseCompany from "../../../features/AddContract/AddContractChooseCompany";
import AddContractSubmit from "../../../features/AddContract/AddContractSubmit";
import AddContractVerifyDetails from "../../../features/AddContract/AddContractVerifyDetails";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { RegisterData } from "../../../types/requestTypes";
import type {
  Company,
  ValidateContractResponse
} from "../../../types/responseTypes";
import type { ExceptionDto } from "../../../types/schema";

import { RegisterForm } from "./RegisterForm";
import { RegisterSuccess } from "./RegisterSuccess";

export default function RegisterPage() {
  const { t } = useTranslation();
  const { layout } = useWindowSize();

  const [step, setStep] = useState<number>(0);
  const [email, setEmail] = useState<string>();
  const [password, setPassword] = useState<string>();
  const [selectedCompany, setSelectedCompany] = useState<Company>(null);
  const [contractNumber, setContractNumber] = useState<string>(null);
  const [invoiceNumber, setInvoiceNumber] = useState<string>(null);
  const [contractDetails, setContractDetails] =
    useState<ValidateContractResponse>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [validCaptcha, setValidCaptcha] = useState(false);

  useEffect(() => {
    setContractNumber(null);
    setInvoiceNumber(null);
    setContractDetails(null);
  }, [selectedCompany]);

  const finalRegisterData: RegisterData = {
    email,
    password,
    companyId: selectedCompany?.id ?? null,
    contractNumber,
    invoiceNumber
  };

  const onSkipHandler = async () => {
    setIsLoading(true);
    await userActions
      .registerAccount(finalRegisterData)
      .then(() => {
        setIsLoading(false);
        setStep(4);
      })
      .catch((err) => {
        setIsLoading(false);
        const ex: ExceptionDto = err.response.data;
        toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
      });
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <BackBtn />
            {step >= 1 && step <= 3 ? (
              <IonButtons slot="end">
                <FormSteps
                  steps={3}
                  currentStep={step}
                  setCurrentStep={setStep}
                />
              </IonButtons>
            ) : (
              ""
            )}
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout layoutType={"back"}>
        {layout !== "phone" && step >= 1 && step <= 3 ? (
          <FormSteps steps={3} currentStep={step} setCurrentStep={setStep} />
        ) : (
          ""
        )}

        {step === 0 ? (
          <RegisterForm
            email={email}
            setEmail={setEmail}
            password={password}
            setPassword={setPassword}
            setStep={setStep}
            validCaptcha={validCaptcha}
            setValidCaptcha={setValidCaptcha}
          />
        ) : step === 1 ? (
          <AddContractChooseCompany
            setSelectedCompany={setSelectedCompany}
            setStep={setStep}
          />
        ) : step === 2 ? (
          <AddContractVerifyDetails
            selectedCompany={selectedCompany}
            contractNumber={contractNumber}
            setContractNumber={setContractNumber}
            invoiceNumber={invoiceNumber}
            setInvoiceNumber={setInvoiceNumber}
            setContractDetails={setContractDetails}
            setStep={setStep}
          />
        ) : step === 3 ? (
          <AddContractSubmit
            source={"register"}
            data={finalRegisterData}
            setStep={setStep}
            selectedCompany={selectedCompany}
            contractNumber={contractNumber}
            invoiceNumber={invoiceNumber}
            contractDetails={contractDetails}
            setContractDetails={setContractDetails}
          />
        ) : (
          <RegisterSuccess />
        )}

        {layout !== "phone" && step >= 1 && step <= 3 ? (
          <IonButton
            data-testid="skip-btn"
            fill="clear"
            expand="block"
            onClick={onSkipHandler}
            disabled={isLoading}
          >
            {isLoading ? <IonSpinner name="circles" /> : ""}
            {t("login.skipFinish")}
          </IonButton>
        ) : (
          ""
        )}
      </SidebarLayout>

      {layout === "phone" && step >= 1 && step <= 3 ? (
        <IonFooter>
          <IonButton
            fill="clear"
            expand="block"
            onClick={onSkipHandler}
            disabled={isLoading}
          >
            {isLoading ? <IonSpinner name="circles" /> : ""}
            {t("login.skipFinish")}
          </IonButton>
        </IonFooter>
      ) : (
        ""
      )}
    </IonPage>
  );
}
