import type { EuplatescData } from "../../../../types/schema";

export const euplatescModule = function (form, backendResponse: EuplatescData) {
  const _form = form;
  const _backendResponse = backendResponse;

  function createFormInputs() {
    const hashableData = _backendResponse.hashableData;
    const billingDetails = _backendResponse.billingDetails;

    createHiddenInputAndAppendToForm("amount", hashableData.amount);
    createHiddenInputAndAppendToForm("curr", hashableData.curr);
    createHiddenInputAndAppendToForm("fp_hash", hashableData.fp_hash);
    createHiddenInputAndAppendToForm("invoice_id", hashableData.invoice_id);
    createHiddenInputAndAppendToForm("merch_id", hashableData.merch_id);
    createHiddenInputAndAppendToForm("nonce", hashableData.nonce);
    createHiddenInputAndAppendToForm("order_desc", hashableData.order_desc);
    createHiddenInputAndAppendToForm("timestamp", hashableData.timestamp);

    createHiddenInputAndAppendToForm("email", billingDetails.email);
    createHiddenInputAndAppendToForm("fname", billingDetails.fname);
    createHiddenInputAndAppendToForm("phone", billingDetails.phone);

    createHiddenInputAndAppendToForm(
      "ExtraData[successurl]",
      _backendResponse.successUrl
    );
    createHiddenInputAndAppendToForm(
      "ExtraData[failedurl]",
      _backendResponse.failedUrl
    );
    createHiddenInputAndAppendToForm(
      "ExtraData[orderId]",
      _backendResponse.orderId
    );
    createHiddenInputAndAppendToForm("ExtraData[ep_method]", "POST");
  }

  function createHiddenInputAndAppendToForm(name, value) {
    const formInput = document.createElement("input");
    formInput.type = "hidden";
    formInput.name = name;
    formInput.value = value;
    _form.appendChild(formInput);
  }

  return {
    submitForm() {
      _form.textContent = ""; // clear form
      createFormInputs();
      _form.submit();
    }
  };
};
