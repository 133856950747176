import React, { useContext, useEffect, useState } from "react";
import { informationCircle } from "ionicons/icons";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import validator from "validator";

import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonSpinner,
  IonTextarea
} from "@ionic/react";

import { axiosPrivate } from "../../../api/customAxios";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import AuthContext from "../../../context/AuthContext";
import { useSettings } from "../../../context/SettingsContext";
import i18n from "../../../i18n";
import type { Language } from "../../../types/otherTypes";
import type { ContactRequest } from "../../../types/requestTypes";
import type { ExceptionDto } from "../../../types/schema";

declare global {
  interface Window {
    grecaptcha: any;
  }
}

export const ContactContent: React.FC = () => {
  const { t } = useTranslation();
  const { settings } = useSettings();
  const { isLoggedIn, mailCtx, nameCtx } = useContext(AuthContext);
  const lang = i18n.resolvedLanguage as Language;

  const [email, setEmail] = useState<string>("");
  const [name, setName] = useState<string>("");
  const [message, setMessage] = useState<string>("");
  const [isLoading, setLoading] = useState(false);
  const [validCaptcha, setValidCaptcha] = useState(false);

  useEffect(() => {
    if (isLoggedIn) {
      setEmail(mailCtx);
      setName(nameCtx);
    }
  }, [mailCtx, nameCtx, isLoggedIn]);

  const onSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);

    if (!validator.isEmail(email.trim())) {
      setLoading(false);
      toastAdd(t([`err.EMAIL_NOT_VALID`, "err.__"]), "error");
      return;
    }

    const contactMessage: ContactRequest = {
      name: name,
      email: email?.trim(),
      body: message
    };

    try {
      const response = await axiosPrivate.post(`/app/contact`, contactMessage);
      setLoading(false);
      if (response.status === 200) {
        if (isLoggedIn) {
          setEmail(mailCtx);
          setName(nameCtx);
        } else {
          setEmail("");
          setName("");
        }
        setMessage("");

        window.grecaptcha.reset();
        toastAdd(t("message.sent"), "success");
      }
    } catch (err: any) {
      setLoading(false);
      const ex: ExceptionDto = err.response.data;
      toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
    }
  };

  const onChangeCaptcha = (value) => {
    if (value) {
      setValidCaptcha(true);
    } else {
      setValidCaptcha(false);
    }
  };

  return (
    <form className="contact-form" onSubmit={onSubmit}>
      <div className="password-hint-wrapper">
        <p>{t("contactForm.footer1")}</p>
        <IonIcon icon={informationCircle} />
      </div>

      <IonLabel>{t("profile.name")}</IonLabel>
      <IonInput
        id="name"
        onIonChange={(e) => setName(e.target.value as string)}
        value={name}
        type="text"
        inputmode="text"
        autocomplete="off"
        placeholder={t("fullname")}
        readonly={isLoggedIn && !!nameCtx}
      />

      <IonLabel>{t("profile.email")}</IonLabel>
      <IonInput
        id="email"
        onIonChange={(e) => setEmail(e.target.value as string)}
        value={email}
        type="text"
        inputmode="email"
        autocomplete="off"
        placeholder={t("email")}
        readonly={isLoggedIn}
      />

      <IonLabel>{t("contact.msg")}</IonLabel>
      <IonTextarea
        id="body"
        onIonChange={(e) => setMessage(e.target.value as string)}
        value={message}
        placeholder={t("yourMessage")}
      />

      <div className="contact-recaptcha">
        <ReCAPTCHA
          sitekey={settings.recaptchaSiteKey}
          theme="light"
          onChange={onChangeCaptcha}
          hl={lang}
        />
      </div>

      {!isLoading ? (
        <IonButton
          type="submit"
          expand="block"
          fill="solid"
          disabled={!validCaptcha}
        >
          {t("send")}
        </IonButton>
      ) : (
        <IonButton type="submit" expand="block" fill="solid" disabled={true}>
          <IonSpinner name="circles" /> {t("sending")}
        </IonButton>
      )}
      <input type="submit" className="submit-enter" />
    </form>
  );
};
