import React from "react";
import { chevronForward } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar
} from "@ionic/react";

import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../components/UI/BackBtn";
import { useWindowSize } from "../../../hooks/useWindowSize";

function HelpPage() {
  const { t } = useTranslation();
  const { layout } = useWindowSize();

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout className="help-page" layoutType={"back"}>
        <div className="help-page-content">
          <IonLabel className="ag-h2 black">{t("help")}</IonLabel>

          <IonList lines="full">
            <IonItem mode="md" className="cursor-hover" routerLink="/terms">
              <IonLabel className="gray">{t("termsAndConditions")}</IonLabel>
              <IonIcon className="chevron" icon={chevronForward} />
            </IonItem>

            <IonItem mode="md" className="cursor-hover" routerLink="/privacy">
              <IonLabel className="gray">{t("privacyPolicy")}</IonLabel>
              <IonIcon className="chevron" icon={chevronForward} />
            </IonItem>

            <IonItem mode="md" className="cursor-hover" routerLink="/cookies">
              <IonLabel className="gray">{t("cookiesPolicy")}</IonLabel>
              <IonIcon className="chevron" icon={chevronForward} />
            </IonItem>

            <IonItem
              mode="md"
              key="faq"
              className="cursor-hover"
              routerLink="/faq"
            >
              <IonLabel className="gray">{t("faq")}</IonLabel>
              <IonIcon className="chevron" icon={chevronForward} />
            </IonItem>
          </IonList>
        </div>

        <div className="copyright">
          <p>
            &copy;{t("copyright")}
            {new Date().getFullYear()} {t("agree.company")}
          </p>
          <p>{t("allRightsReserved")}</p>
        </div>
      </SidebarLayout>
    </IonPage>
  );
}

export default HelpPage;
