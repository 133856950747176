import React, { useContext, useMemo } from "react";
import { chevronForward, warningOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IonAvatar, IonBadge, IonIcon, IonItem, IonLabel } from "@ionic/react";

import UserContext from "../../../context/UserContext";
import type { Contract, ReadingSummary } from "../../../types/responseTypes";

interface ReadingsListItemProps {
  contract: Contract & ReadingSummary;
}

const ReadingsListItem: React.FC<ReadingsListItemProps> = ({ contract }) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { companiesCtx } = useContext(UserContext);

  const company = useMemo(
    () =>
      companiesCtx?.filter((company) => company?.id === contract?.companyId)[0],
    [companiesCtx, contract]
  );

  return (
    <IonItem
      className="card-widget-list-item cursor-hover readings-list"
      onClick={() => history.push(`/readings/${contract?.id}`)}
    >
      <IonAvatar slot="start">
        <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
      </IonAvatar>
      <IonLabel>
        <span className="ag-body-text">{company?.shortName}</span>
        <br />
        <span className="ag-number-description">
          {contract?.inSelfReadingPeriod === null ? (
            <span className="warning-color">{t("index.noPeriod")}</span>
          ) : contract?.inSelfReadingPeriod &&
            contract?.nrOfMetersAvailableForNewReading > 0 ? (
            <span className="danger-color">
              {t("index.reading")}
              {contract.monthlyReadingPeriod.startDate} -{" "}
              {contract.monthlyReadingPeriod.endDate}
              {t("index.ofMonth")}
            </span>
          ) : (
            <span>
              {t("index.reading")}
              {contract.monthlyReadingPeriod.startDate} -{" "}
              {contract.monthlyReadingPeriod.endDate}
              {t("index.ofMonth")}
            </span>
          )}
        </span>
        <br />
        <span className="ag-number-description gray capitalize">
          {contract?.name.toLowerCase()}
        </span>
        <br />
        <span className="ag-number-description gray index-address">
          {contract?.deliveryAddress}
        </span>
        <br />
        <span className="ag-number-description gray">
          {t("tabs.contractNo")} {contract?.number}
        </span>
      </IonLabel>

      <IonIcon className="chevron" icon={chevronForward} />

      <div className="readings-list-icon-details">
        {contract?.nrOfMetersAvailableForNewReading > 0 && (
          <IonBadge color="danger">
            {contract?.nrOfMetersAvailableForNewReading}
          </IonBadge>
        )}
        {contract?.inSelfReadingPeriod === null && (
          <IonIcon icon={warningOutline} color="warning" />
        )}
      </div>
    </IonItem>
  );
};

export default ReadingsListItem;
