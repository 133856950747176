import React, { useContext } from "react";
import { warningOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon } from "@ionic/react";

import AuthContext from "../../../context/AuthContext";
import type { Contract } from "../../../types/responseTypes";
import { isContractActive } from "../../../utils/globalFunctions";

import styles from "./EditContractPage.module.scss";

interface EditContractDetailsProps {
  contract: Contract;
}

const EditContractDetails: React.FC<EditContractDetailsProps> = ({
  contract
}) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  const isActive = isContractActive(contract);

  return (
    <div className={styles.contractDetails}>
      {!isActive ? (
        <div className={styles.isInactive}>
          <p>{t("contract.inactive")}</p>
          <IonIcon icon={warningOutline} color="warning" />
        </div>
      ) : (
        ""
      )}
      <p>{t("contract.name")}</p>
      <p>{contract?.name.toLowerCase()}</p>
      <p>{t("contract.no")}</p>
      <p>{contract?.number}</p>
      <p>{t("contract.deliveryAddress")}</p>
      <p>{contract?.deliveryAddress}</p>
      <p>{t("contract.homeAddress")}</p>
      <p>{contract?.homeAddress}</p>
      {!isActive ? (
        <>
          <p>{t("contract.endDate")}</p>
          <p>{new Date(contract?.endDate).toLocaleDateString(langCtx)}</p>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default EditContractDetails;
