import { useEffect, useState } from "react";
import { alertCircleOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle,
  IonIcon,
  IonToggle
} from "@ionic/react";

import FormFeedback from "../../../../../components/UI/FormFeedback";
import WizardMenu from "../../WizardMenu";

import Address from "./RequestFormParts/Address";
import CiData from "./RequestFormParts/CiData";
import Contact from "./RequestFormParts/Contact";
import StandardInput from "./StandardInput";

import styles from "../CreateContractPage.module.scss";

interface RequestForms {
  step: number;

  setStep(step: number): void;
}

interface DataCiType {
  series: string;
  number: number;
  issueDate: any;
  cnp: number;
}

type AddressType = {
  county: string;
  city: string;
  street: string;
  number: number;
  building: any;
  entrance: any;
  floor: any;
  ap: any;
  optionalAddress?: string;
};

interface ContactType {
  email: string;
  phone: string;
}

const RequestFormPage: React.FC<RequestForms> = ({ step, setStep }) => {
  const { t } = useTranslation();

  const [firstName, setFirstName] = useState<string>("");
  const [storedFileName, setStoredFileName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [ciData, setCiData] = useState<DataCiType>({
    series: null,
    number: null,
    issueDate: null,
    cnp: null
  });

  const enableSubmit = () => {
    return !!(
      ciData.series &&
      ciData.number &&
      ciData.issueDate &&
      ciData.cnp &&
      address.county &&
      // address.city &&
      // address.street &&
      // address.number &&
      firstName &&
      lastName
    );
  };

  const [address, setAddress] = useState<AddressType>({
    county: null,
    city: null,
    street: null,
    number: null,
    building: null,
    entrance: null,
    floor: null,
    ap: null
    // optionalAddress: null,
  });

  const [contact, setContact] = useState<ContactType>({
    email: null,
    phone: null
  });

  const [toggleAddress, setToggleAddress] = useState(true);
  const [toggleContact, setToggleContact] = useState(true);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const history = useHistory();

  const onSubmit = (e) => {
    setIsLoading(true);
    e.preventDefault();
    if (enableSubmit()) {
      history.push({
        pathname: `/demands/signature`,
        state: { ciData, address, contact, firstName, lastName }
      });
    } else {
      setErrorMsg(t("demands.requestFormPage.uncompletedField"));
    }
    setIsLoading(false);
  };

  // const submitHandler = async (e) => {
  //   e.preventDefault();
  //   setIsLoading(true);
  //   try {
  //     const data: AddContract = {
  //       companyId: selectedCompany?.id,
  //       contractNumber: contractNumber,
  //       invoiceNumber: invoiceNumber,
  //     };
  //     let response = await contractActions.validate(data);
  //     if (!response.valid) {
  //       setErrorMsg(t("err.NO_CONTRACT_FOUND"));
  //     } else {
  //       setContractDetails(response);
  //       if (setStep) {
  //         setStep(prevState => prevState + 1);
  //       }
  //     }
  //   } catch (err) {
  //     const ex: ExceptionDto = err.response.data;
  //     setErrorMsg(t([`err.${ex.message}`, "err.__"]));
  //   } finally {
  //     {
  //       setIsLoading(false);
  //     }
  //   }
  // };

  // useEffect(() => {
  //   setErrorMsg(null);
  // }, [contractNumber, invoiceNumber]);

  useEffect(() => {
    const sessionStorageData = JSON.parse(sessionStorage.getItem("CI_DATA"));
    setStoredFileName(sessionStorageData.storedFileName);
    setFirstName(sessionStorageData.ocr.firstName);
    setLastName(sessionStorageData.ocr.lastName);
    setCiData((prev) => ({
      ...prev,
      series: sessionStorageData?.ocr.ciData.series
    }));
    setCiData((prev) => ({
      ...prev,
      number: sessionStorageData?.ocr.ciData.number
    }));
    setCiData((prev) => ({ ...prev, cnp: sessionStorageData?.ocr.ciData.cnp }));
    setCiData((prev) => ({
      ...prev,
      issueDate: sessionStorageData?.ocr.ciData.issueDate
    }));
    setAddress((prev) => ({
      ...prev,
      county: sessionStorageData?.ocr.address.county
    }));
    setAddress((prev) => ({
      ...prev,
      city: sessionStorageData?.ocr.address.city
    }));
    setAddress((prev) => ({
      ...prev,
      street: sessionStorageData?.ocr.address.street
    }));
    setAddress((prev) => ({
      ...prev,
      number: sessionStorageData?.ocr.address.number
    }));
    setAddress((prev) => ({
      ...prev,
      entrance: sessionStorageData?.ocr.address.entrance
    }));
    setAddress((prev) => ({
      ...prev,
      building: sessionStorageData?.ocr.address.building
    }));
    setAddress((prev) => ({
      ...prev,
      floor: sessionStorageData?.ocr.address.floor
    }));
    setAddress((prev) => ({ ...prev, ap: sessionStorageData?.ocr.address.ap }));
    // setContact((prev) => ({ ...prev, email: localStorageData?.contact.email }));
    // setContact((prev) => ({ ...prev, phone: localStorageData?.contact.phone }));
  }, []);

  useEffect(() => {
    sessionStorage.setItem(
      "CI_DATA",
      JSON.stringify({
        ocr: { address: { ...address }, ciData, contact, firstName, lastName },
        storedFileName
      })
    );
    setErrorMsg(null);
  }, [
    firstName,
    lastName,
    ciData.series,
    ciData.number,
    ciData.cnp,
    ciData.issueDate,
    address.county
    // address.city,
    // address.street,
    // address.number,
    // address.entrance,
    // address.ap,
    // address.building,
    // contact.email,
    // contact.phone,
  ]);

  // console.log(nameSurname);

  return (
    <form onSubmit={onSubmit}>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={styles.title}>
            {t("demands.requestFormPage.title")}
          </IonCardTitle>
          <IonCardSubtitle className={styles.title}>
            {t("demands.requestFormPage.subtitle")}
          </IonCardSubtitle>
        </IonCardHeader>

        <IonCardContent>
          <IonCardTitle className={styles.header}>
            {`\xa0\xa0\xa0I. ${t("demands.requestFormPage.personalDataLabel")}`}
          </IonCardTitle>
        </IonCardContent>
        {/* <ToolTipRequestInputs
          showTooltip={showTooltip}
          showValidationState={showValidationState}
          position={"bottom"}
        /> */}

        <div className={styles.spacedInputs}>
          <StandardInput
            inputValue={firstName}
            setInputValue={(event) => setFirstName(event.target.value)}
            labelName={t("demands.requestFormPage.firstName")}
            errorMsg={errorMsg}
          />
          <StandardInput
            inputValue={lastName}
            setInputValue={(event) => setLastName(event.target.value)}
            labelName={t("demands.requestFormPage.lastName")}
            errorMsg={errorMsg}
          />
        </div>

        <div className={styles.surname}>
          <IonCardContent>
            <IonCardSubtitle
              className={styles.subHeader}
            >{`\xa0\xa0 ${t("demands.requestFormPage.ciDataLabel")}`}</IonCardSubtitle>
          </IonCardContent>
          <CiData setCiData={setCiData} ciData={ciData} errorMsg={errorMsg} />
        </div>

        <IonCardContent>
          <IonCardSubtitle
            className={styles.subHeader}
          >{`\xa0\xa0 ${t("demands.requestFormPage.addressLabel")}`}</IonCardSubtitle>
        </IonCardContent>
        {/*<Address*/}
        {/*  setAddress={setAddress}*/}
        {/*  address={address}*/}
        {/*  errorMsg={errorMsg}*/}
        {/*/>*/}
        {/*Adresa dintr-o bucată*/}

        <StandardInput
          inputValue={address?.county}
          setInputValue={(event) =>
            setAddress((prev) => ({
              ...prev,
              county: event.target.value as string
            }))
          }
          labelName={t("demands.requestFormPage.addressOptionalName")}
          className={styles.largeInput}
          errorMsg={errorMsg}
        />

        {/* Adresa optionala */}
        <div className={styles.address}>
          <IonCardContent className={`${styles.requestText}`}>
            {t("demands.requestFormPage.mailingAddressElaborate")}
          </IonCardContent>

          <div className={styles.toggleWrapper}>
            <IonCardContent
              className={`${styles.requestText} ${
                !toggleAddress ? styles.approvedText : null
              }`}
            >
              {t("demands.requestFormPage.mailingAddressToggle.no")}
            </IonCardContent>
            <IonToggle
              className={styles.toggle}
              checked={toggleAddress}
              slot="start"
              color="tertiary"
              onClick={() => setToggleAddress((prev) => !prev)}
            />
            <IonCardContent
              className={`${styles.requestText} ${
                toggleAddress ? styles.approvedText : null
              }`}
            >
              {t("demands.requestFormPage.mailingAddressToggle.yes")}
            </IonCardContent>
          </div>
          {!toggleAddress ? <Address setAddress={setAddress} /> : null}
        </div>
        <IonCardContent>
          <IonCardSubtitle
            className={styles.subHeader}
          >{`\xa0\xa0 ${t("demands.requestFormPage.contact.label")}`}</IonCardSubtitle>
        </IonCardContent>
        <div>
          <Contact setContact={setContact} contact={contact} />

          <div>
            <IonCardContent className={`${styles.requestText}`}>
              {t("demands.requestFormPage.contact.optionsLabel")}
            </IonCardContent>

            <div className={styles.toggleWrapper}>
              <IonCardContent
                className={`${styles.requestText} ${
                  !toggleContact ? styles.approvedText : null
                }`}
              >
                {t("demands.requestFormPage.contact.toggleOps.email")}
              </IonCardContent>
              <IonToggle
                className={styles.toggle}
                checked={toggleContact}
                slot="start"
                color="tertiary"
                onClick={() => setToggleContact((prev) => !prev)}
              />
              <IonCardContent
                className={`${styles.requestText} ${
                  toggleContact ? styles.approvedText : null
                }`}
              >
                {t("demands.requestFormPage.contact.toggleOps.phone")}
              </IonCardContent>
            </div>
          </div>
          <div>
            <IonCardContent
              className={`${styles.requestText} ${styles.textAligned}`}
            >
              {`\xa0\xa0Mă angajez ca, în maximum 30 de zile de la data anunţării, să mă
              prezint în vederea semnării şi însuşirii acestuia, în caz contrar,
              intervenind acceptarea tacită a contractului, ce rezultă cu
              certitudine din acţiunea şi intenţia mea de încheiere a
              contractului de furnizare/prestare a serviciului, cuprinse în
              prezenta cerere.`}
            </IonCardContent>
            <IonCardContent
              className={`${styles.requestText} ${styles.textAligned}`}
            >
              {`\xa0\xa0Declar pe propria răspundere că dețin/ocup în mod legal spațiul,
              că am dreptul să solicit și să închei contractul de furnizare a
              serviciului de alimentare cu apă și de canalizare la adresa mai
              sus menționată. Având în vedere dispozițiile art.326 din Codul
              penal care sancționează falsul în declarații, confirm că datele
              din cerere și documentele depuse în scopul contractării sunt
              corecte, reale și neschimbate.`}
            </IonCardContent>
            <IonCardContent
              className={`${styles.requestText} ${styles.textAligned}`}
            >
              {`\xa0\xa0Prezenta cerere înregistrată de operator ţine loc de contract de
              furnizare a serviciilor de alimentare cu apă şi de canalizare,
              până la perfectarea acestuia în conformitate cu dispoziţiile Legii
              nr. 241/2006 republicată, dispoziţiile acestui act normativ fiind
              aplicabile şi în perioada cuprinsă între înregistrarea cererii şi
              încheierea contractului.`}
            </IonCardContent>
          </div>
        </div>
      </IonCard>
      <IonCardContent>
        <IonIcon color={"danger"} icon={alertCircleOutline} /> -{" "}
        {t("demands.requestFormPage.necessaryField")}
      </IonCardContent>
      <WizardMenu
        disabled={isLoading}
        setStep={setStep}
        step={step}
        isLoading={isLoading}
        // handleStateChange={handleStateChange}
        // onSubmit={onSubmit}
      />
      {/* <IonButton type="submit"></IonButton> */}

      {errorMsg ? <FormFeedback type={"error"}>{errorMsg}</FormFeedback> : ""}
    </form>
  );
};

export default RequestFormPage;
