import React from "react";
import { useTranslation } from "react-i18next";

import { IonLabel, IonList } from "@ionic/react";

import type { InvoicePayment } from "../../../types/responseTypes";

import InvoiceDetailsPaymentsListItem from "./InvoiceDetailsPaymentsListItem";

interface InvoiceDetailsPaymentsListProps {
  invoicePayments: InvoicePayment[];
}

const InvoiceDetailsPaymentsList: React.FC<InvoiceDetailsPaymentsListProps> = ({
  invoicePayments
}) => {
  const { t } = useTranslation();

  return (
    <>
      {invoicePayments?.length > 0 && (
        <div className="card-widget top-10">
          <IonLabel className="ag-h2">{t("invoice.payments.title")}</IonLabel>
          <IonList lines="full">
            {invoicePayments?.map((payment, idx) => (
              <InvoiceDetailsPaymentsListItem key={idx} payment={payment} />
            ))}
          </IonList>
        </div>
      )}
    </>
  );
};

export default InvoiceDetailsPaymentsList;
