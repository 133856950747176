import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";
import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSpinner,
  IonText
} from "@ionic/react";

import FormFeedback from "../../../../components/UI/FormFeedback";
import InfoBox from "../../../../components/UI/InfoBox";
import type { CustomPaymentRequest } from "../../../../types/requestTypes";
import type { Contract } from "../../../../types/responseTypes";
import {
  cleanContractPhone,
  validatePaymentAmount,
  validatePaymentPhone
} from "../../../../utils/globalFunctions";

interface CustomPaymentFormProps {
  contract: Contract;
  startPayment: (val: CustomPaymentRequest) => void;
  acceptTerms: boolean;
  setAcceptTerms: (val) => void;
  setShowTermsModal: (val: boolean) => void;
  setShowPrivacyModal: (val: boolean) => void;
}

const CustomPaymentForm: React.FC<CustomPaymentFormProps> = ({
  contract,
  startPayment,
  acceptTerms,
  setAcceptTerms,
  setShowTermsModal,
  setShowPrivacyModal
}) => {
  const { t } = useTranslation();

  const [phone, setPhone] = useState("");
  const [amount, setAmount] = useState<number>();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string>(null);
  const [info, setInfo] = useState<string>(null);

  const isMobile = Capacitor.isNativePlatform();

  useEffect(() => {
    // din ERP-ul furnizorului pot sa vina date invalide!
    // se vor elimina caractere care nu sunt numerice din nr de tel existent pe contract
    // chiar daca rezultatul final va fi un nr format din mai putin de 10 cifre
    setPhone(cleanContractPhone(contract?.phone));
  }, [contract]);

  const submitHandler = async () => {
    if (!validatePaymentAmount(amount)) {
      setError(t("invoice.pay.amount.check"));
      setIsLoading(false);
      return;
    }
    if (!validatePaymentPhone(phone)) {
      setError(t("invoice.pay.phoneCheck.mobileRequired"));
      setIsLoading(false);
      return;
    }
    const customPaymentRequestObj: CustomPaymentRequest = {
      contractId: contract?.id,
      amount: amount,
      phone: phone,
      isMobile: isMobile
    };
    await startPayment(customPaymentRequestObj);
  };

  const payBtnHandler = (event) => {
    event.preventDefault();
    setIsLoading(true);

    if (!acceptTerms) {
      setInfo(t("payment.terms&conditions"));
      setIsLoading(false);
    } else {
      submitHandler().then(() => setIsLoading(false));
    }
  };

  useEffect(() => {
    if (error) {
      setError(null);
    }
    if (acceptTerms) {
      setInfo(null);
    }
  }, [amount, phone, acceptTerms]);

  return (
    <form>
      <IonGrid>
        <IonRow className="ion-align-items-center">
          <IonCol
            size="12"
            className="ion-text-left invoice-payment-phone-check"
          >
            <IonLabel>{t("invoice.pay.amount")}</IonLabel>
            <IonInput
              id="amount"
              onIonChange={(e) => setAmount(e.target.value as number)}
              value={amount}
              type="number"
              step="0.01"
              min="1"
              inputmode="numeric"
              placeholder={"1.00"}
            />
          </IonCol>
        </IonRow>
        {!contract?.phone ||
        cleanContractPhone(contract?.phone).length !== 10 ? (
          <IonRow className="ion-align-items-center">
            <IonCol
              size="12"
              className="ion-text-left invoice-payment-phone-check"
            >
              <IonText className="ion-text-left">
                {t("invoice.pay.phoneCheck.desc")}
              </IonText>
              <IonLabel>{t("invoice.pay.phoneCheck.phone")}</IonLabel>
              <IonInput
                id="phone"
                onIonChange={(e) => setPhone(e.target.value as string)}
                value={phone}
                type="tel"
                inputmode="tel"
                autocomplete="tel"
                placeholder={"07XXXXXXXX"}
              />
            </IonCol>
          </IonRow>
        ) : null}

        <IonRow>
          <IonCol className="ion-text-left">
            <IonItem lines="none" className="ion-no-padding">
              <IonCheckbox
                slot="start"
                mode="md"
                color="tertiary"
                checked={acceptTerms}
                onIonChange={() => setAcceptTerms((prevState) => !prevState)}
              />
              <label>
                {t("agree.accept")}
                <a onClick={() => setShowTermsModal(true)}>
                  {t("agree.terms")}
                </a>
                {t("agree.and")}
                <a onClick={() => setShowPrivacyModal(true)}>
                  {" "}
                  {t("agree.policy")}
                </a>
                {t("agree.company")}
              </label>
            </IonItem>
          </IonCol>
        </IonRow>

        <IonRow>
          <IonCol className="ion-text-center">
            <IonButton
              fill="solid"
              disabled={!amount || !phone || isLoading}
              onClick={payBtnHandler}
            >
              {isLoading ? (
                <>
                  <IonSpinner name="circles" />{" "}
                </>
              ) : (
                ""
              )}
              {t("invoice.pay.phoneCheck.next")}
            </IonButton>

            {error ? <FormFeedback type={"error"}>{error}</FormFeedback> : null}
            {info ? <InfoBox color={"tertiary"}>{info}</InfoBox> : null}
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="invoice-payment-logos">
            <img src="./assets/img/visa.png" alt="Logo Visa" />
            <img src="./assets/img/mastercard.png" alt="Logo Mastercard" />
          </IonCol>
        </IonRow>
      </IonGrid>
    </form>
  );
};

export default CustomPaymentForm;
