import React from "react";

import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";

import type { Company } from "../types/responseTypes";

interface CompanyHeaderProps {
  company: Company;
}

export const CompanyHeader: React.FC<CompanyHeaderProps> = ({ company }) => {
  return (
    <>
      {company ? (
        <IonGrid className="contract-header">
          <IonRow className="ion-align-items-center">
            <IonCol size="12" className="ion-text-center">
              <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size="12" className="ion-text-center">
              <IonLabel
                className="ag-h2 black multiline"
                data-testid={company?.id}
              >
                {company?.name}
              </IonLabel>
            </IonCol>
          </IonRow>
        </IonGrid>
      ) : (
        ""
      )}
    </>
  );
};
