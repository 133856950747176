import React from "react";
import { timerOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonChip, IonIcon, IonLabel } from "@ionic/react";

import InfoBox from "../../../../components/UI/InfoBox";
import type { Contract, Meter } from "../../../../types/responseTypes";

import styles from "../AddReadingPage.module.scss";

interface LastReadingNotInCurrentMonthProps {
  contract: Contract;
  selectedMeter: Meter;
}

const LastReadingNotInCurrentMonth: React.FC<
  LastReadingNotInCurrentMonthProps
> = ({ contract, selectedMeter }) => {
  const { t } = useTranslation();

  const canEditLastReading = !selectedMeter?.readings[0]?.sentToErp;

  return (
    <>
      <IonChip outline={true} color="medium" className={styles.readingInterval}>
        <IonIcon icon={timerOutline} color="medium" />
        <IonLabel>
          {t("index.readingInterval")}
          {contract?.monthlyReadingPeriod.startDate}
          {" - "}
          {contract?.monthlyReadingPeriod.endDate} {t("index.ofMonth")}
        </IonLabel>
      </IonChip>

      <InfoBox color="tertiary">
        {t("index.alreadySent")}{" "}
        {canEditLastReading
          ? t("index.alreadySent.canEdit")
          : t("index.alreadySent.cannotEdit")}
      </InfoBox>
    </>
  );
};

export default LastReadingNotInCurrentMonth;
