import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import DownloadPdfBtn from "../../../components/DownloadPdfBtn";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import type { PaidInvoiceInfo } from "../../../types/responseTypes";

import styles from "./PaidInvoicesListGroupItem.module.scss";

interface PaidInvoicesListGroupItemProps {
  className: string;
  invoice: PaidInvoiceInfo;
}

const PaidInvoicesListGroupItem: React.FC<PaidInvoicesListGroupItemProps> = ({
  className,
  invoice
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companiesCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);
  const location = useLocation().pathname;

  const company = companiesCtx?.filter(
    (company) => company?.id === invoice?.companyId
  )[0];

  return (
    <div className={`${styles.invoice} ${className}`}>
      <div className={styles.info}>
        <img alt={`${invoice?.companyShortName} logo`} src={company?.logoUrl} />
        <div className={styles.details}>
          <p>{invoice?.companyShortName}</p>
          <p>{invoice?.contractName?.toLowerCase()}</p>
          <p>{invoice?.address}</p>
          <p>
            {invoice?.serial} {invoice?.number}
            {" - "}
            {new Date(invoice?.invoiceDate).toLocaleDateString(langCtx)}
          </p>
        </div>
        <div className={styles.status}>
          <p>{t("invoice.payments.paid")}</p>
          <p>
            {invoice?.amount.toLocaleString(langCtx)} {t("currency")}
          </p>
        </div>
      </div>

      <div className={styles.actions}>
        {invoice?.isPdfDownloadAllowed && <DownloadPdfBtn invoice={invoice} />}

        <span>
          <a
            className="cursor-hover"
            onClick={() =>
              history.push({
                pathname: `/invoices/detail/${invoice?.id}`,
                state: { from: location }
              })
            }
          >
            {t("invoice.details")}
          </a>
        </span>
      </div>
    </div>
  );
};

export default PaidInvoicesListGroupItem;
