import React, { useState } from "react";
import { chevronDown, createOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon } from "@ionic/react";

import type {
  Contract,
  ContractNotificationSettings
} from "../../../types/responseTypes";

import EditContractNotificationSettingsRow from "./EditContractNotificationSettingsRow";

import styles from "./EditContractPage.module.scss";

interface EditContractNotificationSettingsProps {
  isElectronicInvoice: boolean;
  hasReadings: boolean;
  notificationSettings: ContractNotificationSettings;
  setNotificationSettings: (value) => void;
  contract: Contract;
}

const EditContractNotificationSettings: React.FC<
  EditContractNotificationSettingsProps
> = ({
  isElectronicInvoice,
  hasReadings,
  notificationSettings,
  setNotificationSettings,
  contract
}) => {
  const { t } = useTranslation();

  const [isOpen, setIsOpen] = useState<boolean>(true);

  return (
    <div className={styles.settings}>
      <h3
        className={`${styles.settingsTitle} ${isOpen ? styles.open : ""}`}
        onClick={() => setIsOpen((prevState) => !prevState)}
      >
        <IonIcon icon={createOutline} />
        <span>{t("notificationConfiguration")}</span>
        <IonIcon icon={chevronDown} />
      </h3>
      <div className={`${styles.settingsWrapper} ${isOpen ? styles.open : ""}`}>
        <EditContractNotificationSettingsRow
          isDAE={contract?.clientType === "DAE"}
          title={t("contract.edit.notifications.issue")}
          setting={"generateInvoice"}
          isElectronicInvoice={isElectronicInvoice}
          notificationSettings={notificationSettings}
          setNotificationSettings={setNotificationSettings}
        />

        <EditContractNotificationSettingsRow
          isDAE={contract?.clientType === "DAE"}
          title={t("contract.edit.notifications.payment")}
          setting={"payInvoice"}
          notificationSettings={notificationSettings}
          setNotificationSettings={setNotificationSettings}
        />

        <EditContractNotificationSettingsRow
          isDAE={contract?.clientType === "DAE"}
          title={t("contract.edit.notifications.dueDate")}
          setting={"dueInvoice"}
          notificationSettings={notificationSettings}
          setNotificationSettings={setNotificationSettings}
        />

        {hasReadings ? (
          <>
            <EditContractNotificationSettingsRow
              isDAE={contract?.clientType === "DAE"}
              title={t("contract.edit.notifications.readingAdded")}
              setting={"readingAdded"}
              notificationSettings={notificationSettings}
              setNotificationSettings={setNotificationSettings}
            />

            <EditContractNotificationSettingsRow
              isDAE={contract?.clientType === "DAE"}
              title={t("contract.edit.notifications.readingInt")}
              setting={"startReadingInterval"}
              notificationSettings={notificationSettings}
              setNotificationSettings={setNotificationSettings}
            />
          </>
        ) : (
          ""
        )}

        <EditContractNotificationSettingsRow
          isDAE={contract?.clientType === "DAE"}
          title={t("contract.edit.notifications.news")}
          setting={"incident"}
          notificationSettings={notificationSettings}
          setNotificationSettings={setNotificationSettings}
        />
      </div>
    </div>
  );
};

export default EditContractNotificationSettings;
