import React, { useContext, useMemo, useState } from "react";
import type { AxiosResponse } from "axios";
import type { FormEvent } from "react";
import { useTranslation } from "react-i18next";
import validator from "validator";

import { IonButton, IonCol, IonGrid, IonRow, IonSpinner } from "@ionic/react";

import { contractActions } from "../../../api/ContractActions";
import { axiosPrivate } from "../../../api/customAxios";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import UserContext from "../../../context/UserContext";
import type {
  EditContract,
  ElectronicInvoiceEmails
} from "../../../types/requestTypes";
import type {
  Company,
  Contract,
  ContractDetails,
  ContractNotificationSettings
} from "../../../types/responseTypes";
import type { ExceptionDto } from "../../../types/schema";

import EditContractContactInfo from "./EditContractContactInfo";
import EditContractDeleteModal from "./EditContractDeleteModal";
import EditContractInvoiceType from "./EditContractInvoiceType";
import EditContractNotificationSettings from "./EditContractNotificationSettings";

interface EditContractFormProps {
  contract: Contract;
  contractDetails: ContractDetails;
  setSuccessfulUpdate: (value) => void;
}

const EditContractForm: React.FC<EditContractFormProps> = ({
  contract,
  contractDetails,
  setSuccessfulUpdate
}) => {
  const { t } = useTranslation();
  const { companiesCtx } = useContext(UserContext);

  const [contactMail, setContactMail] = useState<string>(contract?.email);
  const [contactPhone, setContactPhone] = useState<string>(contract?.phone);
  const [electronicInvoiceEmails, setElectronicInvoiceEmails] = useState<
    ElectronicInvoiceEmails[]
  >(contractDetails?.electronicInvoiceEmails);
  const [isElectronicInvoice, setIsElectronicInvoice] = useState<boolean>(
    electronicInvoiceEmails && electronicInvoiceEmails.length > 0
  );
  const [notificationSettings, setNotificationSettings] =
    useState<ContractNotificationSettings>(
      contractDetails?.notificationSettings
    );
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [electronicInvoiceEmailHasError, setElectronicInvoiceEmailHasError] =
    useState<boolean>(false);
  const [newElectronicInvoiceEmail, setNewElectronicInvoiceEmail] =
    useState<ElectronicInvoiceEmails>(null);

  const company = useMemo(
    (): Company =>
      companiesCtx?.filter(
        (company: Company): boolean => company.id === contract?.companyId
      )[0],
    [companiesCtx, contract]
  );
  const cannotModifyContractEmail: boolean =
    electronicInvoiceEmails?.length !== 0 &&
    company?.hasSyncEnabledBetweenElectronicInvoiceAndContractEmail;
  const hasReadings: boolean = company?.usingMeters;

  function isValidEmail(commaSeparatedEmails: string): boolean {
    if (!commaSeparatedEmails) return true;
    const emails: string[] = commaSeparatedEmails.split(",");
    for (const email of emails) {
      if (!validator.isEmail(email ? email?.trim() : email)) return false;
    }
    return true;
  }

  function validatePhoneNumber(input_str: string): boolean {
    const validation = /^(\+4|004)?(07|02|03)\d{8}$/;
    return validation.test(input_str);
  }

  const editContract = async (requestData: EditContract) => {
    if (!requestData) return;

    if (!isValidEmail(requestData.email)) {
      setLoading(false);
      toastAdd(t([`err.EMAIL_NOT_VALID`, "err.__"]), "error");
      return;
    }
    if (!requestData.phone || !validatePhoneNumber(requestData.phone)) {
      setLoading(false);
      toastAdd(t([`err.PHONE_NOT_VALID`, "err.__"]), "error");

      return;
    }

    if (isElectronicInvoice && !requestData.electronicInvoiceEmails?.length) {
      setLoading(false);
      setElectronicInvoiceEmailHasError(true);
      toastAdd(t([`err.CONTRACT_EMAILLIST_EMPTY`, "err.__"]), "error");
      return;
    }

    try {
      await contractActions.edit(contract?.id, requestData);
      setLoading(false);
      setSuccessfulUpdate((prevState) => prevState + 1);

      if (
        isElectronicInvoice &&
        requestData.electronicInvoiceEmails[
          requestData.electronicInvoiceEmails.length - 1
        ].confirmedEmail === undefined
      ) {
        toastAdd(t("contract.updated.emailNotValidated"), "success");
      } else {
        toastAdd(t("contract.updated"), "success");
      }
    } catch (err: any) {
      setLoading(false);
      const ex: ExceptionDto = err.response.data;
      toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
    }
  };

  const electronicInvoiceEmailsHandler = (
    updateEmail: ElectronicInvoiceEmails,
    action: "add" | "remove"
  ) => {
    if (!updateEmail || !action) {
      return;
    }
    if (action === "remove") {
      setElectronicInvoiceEmails(
        (prevState: ElectronicInvoiceEmails[]): ElectronicInvoiceEmails[] =>
          prevState.filter(
            (email: ElectronicInvoiceEmails): boolean =>
              email.email !== updateEmail?.email
          )
      );
    } else {
      if (!validator.isEmail(updateEmail.email.trim())) {
        setElectronicInvoiceEmailHasError(true);
        toastAdd(t([`err.EMAIL_NOT_VALID`, "err.__"]), "error");
        return;
      }

      axiosPrivate
        .get(`/confirm-contract-email/is-confirmed?email=${updateEmail.email}`)
        .then((response: AxiosResponse) => {
          if (response.data) {
            updateEmail.confirmedEmail = true;
            setElectronicInvoiceEmails(
              (
                prevState: ElectronicInvoiceEmails[]
              ): ElectronicInvoiceEmails[] => [...prevState, updateEmail]
            );
            setNewElectronicInvoiceEmail(null);
          } else {
            setElectronicInvoiceEmails(
              (
                prevState: ElectronicInvoiceEmails[]
              ): ElectronicInvoiceEmails[] => [...prevState, updateEmail]
            );
            setNewElectronicInvoiceEmail(null);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const submitHandler = async (e: FormEvent) => {
    e.preventDefault();
    setLoading(true);

    if (electronicInvoiceEmailHasError) {
      setLoading(false);
      return;
    }

    if (newElectronicInvoiceEmail?.email !== undefined) {
      electronicInvoiceEmailsHandler(newElectronicInvoiceEmail, "add");

      const requestData: EditContract = {
        id: contract?.id,
        phone: contactPhone,
        email: contactMail?.trim(),
        electronicInvoiceEmails: [
          ...electronicInvoiceEmails,
          newElectronicInvoiceEmail
        ],
        notificationSettings: notificationSettings
      };

      await editContract(requestData);
    } else {
      const requestData: EditContract = {
        id: contract?.id,
        phone: contactPhone,
        email: contactMail?.trim(),
        electronicInvoiceEmails: electronicInvoiceEmails,
        notificationSettings: notificationSettings
      };

      await editContract(requestData);
    }
  };

  const isElectronicInvoiceDisabled: boolean =
    contract.clientType === "DAE" && contract.companyId === 1;

  return (
    <form onSubmit={submitHandler}>
      <EditContractContactInfo
        cannotModifyContractEmail={cannotModifyContractEmail}
        contactMail={contactMail}
        contactPhone={contactPhone}
        setContactMail={setContactMail}
        setContactPhone={setContactPhone}
      />

      <EditContractInvoiceType
        isElectronicInvoiceDisabled={isElectronicInvoiceDisabled}
        isRevertToPhysicalInvoicePermitted={
          contractDetails?.isRevertToPhysicalInvoicePermitted
        }
        electronicInvoiceEmails={electronicInvoiceEmails}
        setElectronicInvoiceEmails={setElectronicInvoiceEmails}
        isElectronicInvoice={isElectronicInvoice}
        setIsElectronicInvoice={setIsElectronicInvoice}
        setNotificationSettings={setNotificationSettings}
        electronicInvoiceEmailHasError={electronicInvoiceEmailHasError}
        setElectronicInvoiceEmailHasError={setElectronicInvoiceEmailHasError}
        newElectronicInvoiceEmail={newElectronicInvoiceEmail}
        setNewElectronicInvoiceEmail={setNewElectronicInvoiceEmail}
        companyId={contract?.companyId}
      />

      <EditContractNotificationSettings
        contract={contract}
        isElectronicInvoice={isElectronicInvoice}
        hasReadings={hasReadings}
        notificationSettings={notificationSettings}
        setNotificationSettings={setNotificationSettings}
      />

      <IonGrid>
        <IonRow>
          <IonCol size="6">
            <IonButton
              strong={true}
              type="submit"
              expand="block"
              color="primary"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <IonSpinner name="circles" /> {t("saving")}
                </>
              ) : (
                t("save")
              )}
            </IonButton>
          </IonCol>

          <IonCol size="6">
            <IonButton
              color="danger"
              expand="block"
              strong={true}
              onClick={() => setShowDeleteModal(true)}
            >
              {t("delete")}
            </IonButton>
          </IonCol>
        </IonRow>
      </IonGrid>
      <input type="submit" className="submit-enter" />

      <EditContractDeleteModal
        contractId={contract?.id}
        showModal={showDeleteModal}
        updateShowModal={setShowDeleteModal}
        type={"contracts"}
      />
    </form>
  );
};

export default EditContractForm;
