import type { MobilPayData } from "../../../../types/schema";

export const mobilPayModule = function (form, backendResponse: MobilPayData) {
  let _form = form;
  let _backendResponse = backendResponse;

  function createMobilPayFormInputs() {
    const mobilPayRequest = _backendResponse;

    createHiddenInputAndAppendToMobilPayForm(
      "env_key",
      mobilPayRequest.env_key
    );
    createHiddenInputAndAppendToMobilPayForm("data", mobilPayRequest.data);
  }

  function createHiddenInputAndAppendToMobilPayForm(name, value) {
    const formInput = document.createElement("input");
    formInput.type = "hidden";
    formInput.name = name;
    formInput.value = value;
    _form.appendChild(formInput);
  }

  return {
    setForm: function (form) {
      _form = form;
    },
    setBackendResponse: function (backendResponse) {
      _backendResponse = backendResponse;
    },
    submitToMobilPay() {
      _form.textContent = ""; // clear form
      createMobilPayFormInputs();
      _form.submit();
    }
  };
};
