import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import { useTranslation } from "react-i18next";
import validator from "validator";

import { IonButton, IonInput, IonLabel, IonSpinner } from "@ionic/react";

import { userActions } from "../../../api/UserActions";
import PasswordInput from "../../../components/PasswordComponents/PasswordInput";
import PasswordValidationTooltip from "../../../components/PasswordComponents/PasswordValidationTooltip";
import FormFeedback from "../../../components/UI/FormFeedback";
import { useSettings } from "../../../context/SettingsContext";
import AcceptTermsAndPrivacy from "../../../features/AcceptTermsAndPrivacy";
import i18n from "../../../i18n";
import type {
  Language,
  PasswordValidationType
} from "../../../types/otherTypes";
import type { ExceptionDto } from "../../../types/schema";
import {
  initialPasswordValidationState,
  passMinLength,
  regex
} from "../../../utils/globalVariables";

import styles from "./RegisterPage.module.scss";

interface RegisterFormProps {
  email: string;
  setEmail: (value: string) => void;
  password: string;
  setPassword: (value: string) => void;
  setStep: (value) => void;
  validCaptcha: boolean;
  setValidCaptcha: (value: boolean) => void;
}

export const RegisterForm: React.FC<RegisterFormProps> = ({
  email,
  setEmail,
  password,
  setPassword,
  setStep,
  validCaptcha,
  setValidCaptcha
}) => {
  const { t } = useTranslation();
  const { settings } = useSettings();

  const [password2, setPassword2] = useState<string>();
  //stateul validCaptcha mutat la parinte ca sa pot schimba state ul in test
  const [showTooltip, setShowTooltip] = useState(false);
  const [accept, setAccept] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [showValidationState, setShowValidationState] =
    useState<PasswordValidationType>(initialPasswordValidationState);
  const [errorMsg, setErrorMsg] = useState<string>();

  const lang = i18n.resolvedLanguage as Language;

  const onChange = (value) => {
    if (value) {
      setValidCaptcha(true);
    } else {
      setValidCaptcha(false);
    }
  };

  const validatePass1 = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    setShowValidationState({
      minLength: inputValue.length >= passMinLength,
      specialChar: regex.specialChar.test(inputValue),
      lowerChar: regex.lowerChar.test(inputValue),
      upperChar: regex.upperChar.test(inputValue),
      digit: regex.digit.test(inputValue)
    });
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (!validator.isEmail(email)) {
      setErrorMsg(t("err.EMAIL_NOT_VALID"));
      return;
    }
    if (password.length < passMinLength) {
      setErrorMsg(t("err.PASSWORD_MIN_8_CHARACTERS"));
      return;
    }
    if (!regex.upperChar.test(password)) {
      setErrorMsg(t("err.PASSWORD_MIN_1_UPPER"));
      return;
    }
    if (!regex.lowerChar.test(password)) {
      setErrorMsg(t("err.PASSWORD_MIN_1_AZ"));
      return;
    }
    if (!regex.digit.test(password)) {
      setErrorMsg(t("err.PASSWORD_MIN_1_09"));
      return;
    }
    if (!regex.specialChar.test(password)) {
      setErrorMsg(t("err.PASSWORD_MIN_1_SPECIAL_CHAR"));
      return;
    }
    if (password !== password2) {
      setErrorMsg(t("err.PASSWORD_IDENTIC_PASSWORD2"));
      return;
    }
    if (!accept) {
      setErrorMsg(t("err.MANDATORY_TERMS"));
      return;
    }

    setLoading(true);

    try {
      const response = await userActions.checkEmailAvailability(email);
      if (!response.available) {
        setErrorMsg(t("err.USER_ALREADY_EXIST"));
      } else {
        setStep((prevState) => prevState + 1);
      }
    } catch (err: any) {
      const ex: ExceptionDto = err.response.data;
      setErrorMsg(t([`err.${ex.message}`, "err.__"]));
    } finally {
      setLoading(false);
    }
  };

  const changePass2 = (e) => {
    setPassword2(e.target.value as string);
  };

  useEffect(() => {
    if (errorMsg) {
      setErrorMsg(null);
    }
  }, [email, password, password2, accept, validCaptcha]);

  return (
    <section className={`${styles.registerWrapper} INTERPOLATED`}>
      <img
        src="/assets/img/aqmeter-small.png"
        alt="Aqmeter"
        className={styles.logo}
      />
      <IonLabel data-testid="titluRegister" className={styles.title}>
        {t("login.signUpButton")}
      </IonLabel>

      <form onSubmit={submitHandler} className={styles.form}>
        <IonInput
          data-testid="emailInput"
          title="emailInput"
          onIonChange={(e) => setEmail(e.target.value as string)}
          value={email}
          type="text"
          inputmode="email"
          autocomplete="off"
          placeholder={t("email")}
        />

        <PasswordValidationTooltip
          showTooltip={showTooltip}
          showValidationState={showValidationState}
        />

        <PasswordInput
          data-testid="passwordInput"
          value={password}
          placeholder={t("login.newPassword")}
          size={"large"}
          onChange={validatePass1}
          required={true}
          tooltip={"on"}
          setShowTooltip={setShowTooltip}
        />
        <PasswordInput
          data-testid="passwordInput2"
          value={password2}
          placeholder={t("login.repeatPassword")}
          size={"large"}
          onChange={changePass2}
          required={true}
          tooltip={"off"}
        />

        <AcceptTermsAndPrivacy
          accept={accept}
          setAccept={setAccept}
          lang={lang}
        />

        <div className={styles.captcha}>
          <ReCAPTCHA
            data-testid="recaptcha"
            sitekey={settings.recaptchaSiteKey}
            theme="light"
            onChange={onChange}
            hl={lang}
          />
        </div>

        <IonButton
          strong={true}
          type="submit"
          color="primary"
          expand="block"
          disabled={
            isLoading || !email || !password || !password2 || !validCaptcha
          }
        >
          {isLoading ? (
            <>
              <IonSpinner data-testid="spinner" name="circles" />{" "}
              {t("login.createAccountLoading")}
            </>
          ) : (
            t("login.createAccount")
          )}
        </IonButton>

        {errorMsg ? (
          <FormFeedback type={"error"} data-testid="error">
            {errorMsg}
          </FormFeedback>
        ) : (
          ""
        )}

        <input
          type="submit"
          className="submit-enter"
          disabled={
            isLoading || !email || !password || !password2 || !validCaptcha
          }
        />
      </form>
    </section>
  );
};
