import React, { createContext, useState } from "react";

import type { Company, Contract, DashboardMenu } from "../types/responseTypes";

interface UserContextValue {
  menuCtx: DashboardMenu;
  companiesCtx: Company[];
  contractsCtx: Contract[];
  updateMenuCtx: (responseData: DashboardMenu) => void;
  updateCompaniesCtx: (responseData: Company[]) => void;
  updateContractsCtx: (responseData: Contract[]) => void;
}

const initialMenuValue: DashboardMenu = {
  totalUnpaidInvoices: 0,
  hasWaterContracts: false,
  totalReadingsToSend: 0,
  totalUnreadNotifications: 0
};

const UserContext = createContext<UserContextValue>({
  menuCtx: initialMenuValue,
  companiesCtx: [],
  contractsCtx: [],
  updateMenuCtx: () => {},
  updateCompaniesCtx: () => {},
  updateContractsCtx: () => {}
});

type UserProviderProps = {
  children: React.ReactNode;
};

export const UserProvider: React.FC<UserProviderProps> = ({ children }) => {
  const [menuCtx, setMenuCtx] = useState<DashboardMenu>(initialMenuValue);
  const [companiesCtx, setCompaniesCtx] = useState<Company[]>([]);
  const [contractsCtx, setContractsCtx] = useState<Contract[]>([]);

  // console.log('in ctx ', menuCtx.totalReadingsToSend);

  const updateMenuCtx = (responseData: DashboardMenu): void => {
    setMenuCtx(responseData);
  };

  const updateCompaniesCtx = (responseData: Company[]): void => {
    setCompaniesCtx(responseData);
  };

  const updateContractsCtx = (responseData: Contract[]): void => {
    setContractsCtx(responseData);
  };

  const contextValue: UserContextValue = {
    menuCtx,
    companiesCtx,
    contractsCtx,
    updateMenuCtx,
    updateCompaniesCtx,
    updateContractsCtx
  };

  return (
    <UserContext.Provider value={contextValue}>{children}</UserContext.Provider>
  );
};

export default UserContext;
