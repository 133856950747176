import { useContext, useState } from "react";
import { alertCircle, checkmarkCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import {
  IonButton,
  IonIcon,
  IonInput,
  IonLabel,
  IonSpinner
} from "@ionic/react";

import { contractActions } from "../../api/ContractActions";
import { updateContextActions } from "../../api/UpdateContextActions";
import { userActions } from "../../api/UserActions";
import { CompanyHeader } from "../../components/CompanyHeader";
import { toastAdd } from "../../components/CustomToast/CustomToasts";
import FormFeedback from "../../components/UI/FormFeedback";
import AuthContext from "../../context/AuthContext";
import UserContext from "../../context/UserContext";
import type { AddContract, RegisterData } from "../../types/requestTypes";
import type {
  Company,
  ValidateContractResponse
} from "../../types/responseTypes";
import type { ExceptionDto } from "../../types/schema";

import styles from "../../pages/4.Contracts/AddContract/AddContractPage.module.scss";

interface AddContractSubmitProps {
  source: "contracts" | "register";
  data?: RegisterData;
  selectedCompany: Company;
  contractNumber: string;
  invoiceNumber: string;
  contractDetails: ValidateContractResponse;
  setContractDetails: (value: ValidateContractResponse) => void;
  setStep?: (value) => void;
}

const AddContractSubmit: React.FC<AddContractSubmitProps> = ({
  source,
  data,
  selectedCompany,
  contractNumber,
  invoiceNumber,
  contractDetails,
  setContractDetails,
  setStep
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateMenuCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      if (source === "contracts") {
        // user logat, la adaugare contract
        const data: AddContract = {
          companyId: selectedCompany?.id,
          contractNumber: contractNumber,
          invoiceNumber: invoiceNumber
        };
        await contractActions.add(data).then(() => setIsLoading(false));
        await updateContextActions
          .getMenuDetails()
          .then((data) => updateMenuCtx(data))
          .then(() => {
            toastAdd(t("contract.added"), "success");
            history.push("/contracts");
          });
      } else {
        // creare cont nou cu adaugare contract
        await userActions.registerAccount(data).then(() => {
          setIsLoading(false);
          setStep((prevState) => prevState + 1);
        });
      }
    } catch (err) {
      setIsLoading(false);
      const ex: ExceptionDto = err.response.data;
      setErrorMsg(t([`err.${ex.message}`, "err.__"]));
      if (ex.message === "USER_ALREADY_HAS_CONTRACT") {
        setContractDetails(null);
      }
    }
  };

  return (
    <>
      <CompanyHeader company={selectedCompany} />

      <form className={styles.verificationForm} onSubmit={submitHandler}>
        <IonLabel>{t("contract.no")}</IonLabel>
        <IonInput
          data-testid="contractNo"
          value={contractNumber}
          type="text"
          inputmode="text"
          autocomplete="off"
          placeholder={t("placeholder.addHere")}
          className={errorMsg ? "error" : "success"}
          readonly
        >
          <IonIcon icon={errorMsg ? alertCircle : checkmarkCircle} />
        </IonInput>

        <IonLabel>{t("contract.invoiceNo")}</IonLabel>
        <IonInput
          data-testid="invoiceNo"
          value={invoiceNumber}
          type="text"
          inputmode="text"
          autocomplete="off"
          placeholder={t("placeholder.addHere")}
          className={errorMsg ? "error" : "success"}
          readonly
        >
          <IonIcon icon={errorMsg ? alertCircle : checkmarkCircle} />
        </IonInput>

        {contractDetails ? (
          <div className={styles.contractDetails}>
            <IonLabel>{t("contract.name")}</IonLabel>
            <p>{contractDetails?.contractName}</p>

            <IonLabel>{t("contract.address")}</IonLabel>
            <p>{contractDetails?.address}</p>

            <IonLabel>{t("contract.lastInvoice")}</IonLabel>
            <p>
              {new Date(contractDetails?.invoiceDate).toLocaleDateString(
                langCtx
              )}{" "}
              - {contractDetails?.invoiceValue.toLocaleString(langCtx)}{" "}
              {t("currency")}
            </p>
          </div>
        ) : (
          ""
        )}

        <IonButton
          data-testid="submitBtn"
          className="ag-cta"
          type="submit"
          expand="block"
          fill="solid"
          disabled={isLoading}
        >
          {isLoading ? (
            <>
              <IonSpinner name="circles" /> {t("adding")}
            </>
          ) : (
            t("add")
          )}
        </IonButton>

        {errorMsg ? <FormFeedback type={"error"}>{errorMsg}</FormFeedback> : ""}
      </form>
    </>
  );
};

export default AddContractSubmit;
