import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IonCheckbox, IonItem } from "@ionic/react";

import type { Language } from "../types/otherTypes";

import ModalPrivacy from "./ModalPrivacy";
import ModalTermsAndConditions from "./ModalTermsAndConditions";

type AcceptTermsAndPrivacyProps = {
  accept: boolean;
  setAccept: (value) => void;
  lang: Language;
};

const AcceptTermsAndPrivacy: React.FC<AcceptTermsAndPrivacyProps> = ({
  accept,
  setAccept,
  lang
}) => {
  const { t } = useTranslation();

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);

  return (
    <>
      <IonItem lines="none" className="ion-no-padding">
        <IonCheckbox
          data-testid="acceptTermsAndPrivacy"
          slot="start"
          mode="md"
          color="tertiary"
          checked={accept}
          onIonChange={() => setAccept((prevState) => !prevState)}
        />
        <label>
          {t("agree.accept")}
          <a onClick={() => setShowTermsModal(true)}>{t("agree.terms")}</a>
          {t("agree.and")}
          <a onClick={() => setShowPrivacyModal(true)}> {t("agree.policy")}</a>
          {t("agree.company")}
        </label>
      </IonItem>

      <ModalTermsAndConditions
        showModal={showTermsModal}
        setShowModal={setShowTermsModal}
        lang={lang}
      />
      <ModalPrivacy
        showModal={showPrivacyModal}
        setShowModal={setShowPrivacyModal}
        lang={lang}
      />
    </>
  );
};

export default AcceptTermsAndPrivacy;
