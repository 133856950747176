import React, { useContext } from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

import UserContext from "../../../context/UserContext";
import type { PaidInvoiceInfo } from "../../../types/responseTypes";

import PaidInvoicesListGroup from "./PaidInvoicesListGroup";

import styles from "./PaidInvoicesPage.module.scss";

interface PaidInvoicesListProps {
  invoices: PaidInvoiceInfo[];
}

const groupInvoicesByDate = (invoices) => {
  return invoices.reduce((accum, elem) => {
    const currentMonth = moment(elem.invoiceDate).month();
    const currentYear = moment(elem.invoiceDate).year();
    const currentGroup = accum.find(
      (group) => group.month === currentMonth && group.year === currentYear
    );

    if (!currentGroup) {
      return [
        ...accum,
        { month: currentMonth, year: currentYear, invoiceList: [elem] }
      ];
    } else {
      const newAccum = accum.filter(
        (group) => !(group.month === currentMonth && group.year === currentYear)
      );
      return [
        ...newAccum,
        {
          month: currentMonth,
          year: currentYear,
          invoiceList: [...currentGroup.invoiceList, elem]
        }
      ];
    }
  }, []);
};

const PaidInvoicesList: React.FC<PaidInvoicesListProps> = ({ invoices }) => {
  const { t } = useTranslation();
  const { contractsCtx } = useContext(UserContext);

  const groupedInvoices = groupInvoicesByDate(invoices);
  const numberOfContracts = contractsCtx?.length;

  return (
    <>
      {!invoices?.length ? (
        numberOfContracts === 0 ? (
          <p className={styles.empty}>{t("invoiceHistoryNoContract")}</p>
        ) : (
          <p className={styles.empty}>{t("invoiceHistoryEmpty")}</p>
        )
      ) : (
        ""
      )}
      {invoices &&
        groupedInvoices.map((group) => (
          <PaidInvoicesListGroup
            key={`${group.month} ${group.year}`}
            month={group.month}
            year={group.year}
            invoices={group.invoiceList}
          />
        ))}
    </>
  );
};

export default PaidInvoicesList;
