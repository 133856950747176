import React, { useContext, useState } from "react";
import { trashSharp } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../../components/UI/BackBtn";
import AuthContext from "../../../../context/AuthContext";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { Notification } from "../../../../types/responseTypes";
import { getNotificationIcon } from "../../../../utils/globalFunctions";
import ConfirmActionModal from "../ConfirmActionModal";

import styles from "../Notifications.module.scss";

export const NotificationDetailsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const notification = useLocation<Notification>().state;
  const { langCtx } = useContext(AuthContext);

  const [showDeleteModal, setShowDeleteModal] = useState(false);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn url={"/notifications"} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout layoutType={"back"} url={"/notifications"}>
        {notification ? (
          <div className={styles.notificationCard}>
            <div className={styles.notificationIcon}>
              <IonIcon icon={getNotificationIcon(notification)} />
            </div>
            <div className={styles.notificationText}>
              <IonLabel className={styles.notificationSubject}>
                {notification?.subject
                  ? notification?.subject
                  : t("notification?.noSubject")}
              </IonLabel>
              <p className={styles.notificationContent}>
                {notification?.content}
              </p>
              <p className={styles.notificationCreatedDate}>
                {notification?.createdAt
                  ? new Date(
                      notification?.createdAt.replace(" ", "T")
                    ).toLocaleDateString(langCtx) +
                    "   " +
                    new Date(
                      notification?.createdAt.replace(" ", "T")
                    ).toLocaleTimeString(langCtx, {
                      hour: "2-digit",
                      minute: "2-digit"
                    })
                  : "-"}
              </p>
            </div>
            <div
              className={styles.notificationDelete}
              onClick={() => setShowDeleteModal(true)}
            >
              <IonIcon icon={trashSharp} />
            </div>
          </div>
        ) : (
          ""
        )}
      </SidebarLayout>

      <ConfirmActionModal
        view={"detail"}
        action={"delete"}
        notificationIds={[notification?.id]}
        showModal={showDeleteModal}
        updateShowModal={setShowDeleteModal}
      />
    </IonPage>
  );
};
