import React from "react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonModal,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { TermsContentEN } from "../pages/5.More/Help/Terms/TermsContentEN";
import { TermsContentRO } from "../pages/5.More/Help/Terms/TermsContentRO";
import type { ModalProps } from "../types/otherTypes";

const ModalTermsAndConditions: React.FC<ModalProps> = ({
  showModal,
  setShowModal,
  lang
}) => {
  const { t } = useTranslation();

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => setShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("termsAndConditions")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => setShowModal(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        {lang === "en" ? <TermsContentEN /> : <TermsContentRO />}
      </IonContent>
    </IonModal>
  );
};

export default ModalTermsAndConditions;
