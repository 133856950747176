import React, { useContext } from "react";
import { chevronForward, warningOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonAvatar, IonIcon, IonItem, IonLabel } from "@ionic/react";

import UserContext from "../../../context/UserContext";
import type { Contract } from "../../../types/responseTypes";
import { isContractActive } from "../../../utils/globalFunctions";

interface ContractsListItemProps {
  contract: Contract;
}

const ContractsListItem: React.FC<ContractsListItemProps> = ({ contract }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { companiesCtx } = useContext(UserContext);

  const company = companiesCtx?.filter(
    (company) => company?.id === contract?.companyId
  )[0];

  return (
    <IonItem
      key={contract.id}
      className="card-widget-list-item cursor-hover readings-list"
      style={{ marginLeft: 0, marginRight: 0 }}
      onClick={() => history.push(`/contracts/${contract.id}`)}
    >
      <IonAvatar slot="start">
        <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
      </IonAvatar>

      <IonLabel>
        <span className="ag-body-text">{company?.shortName}</span>
        <br />
        {!isContractActive(contract) && (
          <>
            <span className="ag-number-description warning-color">
              {t("contract.inactive")}
            </span>
            <br />
          </>
        )}
        <span className="ag-number-description capitalize">
          {contract.name.toLowerCase()}
        </span>
        <br />
        <span className="ag-number-description">
          {contract.deliveryAddress}
        </span>
        <br />
        <span className="ag-number-description">
          {t("tabs.contractNo")} {contract.number}
        </span>
        <br />
      </IonLabel>

      <IonIcon className="chevron" icon={chevronForward} />

      <div className="readings-list-icon-details">
        {!isContractActive(contract) && (
          <IonIcon icon={warningOutline} color="warning" />
        )}
      </div>
    </IonItem>
  );
};

export default ContractsListItem;
