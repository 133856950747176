import React from "react";
import { chevronDown } from "ionicons/icons";

import { IonIcon } from "@ionic/react";

import styles from "./ListDemands.module.scss";

interface DetailedStatusInfoProps {
  statusInfo?: string;
  isSyncOpen?: boolean;
}

const ContractsWithUpdatingBalance: React.FC<DetailedStatusInfoProps> = ({
  statusInfo,
  isSyncOpen
}) => {
  return (
    <div>
      <div className={`${styles.statusInfo} `}>
        <IonIcon
          className={`${styles.iconChevronDown} ${
            isSyncOpen ? styles.open : ""
          }`}
          icon={chevronDown}
          color="medium"
        />
      </div>
      {isSyncOpen ? (
        <p className={styles.bottomTextInfo}>
          {statusInfo === "Trimisă"
            ? "Cererea ta a fost trimisă către furnizor. Vom reveni cu detalii dupa procesarea cererii."
            : statusInfo === "În analiză"
              ? "Cererea ta a fost preluată în analiză de către departamentul Contracte al furnizorului."
              : statusInfo === "Respinsă"
                ? "Cererea a fost respinsă cu motivarea: x."
                : statusInfo === "Aprobată"
                  ? "Cererea a fost aprobată. Urmează să fii contactat de furnizor pentru finalizare."
                  : null}
        </p>
      ) : null}
    </div>
  );
};

export default ContractsWithUpdatingBalance;
