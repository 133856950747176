import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IonText } from "@ionic/react";

import AuthContext from "../../../../context/AuthContext";
import type { Invoice } from "../../../../types/responseTypes";

import InvoicesPaymentListItem from "./InvoicesPaymentListItem";

interface InvoicesPaymentListProps {
  invoices: Invoice[];
  selectedInvoices: number[];
}

const InvoicesPaymentList: React.FC<InvoicesPaymentListProps> = ({
  invoices,
  selectedInvoices
}) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);
  const extractedInvoices = invoices?.filter((invoice) =>
    selectedInvoices?.includes(invoice?.id)
  );

  return (
    <div className="invoice-payment-wrapper">
      <IonText className="ion-text-left">
        {t("invoice.pay.phoneCheck.selection")}
      </IonText>

      {extractedInvoices?.map((invoice) => (
        <InvoicesPaymentListItem key={invoice?.id} invoice={invoice} />
      ))}

      <IonText className="ion-text-right">
        <span className="gray">{t("invoice.moreInfo.total")}</span>
        <span className="primary-color">
          {parseFloat(
            extractedInvoices
              ?.reduce((accum, elem) => accum + elem?.dueAmount, 0)
              .toFixed(2)
          ).toLocaleString(langCtx)}{" "}
          {t("currency")}
        </span>
      </IonText>
    </div>
  );
};

export default InvoicesPaymentList;
