import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";

import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import type { ReadingHistoryInfo } from "../../../types/responseTypes";

import styles from "./ReadingsHistoryPage.module.scss";

interface ReadingsHistoryItemProps {
  reading: ReadingHistoryInfo;
}

const ReadingsHistoryItem: React.FC<ReadingsHistoryItemProps> = ({
  reading
}) => {
  const { t } = useTranslation();
  const { companiesCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);

  const company = useMemo(
    () =>
      companiesCtx?.filter((company) => company?.id === reading?.companyId)[0],
    [companiesCtx, reading]
  );

  return (
    <div className={`${styles.reading}`}>
      <img alt={`${reading?.companyShortName} logo`} src={company?.logoUrl} />
      <div className={styles.details}>
        <p>{reading?.companyShortName}</p>
        <p>{reading?.contractName?.toLowerCase()}</p>
        <p>{reading?.address}</p>
        <p>
          {t("tabs.contractNo")}
          {": "}
          {reading?.contractNumber}
        </p>
        <p>
          {t("index.meter")}
          {": "}
          {reading?.meterSerial}
        </p>
      </div>
      <div className={styles.status}>
        <p>{t("readingIndexLabel")}</p>
        <p>
          <span className={styles.strong}>{reading?.value}</span>{" "}
          {(reading?.unit === "mc" && (
            <span>
              m<sup>3</sup>
            </span>
          )) ||
            reading?.unit}
        </p>
        {reading?.selfReading ? (
          <>
            <p className={styles.primary}>{t("index.reading.self1")}</p>
            <p className={styles.primary}>{t("index.reading.self2")}</p>
          </>
        ) : (
          <>
            <p className={styles.warning}>{t("index.reading.operator1")}</p>
            <p className={styles.warning}>{t("index.reading.operator2")}</p>
          </>
        )}
        <p>{new Date(reading?.readingDate).toLocaleDateString(langCtx)}</p>
      </div>
    </div>
  );
};

export default ReadingsHistoryItem;
