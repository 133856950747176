import { t } from "i18next";

import StandardInput from "../StandardInput";

import styles from "../../CreateContractPage.module.scss";

interface ContactProps {
  setContact: (contact) => void;
  contact: any;
}

const Contact: React.FC<ContactProps> = ({ setContact, contact }) => {
  return (
    <div>
      <StandardInput
        setInputValue={(event) =>
          setContact((prev) => ({
            ...prev,
            phone: event.target.value as string
          }))
        }
        inputValue={contact?.phone}
        labelName={t("demands.requestFormPage.contact.phone")}
        className={styles.largeInput}
        notRequired={true}
      />
      <StandardInput
        setInputValue={(event) =>
          setContact((prev) => ({
            ...prev,
            email: event.target.value as string
          }))
        }
        inputValue={contact?.email}
        labelName={t("demands.requestFormPage.contact.email")}
        className={styles.largeInput}
        notRequired={true}
      />
    </div>
  );
};

export default Contact;
