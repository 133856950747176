import React, { useContext, useMemo, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useLocation, useParams } from "react-router";

import { Capacitor } from "@capacitor/core";
import {
  IonButton,
  IonButtons,
  IonContent,
  IonHeader,
  IonIcon,
  IonLabel,
  IonModal,
  IonPage,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { paymentActions } from "../../../../api/PaymentActions";
import { AqNotificationsBell } from "../../../../components/AqNotificationsBell";
import { ContractHeader } from "../../../../components/ContractHeader";
import { toastAdd } from "../../../../components/CustomToast/CustomToasts";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../../components/UI/BackBtn";
import AuthContext from "../../../../context/AuthContext";
import { useSettings } from "../../../../context/SettingsContext";
import UserContext from "../../../../context/UserContext";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { CustomPaymentRequest } from "../../../../types/requestTypes";
import type { ExceptionDto, PaymentData } from "../../../../types/schema";
import { openPaymentPageForMobile } from "../../../../utils/globalFunctions";
import PrivacyContentEN from "../../../5.More/Help/Privacy/PrivacyContentEN";
import { PrivacyContentRO } from "../../../5.More/Help/Privacy/PrivacyContentRO";
import { TermsContentEN } from "../../../5.More/Help/Terms/TermsContentEN";
import { TermsContentRO } from "../../../5.More/Help/Terms/TermsContentRO";
import PaymentForms from "../Common/PaymentForms";
import RenderPaymentForms from "../Common/RenderPaymentForms";

import CustomPaymentForm from "./CustomPaymentForm";

interface ParamObj {
  contractId: string;
}

interface LocationObj {
  payType: "partial" | "advance";
}

const CustomPaymentPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { settings } = useSettings();
  const { contractsCtx } = useContext(UserContext);
  const { contractId } = useParams() as ParamObj;
  const { payType } = useLocation().state as LocationObj;
  const { langCtx } = useContext(AuthContext);

  const [showTermsModal, setShowTermsModal] = useState(false);
  const [showPrivacyModal, setShowPrivacyModal] = useState(false);
  const [payRequestData, setPayRequestData] = useState<PaymentData | null>(
    null
  );
  const [acceptTerms, setAcceptTerms] = useState(false);

  const domains = settings.domain;
  const isMobile = Capacitor.isNativePlatform();
  const contract = useMemo(
    () => contractsCtx?.filter((contract) => contract?.id === +contractId)[0],
    [contractsCtx, contractId]
  );

  const startPayment = async (
    customPaymentRequestObj: CustomPaymentRequest
  ) => {
    try {
      const paymentResponse = await paymentActions.customPayment(
        customPaymentRequestObj
      );
      if (isMobile) {
        await openPaymentPageForMobile(domains, paymentResponse, +contractId);
      } else {
        setPayRequestData(paymentResponse);
      }
    } catch (err: any) {
      const ex: ExceptionDto = err.response.data;
      if (ex) {
        if (ex.message === "INVALID_PHONE") {
          toastAdd(t("invoice.pay.phoneCheck.mobileRequired"), "info");
        } else if (ex.message === "INVALID_AMOUNT") {
          toastAdd(t("invoice.pay.amount.check"), "error");
        } else {
          toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
        }
      }
    }
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout == "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout layoutType={"back"}>
        <ContractHeader contract={contract} />

        {payType === "advance" ? (
          <IonLabel className="ag-h2 black">
            {t("invoice.pay.advance.title")}
          </IonLabel>
        ) : (
          <IonLabel className="ag-h2 black">
            {t("invoice.pay.partial.title")}
          </IonLabel>
        )}

        <div className={`invoice-payment-modal layout--${layout}`}>
          <CustomPaymentForm
            contract={contract}
            startPayment={startPayment}
            acceptTerms={acceptTerms}
            setAcceptTerms={setAcceptTerms}
            setShowTermsModal={setShowTermsModal}
            setShowPrivacyModal={setShowPrivacyModal}
          />
          <RenderPaymentForms paymentData={payRequestData} />
        </div>

        <IonModal
          isOpen={showTermsModal}
          onDidDismiss={() => setShowTermsModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>{t("termsAndConditions")}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowTermsModal(false)}>
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {langCtx === "en" ? <TermsContentEN /> : <TermsContentRO />}
          </IonContent>
        </IonModal>

        <IonModal
          isOpen={showPrivacyModal}
          onDidDismiss={() => setShowPrivacyModal(false)}
        >
          <IonHeader>
            <IonToolbar>
              <IonTitle>{t("privacyPolicy")}</IonTitle>
              <IonButtons slot="end">
                <IonButton onClick={() => setShowPrivacyModal(false)}>
                  <IonIcon slot="icon-only" icon={closeOutline} />
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <IonContent>
            {langCtx === "en" ? <PrivacyContentEN /> : <PrivacyContentRO />}
          </IonContent>
        </IonModal>

        <PaymentForms />
      </SidebarLayout>
    </IonPage>
  );
};

export default CustomPaymentPage;
