import React from "react";
import { timerOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonChip, IonIcon, IonLabel } from "@ionic/react";

import InfoBox from "../../../../components/UI/InfoBox";
import type { Contract } from "../../../../types/responseTypes";

import styles from "../AddReadingPage.module.scss";

interface MaxReadingsProtocolProps {
  contract: Contract;
}

const MaxReadingsProtocol: React.FC<MaxReadingsProtocolProps> = ({
  contract
}) => {
  const { t } = useTranslation();

  return (
    <>
      <IonChip outline={true} color="medium" className={styles.readingInterval}>
        <IonIcon icon={timerOutline} color="medium" />
        <IonLabel>
          {t("index.readingInterval")}
          {contract?.monthlyReadingPeriod.startDate}
          {" - "}
          {contract?.monthlyReadingPeriod.endDate} {t("index.ofMonth")}
        </IonLabel>
      </IonChip>

      <InfoBox color="warning">{t("index.notAllowed")}</InfoBox>
    </>
  );
};

export default MaxReadingsProtocol;
