import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { IonButton, IonSpinner } from "@ionic/react";

import { userActions } from "../api/UserActions";
import FormFeedback from "../components/UI/FormFeedback";
import InfoBox from "../components/UI/InfoBox";
import type { ActionStatus, UnconfirmedResponse } from "../types/otherTypes";

import styles from "./ResendConfirmationNotice.module.scss";

const ResendConfirmationNotice: React.FC<UnconfirmedResponse> = ({
  type,
  resendUrl
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [actionStatus, setActionStatus] = useState<ActionStatus>();

  const resendConfirmationHandler = async (url) => {
    setIsLoading(true);
    await userActions
      .resendEmailConfirmation(url)
      .then((response) => {
        setIsLoading(false);
        if (response) {
          setActionStatus({
            type: "success",
            message: t("register.resendSuccess")
          });
        } else {
          setActionStatus({ type: "error", message: t("register.resendFail") });
        }
      })
      .catch(() => {
        setIsLoading(false);
        setActionStatus({ type: "error", message: t("register.resendFail") });
      });
  };

  return (
    <section
      className={styles.wrapper}
      data-testid={"resend-confirmation-notice"}
    >
      <InfoBox color={"tertiary"} noMargin={true}>
        {type === "account" ? (
          <>
            {t("err.USER_IS_UNCONFIRMED")} {". "} {t("register.confirm")}
          </>
        ) : (
          <>
            {t("err.USER_HAS_PASSWORD_UNCONFIRMED")} {". "}{" "}
            {t("password.confirm")}
          </>
        )}
      </InfoBox>

      <IonButton
        expand="block"
        color="tertiary"
        disabled={isLoading}
        onClick={() => resendConfirmationHandler(resendUrl)}
      >
        {isLoading ? <IonSpinner name="circles" /> : ""}
        {t("register.resend")}
      </IonButton>

      {actionStatus?.type === "success" ? (
        <FormFeedback type={"success"} noMargin={true}>
          {actionStatus?.message}
        </FormFeedback>
      ) : actionStatus?.type === "error" ? (
        <FormFeedback type={"error"} noMargin={true}>
          {actionStatus?.message}
        </FormFeedback>
      ) : (
        ""
      )}
    </section>
  );
};

export default ResendConfirmationNotice;
