import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import CompanyAndContractFilters from "../../../components/Filters/CompanyAndContractFilters";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type {
  Company,
  Contract,
  Reading,
  ReadingHistoryInfo
} from "../../../types/responseTypes";
import {
  extractHistoryFilters,
  filterHistoryItems
} from "../../../utils/globalFunctions";

import ReadingsHistoryList from "./ReadingsHistoryList";

import styles from "./ReadingsHistoryPage.module.scss";

const getReadingDetails = (
  readings: Reading[],
  contracts: Contract[],
  companies: Company[]
): ReadingHistoryInfo[] => {
  if (!readings?.length || !contracts?.length || !companies?.length) {
    return [];
  }
  return readings.map((reading) => {
    const currentContract = contracts.find(
      (contract) => contract.id === reading.contractId
    );
    const currentCompany = companies.find(
      (company) => company.id === currentContract.companyId
    );
    return {
      ...reading,
      companyId: currentContract.companyId,
      contractNumber: currentContract.number,
      contractName: currentContract.name,
      address: currentContract.deliveryAddress,
      companyShortName: currentCompany.shortName
    };
  });
};

const ReadingsHistoryPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { menuCtx, contractsCtx, companiesCtx } = useContext(UserContext);

  const { data: readingsData, error } = useFetchCaching<Reading[]>("/readings");

  const numberOfContracts = contractsCtx?.length;

  const [companyFilter, setCompanyFilter] = useState(0);
  const [contractFilter, setContractFilter] = useState(0);

  const companyFilterChangeHandler = (selectedCompany) => {
    setCompanyFilter(selectedCompany);
  };
  const contractFilterChangeHandler = (selectedContract) => {
    setContractFilter(selectedContract);
  };

  const readings = getReadingDetails(readingsData, contractsCtx, companiesCtx);
  const filteredReadings = filterHistoryItems(
    readings,
    companyFilter,
    contractFilter
  );
  const extractedFilters = extractHistoryFilters(readings);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" style={{ flexDirection: "column" }}>
              <IonLabel className="ag-h2 black">{t("tabs.index")}</IonLabel>
              {!menuCtx?.totalReadingsToSend ? (
                <IonLabel className="ag-body-text gray">
                  {t("indexToReadCountZero")}
                </IonLabel>
              ) : (
                <IonLabel className="ag-body-text gray">
                  {t("indexToReadHistoryCount", {
                    count: menuCtx?.totalReadingsToSend
                  })}
                </IonLabel>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"readings"}
        menuSelected={"item2"}
      >
        <SkeletonList loading={!readingsData} error={error} />
        {!error && readingsData && extractedFilters ? (
          <>
            <CompanyAndContractFilters
              filters={extractedFilters}
              selectedContract={contractFilter}
              onChangeCompanyFilter={companyFilterChangeHandler}
              onChangeContractFilter={contractFilterChangeHandler}
            />
            <ReadingsHistoryList filteredReadings={filteredReadings} />
          </>
        ) : (
          ""
        )}
        {!error && readingsData && !readingsData?.length ? (
          <>
            {(numberOfContracts === 0 && (
              <p className={styles.empty}>{t("invoiceHistoryNoContract")}</p>
            )) || <p className={styles.empty}>{t("noReadIndex")}</p>}
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default ReadingsHistoryPage;
