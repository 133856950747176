import { useState } from "react";
import { useTranslation } from "react-i18next";

import styles from "./Filters.module.scss";

const CompanyAndContractFilters = (props) => {
  const { t } = useTranslation();
  const companyFilters = props.filters.companies;
  const contractFilters = props.filters.contracts;

  const [filteredCompany, setFilteredCompany] = useState(0);

  const filterContractsByCompany = (company, contractsList) => {
    if (company === 0) {
      return contractsList;
    } else {
      return contractsList?.filter((contract) => contract.company === company);
    }
  };

  const contractFiltersByCompany = filterContractsByCompany(
    filteredCompany,
    contractFilters
  ).sort((a, b) => a.displayName.localeCompare(b.displayName));

  const companyFilterHandler = (value) => {
    setFilteredCompany(value);
    props.onChangeCompanyFilter(value);

    props.onChangeContractFilter(0);
  };

  const contractFilterHandler = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === "0") {
      props.onChangeContractFilter(0);
      setFilteredCompany(0);
      props.onChangeCompanyFilter(0);
    } else {
      const selectedOptionCompany = contractFilters?.filter(
        (contract) => contract.identifier === selectedOption
      )[0].company;

      props.onChangeContractFilter(selectedOption);
      setFilteredCompany(selectedOptionCompany);
      props.onChangeCompanyFilter(selectedOptionCompany);
    }
  };

  return (
    <div className={styles.wrapper}>
      {companyFilters.length > 0 && (
        <>
          <div className={styles.list}>
            <span
              key={0}
              className={`${styles.item} ${filteredCompany === 0 && styles.active}`}
              onClick={() => companyFilterHandler(0)}
            >
              {t("filter.allCompanies")}
            </span>
            {companyFilters.map((company) => (
              <span
                key={company.identifier}
                className={`${styles.item} ${filteredCompany === company.identifier && styles.active}`}
                onClick={() => companyFilterHandler(company.identifier)}
              >
                {company.displayName}
              </span>
            ))}
          </div>
        </>
      )}
      {contractFilters.length > 0 && (
        <>
          <div className={styles.contractsFilter}>
            <select
              value={props.selectedContract}
              onChange={contractFilterHandler}
            >
              <option key={0} value={0}>
                {t("filter.allContracts")}
              </option>
              {contractFiltersByCompany.map((contract) => (
                <option key={contract.identifier} value={contract.identifier}>
                  {contract.displayName}
                </option>
              ))}
            </select>
          </div>
        </>
      )}
    </div>
  );
};
export default CompanyAndContractFilters;
