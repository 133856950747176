import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import InfoBox from "../../../components/UI/InfoBox";
import AuthContext from "../../../context/AuthContext";
import UserContext from "../../../context/UserContext";
import type { NotPaidInvoices } from "../../../types/otherTypes";
import type { Contract } from "../../../types/responseTypes";

interface NotPaidInvoicesWarningsProps {
  contract: Contract;
  invoiceSummary: NotPaidInvoices;
  isPayAmountTooSmall: boolean;
}

const countContractInvoicesPdfDownloadAllowed = (contractInvoices) => {
  let counter = 0;
  for (const invoice of contractInvoices) {
    if (invoice.isPdfDownloadAllowed) {
      counter++;
    }
  }
  return counter;
};

const NotPaidInvoicesWarnings: React.FC<NotPaidInvoicesWarningsProps> = ({
  contract,
  invoiceSummary,
  isPayAmountTooSmall
}) => {
  const { t } = useTranslation();
  const { companiesCtx } = useContext(UserContext);
  const history = useHistory();
  const location = useLocation().pathname;
  const { langCtx } = useContext(AuthContext);

  const company = useMemo(
    () =>
      companiesCtx?.filter((company) => company?.id === contract?.companyId)[0],
    [companiesCtx, contract]
  );

  const hasCustomPayInit = invoiceSummary?.hasCustomPaymentInitialized;
  const customPayAmount = invoiceSummary?.customPaymentInProcessingAmount;
  const dueAmount = invoiceSummary?.totalDueAmount;
  const advancePaymentAllowed = company?.isAdvancePaymentAllowed;
  const partialPaymentAllowed = company?.isPartialPaymentAllowed;
  const minInvoicePaymentAmount = company?.minPaymentAmount;
  const balance = contract?.balance;
  const invoices = invoiceSummary?.invoices;

  return (
    <>
      {contract && invoiceSummary ? (
        <>
          {/* VALOAREA DE PLATA SUB SUMA MINIMA */}
          {invoiceSummary && invoices.length && isPayAmountTooSmall ? (
            <>
              {invoices.length === 1 || dueAmount < minInvoicePaymentAmount ? (
                <InfoBox color="warning">
                  {t("invoice.pay.minVal.sng", {
                    count: minInvoicePaymentAmount
                  })}
                </InfoBox>
              ) : (
                <InfoBox color="warning">
                  {t("invoice.pay.minVal.mtp", {
                    count: minInvoicePaymentAmount
                  })}
                </InfoBox>
              )}
            </>
          ) : (
            ""
          )}

          {/* PLATI FACTURI INITIATE SAU IN PROCESARE */}
          {invoiceSummary?.invoicesInitializedNr > 0 ? (
            <InfoBox color="warning">
              {t("invoice.flag.payInit", {
                count: invoiceSummary?.invoicesInitializedNr
              })}
            </InfoBox>
          ) : (
            ""
          )}

          {invoiceSummary?.invoicesProcessingNr > 0 ? (
            <InfoBox color="primary">
              {t("invoice.flag.processing", {
                count: invoiceSummary?.invoicesProcessingNr
              })}
            </InfoBox>
          ) : (
            ""
          )}

          {/* PLATI CUSTOM INITIATE SAU IN PROCESARE */}
          {invoiceSummary && hasCustomPayInit ? (
            <InfoBox color="warning">
              {invoices.length === 0 ? (
                <>{t("invoice.flag.advancePayInit")}</>
              ) : (
                <>{t("invoice.flag.partialPayInit")}</>
              )}
            </InfoBox>
          ) : (
            ""
          )}

          {invoiceSummary && customPayAmount > 0 ? (
            <InfoBox color="primary">
              {invoices.length === 0 ? (
                <>{t("invoice.flag.advancePayProcessing")}</>
              ) : isPayAmountTooSmall &&
                (invoices.length === 1 ||
                  dueAmount < minInvoicePaymentAmount) ? (
                <>{t("invoice.flag.advancePayProcessing")}</>
              ) : (
                <>{t("invoice.flag.partialPayProcessing")}</>
              )}
              <strong>
                {customPayAmount.toLocaleString(langCtx)} {t("currency")}
              </strong>
              {"."}
            </InfoBox>
          ) : (
            ""
          )}

          {/* PLATI CUSTOM APROBATE ERP */}
          {invoiceSummary &&
          (advancePaymentAllowed || partialPaymentAllowed) &&
          dueAmount > balance &&
          balance > 0 ? (
            <InfoBox color="tertiary">{t("customPayment.partial")}</InfoBox>
          ) : (
            ""
          )}

          {invoiceSummary &&
          (advancePaymentAllowed || partialPaymentAllowed) &&
          dueAmount > balance &&
          balance < 0 ? (
            <InfoBox color="tertiary">
              {t("customPayment.advance")}{" "}
              <strong>
                {balance.toLocaleString(langCtx)} {t("currency")}
              </strong>
              {"."}
            </InfoBox>
          ) : (
            ""
          )}

          {/* DOWNLOAD PDF INDISPONIBIL PT FACTURI PRIN CURIER */}
          {invoiceSummary &&
          countContractInvoicesPdfDownloadAllowed(invoices) === 0 &&
          invoices.length > 0 ? (
            <InfoBox color="tertiary">
              {t("invoice.download.disabled.t1")}{" "}
              <a
                className="cursor-hover"
                onClick={() =>
                  history.push({
                    pathname: `/contracts/${contract?.id}`,
                    state: { from: location }
                  })
                }
              >
                {t("invoice.download.disabled.t2")}
              </a>{" "}
              {t("invoice.download.disabled.t3")}
            </InfoBox>
          ) : (
            ""
          )}
        </>
      ) : (
        ""
      )}
    </>
  );
};

export default NotPaidInvoicesWarnings;
