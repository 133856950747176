import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { updateContextActions } from "../../../api/UpdateContextActions";
import CompanyFilter from "../../../components/Filters/CompanyFilter";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { InvoiceSummary } from "../../../types/responseTypes";
import { filterItems } from "../../../utils/globalFunctions";

import InvoicesContractList from "./InvoicesContractList";
import InvoicesLegend from "./InvoicesLegend";

const mergeContractDetails = (contractsCtx, invoiceSummaries) => {
  if (!contractsCtx?.length || !invoiceSummaries?.length) {
    return [];
  }
  return invoiceSummaries.map((summary) => {
    const currentContract = contractsCtx.find(
      (contract) => contract.id === summary.contractId
    );
    const mergedItem = { ...summary, ...currentContract };
    const { ...finalItem } = mergedItem;
    return finalItem;
  });
};

const InvoicesPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { menuCtx, contractsCtx, updateMenuCtx } = useContext(UserContext);
  const locationObj = useLocation()?.state as { origin: string };

  const [companyFilter, setCompanyFilter] = useState(0);

  const { data: invoiceSummaries, error } = useFetchCaching<InvoiceSummary[]>(
    "/contracts/invoices-summary"
  );

  const invoiceContracts = mergeContractDetails(contractsCtx, invoiceSummaries);
  const unpaidInvoices = menuCtx?.totalUnpaidInvoices;
  const numberOfContracts = contractsCtx?.length;

  const companyFilterChangeHandler = (selectedCompany) => {
    setCompanyFilter(selectedCompany);
  };

  const filteredContracts = filterItems(invoiceContracts, companyFilter);

  useEffect(() => {
    if (locationObj) {
      const { origin } = locationObj;
      if (origin === "payment") {
        updateContextActions
          .getMenuDetails()
          .then((data) => updateMenuCtx(data));
      }
    }
  }, []);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" style={{ flexDirection: "column" }}>
              <IonLabel className="ag-h2 black">{t("tabs.invoices")}</IonLabel>
              {unpaidInvoices === 0 ? (
                <IonLabel className="ag-body-text gray">
                  {t("invoiceToPayCountZero")}
                </IonLabel>
              ) : (
                <IonLabel className="ag-body-text gray">
                  {t("invoiceToPayCount", {
                    count: unpaidInvoices
                  })}{" "}
                </IonLabel>
              )}
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"invoices"}
        menuSelected={"item1"}
      >
        <SkeletonList loading={!invoiceSummaries} error={error} />
        {!error && invoiceSummaries ? (
          <>
            <CompanyFilter onChangeCompanyFilter={companyFilterChangeHandler} />
            <InvoicesContractList
              filteredContracts={filteredContracts}
              numberOfContracts={numberOfContracts}
            />
            <InvoicesLegend filteredContracts={filteredContracts} />
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default InvoicesPage;
