import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory, useLocation } from "react-router";

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonPage,
  IonToolbar
} from "@ionic/react";

import BackBtn from "../../../components/UI/BackBtn";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ImageCardLayout from "../../../layout/ImageCardLayout/ImageCardLayout";

import styles from "./TransactionResultPage.module.scss";

export const TransactionResultPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const history = useHistory();

  const urlQueryParams = useLocation().search;
  // orderId vine de la unii procesatori
  // aOrderId este nr nostru si stim sigur ca vine mereu!
  const transactionId = new URLSearchParams(urlQueryParams).get("aOrderId");
  // pt platformele mobile, vine parametrul isMobile din backend pt ca nu
  // putem depista device-ul dintr-un browser extern, deschis pt plata
  const isMobile = new URLSearchParams(urlQueryParams).get("isMobile");
  const isCustomPayment = new URLSearchParams(urlQueryParams).get(
    "isCustomPayment"
  );

  const backBtnHandler = () => {
    if (isMobile === "true") {
      window.location.replace(`aqmeter:invoices`);
    } else {
      history.push({
        pathname: `/invoices`,
        state: { origin: "payment" }
      });
    }
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" ? (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={"start"}>
              <BackBtn clickAction={backBtnHandler} />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : (
        ""
      )}

      <ImageCardLayout
        title={t("paymentResult.successTitle")}
        imgUrl={"./assets/img/payment.jpg"}
        logo={false}
      >
        {isCustomPayment ? (
          <p className={styles.gray}>{t("paymentResult.successCustomDesc")}</p>
        ) : (
          <p className={styles.gray}>{t("paymentResult.successInvoiceDesc")}</p>
        )}

        <p className={styles.gray}>
          {t("paymentResult.successOrderId")}
          <br />
          <span className={styles.black}>{transactionId}</span>
        </p>

        {layout !== "phone" ? (
          <IonButton color="dark" onClick={backBtnHandler}>
            {t("backToInvoices")}
          </IonButton>
        ) : (
          ""
        )}
      </ImageCardLayout>
    </IonPage>
  );
};
