import React, { useContext } from "react";
import {
  cardOutline,
  chevronForward,
  documentsOutline,
  duplicateOutline,
  mailOpenOutline,
  timerOutline
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";

import UserContext from "../../../context/UserContext";

import classes from "../DashboardPage.module.scss";
import styles from "./QuickActionsCard.module.scss";

const QuickActionsCard: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { menuCtx } = useContext(UserContext);

  return (
    <div className={classes.actionsCard}>
      <IonLabel className="ag-h2">{t("quickAct.title")}</IonLabel>
      <div className={styles.wrapper}>
        <IonList className={styles.actionsList}>
          <IonItem
            key="contracts"
            className="cursor-hover"
            onClick={() => history.push("/contracts")}
          >
            <div slot="start" className={styles.actionsIcon}>
              <IonIcon icon={duplicateOutline} />
            </div>
            <div className={styles.textBox}>
              <span className="ag-cta">{t("quickAct.act1.title")}</span>
              <span className="ag-number-description">
                {t("quickAct.act1.desc")}
              </span>
            </div>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          <IonItem
            key="invoices"
            className="cursor-hover"
            onClick={() => history.push("/invoices")}
          >
            <div slot="start" className={styles.actionsIcon}>
              <IonIcon icon={cardOutline} />
            </div>
            <div className={styles.textBox}>
              <span className="ag-cta">{t("quickAct.act2.title")}</span>
              <span className="ag-number-description">
                {t("quickAct.act2.desc")}
              </span>
            </div>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          <IonItem
            key="invoices-history"
            className="cursor-hover"
            onClick={() => history.push("/invoices/paid")}
          >
            <div slot="start" className={styles.actionsIcon}>
              <IonIcon icon={documentsOutline} />
            </div>
            <div className={styles.textBox}>
              <span className="ag-cta">{t("quickAct.act3.title")}</span>
              <span className="ag-number-description">
                {t("quickAct.act3.desc")}
              </span>
            </div>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          {menuCtx?.hasWaterContracts ? (
            <IonItem
              key="readings"
              className="cursor-hover"
              onClick={() => history.push("/readings")}
            >
              <div slot="start" className={styles.actionsIcon}>
                <IonIcon icon={timerOutline} />
              </div>
              <div className={styles.textBox}>
                <span className="ag-cta">{t("quickAct.act4.title")}</span>
                <span className="ag-number-description">
                  {t("quickAct.act4.desc")}
                </span>
              </div>
              <IonIcon className="chevron" icon={chevronForward} />
            </IonItem>
          ) : (
            ""
          )}

          <IonItem
            lines="none"
            key="contact"
            className="cursor-hover"
            onClick={() => history.push("/contact")}
          >
            <div slot="start" className={styles.actionsIcon}>
              <IonIcon icon={mailOpenOutline} />
            </div>
            <div className={styles.textBox}>
              <span className="ag-cta">{t("quickAct.act5.title")}</span>

              <span className="ag-number-description">
                {t("quickAct.act5.desc")}
              </span>
            </div>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>
        </IonList>
      </div>
    </div>
  );
};

export default QuickActionsCard;
