import React, { useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import SignatureCanvas from "react-signature-canvas";

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonLabel
} from "@ionic/react";

import { userActions } from "../../../../../api/UserActions";
import { useWindowSize } from "../../../../../hooks/useWindowSize";
import type { SignatureUploadedFile } from "../../../../../types/contractDemandsTypes";
import type { Company } from "../../../../../types/responseTypes";
import { DataURIToBlob } from "../../../../../utils/globalFunctions";
import WizardMenu from "../../WizardMenu";

import styles from "../SignaturePage.module.scss";

interface SignatureProps {
  step: number;
  setStep(step: number): void;
  setTrimmedDataSig(trimmedData: string): void;
  trimmedDataSig: string;
}

const Signature: React.FC<SignatureProps> = ({
  step,
  setStep,
  trimmedDataSig,
  setTrimmedDataSig
}) => {
  const { layout } = useWindowSize();
  const [checked, setChecked] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const wrapperRef = useRef<any>(null);
  const sigPadRef = useRef<SignatureCanvas>(null);

  const clearSig = () => {
    sigPadRef?.current.clear();
  };

  useEffect(() => {
    setTrimmedDataSig(null);
  }, []);

  const trimSig = () => {
    setTrimmedDataSig(
      sigPadRef.current.getTrimmedCanvas().toDataURL("image/png")
    );
  };

  const sendSignature = async () => {
    setIsLoading(true);
    setDisabled(true);
    try {
      const blobSignature = DataURIToBlob(trimmedDataSig);
      const data = new FormData();
      data.append("signatureFile", blobSignature, "dummy.png");
      const companyId: Company = JSON.parse(
        sessionStorage.getItem("selectedCompanyFromLocationObj")
      );
      data.append("companyId", `${companyId.id}`);
      const response: SignatureUploadedFile =
        await userActions.uploadSignatureFile(data);
      sessionStorage.setItem(
        "signatureFileName",
        JSON.stringify({ signatureName: response.storedFileName })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
      setDisabled(false);
    }
  };

  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={styles.title}>
            {t("demands.signature.title")}
          </IonCardTitle>
          {!trimmedDataSig ? (
            <IonCardContent style={{ textAlign: "center" }}>
              {t("demands.signature.infoLabel")}
            </IonCardContent>
          ) : (
            <IonCardContent
              style={{ textAlign: "center", color: "var(--ion-color-primary)" }}
            >
              {t("demands.signature.successful")}
            </IonCardContent>
          )}
        </IonCardHeader>
        {/* <div className={styles.signatureWrapper}>
          <SignatureCanvas
            penColor="green"
            canvasProps={{
              width: 500,
              height: 300,
              className: `${styles.sigCanvas}`,
            }}
          />
        </div> */}
        <div className={styles.signatureWrapper} ref={wrapperRef}>
          <div>
            <div onMouseUp={trimSig} onTouchEnd={trimSig}>
              <SignatureCanvas
                clearOnResize={false}
                canvasProps={
                  // wrapperRef?.current?.offsetWidth
                  //   ? styleObject
                  //   :

                  layout === "phone"
                    ? {
                        width: 300,
                        height: 300,
                        className: styles.sigCanvas
                      }
                    : {
                        width: 447,
                        height: 300,
                        className: styles.sigCanvas
                      }
                }
                ref={sigPadRef}
              />
            </div>
            <div className={styles.sigButton}>
              <IonButton
                color="danger"
                onClick={() => {
                  clearSig();
                  setTrimmedDataSig(null);
                }}
              >
                {t("demands.signature.buttonLabel")}
              </IonButton>
            </div>
          </div>
          {/* )} */}
        </div>

        <div className={styles.checkboxCard}>
          <IonCheckbox
            checked={checked}
            slot="start"
            color="tertiary"
            onClick={() => setChecked((prev) => !prev)}
          />
          <IonLabel color="tertiary">
            {t("demands.signature.allowance")}
          </IonLabel>
        </div>
      </IonCard>

      <WizardMenu
        disabled={!(checked && trimmedDataSig) || disabled}
        isLoading={isLoading}
        setStep={setStep}
        step={step}
        type={"signature"}
        sendSignature={sendSignature}
      />
    </div>
  );
};

export default Signature;
