import React from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../../components/UI/BackBtn";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import i18n from "../../../../i18n";
import type { Language } from "../../../../types/otherTypes";

import { TermsContentEN } from "./TermsContentEN";
import { TermsContentRO } from "./TermsContentRO";

export const TermsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const lang = i18n.resolvedLanguage as Language;

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout layoutType={"back"}>
        <IonLabel className="ag-h2 black">{t("termsAndConditions")}</IonLabel>
        {lang === "en" ? <TermsContentEN /> : <TermsContentRO />}
      </SidebarLayout>
    </IonPage>
  );
};
