import React, { useContext, useEffect, useState } from "react";
import { eye, eyeOff } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { getPlatforms, IonButton, IonIcon, IonSpinner } from "@ionic/react";

import FormFeedback from "../../../components/UI/FormFeedback";
import AuthContext from "../../../context/AuthContext";
import ResendConfirmationNotice from "../../../features/ResendConfirmationNotice";
import { startFirebase } from "../../../firebaseInit";
import useLogin from "../../../hooks/useLogin";
import type { UnconfirmedResponse } from "../../../types/otherTypes";
import type { LoginData, LoginRequest } from "../../../types/requestTypes";
import type { ExceptionDto } from "../../../types/schema";
import { getValueOfSearchedUrlQueryParameter } from "../../../utils/globalFunctions";

import TwoFALogin from "./TwoFALogin/TwoFALogin";
import LoginSocialButtons from "./LoginSocialButtons";

import styles from "./Login.module.scss";

interface LoginCardProps {
  setIs2FA: (value: boolean) => void;
  is2FA: boolean;
}

const LoginCard: React.FC<LoginCardProps> = ({ setIs2FA, is2FA }) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateAuthCtx } = useContext(AuthContext);
  const login = useLogin();
  const restrictedToCompany = getValueOfSearchedUrlQueryParameter("ca");

  const [email, setEmail] = useState<string>("");
  const [password, setPassword] = useState<string>("");
  const [isLoading, setIsLoading] = useState(false);
  const [fcmToken, setFcmToken] = useState<string>("");
  const [showPassword, setShowPassword] = useState(false);
  const [unconfirmedType, setUnconfirmedType] = useState<UnconfirmedResponse>();
  const [error, setError] = useState<string>(null);
  const [login2FAState, setLogin2FAState] = useState<LoginData>({
    email: "",
    password: "",
    fcmToken: "",
    pin: null
  });

  useEffect(() => {
    const storedFcm = localStorage.getItem("fcm");
    if (storedFcm) {
      setFcmToken(storedFcm);
    } else {
      startFirebase().then((currentToken) => setFcmToken(currentToken));
    }

    return () => setIsLoading(false);
  }, [fcmToken]);

  const submitHandler = async (event) => {
    event.preventDefault();
    setIsLoading(true);

    setLogin2FAState((prevState) => {
      return {
        ...prevState,
        email: email.trim(),
        password: password,
        fcmToken: fcmToken
      };
    });

    const data: LoginRequest = {
      email: email.trim(),
      password: password,
      fcmToken: fcmToken,
      pin: null,
      platforms: getPlatforms()
    };

    await login(data)
      .then((result) => {
        if (!result?.has2FA) {
          updateAuthCtx(result);
          return;
        }
        setIs2FA(result?.has2FA);
      })
      .catch((err) => {
        setIsLoading(false);
        const exception: ExceptionDto = err.response?.data ?? err.data;

        if (
          err.response?.status === 400 ||
          err.response?.status === 401 ||
          err.status === 400 ||
          err.status === 401
        ) {
          if (exception.message === "SOCIAL_LOGIN_ONLY_EXCEPTION") {
            setError(t("err.SOCIAL_LOGIN_ONLY_EXCEPTION"));
          } else if (exception.message === "USER_IS_DELETED") {
            setError(t("err.USER_IS_DELETED"));
          } else if (exception.message === "USER_IS_UNCONFIRMED") {
            setUnconfirmedType({
              type: "account",
              resendUrl: exception.resendUrl
            });
          } else if (exception.message === "USER_HAS_PASSWORD_UNCONFIRMED") {
            setUnconfirmedType({
              type: "password",
              resendUrl: exception.resendUrl
            });
          } else {
            setError(t("err.INVALID_LOGIN"));
          }
        } else {
          setError(t("err.GENERIC_ERROR"));
        }
      });
  };

  const registerHandler = async () => {
    if (restrictedToCompany) {
      history.push(`/register?ca=${restrictedToCompany}`);
    } else {
      history.push("/register");
    }
  };

  useEffect(() => {
    if (unconfirmedType) {
      setUnconfirmedType(null);
    }
    if (error) {
      setError(null);
    }
  }, [email, password]);

  return (
    <div className={styles.loginForm}>
      {!is2FA ? (
        <>
          <form onSubmit={submitHandler}>
            <input
              value={email}
              onChange={(e) => setEmail(e.target.value as string)}
              type="email"
              autoComplete="email"
              placeholder={t("e-mail")}
              required
            />
            <div className={styles.password}>
              <input
                value={password}
                onChange={(e) => setPassword(e.target.value as string)}
                type={showPassword ? "text" : "password"}
                autoComplete="current-password"
                placeholder={t("login.password")}
                required
              />
              <IonIcon
                onClick={() => setShowPassword((prevState) => !prevState)}
                icon={showPassword ? eyeOff : eye}
                className={showPassword ? styles.off : styles.on}
              />
            </div>

            <input type="submit" className="submit-enter" />

            {!unconfirmedType ? (
              <IonButton
                type="submit"
                color="primary"
                strong={true}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <IonSpinner name="circles" /> {t("login.loading")}
                  </>
                ) : (
                  <>{t("login.cta")}</>
                )}
              </IonButton>
            ) : (
              ""
            )}

            {unconfirmedType?.type === "account" ? (
              <ResendConfirmationNotice
                type={"account"}
                resendUrl={unconfirmedType?.resendUrl}
              />
            ) : unconfirmedType?.type === "password" ? (
              <ResendConfirmationNotice
                type={"password"}
                resendUrl={unconfirmedType?.resendUrl}
              />
            ) : (
              ""
            )}

            {error ? <FormFeedback type={"error"}>{error}</FormFeedback> : ""}
          </form>

          <LoginSocialButtons />

          <IonButton
            fill="clear"
            color="primary"
            strong={true}
            size="small"
            onClick={() => registerHandler()}
          >
            {t("login.signUpButton")}
          </IonButton>
          <IonButton
            fill="clear"
            color="dark"
            strong={true}
            size="small"
            onClick={() => history.push("/forgot-password")}
          >
            {t("login.forgotPassword")}
          </IonButton>
        </>
      ) : (
        <TwoFALogin credentials={login2FAState} source={"login"} />
      )}
    </div>
  );
};
export default LoginCard;
