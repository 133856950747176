import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { IonButton, IonInput, IonLabel, IonSpinner } from "@ionic/react";

import { contractActions } from "../../api/ContractActions";
import { CompanyHeader } from "../../components/CompanyHeader";
import FormFeedback from "../../components/UI/FormFeedback";
import type { AddContract } from "../../types/requestTypes";
import type {
  Company,
  ValidateContractResponse
} from "../../types/responseTypes";
import type { ExceptionDto } from "../../types/schema";

import styles from "../../pages/4.Contracts/AddContract/AddContractPage.module.scss";

interface AddContractVerifyDetailsProps {
  selectedCompany: Company;
  contractNumber: string;
  setContractNumber: (value: string) => void;
  invoiceNumber: string;
  setInvoiceNumber: (value: string) => void;
  setContractDetails: (value: ValidateContractResponse) => void;
  setStep?: (value) => void;
}

const AddContractVerifyDetails: React.FC<AddContractVerifyDetailsProps> = ({
  selectedCompany,
  contractNumber,
  setContractNumber,
  invoiceNumber,
  setInvoiceNumber,
  setContractDetails,
  setStep
}) => {
  const { t } = useTranslation();

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();

  const submitHandler = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const data: AddContract = {
        companyId: selectedCompany?.id,
        contractNumber: contractNumber,
        invoiceNumber: invoiceNumber
      };
      const response = await contractActions.validate(data);
      if (!response.valid) {
        setErrorMsg(t("err.NO_CONTRACT_FOUND"));
      } else {
        setContractDetails(response);
        if (setStep) {
          setStep((prevState) => prevState + 1);
        }
      }
    } catch (err) {
      const ex: ExceptionDto = err.response.data;
      setErrorMsg(t([`err.${ex.message}`, "err.__"]));
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    setErrorMsg(null);
  }, [contractNumber, invoiceNumber]);

  return (
    <>
      <CompanyHeader company={selectedCompany} />
      <form className={styles.verificationForm} onSubmit={submitHandler}>
        <IonLabel>
          {selectedCompany.id !== 6 // Cod client pentru AQUACARAS
            ? t("contract.no")
            : t("contract.clientCode")}
        </IonLabel>
        <IonInput
          data-testid={"contract-number-input"}
          onIonChange={(e) => setContractNumber(e.target.value as string)}
          value={contractNumber}
          type="text"
          inputmode="text"
          autocomplete="off"
          placeholder={t("placeholder.addHere")}
          className={errorMsg ? "error" : ""}
        />

        <IonLabel>{t("contract.invoiceNo")}</IonLabel>
        <IonInput
          data-testid={"invoice-number-input"}
          onIonChange={(e) => setInvoiceNumber(e.target.value as string)}
          value={invoiceNumber}
          type="text"
          inputmode="text"
          autocomplete="off"
          className={errorMsg ? "error" : ""}
          placeholder={t("placeholder.addHere")}
        />

        <IonButton
          className="ag-cta"
          type="submit"
          expand="block"
          fill="solid"
          disabled={(!contractNumber && !contractNumber) || isLoading}
        >
          {isLoading ? (
            <>
              <IonSpinner name="circles" /> {t("verifying")}
            </>
          ) : (
            t("verify")
          )}
        </IonButton>

        {errorMsg ? <FormFeedback type={"error"}>{errorMsg}</FormFeedback> : ""}
      </form>
    </>
  );
};

export default AddContractVerifyDetails;
