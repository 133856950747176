import React from "react";
import { Link } from "react-router-dom";

export const TermsContentRO: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <h5>
        VĂ RUGĂM SĂ CITIȚI ACEST DOCUMENT CU ATENȚIE ÎNAINTE DE A UTILIZA
        SERVICIILE NOASTRE.
      </h5>

      <p className="help-last-update">Ultima modificare: 5 aprilie 2018</p>
      <h6>Acceptarea Termenilor de Utilizare</h6>
      <p className="help-strong">
        Bine ați venit în platforma Aqmeter sau pe site-ul web{" "}
        <Link
          to="https://app.aqmeter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://app.aqmeter.com
        </Link>
        , operate de către Companie (în sensul descris mai jos al acestui
        termen). Accesarea acestui site sau utilizarea Serviciilor oferite de
        Companie implică acceptarea necondiționată a termenilor și condițiilor
        enumerate mai jos („Termenii”).
      </p>
      <ol className="help-nested-list">
        <li>
          <span className="help-strong">Definiții. Scop</span>

          <ol>
            <li>
              Prezentul document conține termenii și condițiile pentru prestarea
              de servicii online prin intermediul platformei Aqmeter și al
              website-ului{" "}
              <Link
                to="https://app.aqmeter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.aqmeter.com
              </Link>
              .
            </li>

            <li>
              Următorii termeni folosiți cu majuscule vor avea înţelesurile de
              mai jos, cu excepția cazului în care se prevede expres altfel:
            </li>

            <span className="definitions">
              <label>”Compania” sau ”Prestatorul” </label>
              <span>
                înseamnă Set Mobile S.R.L., o societate cu răspundere limitată,
                constituită și funcționând în conformitate cu legile din
                România, având sediul social în Bucuresti, str. Rovine, nr.3,
                Sc.A, Ap.21, număr de înregistrare în Registrul Comerțului
                J40/16520/2003, cod unic de identificare RO15967256.
              </span>
            </span>

            <span className="definitions">
              <label>”Operatorul de utilități” sau ”Operatorul” </label>
              <span>
                conform art. 2 lit. g) şi h) din Legea nr. 51/2006, republicată,
                înseamnă persoana juridica română sau străină care are
                competența și capacitatea recunoscute prin licența de a
                furniza/presta, în condițiile reglementarilor în vigoare, un
                serviciu de utilități publice și care asigură nemijlocit
                administrarea și exploatarea sistemului de utilități publice
                aferent acestuia.
              </span>
            </span>

            <span className="definitions">
              <label>“Aplicația Aqmeter” sau ”Aplicația” </label>
              <span>
                înseamnă aplicația software dezvoltată de către Companie și
                comercializată de către aceasta prin intermediul acestei
                denumiri, prin care Utilizatorii pot înregistra autocitiri ale
                contoarelor de apă și efectua plăți online pentru facturi de
                servicii oferite de Operatorul de utilități, accesibilă la
                site-ul web descris în preambul și eventual pentru a fi
                descărcată de către Utilizatori prin intermediul Apple App Store
                și Google Play.
              </span>
            </span>

            <span className="definitions">
              <label>“Comanda” </label>
              <span>
                înseamnă o comandă plasată de Utilizator prin intermediul
                Platformei prin care Utilizatorul îşi exprimă angajamentul de a
                achiziţiona unul sau mai multe Servicii, în condițiile prevăzute
                în prezentul document și/sau agreate cu Aqmeter printr-un mijloc
                de comunicare la distanță.
              </span>
            </span>

            <span className="definitions">
              <label>“Servicii” </label>
              <span>
                înseamnă orice servicii care sunt oferite Utilizatorului în
                vederea achiziţionării, contra cost sau gratuit prin intermediul
                Platformei/Aplicației.
              </span>
            </span>

            <span className="definitions">
              <label>“Platforma Aqmeter” sau “Platforma” </label>
              <span>
                înseamnă totalitatea Aplicațiilor și Serviciilor deținute de
                Companie, publicate prin intermediul domeniului aqmeter.com și
                utilizând logo-uri ale Aqmeter, prin intermediul căreia Compania
                oferă servicii în numele Operatorului. În principal, prin
                intermediul Platformei, se înregistrează citiri de contoare de
                apă, respectiv se procesează plățile aferente facturilor de
                servicii pe care Operatorul le emite Utilizatorilor.
              </span>
            </span>

            <span className="definitions">
              <label>“Utilizator” </label>
              <span>
                înseamnă persoana fizică ce accesează Platforma, își
                înregistrează un Cont și utilizează Aplicațiile și Serviciile
                oferite de către Platformă .
              </span>
            </span>

            <span className="definitions">
              <label>“Cont” </label>
              <span>
                înseamnă secțiunea din Platformă accesibilă Utilizatorului prin
                utilizarea, cu rol de identificare, a unei adrese de e-mail și a
                unei parole, care permite Utilizatorului transmiterea citirilor
                de contoare de apă și plata unei facturi de servicii către
                Operator și care conține informații despre Utilizator și
                istoricul Utilizatorului în Platformă (e.g., Citiri anterioare,
                Facturi fiscale etc.).
              </span>
            </span>

            <span className="definitions">
              <label>“Contract” </label>
              <span>
                înseamnă înţelegerea intervenită între Operator şi Utilizator,
                cu scopul de furnizare/prestare a serviciilor, în condiţiile
                prevăzute de legislaţia în vigoare, respectiv de regulamentul
                serviciului de utilități.
              </span>
            </span>

            <span className="definitions">
              <label>”Furnizor Agreat” </label>
              <span>
                înseamnă o persoană juridică inclusă în Aplicația Aqmeter sau
                site-ul web aqmeter.com, ca urmare a unui contract încheiat
                între unul sau mai mulți dintre furnizorii de apă și Companie.
              </span>
            </span>

            <span className="definitions">
              <label>”Informaţiile Confidenţiale” </label>
              <span>
                înseamnă orice informație în legătură cu Utilizatorul sau
                Compania, asociaţii şi afiliații acestora sau cu Contractul și
                pusă la dispoziție fie înainte sau după data semnării.
              </span>
            </span>

            <span className="definitions">
              <label>“Newsletter” </label>{" "}
              <span>
                înseamnă mijloc de informare periodic, exclusiv electronic,
                respectiv poșta electronică sau altă formă de comunicare la
                distanţă (e.g., e-mail, SMS) cu privire la Serviciile și/sau la
                promoțiile desfășurate de Prestator sau de afiliații acestuia,
                într-o anumită perioadă, fără niciun angajament din partea
                Prestatorului cu referire la informațiile conținute de acesta.
              </span>
            </span>

            <span className="definitions">
              <label>“Specificații” </label>
              <span>
                înseamnă orice detalii privind caracteristicile Serviciilor așa
                cum sunt precizate în descrierea disponibilă pe Platformă.
              </span>
            </span>

            <span className="definitions">
              <label>“Tranzacție” </label>
              <span>
                înseamnă operaţiunea de plată efectuată de către Utilizator,
                respectiv încasare efectuate prin intermediul Platformei în
                numele și pentru Operator, a contravalorii unei facturi de
                servicii ca urmare a unei prestări anterioare efectuate de către
                Operator în beneficiul Utilizatorului.
              </span>
            </span>

            <li>
              Prezentul document stabilește termenii şi condiţiile aplicabile
              raporturilor ce se nasc între Aqmeter şi fiecare Utilizator care
              utilizează Platforma în vederea, cu ocazia sau după efectuarea
              unei Comenzi.
            </li>

            <li>
              Titlurile sunt incluse în acest document pentru convenienţă şi nu
              vor afecta interpretarea acestuia.
            </li>

            <li>
              Pentru evitarea oricărui dubiu, în acord cu politica de afaceri
              stabilită în mod independent și unilateral de către Aqmeter,
              prestarea în mediul online (i.e., prin intermediul Platformei) a
              Serviciilor prestate în mod obișnuit de Aqmeter se face atât către
              consumatori persoane fizice, cât și pentru persoane juridice
              (Companiile de utilități – Operatorii).
            </li>

            <li>
              Aqmeter recomandă lecturarea cu atenţie de către toți potenţialii
              Clienţi a prezentului document ce conține termenii și condițiile
              aplicabile prestării online a Serviciilor Aqmeter.
            </li>

            <li>
              Aspectele privind confidențialitatea și prelucrarea datelor cu
              caracter personal legate de utilizarea, în orice formă, a
              Platformei sunt guvernate de politica de confidențialitate
              („Politica de Confidențialitate”), care completează aceşti Termeni
              și Condiții. Vă rugăm să parcurgeţi în întregime{" "}
              <Link to="/privacy">Politica noastră de Confidențialitate</Link>.
            </li>

            <li>
              Utilizarea Platformei implică acceptarea expresă de către orice
              Utilizator a acestor termeni şi condiţii şi a Politicii de
              Confidenţialitate.
            </li>

            <li>
              Prestatorul îşi rezervă dreptul să modifice oricând termenii și
              condițiile, iar forma actualizată a acestora va putea fi accesată
              de către Clienți pe Platformă în secțiunea ”Termeni și condiții”.
            </li>

            <li>
              Acești termeni şi condiții aplicabile prestării online de servicii
              sunt disponibile Utilizatorului pentru stocare şi reproducere.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Politica Platformei. Caracteristici principale ale prestării online
            a serviciilor de către Aqmeter
          </span>
          <ol>
            <li>
              Accesul la Platformă în vederea efectuării unei Comenzi este
              permis oricărui potenţial Utilizator care acţionează cu un scop
              legitim şi care are intenţia de a achiziţiona de la Prestator unul
              sau mai multe Servicii, cu respectarea acestor termeni și
              condiţii.
            </li>

            <li>
              Este strict interzisă utilizarea abuzivă a Platformei. Reprezintă
              utilizare abuzivă orice fel de utilizare a Platformei într-un mod
              care contravine practicilor comerciale corecte, a legislației în
              vigoare sau în orice alt mod care ar fi de natură să prejudicieze
              în orice fel Aqmeter sau afiliații acestuia.
            </li>

            <li>
              Aqmeter îşi rezervă dreptul de a refuza accesul unor Clienți la o
              parte sau la toate funcțiile Platformei și de a restricționa
              procesarea și/sau livrarea unei Comenzi dacă, în opinia rezonabilă
              a Aqmeter, există suspiciuni de fraudă din partea Utilizatorului,
              dacă acesta are un comportament care poate prejudicia interesele
              Aqmeter sau ale afiliaților acestuia sau dacă acesta utilizează în
              mod abuziv Platforma.
            </li>

            <li>
              Prestatorul își rezervă dreptul de refuza prestarea Serviciilor în
              scopuri ilicite și imorale. În acest sens în cazul în care
              Prestatorul consideră că solicitarea Serviciilor poate avea un
              scop imoral sau ilicit acesta va informa Utilizatorul despre
              refuzul de a presta Serviciilor și va returna contravaloarea
              acestora (dacă au fost achitate) fără costuri suplimentare.
            </li>

            <li>
              Prestatorul va putea refuza prestarea Serviciilor și în cazul în
              care Utilizatorul abuzează de modul de completare a Serviciilor
              așa cum este explicat pe website și nu urmează instrucțiunile
              Prestatorului existente pe website cu privire la trimiterea unei
              comenzi.
            </li>

            <li>
              Prin utilizarea Platformei, Utilizatorul acceptă şi este de acord
              cu forma de comunicare la distanţă (e.g., telefonic sau e-mail)
              prin care Prestatorul își derulează operațiunile.
            </li>

            <li>
              Toate informațiile folosite pentru descrierea Serviciilor
              (inclusiv, dar fără a se limita la, imagini statice ori dinamice,
              text descriptiv, prezentări grafice ori video) nu impun nicio
              obligaţie Prestatorului, acestea având exclusiv rol de prezentare
              şi informare. Prestatorul va depune toate diligenţele rezonabile
              pentru a asigura corectitudinea informațiilor cu privire la
              prețuri, Servicii și Specificațiile acestora prezentate pe
              Platformă.
            </li>

            <li>
              Dacă prețurile sau orice alte detalii în legătură cu
              Specificațiile Serviciilor au fost afișate eronat pe Platformă,
              din orice motiv, Prestatorul va informa prin e-mail Utilizatorul
              care s-a înregistrat în Platformă, în cel mai scurt timp în
              legătură cu o astfel de eroare.
            </li>

            <li>
              Compania poate publica pe Platformă informații despre Servicii
              și/sau promoții practicate de către acesta sau de către oricare
              alt terț cu care Compania are încheiate contracte de parteneriat,
              într-o anumită perioadă de timp.
            </li>

            <li>
              Serviciile care fac obiectul prestării în cadrul unei promoţii sau
              campanii de orice tip se vor supune şi termenilor şi condiţiilor
              aplicabile respectivei campanii sau promoţii.
            </li>

            <li>
              Orice promoții prezentate pe Platformă sunt valabile pe durata
              menționată. În cazul în care nu este indicată o durată pentru
              desfășurarea promoțiilor, acestea vor fi valabile în limitele
              stocurilor disponibile.
            </li>

            <li>
              Comanda se consideră acceptată de către Aqmeter la momentul
              efectuării plății de către Utilizator către Furnizorul Agreat prin
              intermediul mijloacelor electronice de plată (ie. prin intermediul
              unui card de credit/debit sau prin intermediul unui transfer
              bancar, în acest din urmă caz comanda fiind considerată acceptată
              la momentul confirmării de către Furnizorul agreat a debitării
              contului bancar de către Utilizator).
            </li>

            <li>
              Pentru motive justificate (inclusiv, dar fără a se limita la
              suspiciunea de fraudă din partea Utilizatorului, neefectuarea
              plății sau imposibilitatea tehnică a Platformei de a furniza
              respectivele Servicii), Prestatorul își rezervă dreptul de a anula
              Comanda plasată de Utilizator. Într-o astfel de situaţie, Aqmeter
              va înştiinţa de îndată Utilizatorul cu privire la modificarea
              intervenită iar Utilizatorul va putea să accepte ori să refuze
              modificarea Comenzii (în situația în care modificarea este de
              ordin temporal sub aspectul livrării Serviciilor). În cazul în
              care Utilizatorul refuză, Comanda se consideră anulată, iar
              părţile vor fi repuse în situaţia anterioară emiterii Comenzii
              (inclusiv rambursarea de către Prestator a oricăror sume încasate,
              dacă este cazul) și fără ca Prestatorul să aibă vreo răspundere
              față de Utilizator.
            </li>

            <li>
              Comanda este acceptată iar Contractul se consideră încheiat între
              Prestator și Utilizator, în momentul primirii de către Utilizator
              de la Prestator, prin intermediul poștei electronice și/sau SMS, a
              notificării privind efectuarea cu succes a plății Serviciilor.
              Plata Serviciilor se va putea face exclusiv conform
              instrucțiunilor afișate pe site, incluzând, dar fără a se limita
              la plata prin intermediul cardurilor online sau a transferului
              bancar. Din Contract fac parte atât acești termeni şi condiţii cât
              şi orice înţelegeri ulterioare intervenite între Utilizator şi
              Prestator cu privire la o Comandă, indiferent dacă acestea sunt
              sau nu cuprinse într-un înscris electronic sau pe suport fizic
              (inclusiv, dar fără a se limita la, orice înțelegere între Aqmeter
              și Utilizator cu privire la Produsele realizate de Aqmeter conform
              specificațiilor Utilizatorului).
            </li>

            <li>
              Prestatorul pune la dispoziţia Utilizatorului o adresă de e-mail
              în secţiunea „Contact” din Platformă, pentru identificarea şi
              corectarea erorilor survenite cu ocazia introducerii datelor. Ca
              regulă, comunicarea cu Prestatorul se va realiza prin adresele
              menționate la secțiunea “Contact” din Platformă.
            </li>

            <li>
              Prestatorul poate să adere oricând la coduri de conduită privind
              activitatea de furnizare online a Serviciilor urmând să aducă la
              cunoştinţa Clienţilor acest fapt şi să asigure posibilitatea
              Clienților de a consulta efectiv aceste coduri.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Comenzile</span>
          <ol>
            <li>
              Utilizatorul poate efectua Comenzi prin intermediul Platformei,
              exclusiv prin înregistrarea prealabilă a unui Cont, prin
              selectarea și adăugarea Produselor dorite în coșul de cumpărături.
            </li>

            <li>
              Utilizatorul va înregistra un Cont în secțiunea dedicată din
              Platformă. Prin înregistrarea unui Cont, Utilizatorul va gestiona
              mai eficient Comenzile plasate pe Platformă, având posibilitatea
              de a vizualiza în Cont informații despre Comenzi anterioare,
              facturi fiscale etc. Înregistrarea unui Cont se va putea face prin
              intermediul furnizării unei adrese electronice de e-mail și a unei
              parole aferente sau prin intermediul logării cu diferite tipuri de
              rețele sociale (incluzând, dar fără a se limita la Facebook,
              Linkedin, Google etc.).Aqmeter își rezervă dreptul să valideze
              Comenzile înainte de onorarea lor prin contactarea telefonică sau
              prin e-mail a Clienților, iar Clienţii declară în mod expres că
              acceptă acest drept al Prestatorului.
            </li>

            <li>
              Adăugarea unui Serviciu în coșul de cumpărături (sau orice altă
              formă prin care se poate achiziționa, prin intermediul Aplicației
              Aqmeter sau al Platformei, un Serviciu), în lipsa finalizării
              Comenzii, nu duce la înregistrarea unei Comenzi.
            </li>

            <li>
              Comanda va fi considerată finalizată prin plata integrală de către
              Utilizator a prețului Produselor din Comandă, prin una dintre
              modalitățile de plată acceptate de Prestator astfel cum sunt
              indicate expres pe Platformă și care pot fi consultate de către
              Utilizator cel mai târziu la începutul procesului de formulare a
              Comenzii.
            </li>

            <li>
              Prin finalizarea Comenzii, Utilizatorul confirmă că toate datele
              pe care le-a furnizat sunt corecte, complete și adevărate la data
              plasării Comenzii. Prin plasarea unei Comenzi Utilizatorul
              recunoaşte în mod explicit că respectiva Comandă implică obligaţia
              sa fermă de a achita ”suma totală de plată” indicată.
            </li>

            <li>
              Prin crearea unui Cont şi, după caz, finalizarea Comenzii,
              Utilizatorul consimte că Prestatorul poate să îl contacteze, prin
              orice mijloc disponibil, respectiv sistem automatizat de apel fără
              intervenție umană, fax, e-mail, în orice situație în care este
              necesară contactarea Utilizatorului.
            </li>

            <li>
              Prestatorul poate anula Comanda plasată de către Utilizator, cu
              notificarea concomitentă sau ulterioară a Utilizatorului, și fără
              ca o astfel de anulare să atragă vreo răspundere a Aqmeter față de
              Utilizator, în următoarele cazuri:
            </li>

            <ul>
              <li>
                neacceptarea Tranzacției de către banca emitentă a cardului
                Utilizatorului, în cazul plății online;
              </li>
              <li>
                invalidarea Tranzacției de către procesatorul de carduri agreat
                de Aqmeter, în cazul plății online;
              </li>
              <li>
                datele furnizate de către Utilizator cu ocazia accesării
                Platformei sunt incomplete sau incorecte;
              </li>
              <li>
                Prestatorul consideră în mod rezonabil că prin accesarea
                Platformei şi plasarea Comenzii Utilizatorul urmărește un scop
                ilicit sau poate cauza orice fel de prejudicii Aqmeter sau
                afiliaților acestuia;
              </li>
              <li>
                oricare dintre termenii şi condiţiile din prezentul document nu
                au fost respectate întocmai.
              </li>
            </ul>
          </ol>
        </li>

        <li>
          <span className="help-strong">Prețuri. Facturare. Plată. Retur</span>
          <ol>
            <li>
              Prețurile Serviciilor afișate pe Platformă sunt exprimate în Lei
              și nu includ T.V.A. conform legislației în vigoare. Prețurile
              valabile pentru Servicii sunt cele afișate pe Platformă în
              momentul finalizării unei Comenzi.
            </li>

            <li>
              Plata Serviciilor afișate în Platformă/Aplicație se face de către
              Utilizator direct în contul Furnizorului agreat (ie. compania de
              utilități), Platforma/Aplicația Aqmeter nefiind răspunzătoare
              pentru corectitudinea Tranzacțiilor efectuate de Utilizator către
              Furnizorul agreat.
            </li>

            <li>
              Aqmeter va putea actualiza în orice moment prețurile Serviciilor,
              iar o astfel de actualizare va înlocui orice prețuri afișate
              anterior pentru respectivele Servicii.
            </li>

            <li>
              În funcție de relațiile existente între Utilizator și Furnizorul
              agreat (ie. existența unui raport sau nu), Utilizator va regăsi în
              Aplicație/Platformă contractul încheiat între Utilizator și
              Furnizorul agreat (ex. compania de apă, compania de salubritate),
              precum și facturile scadente în conformitate cu situația juridică
              dintre cei doi. De asemenea, Utilizatorul poate efectua plata și
              doar pe baza facturilor emise de Furnizorul agreat pe care le
              deține și sunt în posesia lui.
            </li>

            <li>
              Ulterior efectuării cu succes a Tranzacției, Utilizator va regăsi
              în Platformă/Aplicație istoricul Tranzacțiilor efectuate, precum
              și dovada efectuării Tranzacțiilor, așa cum au fost comunicate de
              către Furnizorul agreat prin intermediul Platformei/Aplicației.
            </li>

            <li>
              Conform legislației în vigoare din România, pentru acele Comenzi
              pentru care Prestatorul va accepta plata cu cardul bancar, acesta
              nu va solicita plăți suplimentare.
            </li>

            <li>
              În cazul plăților online, Prestatorul nu este și nu poate fi ţinut
              responsabil pentru niciun alt cost suportat de Utilizator în plus
              față de preţul Serviciului achiziţionat incluzând, dar fără a se
              limita la, comisioane de transfer ori de conversie valutară
              aplicate de către banca emitentă a cardului Utilizatorului, în
              cazul în care moneda de emitere a cardului diferă de moneda în
              care se efectuează vânzarea.
            </li>

            <li>
              În cazul în care Utilizatorul solicită returnarea contravalorii
              Serviciilor, acesta se va adresa cu o cerere către Furnizorul
              agreat, Prestatorul nefiind responsabil pentru returnarea sumelor
              de bani încasate ca urmare a efectuării Tranzacției prin
              Platformă/Aplicație. Orice solicitare a Utilizatorului cu privire
              la prețurile afișate sau efectuarea Tranzacțiilor prin una din
              modalitățile prevăzute de Platformă/Aplicație va fi rezolvată de
              către Furnizorul agreat, Prestatorul fiind absolvit de orice vină.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Serviciile oferite</span>
          <ol>
            <li>
              Compania oferă Utilizatorului în mod gratuit acces la Aplicația
              Aqmeter prin care acesta își poate achita facturile la companiile
              de utilități (Operatorii) si poate efectua alte plăți. Serviciile
              furnizate prin intermediul Aplicației Aqmeter se adresează numai
              Utilizatorilor care acceptă acești termeni și condiții.
            </li>

            <li>
              Prin intermediul Aplicației/Platformei Aqmeter Utilizatorii sunt
              puși în legătură directă cu Furnizorii agreați (ie. companiile de
              apă, companiile de salubritate), plata Serviciilor efectuându-se
              direct către contul bancar al acestora.
            </li>

            <li>
              De asemenea, Compania oferă Furnizorilor agreați, contracost,
              acces la Aplicația/Platforma Aqmeter pentru a-i pune în legătură
              cu Utilizatorii înscriși prin Aplicația Aqmeter/Platformă.
            </li>

            <li>
              Pentru evitarea oricărui dubiu, Compania nu poate fi trasă la
              răspundere pentru orice utilizare neconformă cu scopul Aplicației
              Aqmeter sau a site-ului web aqmeter.com, de către alte persoane
              decât Utilizatorii/Operatorii, astfel cum sunt definiți în acești
              termeni și aceste condiții, având în vedere faptul că Aplicația
              Aqmeter și site-ul web aqmeter.com sunt dezvoltate de către
              Companie având în vederea Utilizatorilor și profilul general al
              acestora, inclusiv nivelul de responsabilitate așteptat din partea
              unui utilizator mediu de aplicație similară cu Aplicația Aqmeter
              sau site-ul web aqmeter.com.
            </li>

            <li>
              Prin intermediul Aplicației Aqmeter și/sau a site-ului web
              aqmeter.com, Compania oferă acces utilizatorilor la următoarele
              servicii:
            </li>

            <ul>
              <li>
                pentru Operatori:
                <ul>
                  <li>gestiune date contractuale abonați;</li>
                  <li>gestiune cartoteci abonați;</li>
                  <li>programare ordine de lucru;</li>
                  <li>vizualizare citiri de contoare;</li>
                  <li>vizualizare facturi abonați;</li>
                  <li>
                    integrare cu soluții de citire utilizând smart metere;
                  </li>
                </ul>
              </li>
              <li>
                pentru Utilizatori:
                <ul>
                  <li>autocitire index contor</li>
                  <li>înregistrare cereri de citire autorizată</li>
                  <li>actualizare date contractuale</li>
                  <li>
                    notificare în timp real relativ la incidentele de furnizare
                    a serviciilor
                  </li>
                </ul>
              </li>
            </ul>
          </ol>
        </li>

        <li>
          <span className="help-strong">Garanții</span>
          <ol>
            <li>
              Compania nu își asumă răspunderea, în nicio situație, pentru nicio
              daună pricinuită direct sau indirect și pentru nicio lipsă de
              profit directă sau indirectă, suferite ca urmare a utilizării sau
              a întreruperii utilizării serviciilor oferite de Aplicația Aqmeter
              și/sau site-ul web aqmeter.com
            </li>

            <li>
              Compania nu va răspunde pentru niciun prejudiciu sau pagubă care
              pot interveni din întârzierea plăților efectuate prin Aplicația
              Aqmeter și/sau site-ul web aqmeter.com sau ca urmare a
              funcționării necorespunzătoare a Aplicației Aqmeter și/sau
              site-ului web aqmeter.com.
            </li>

            <li>
              Compania nu răspunde pentru informațiile transmise de către
              Furnizorul agreat către Utilizator prin intermediul
              Platformei/Aplicației, acesta fiind un raport distinct între cele
              două părți, Compania nefiind responsabilă nici pentru Tranzacțiile
              efectuate de către Utilizator către Furnizorul agreat, indiferent
              că acestea se datorează Furnizorului agreat sau procesatorului de
              plăți online.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Proprietate Intelectuală</span>
          <ol>
            <li>
              Denumirile comerciale, mărcile, drepturile de autor şi orice alte
              alte drepturi de proprietate intelectuală înregistrate sau în curs
              de înregistrare referitoare la Serviciile deținute sau utilizate
              de Aqmeter sunt și vor rămâne proprietatea exclusivă a Aqmeter sau
              după caz, a licenţiatorilor Aqmeter. Utilizatorul nu va avea
              niciun drept și nicio pretenţie cu privire la acestea.
            </li>

            <li>
              Utilizatorul nu va acţiona în niciun fel care ar putea aduce
              atingere drepturilor prevăzute în art. 8.1 de mai sus.
              Utilizatorul se obligă să nu folosească în activitatea sa niciun
              semn sau denumire similară sau identică cu mărcile, denumirile
              comerciale ale Serviciilor etc., fie ca parte dintr-o denumire fie
              în orice alt mod.
            </li>

            <li>
              Toate informațiile disponibile pe Platformă (inclusiv, dar fără a
              se limita la, imagini, text, logo-uri, simboluri) care pot fi
              vizualizate sau accesate în orice mod prin utilizarea unui
              echipament electronic, conținutul e-mailurilor trimise
              Utilizatorului de către Prestator, orice informaţie comunicată
              Utilizatorului (inclusiv, dar fără a se limita la, date
              referitoare la Prestator, activitatea acestuia etc.) prin orice
              mijloc de către un reprezentant al Prestatorului sunt și rămân
              proprietatea exclusivă a Aqmeter. Utilizatorul poate copia,
              transfera și/sau utiliza astfel de date numai în scopuri personale
              sau în afara unei activități desfășurate cu titlu profesional și
              numai în cazul în care nu intră în conflict cu acești termeni și
              condiții.
            </li>

            <li>
              Prestatorul va deține în mod exclusiv toate drepturile de
              proprietate intelectuală (inclusiv, dar fără a se limita la,
              drepturi de autor, drepturi asupra mărcilor, drepturi de design
              industrial) asupra Livrabilelor sau Serviciilor precum și toate
              drepturile (inclusiv dar fără a se limita la dreptul de a folosi,
              modifica, dezvălui sau publica) asupra ideilor, conceptelor,
              know-how-ul, metodelor, soluțiilor, tehnicilor, proceselor și
              aptitudinile și adaptările Livrabilelor, pe toată durata
              desfășurării activității sale.
            </li>

            <li>
              Utilizatorul ia la cunoștință și acceptă că prin prestarea
              Serviciilor nu are loc niciun transfer al niciunui drept de
              proprietate intelectuală sau industrială cu privire la ideile,
              denumirile, design-ul, mărcile, conceptele, know-how-ul, metodele,
              soluțiile, tehnicile, scripturi-le, codurile, procesele,
              aptitudinile și adaptările conținute de Livrabil sau aferente
              Serviciilor.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Dreptul de retragere al Utilizatorului
          </span>
          <ol>
            <li>
              Conform legislației în vigoare aplicabile contractelor încheiate
              la distanță,{" "}
              <span className="help-strong">
                nu se asigură dreptul de retragere în cazul contractelor de
                prestări servicii, după prestarea completă a serviciilor
              </span>
              , dacă executarea a început cu acordul prealabil expres al
              Utilizatorului și după ce acesta a confirmat că a luat cunoștință
              de faptul că își va pierde dreptul la retragere după executarea
              completă a Contractului de către Prestator;
            </li>

            <li>
              De asemenea, dată fiind relația între Platformă/Aplicație,
              Furnizorul agreat și Utilizator, orice reclamație/sugestie, cerere
              de returnare a sumelor de bani pe Tranzacțiile efectuate se vor
              soluționa direct între Utilizator și Furnizorul agreat,
              Platforma/Aplicația acționând exclusiv ca o interfață între cele
              două Părți.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Publicitate</span>
          <ol>
            <li>
              Prezenta procedură va fi menţionată în toate comunicările cu
              caracter comercial transmise de Prestator Utilizatorilor.
            </li>

            <li>
              Newsletterele Aqmeter sunt transmise prin intermediul partenerilor
              specializați și agreați de Aqmeter.
            </li>

            <li>
              Renunțarea Utlizatorului la primirea Newslettere-lor nu implică
              renunțarea la acceptul dat pentru restul termenilor și condițiilor
              privind vânzările online efectuate de Aqmeter.
            </li>

            <li>
              Utilizatorul îşi poate exprima consimţământul pentru a primi
              comunicări comerciale prin poşta electronică, permiţând
              Prestatorului și colaboratorilor săi să efectueze astfel de
              comunicări, prin bifarea opţiunii specifice la finalul acestor
              termeni şi condiţii. Subiectul mesajelor electronice transmise
              prin e-mail va începe cu termenul „PUBLICITATE” scris cu
              majuscule.
            </li>

            <li>
              Utilizatorul poate oricând să revoce consimţământul privind astfel
              de comunicări comerciale prin:
              <ul>
                <li>
                  transmiterea unei solicitări în scris Vânzătorului la adresa
                  de e-mail din secţiunea „Contact” din Platformă; în acest caz,
                  revocarea va produce efecte în cel mult 48 (patruzeci și opt)
                  de ore de la inițierea procedurii;
                </li>
                <li>
                  renunțarea la primirea Newslettere-lor, în orice moment, prin
                  accesarea link-ului dedicat care se regăsește în cuprinsul
                  oricărui Newsletter;
                </li>
                <li>
                  bifarea optiunii de retragere a consimţământului în secţiunea
                  dedicată de pe Platformă.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Răspundere</span>
          <ol>
            <li>
              Prestatorul nu răspunde pentru niciun fel de prejudicii cauzate
              Utilizatorului/Operatorului sau oricărui terț ca urmare a
              îndeplinirii de către Prestator a oricăreia din obligațiile sale
              conform Comenzii și nici pentru daune care rezultă din utilizarea
              necorespunzătoare a Serviciilor oferite.
            </li>

            <li>
              Prestatorul nu poate fi în niciun caz responsabil de vreo pierdere
              a folosinței, de contracte, date, fond comercial, venituri sau
              profituri (indiferent dacă sunt considerate pretenții directe sau
              nu) sau orice pierdere, daună sau cheltuială subsecventă,
              specială, indirectă, incidentală, punitivă sau exemplară în baza
              sau în legătură cu prezentul Contract sau Serviciile oferite.
            </li>

            <li>
              Utilizatorul va păstra în condiții de siguranță username-ul și
              parola aferente Contului, fiind singurul responsabil în cazul
              utilizării fraudulose a acestora de către un terț.
            </li>

            <li>
              Aqmeter nu va răspunde pentru niciun prejudiciu cauzat de orice
              disfuncționalități tehnice ale Platformei (e.g., imposibilitatea
              accesării oricărui link de pe Platformă).
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Sesizări și reclamații</span>
          <ol>
            <li>
              Pentru sesizări sau reclamații legate de Serviciul achiziționat,
              Utilizatorul are la dispoziție formularul de sesizări disponibil
              pe Platformă.
            </li>

            <li>
              Utilizatorul poate formula reclamaţii cu privire la Comenzi, care
              vor fi trimise pe adresa de e- mail menţionată în secţiunea
              „Contact” de pe Platformă. Sesizările astfel recepţionate vor fi
              soluționate de Prestator în 30 (treizeci) de zile calendaristice
              de la primirea lor.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Utilizarea şi prelucrarea datelor cu caracter personal ale
            persoanelor fizice
          </span>
          <ol>
            <li>
              Prin utilizarea Platformei, Utilizatorul întelege şi acceptă să
              transmită Aqmeter date cu caracter personal, aceste date urmând să
              facă obiectul prelucrării în conformitate cu şi pentru scopurile
              prevăzute în Politica de Confidențialitate, care completează
              aceşti Termeni și Condiții.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Confidențialitate</span>
          <ol>
            <li>
              Atât Prestatorul cât și Utilizator se obligă și garantează să
              păstreze confidențialitatea Informațiilor Confidențiale, să
              prevină divulgarea acestora terților și să nu le folosească în
              alte scopuri decât cele avute în vedere prin prezentul Contract,
              cu excepția celor autorizate în prealabil în scris de către
              Prestator/Utilizator și sub rezerva termenilor și condițiilor
              impuse de aceasta.
            </li>

            <li>
              Informațiile Confidențiale pot fi puse la dispoziție fie în scris,
              în formă lizibilă vizual sau electronic, inclusiv prin fax sau
              prin alte forme electronice de transmitere sau oral, și pot fi
              marcate ca fiind confidențiale sau nu.
            </li>

            <li>
              Următoarele informații nu sunt considerate ca fiind Informații
              Confidențiale: (i) sunt sau au devenit publice (inclusiv, fără
              limitare la orice informații prezentate oricărei agenții
              guvernamentale și disponibile publicului) altfel decât în urma
              unei dezvăluiri de către Prestator cu încălcarea prezentei
              secțiunii (ii) sunt puse la dispoziția Prestatorului pe baze
              neconfidențiale dintr-o sursă diferită de Utilizator despre care
              Prestatorul consideră că nu îi este interzis să dezvăluie astfel
              de informații către Prestator (iii) sunt cunoscute de Prestator
              înainte de a fi primite de la Utilizator fără nicio obligație de
              confidențialitate sau (iii) sunt dezvoltate de Prestator
              independent de Informațiile Confidențiale dezvăluite de
              Utilizator.
            </li>

            <li>
              Utilizatorul nu va dezvălui niciunui terț Informațiile
              Confidențiale primite de la Prestator, cu excepția Livrabilului și
              doar în condițiile arătate prin prezentele termeni și condiții și
              cu excepția cazului în care este obligat conform legislației în
              vigoare. În cazul în care Utilizator va dezvălui Informațiile
              Confidențiale unei alte persoane (publice sau private) decât cele
              necesare pentru ducerea la îndeplinire a Serviciului contractat
              (ie. Registrul Comerțului, instanțele de judecată, autoritățile de
              poliție, instituții bancare, agențiile naționale de administrare
              fiscală sau parteneri comerciali), Prestatorul își rezervă dreptul
              de a se îndrepta împotriva Utilizatorului pentru acoperirea
              oricăror prejudicii ar putea rezulta din această dezvăluire.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Notificări</span>
          <ol>
            <li>
              Prezentele Termeni și Condiții împreună cu Formularul de
              înregistrare reprezintă întregul Contract dintre părți și
              prevalează asupra oricăror înțelegeri anterioare dintre părți.
              Părțile agreează că nici o comunicare dintre părți nu poate
              modifica prezentul contract decât dacă va fi efectuată în scris și
              acceptată prin semnătură de către ambele părți.
            </li>

            <li>
              Utilizatorul este de acord ca toate comunicările efectuate în baza
              prezentului Contract să aibă loc prin intermediul poștei
              electronice la adresa comunicată de acesta în Platformă,
              consimțind că o astfel de comunicare este validă prin simpla
              dovadă din partea Prestatorului privind trimiterea comunicării.
              Prestatorul are dreptul să folosească și alte metode de
              transmitere (prin poștă, curier sau prin intermediul executorilor
              judecătorești) a comunicărilor sale către Utilizator.
            </li>

            <li>
              Părțile convin că toate comunicările în legătură cu prezentul
              Contract se vor efectua la următoarele adrese:
              <ul>
                <li>
                  Pentru Utilizator/Operator - la adresa poștală menționată în
                  prin intermediul Platformei;
                </li>
                <li>
                  Pentru Companie - la următoarea adresă electronică:{" "}
                  <Link to="mailto:office@setmobile.ro">
                    office@setmobile.ro
                  </Link>{" "}
                  .
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Forța majoră</span>
          <ol>
            <li>
              Nici Prestatorul și nici Utilizatorul nu va fi răspunzător pentru
              neexecutarea obligațiilor sale contractuale, dacă o astfel de
              neexecutare la termen și/sau în mod corespunzător, totală sau
              parțială, este datorată unui eveniment de forță majoră astfel cum
              este definit de codul civil român.
            </li>

            <li>
              Dacă în termen de 15 (cincisprezece) zile de la data producerii
              evenimentului de forță majoră, respectivul eveniment nu încetează,
              oricare dintre Prestator sau Utilizator va avea dreptul să
              notifice celeilalte părți încetarea de plin drept a contractului
              fără ca vreuna dintre ele să poată pretinde celeilalte
              daune-interese.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Legea aplicabilă și soluționarea disputelor
          </span>

          <ul className="local-law">
            <li>Prezentul contract este supus legii române.</li>
            <li>
              Aqmeter și Utilizatorul vor încerca să rezolve pe cale amiabilă
              orice dispute sau neînțelegeri ar putea să apară. În măsura în
              care soluționarea amiabilă nu va fi posibilă, litigiile vor fi
              soluționate de instanțele judecătorești române competente conform
              legii.
            </li>
            <li>
              Detalii privind metodele alternative de soluționare a disputelor
              sunt disponibile pe platforma de{" "}
              <Link
                to="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.show&amp;lng=RO"
                target="_blank"
                rel="noopener noreferrer"
              >
                Soluționare online a litigiilor
              </Link>
              , a Uniunii Europene.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};
