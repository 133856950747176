import React, { useState } from "react";

import {
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonCheckbox,
  IonLabel
} from "@ionic/react";

import WizardMenu from "../../WizardMenu";

import styles from "../CreateContractPage.module.scss";

interface GDPRPageProps {
  step: number;
  setStep(step: number): void;
}

const GDPRPage: React.FC<GDPRPageProps> = ({ step, setStep }) => {
  const [checked, setChecked] = useState({
    terms: false,
    read: false,
    seen: false
  });

  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={styles.title}>
            Regulamentul General privind Protecția Datelor
          </IonCardTitle>
        </IonCardHeader>

        <IonCardContent className={styles.textAligned}>
          {`\xa0\xa0 În conformitate cu prevederile REGULAMENTULUI (UE) 2016/679 AL
          PARLAMENTULUI EUROPEAN ȘI AL CONSILIULUI din 27 aprilie 2016 privind
          protecția persoanelor fizice în ceea ce privește prelucrarea datelor
          cu caracter personal și privind liberă circulație a acestor date și de
          abrogare a Directivei 95/46/CE (Regulamentul general privind protecția
          datelor), cu modificările și completările ulterioare, precum și cu
          prevederile Directivei 2002/58/CE privind prelucrarea datelor
          personale și protejarea confidențialității în sectorul comunicațiilor
          publice (Directiva asupra confidențialității și comunicațiilor
          electronice) și a Legii nr. 506/2004 privind prelucrarea cu caracter
          personal și protecția vieții private în sectorul comunicațiilor
          electronice, persoanele fizice care se angajează în procesul de
          contractare trebuie să își exprime acordul pentru prelucrarea datelor
          personale de către Aqmeter.`}
        </IonCardContent>

        <div className={styles.checkboxCard}>
          <IonCheckbox
            checked={checked.terms}
            slot="start"
            color="tertiary"
            onClick={() =>
              setChecked((prev) => ({ ...prev, terms: !prev.terms }))
            }
          />
          <IonLabel color="tertiary">
            Sunt de acord cu <strong>termenii și condițiile</strong>.
          </IonLabel>
        </div>
        <div className={styles.checkboxCard}>
          <IonCheckbox
            checked={checked.seen}
            slot="start"
            color="tertiary"
            onClick={() =>
              setChecked((prev) => ({ ...prev, seen: !prev.seen }))
            }
          />
          <IonLabel color="tertiary">
            {"Am luat la cunoștință informațiile expuse mai sus."}
          </IonLabel>
        </div>
        {/* <div className={styles.checkboxCard}>
          <IonCheckbox
            checked={checked.read}
            slot="start"
            color="tertiary"
            onClick={() =>
              setChecked((prev) => ({ ...prev, read: !prev.read }))
            }
          />
          <IonLabel color="tertiary">{"Mi-este clar."}</IonLabel>
        </div> */}
      </IonCard>

      {/* <label>First Name</label>
      <input
        type="text"
        className="form-control"
        name="firstname"
        placeholder="First Name"
      /> */}
      <WizardMenu
        disabled={!(checked.seen && checked.terms)}
        setStep={setStep}
        step={step}
      />
    </div>
  );
};

export default GDPRPage;
