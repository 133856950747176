import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import { AqNotificationsBell } from "../../../../components/AqNotificationsBell";
import { CompanyHeader } from "../../../../components/CompanyHeader";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../../components/UI/BackBtn";
import FormStepsContractare from "../../../../components/UI/Forms/FormStepsContractare";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { Company } from "../../../../types/responseTypes";

import CIPage from "./PagesCreateContract/CIPage";
import ClientTypePage from "./PagesCreateContract/ClientTypePage";
import GDPRPage from "./PagesCreateContract/GDPRPage";
import RequestFormPage from "./PagesCreateContract/RequestFormPage";

import styles from "./CreateContractPage.module.scss";

const CreateContractPage: React.FC = () => {
  const { layout } = useWindowSize();
  const locationObj = useLocation()?.state as {
    company: Company;
  };

  const [step, setStep] = useState<number>(1);
  const [scenario, setScenario] = useState<string>("");
  const [specification, setSpecification] = useState({
    specification: {
      clientType: null,
      target: null,
      propertyOwnership: null
    }
  });
  const [selectedCompanyFromLocationObj, setSelectedCompanyFromLocationObj] =
    useState(locationObj?.company);

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem("step"))) {
      setStep(JSON.parse(sessionStorage.getItem("step")));
    }
    if (JSON.parse(sessionStorage.getItem("clientType"))) {
      setScenario(JSON.parse(sessionStorage.getItem("clientType")));
    }
    if (JSON.parse(sessionStorage.getItem("specification"))) {
      setSpecification(JSON.parse(sessionStorage.getItem("specification")));
    }
    if (!locationObj) {
      setSelectedCompanyFromLocationObj(
        JSON.parse(sessionStorage.getItem("selectedCompanyFromLocationObj"))
      );
    }
  }, []);

  useEffect(() => {
    sessionStorage.setItem("step", JSON.stringify(step));
    sessionStorage.setItem("clientType", JSON.stringify(scenario));
    sessionStorage.setItem("specification", JSON.stringify(specification));
    sessionStorage.setItem(
      "selectedCompanyFromLocationObj",
      JSON.stringify(selectedCompanyFromLocationObj)
    );
  }, [step, scenario, selectedCompanyFromLocationObj]);

  // Do something on step change

  const renderStep = (step) => {
    if (step === 1)
      return (
        <ClientTypePage
          setScenario={setScenario}
          setStep={setStep}
          setSpecification={setSpecification}
          scenario={scenario}
          specification={specification}
        />
      );
    if (step === 2) return <GDPRPage setStep={setStep} step={step} />;
    if (step === 3) return <CIPage setStep={setStep} step={step} />;
    if (step === 4) return <RequestFormPage setStep={setStep} step={step} />;
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"demands"}
        menuSelected={"item3"}
      >
        {step >= 1 && step <= 4 ? (
          <IonButtons slot="end" className={styles.navButtons}>
            <FormStepsContractare
              steps={4}
              currentStep={step}
              setCurrentStep={setStep}
              formStepsType={"demand"}
              scenario={scenario}
            />
          </IonButtons>
        ) : (
          ""
        )}

        <CompanyHeader company={selectedCompanyFromLocationObj} />

        {renderStep(step)}
      </SidebarLayout>
    </IonPage>
  );
};

export default CreateContractPage;
