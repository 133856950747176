/* eslint-disable no-unsafe-finally */
import { initializeApp } from "firebase/app";
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAbjkIi27wgq1TpBes01TchgdOjHTbCtZE",
  authDomain: "annuntia-1764a.firebaseapp.com",
  databaseURL: "https://annuntia-1764a.firebaseio.com",
  projectId: "annuntia-1764a",
  storageBucket: "annuntia-1764a.appspot.com",
  messagingSenderId: "473440744597",
  appId: "1:473440744597:web:b6b431dc4904ec1274d79c",
  measurementId: "G-M105B84P73"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// Initialize Firebase Cloud Messaging and get a reference to the service

const messaging = (async () => {
  try {
    const isSupportedBrowser = await isSupported();
    if (!isSupportedBrowser) return;
    return getMessaging(app);
  } catch (err) {
    console.log(err);
  }
})();

const { REACT_APP_VAPID_KEY } = process.env;

export const startFirebase = async () => {
  const isSupportedBrowser = await isSupported();
  if (!isSupportedBrowser) return;
  return await requestForToken();
};

export const requestForToken = async () => {
  try {
    const messagingResolve = await messaging;
    const currentToken = await getToken(messagingResolve, {
      vapidKey: REACT_APP_VAPID_KEY
    });
    if (currentToken) {
      console.log("FCM token received and stored.");
      localStorage.setItem("fcm", currentToken);
      return currentToken;
    } else {
      console.log(
        "No FCM registration token available. Request permission to generate one."
      );
    }
  } catch (err) {
    console.log("An error occurred while retrieving token. ", err);
  }
};

export const onMessageListener = async () =>
  new Promise((resolve) =>
    (async () => {
      try {
        const messagingResolve = await messaging;
        onMessage(messagingResolve, (payload) => {
          console.log("On message: ", messaging, payload);
          resolve(payload);
        });
      } finally {
        return;
      }
      // catch (err) {
      //   console.log(`Error during onMessageListener: ${err ? err : ''}`);
      //   reject(`Error during onMessageListener`);
      // }
    })()
  );
