import { useEffect } from "react";
import type React from "react";
import { useHistory, useLocation } from "react-router-dom";

import type { URLOpenListenerEvent } from "@capacitor/app";
import { App } from "@capacitor/app";
import { Browser } from "@capacitor/browser";
import { isPlatform } from "@ionic/core/components";

import { axiosPrivate } from "./api/customAxios";

const AppUrlListener: React.FC<any> = () => {
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (location.pathname.includes("/confirm-contract-email")) {
      // perform your action here
      axiosPrivate.get(location.pathname).then(() => {
        history.push("/validation");
      });
    }
  }, [location.pathname]);

  useEffect(() => {
    App.addListener("appUrlOpen", (event: URLOpenListenerEvent) => {
      console.log("App opened with URL: " + event.url);

      const url = event.url;
      const slug = url.split("aqmeter:").pop();

      if (slug) {
        if (url === "aqmeter:invoices") {
          history.push({
            pathname: `/invoices`,
            state: { origin: "payment" }
          });
        } else {
          history.push("/" + slug);
        }
      }

      if (isPlatform("ios") && url.indexOf("aqmeter:") !== -1) {
        Browser.close().then(() => {
          if (url === "aqmeter:invoices") {
            history.push({
              pathname: `/invoices`,
              state: { origin: "payment" }
            });
          }
        });
      }
    });
  }, []);

  return null;
};

export default AppUrlListener;
