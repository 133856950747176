import { useTranslation } from "react-i18next";

import { IonButton, IonSpinner } from "@ionic/react";

import styles from "./Create/CreateContractPage.module.scss";

interface WizardMenuProps {
  disabled?: boolean;
  isLoading?: boolean;
  step: number;
  setStep: (step) => void;
  handleStateChange?: () => void;
  onSubmit?: () => void;
  type?: string;
  sendSignature?: () => void;
}
const WizardMenu: React.FC<WizardMenuProps> = ({
  disabled,
  step,
  setStep,
  isLoading,
  type,
  sendSignature
}: WizardMenuProps): JSX.Element => {
  // console.log({ disabled })
  // console.log({ isLoading })

  const { t } = useTranslation();

  return (
    <div>
      <hr />
      <div
        className={
          step === 1 || step === 5
            ? styles.singleStatsButton
            : styles.statsButtons
        }
      >
        {!(step === 1) ? (
          <IonButton
            className={styles.backButton}
            title={t("demands.wizard.buttonsLabel.back")}
            onClick={() => {
              setStep((prevStep) => prevStep - 1);
            }}
          />
        ) : null}

        <IonButton
          className={styles.continueButton}
          disabled={disabled ? disabled : null}
          type={step === 4 ? "submit" : "button"}
          onClick={async () => {
            if (type === "signature") {
              await sendSignature();
              if (step < 4) {
                setStep((prevStep) => prevStep + 1);
              }
            } else if (step < 4) {
              setStep((prevStep) => prevStep + 1);
            }
          }}
        >
          {disabled && isLoading ? (
            <>
              <IonSpinner name="circles" /> {t("verifying")}
            </>
          ) : (
            t("demands.wizard.buttonsLabel.continue")
          )}
        </IonButton>
      </div>
    </div>
  );
};

export default WizardMenu;
