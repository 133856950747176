import React, { useState } from "react";
import {
  checkmarkCircleOutline,
  informationCircleOutline,
  removeCircleOutline,
  warningOutline
} from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { Browser } from "@capacitor/browser";
import { Capacitor } from "@capacitor/core";
import { IonAvatar, IonButton, IonIcon, IonItem, IonLabel } from "@ionic/react";

import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { Company } from "../../../../types/responseTypes";
import EditContractDeleteModal from "../../../4.Contracts/EditContract/EditContractDeleteModal";

import DetailedStatusInfo from "./DetailedStatusInfo";

import styles from "./ListDemands.module.scss";

type Files = {
  id: number;
  label: string;
  url: string;
};

interface ListItemDemandsProps {
  id: number;
  showModal?: boolean;
  updateShowModal?: React.Dispatch<React.SetStateAction<boolean>>;
  statusText: string;
  clientType?: string;
  surname?: string;
  address?: { place: string; street?: string; nr?: string };
  company?: Company;
  status?: string;
  onDelete?: () => void;
  files: Files[];
}

const ListItemDemands: React.FC<ListItemDemandsProps> = ({
  id,
  statusText,
  clientType,
  surname,
  address,
  company,
  status,
  onDelete,
  showModal,
  updateShowModal,
  files
}) => {
  const { t } = useTranslation();

  const stylez = {
    color:
      status === "Aprobată"
        ? "var(--ion-color-primary)"
        : status === "În analiză"
          ? "var(--ion-color-warning)"
          : status === "Respinsă"
            ? "var(--ion-color-danger)"
            : status === "Trimisă"
              ? "var(--ion-color-medium)"
              : ""
  };

  const iconStatus = (status: string) => {
    return status === "Trimisă" ? (
      <IonIcon
        className={styles.statusIconConfig}
        icon={informationCircleOutline}
      />
    ) : status === "În analiză" ? (
      <IonIcon className={styles.statusIconConfig} icon={warningOutline} />
    ) : status === "Respinsă" ? (
      <IonIcon
        slot="end"
        className={styles.statusIconConfig}
        icon={removeCircleOutline}
      />
    ) : status === "Aprobată" ? (
      <IonIcon
        slot="end"
        className={styles.statusIconConfig}
        icon={checkmarkCircleOutline}
      />
    ) : (
      ""
    );
  };

  const isMobile = Capacitor.isNativePlatform();

  const openPdfInNewTab = async (url) => {
    try {
      if (isMobile) {
        await Browser.open({ url: url });
      } else {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer");
        if (newWindow) newWindow.opener = null;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const [isSyncOpen, setIsSyncOpen] = useState(false);
  const { layout } = useWindowSize();

  function getDivLinks() {
    return (
      <div className={layout === "phone" ? styles.linksWrapperPhoneLeft : null}>
        {files.map((file) => {
          return (
            // <a href={ `${process.env.REACT_APP_API_URL}/contract-demands/file/${file.id}`}>DL</a>
            <a
              href={file.url}
              onClick={async (event) => {
                event.stopPropagation();
                event.preventDefault();
                openPdfInNewTab(file.url);
              }}
              key={file.id}
              className={
                layout === "phone"
                  ? styles.documentLinksPhoneLeft
                  : styles.documentLinks
              }
            >
              {file.label}
            </a>
          );
        })}
      </div>
    );
  }

  return (
    <div className={styles.demandItemsList}>
      <IonItem
        className={`card-widget-list-item invoice-view cursor-hover readings-list ${styles.shadowRootItem} ${styles.cardWrapper} `}
        onClick={() => setIsSyncOpen((prevState) => !prevState)}
      >
        {/*<div*/}
        {/*  className={`${styles.cardHeader} ${*/}
        {/*    status ? colorSetup(status) : colorSetup(statusColor)*/}
        {/*  }`}*/}
        {/*></div>*/}
        <div className={styles.splitFooter}>
          <div className={styles.cardFooter}>
            <IonLabel>
              <div className={styles.logoPlusStatusDetailsWrapper}>
                <IonAvatar slot="start" className={`${styles.companyImage}`}>
                  <img src={company?.logoUrl} alt={company?.shortName} />
                </IonAvatar>

                <IonLabel className={styles.statusDetailsContent}>
                  <span className="ag-body-text ellipse">
                    {company?.shortName}
                  </span>
                  <span className="ag-number-description ellipse">
                    {clientType}
                  </span>
                  <span className="ag-number-description capitalize ellipse">
                    {surname}
                  </span>
                  <span className="ag-number-description ellipse">
                    {address
                      ? `${address.place}, ${address?.street}, ${address?.nr}`
                      : "Constanta, Voinasi, 23"}
                  </span>
                  {getDivLinks()}
                </IonLabel>
              </div>
            </IonLabel>
            <div className={styles.statusText}>
              <IonLabel
                style={{
                  ...stylez,
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  gap: "10px"
                }}
                className={styles.statusDetailsContent}
              >
                {status}
                {iconStatus(status)}
              </IonLabel>

              {status ? (
                <IonButton
                  className={styles.cancelButton}
                  color="danger"
                  expand="block"
                  strong={true}
                  onClick={(event) => {
                    event.stopPropagation();
                    updateShowModal((prev) => !prev);
                  }}
                >
                  {t("demands.list.cancelButtonLabel")}
                </IonButton>
              ) : // <p
              //   onClick={onDelete}
              //   style={{
              //     color: "red",
              //     paddingTop: "10px",
              //     textAlign: "start",
              //   }}
              // >
              //   Anulează
              // </p>
              null}
            </div>
          </div>
          <DetailedStatusInfo
            statusInfo={status ?? statusText}
            isSyncOpen={isSyncOpen}
          />
        </div>
        <EditContractDeleteModal
          showModal={showModal}
          updateShowModal={updateShowModal}
          contractId={id}
          deleteDemandsHandler={onDelete}
          type={"demands"}
        />
      </IonItem>
    </div>
  );
};

export default ListItemDemands;
