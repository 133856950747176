import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";

import AuthContext from "../../../context/AuthContext";
import type { InvoicePayment } from "../../../types/responseTypes";

interface InvoiceDetailsPaymentsListItemProps {
  payment: InvoicePayment;
}

const InvoiceDetailsPaymentsListItem: React.FC<
  InvoiceDetailsPaymentsListItemProps
> = ({ payment }) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol className="invoice-information">
            <span className="ag-number-description">
              <span className="gray">
                {t("invoice.payments.date")}
                {": "}
              </span>
              <span className="black capitalize">{payment?.date}</span>
            </span>
            <span className="ag-number-description">
              <span className="gray">
                {t("invoice.payments.amount")}
                {": "}
              </span>
              <span className="black capitalize">
                {/*{parseFloat(payment?.amount).toLocaleString(langCtx)}{" "}*/}
                {payment.amount.toLocaleString(langCtx)} {t("currency")}
              </span>
            </span>
            <span className="ag-number-description">
              <span className="gray">
                {t("invoice.payments.info")}
                {": "}
              </span>
              <span className="black lowercase">
                {payment?.docTypeName}
                {": "}
                {payment?.erpReferenceNr}
              </span>
            </span>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default InvoiceDetailsPaymentsListItem;
