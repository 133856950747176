import { useContext, useEffect } from "react";

import DemandContext from "../context/DemandContext";
import UserContext from "../context/UserContext";
import type { Demand } from "../types/contractDemandsTypes";
import type { Contract, DashboardMenu } from "../types/responseTypes";

import useFetchCaching from "./useFetchCaching";

const useBubbleTabsInfoUpdate = () => {
  const { menuCtx, updateMenuCtx, updateContractsCtx } =
    useContext(UserContext);
  const { data: contracts } = useFetchCaching<Contract[]>("/contracts");
  const { data: menuInfo } = useFetchCaching<DashboardMenu>("/dashboard/menu");
  const { data: demands } = useFetchCaching<Demand[]>("/contract-demands");

  const { updateNumberOfDemands, numberOfDemands } = useContext(DemandContext);
  useEffect(() => {
    if (menuInfo) {
      updateMenuCtx(menuInfo);
    }
    if (contracts) {
      updateContractsCtx(contracts);
    }
    if (demands) {
      updateNumberOfDemands(demands.length);
    }
  }, [menuInfo, contracts, demands]);

  const unpaidInvoices = menuCtx?.totalUnpaidInvoices;
  const hasWaterContracts = menuCtx?.hasWaterContracts;
  const meterReadings = menuCtx?.totalReadingsToSend;
  const unreadNotifs = menuCtx?.totalUnreadNotifications;
  return [
    unpaidInvoices,
    hasWaterContracts,
    meterReadings,
    unreadNotifs,
    numberOfDemands
  ];
};

export default useBubbleTabsInfoUpdate;
