import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { useHistory, useParams } from "react-router";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import { ContractHeader } from "../../../components/ContractHeader";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../components/UI/BackBtn";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { NotPaidInvoices, PendingCounts } from "../../../types/otherTypes";
import type {
  Company,
  Invoice,
  PaidOrNotPaidInvoices
} from "../../../types/responseTypes";
import PaymentForms from "../Payments/Common/PaymentForms";

import NotPaidInvoicesButtons from "./NotPaidInvoicesButtons";
import NotPaidInvoicesList from "./NotPaidInvoicesList";
import NotPaidInvoicesWarnings from "./NotPaidInvoicesWarnings";

const countContractPendingInvoices = (
  contractInvoices: Invoice[]
): PendingCounts => {
  if (!contractInvoices?.length)
    return {
      invoicesProcessingNr: 0,
      invoicesInitializedNr: 0
    };
  return contractInvoices.reduce(
    (accum, elem) => {
      return {
        invoicesProcessingNr: elem.isInProcessing
          ? accum.invoicesProcessingNr + 1
          : accum.invoicesProcessingNr,
        invoicesInitializedNr: elem.hasPaymentInitialized
          ? accum.invoicesInitializedNr + 1
          : accum.invoicesInitializedNr
      };
    },
    {
      invoicesProcessingNr: 0,
      invoicesInitializedNr: 0
    }
  );
};

const reducer = (state, action) => {
  switch (action.type) {
    case "updateSelection":
      return { ...state, selectedInvoices: action.payload };
    case "updateButton":
      return { ...state, btnValue: action.payload };
    case "checkPayAmount":
      return { ...state, isPayAmountTooSmall: action.payload };
  }
};

const NotPaidInvoicesPage: React.FC = () => {
  const { layout } = useWindowSize();
  const history = useHistory();
  const { contractsCtx, companiesCtx } = useContext(UserContext);
  const { contractId } = useParams<{ contractId: string }>();

  const [state, dispatch] = useReducer(reducer, {
    selectedInvoices: [],
    btnValue: 0,
    isPayAmountTooSmall: false
  });

  const contract = useMemo(
    () => contractsCtx?.filter((contract) => contract?.id === +contractId)[0],
    [contractsCtx, contractId]
  );

  useEffect(() => {
    if (!contract) {
      history.push("/invoices");
    }
  }, []);

  const company = useMemo(
    (): Company =>
      companiesCtx?.filter(
        (company: Company): boolean => company?.id === contract?.companyId
      )[0],
    [companiesCtx, contract]
  );

  const { data: notPaidInvoices, error } =
    useFetchCaching<PaidOrNotPaidInvoices>(
      `/invoices?contractId=${contractId}&paid=false`
    );

  const countPendingInvoices: PendingCounts = countContractPendingInvoices(
    notPaidInvoices?.invoices
  );
  const { invoicesProcessingNr, invoicesInitializedNr } = countPendingInvoices;
  const invoiceSummary: NotPaidInvoices = {
    ...notPaidInvoices,
    ...countPendingInvoices
  };
  const hasCustomPaymentInitialized: boolean =
    invoiceSummary?.hasCustomPaymentInitialized;
  const minInvoicePaymentAmount: number = company?.minPaymentAmount;
  const hasPendingPayments: boolean =
    invoicesProcessingNr > 0 ||
    invoicesInitializedNr > 0 ||
    hasCustomPaymentInitialized;

  const calculateValue = (invoices: Invoice[], selectedInvoices: number[]) => {
    if (invoices && selectedInvoices) {
      let sum = 0;
      for (const invoice of invoices) {
        if (selectedInvoices.includes(invoice.id)) {
          sum += invoice.dueAmount;
        }
        dispatch({ type: "updateButton", payload: parseFloat(sum.toFixed(2)) });
      }
    }
  };

  useEffect(() => {
    calculateValue(notPaidInvoices?.invoices, state.selectedInvoices);
  }, [notPaidInvoices, state.selectedInvoices]);

  useEffect(() => {
    if (minInvoicePaymentAmount && state.btnValue < minInvoicePaymentAmount) {
      dispatch({ type: "checkPayAmount", payload: true });
    } else {
      dispatch({ type: "checkPayAmount", payload: false });
    }
  }, [state.btnValue, company]);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn url={"/invoices"} />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout layoutType={"back"} url={"/invoices"}>
        <ContractHeader contract={contract} />
        <SkeletonList loading={!notPaidInvoices} error={error} />
        {!error && notPaidInvoices ? (
          <>
            <NotPaidInvoicesButtons
              contract={contract}
              selectedInvoices={state.selectedInvoices}
              invoiceSummary={invoiceSummary}
              contractDetails={{
                btnValue: state.btnValue,
                isPayAmountTooSmall: state.isPayAmountTooSmall,
                hasPendingPayments
              }}
            />
            <NotPaidInvoicesWarnings
              contract={contract}
              invoiceSummary={invoiceSummary}
              isPayAmountTooSmall={state.isPayAmountTooSmall}
            />
            <NotPaidInvoicesList
              contract={contract}
              invoiceSummary={invoiceSummary}
              selectedInvoices={state.selectedInvoices}
              dispatch={dispatch}
            />
          </>
        ) : (
          ""
        )}
        <PaymentForms />
      </SidebarLayout>
    </IonPage>
  );
};

export default NotPaidInvoicesPage;
