import React, { useEffect, useMemo } from "react";
import type { RouteComponentProps } from "react-router";

import { IonContent, IonPage } from "@ionic/react";

import { useSettings } from "../../../../context/SettingsContext";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type {
  EuplatescData,
  MobilPayData,
  PaymentData,
  PayUData
} from "../../../../types/schema";
import { euplatescModule } from "../Providers/euplatesc";
import { mobilPayModule } from "../Providers/mobilPay";
import { payuModule } from "../Providers/payU";

type PayPageProps = RouteComponentProps<{
  contractId: string;
  paymentData: string;
}>;

export const PayPage: React.FC<PayPageProps> = ({ match }) => {
  const { layout } = useWindowSize();
  const { settings } = useSettings();

  const paymentData = useMemo(
    () =>
      JSON.parse(
        atob(decodeURIComponent(match.params.paymentData))
      ) as PaymentData,
    []
  );

  const renderFormMobilPay = (data: MobilPayData) => {
    const _form = document.querySelector("#mobilPayForm");
    if (_form) {
      const mobilPayModuleInstance = mobilPayModule(_form, data);
      mobilPayModuleInstance.submitToMobilPay();
    }
  };

  const renderFormPayU = (data: PayUData) => {
    const _form = document.querySelector("#payuForm");
    if (_form) {
      const payuModuleInstance = payuModule(_form, data);
      payuModuleInstance.submitToPayU();
    }
  };

  const renderFormEuplatesc = (data: EuplatescData) => {
    const _form = document.querySelector("#euplatescForm");
    if (!_form) throw new Error("Didn't find euplatesc form!");
    const euplatescModuleInstance = euplatescModule(_form, data);
    euplatescModuleInstance.submitForm();
  };

  useEffect(() => {
    {
      paymentData?.mobilPay && renderFormMobilPay(paymentData.mobilPay);
    }
    {
      paymentData?.payU && renderFormPayU(paymentData.payU);
    }
    {
      paymentData?.euplatesc && renderFormEuplatesc(paymentData.euplatesc);
    }
  }, []);

  return (
    <IonPage className={`layout--${layout}`}>
      <IonContent fullscreen>
        <form
          id="mobilPayForm"
          name="mobilPayForm"
          method="POST"
          action={
            settings.env == "production"
              ? "https://secure.mobilpay.ro"
              : "http://sandboxsecure.mobilpay.ro"
          }
        ></form>
        <form
          id="payuForm"
          name="payuForm"
          method="post"
          action={
            settings.env == "production"
              ? "https://secure.payu.ro/order/lu.php"
              : "https://sandbox.payu.ro/order/lu.php"
          }
        ></form>
        <form
          id="euplatescForm"
          name="euplatescForm"
          method="post"
          action="https://secure.euplatesc.ro/tdsprocess/tranzactd.php"
        ></form>
      </IonContent>
    </IonPage>
  );
};
