import React from "react";
import { useTranslation } from "react-i18next";

import { IonItem, IonList } from "@ionic/react";

import type { Contract, InvoiceSummary } from "../../../types/responseTypes";

import InvoicesContractListItem from "./InvoicesContractListItem";

type mergedInvoiceContract = Contract & InvoiceSummary;

interface InvoicesContractListProps {
  filteredContracts: mergedInvoiceContract[];
  numberOfContracts: number;
}

const InvoicesContractList: React.FC<InvoicesContractListProps> = ({
  filteredContracts,
  numberOfContracts
}) => {
  const { t } = useTranslation();

  return (
    <IonList lines="none">
      {numberOfContracts === 0 ? (
        <IonItem>
          <span className="ag-body-text">{t("invoiceListNoContract")}</span>
        </IonItem>
      ) : (
        filteredContracts.map((contract) => (
          <InvoicesContractListItem key={contract.id} contract={contract} />
        ))
      )}
    </IonList>
  );
};

export default InvoicesContractList;
