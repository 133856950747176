import React, { Suspense } from "react";
import { createRoot } from "react-dom/client";
import { ToastContainer } from "react-toastify";

import { IonItem, IonLabel, IonSpinner } from "@ionic/react";

import { AuthProvider } from "./context/AuthContext";
import { UserProvider } from "./context/UserContext";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

import "react-toastify/dist/ReactToastify.css";

import "./i18n";

const root = document.getElementById("root");

if (root !== null) {
  createRoot(root).render(
    <React.StrictMode>
      <ToastContainer
        closeOnClick
        draggable
        autoClose={5000}
        position={"top-right"}
      />
      <Suspense
        fallback={
          <IonItem lines="none">
            <IonSpinner slot="start" color="primary" />
            <IonLabel color="medium">Se încarcă</IonLabel>
          </IonItem>
        }
      >
        <AuthProvider>
          <UserProvider>
            <App />
          </UserProvider>
        </AuthProvider>
      </Suspense>
    </React.StrictMode>
  );
}

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
