import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonItem,
  IonLabel,
  IonPage,
  IonSpinner,
  IonToolbar
} from "@ionic/react";

import { userActions } from "../../../api/UserActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import BackBtn from "../../../components/UI/BackBtn";
import FormFeedback from "../../../components/UI/FormFeedback";
import AuthContext from "../../../context/AuthContext";
import { useWindowSize } from "../../../hooks/useWindowSize";
import ImageCardLayout from "../../../layout/ImageCardLayout/ImageCardLayout";

const AccountConfirmationPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { updateAuthCtx } = useContext(AuthContext);
  const { token } = useParams() as { token: string };

  const [error, setError] = useState<string>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    userActions
      .confirmAccount(token)
      .then((response) => {
        if (response) {
          toastAdd(t("register.confirmation.success"), "success");
          updateAuthCtx(response);
        } else {
          setError(t("register.confirmation.failed"));
        }
        setIsLoading(false);
      })
      .catch((err) => {
        setError(t([`err.${err.response?.data?.message}`, "err.__"]));
        setIsLoading(false);
      });
  }, []);

  return (
    <IonPage className={`layout--${layout}`}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <BackBtn />
          </IonButtons>
          <IonButtons slot={"end"}>
            <LanguageSwitcher />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ImageCardLayout
        title={t("register.confirmation.title")}
        imgUrl={"./assets/img/register.jpg"}
        logo={true}
      >
        {isLoading ? (
          <IonItem lines="none">
            <IonSpinner slot="start" color="primary" />
            <IonLabel color="medium">{t("suspense.loading")}</IonLabel>
          </IonItem>
        ) : (
          <>
            {error ? (
              <FormFeedback type={"error"}>{error}</FormFeedback>
            ) : (
              <FormFeedback type={"success"}>
                {t("register.confirmation.success")}
              </FormFeedback>
            )}
            <IonButton color={error ? "dark" : "tertiary"} href={"/login"}>
              {error ? t("backToLogin") : t("login.cta")}
            </IonButton>
          </>
        )}
      </ImageCardLayout>
    </IonPage>
  );
};

export default AccountConfirmationPage;
