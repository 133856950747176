import React, { useContext } from "react";
import { chevronForward } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IonAvatar, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";

import UserContext from "../../../../context/UserContext";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type { Company } from "../../../../types/responseTypes";

import styles from "../../../../pages/4.Contracts/AddContract/AddContractPage.module.scss";

interface AddContractChooseCompanyProps {
  setSelectedCompany?: (value: Company) => void;
}

const AddContractChooseCompany: React.FC<AddContractChooseCompanyProps> = ({
  setSelectedCompany
}) => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { companiesCtx } = useContext(UserContext);
  const history = useHistory();

  const selectionHandler = (company) => {
    setSelectedCompany(company);
    history.push({
      pathname: `/demands/create`,
      state: { company }
    });
    sessionStorage.setItem("enableStep3&4", "true");
  };

  return (
    <>
      <IonLabel className={styles.title}>
        {t("demands.pickCompany.title")}
      </IonLabel>
      <IonLabel className={styles.subtitle}>
        {t("demands.pickCompany.description")}
      </IonLabel>

      <IonList lines="none">
        {companiesCtx?.map((company) => (
          <IonItem
            key={company?.id}
            className={styles.companyItem}
            onClick={() => selectionHandler(company)}
          >
            <IonAvatar slot="start">
              <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
            </IonAvatar>
            <IonLabel className={styles.companyName}>
              {layout === "phone" ? company?.shortName : company?.name}
            </IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default AddContractChooseCompany;
