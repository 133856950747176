import React from "react";

import styles from "./FormSteps.module.scss";

type FormStepProps = {
  step: number;
  active: boolean;
  disabled: boolean;
  setCurrentStep: (value: number) => void;
};
const FormStep: React.FC<FormStepProps> = ({
  step,
  active,
  disabled,
  setCurrentStep
}) => {
  return (
    <button
      className={`${styles.step} ${active ? styles.active : ""}`}
      onClick={() => setCurrentStep(step)}
      disabled={disabled}
    >
      {step}
    </button>
  );
};

export default FormStep;
