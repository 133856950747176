import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";
import { isPlatform } from "@ionic/core/components";
import {
  IonButtons,
  IonContent,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { aqmeterActions } from "../../../api/AqmeterActions";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import PublicHelpMenu from "../../../components/PublicHelpMenu";
import { useSettings } from "../../../context/SettingsContext";
import { useWindowSize } from "../../../hooks/useWindowSize";

import LoginAppStoreButtons from "./LoginAppStoreButtons";
import LoginCard from "./LoginCard";

import styles from "./Login.module.scss";

const LoginPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { settings } = useSettings();
  const isMobile = Capacitor.isNativePlatform();
  const isIos = isPlatform("ios");

  const [is2FA, setIs2FA] = useState(null);
  const [mobileVersion, setMobileVersion] = useState(null);

  useEffect(() => {
    aqmeterActions.getMobileVersion().then((version) => {
      setMobileVersion(version);
    });
  }, []);

  const appVersion = isMobile ? mobileVersion : settings?.version;

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" ? (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot={"start"}>
              <LanguageSwitcher />
            </IonButtons>
            <IonButtons slot={"end"}>
              <PublicHelpMenu />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      ) : null}

      <IonContent fullscreen>
        <main
          className={`${styles.loginPage} ${
            layout === "tablet"
              ? styles.tablet
              : layout === "desktop"
                ? styles.desktop
                : styles.phone
          }`}
        >
          <section className={styles.loginFormWrapper}>
            <div className={styles.loginFormTop}>
              {layout === "desktop" ? (
                <div
                  className={`${styles.businessHeader} ${
                    isIos && isMobile ? styles.ios : ""
                  }`}
                >
                  <IonToolbar>
                    <IonButtons slot={"start"}>
                      <LanguageSwitcher />
                    </IonButtons>
                    <IonButtons slot={"end"}>
                      <PublicHelpMenu />
                    </IonButtons>
                  </IonToolbar>
                  <img src="/assets/img/aqmeter-small.png" alt="Aqmeter" />
                </div>
              ) : null}

              {layout === "phone" ? (
                <img src="/assets/img/aqmeter-small.png" alt="Aqmeter" />
              ) : null}

              <LoginCard setIs2FA={setIs2FA} is2FA={is2FA} />
            </div>

            <div className={styles.loginFormBottom}>
              {!isMobile && !is2FA ? <LoginAppStoreButtons /> : ""}
              {!is2FA ? (
                <IonLabel>
                  {t("version")}: {appVersion}
                </IonLabel>
              ) : (
                ""
              )}
            </div>
          </section>

          {layout !== "phone" ? (
            <section
              className={styles.businessInfo}
              style={{ background: "url('/assets/img/login.jpg')" }}
            >
              {layout === "tablet" ? (
                <div
                  className={`${styles.businessHeader} ${
                    isIos && isMobile ? styles.ios : ""
                  }`}
                >
                  <IonToolbar>
                    <IonButtons slot={"start"}>
                      <LanguageSwitcher />
                    </IonButtons>
                    <IonButtons slot={"end"}>
                      <PublicHelpMenu />
                    </IonButtons>
                  </IonToolbar>
                  <img src="/assets/img/aqmeter-small.png" alt="Aqmeter" />
                </div>
              ) : null}

              <div className={styles.businessText}>
                <IonLabel data-testid="loginTitle">{t("login.title")}</IonLabel>
                <IonLabel>{t("login.description")}</IonLabel>
              </div>
            </section>
          ) : null}
        </main>
      </IonContent>
    </IonPage>
  );
};

export default LoginPage;
