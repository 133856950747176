import type { AddContract, EditContract } from "../types/requestTypes";
import type {
  Contract,
  ContractDetails,
  ValidateContractResponse
} from "../types/responseTypes";

import { axiosPrivate } from "./customAxios";

class ContractActions {
  async validate(data: AddContract): Promise<ValidateContractResponse> {
    try {
      const response = await axiosPrivate.post("/contracts/validate", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async add(data: AddContract): Promise<void> {
    try {
      const response = await axiosPrivate.post(
        "/users/contract-association",
        data
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async remove(contractId: number): Promise<void> {
    try {
      const response = await axiosPrivate.delete(
        `/users/contract-association?contractId=${contractId}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getInfo(contractId: number): Promise<ContractDetails> {
    try {
      const response = await axiosPrivate.get(`/contracts/${contractId}`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async edit(contractId: number, updateData: EditContract): Promise<void> {
    try {
      const response = await axiosPrivate.patch(
        `/contracts/${contractId}`,
        updateData
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async getAllUserContracts(): Promise<Contract[]> {
    try {
      const response = await axiosPrivate.get(`/contracts`);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }
}

export const contractActions = new ContractActions();
