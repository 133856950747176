import type { PayUData } from "../../../../types/schema";

export const payuModule = function (form, backendResponse: PayUData) {
  let _form = form;
  let _backendResponse = backendResponse;

  function createPayuFormInputs() {
    const payuRequest = _backendResponse.payuRequest;

    createHiddenInputAndAppendToPayuForm("MERCHANT", payuRequest.merchantId);
    createHiddenInputAndAppendToPayuForm("ORDER_REF", payuRequest.orderRef);
    createHiddenInputAndAppendToPayuForm("ORDER_DATE", payuRequest.orderDate);
    createHiddenInputAndAppendToPayuForm(
      "ORDER_SHIPPING",
      payuRequest.orderShipping
    );
    createHiddenInputAndAppendToPayuForm("PAY_METHOD", payuRequest.payMethod);
    createHiddenInputAndAppendToPayuForm(
      "BACK_REF",
      payuRequest.backRef + _backendResponse.orderId
    );
    createHiddenInputAndAppendToPayuForm(
      "ORDER_HASH",
      _backendResponse.orderHash
    );
    createHiddenInputAndAppendToPayuForm(
      "PRICES_CURRENCY",
      payuRequest.pricesCurrency
    );

    // Billing details
    createHiddenInputAndAppendToPayuForm("BILL_FNAME", payuRequest.billFname);
    createHiddenInputAndAppendToPayuForm("BILL_LNAME", payuRequest.billLname);
    createHiddenInputAndAppendToPayuForm("BILL_EMAIL", payuRequest.billEmail);
    createHiddenInputAndAppendToPayuForm("BILL_PHONE", payuRequest.billPhone);
    createHiddenInputAndAppendToPayuForm(
      "BILL_COUNTRYCODE",
      payuRequest.billCountryCode
    );

    // Delivery Address as OrderID
    createHiddenInputAndAppendToPayuForm(
      "DELIVERY_ADDRESS",
      payuRequest.deliveryAddress
    );

    payuRequest.orderProductName.forEach((pName) => {
      createHiddenInputAndAppendToPayuForm("ORDER_PNAME[]", pName);
    });

    payuRequest.orderProductCode.forEach((pCode) => {
      createHiddenInputAndAppendToPayuForm("ORDER_PCODE[]", pCode);
    });

    payuRequest.orderProductInfo.forEach((pInfo) => {
      createHiddenInputAndAppendToPayuForm("ORDER_PINFO[]", pInfo);
    });

    payuRequest.orderProductPrice.forEach((pPrice) => {
      createHiddenInputAndAppendToPayuForm("ORDER_PRICE[]", pPrice);
    });

    payuRequest.orderProductQuantity.forEach((pQty) => {
      createHiddenInputAndAppendToPayuForm("ORDER_QTY[]", pQty);
    });

    payuRequest.orderProductVat.forEach((pVat) => {
      createHiddenInputAndAppendToPayuForm("ORDER_VAT[]", pVat);
    });

    payuRequest.orderPriceType.forEach((pType) => {
      createHiddenInputAndAppendToPayuForm("ORDER_PRICE_TYPE[]", pType);
    });

    if (payuRequest.testOrder === "TRUE") {
      createHiddenInputAndAppendToPayuForm("TESTORDER", payuRequest.testOrder);
    }
  }

  function createHiddenInputAndAppendToPayuForm(name, value) {
    const formInput = document.createElement("input");
    formInput.type = "hidden";
    formInput.name = name;
    formInput.value = value;
    _form.appendChild(formInput);
  }

  return {
    setForm: function (form) {
      _form = form;
    },
    setBackendResponse: function (backendResponse) {
      _backendResponse = backendResponse;
    },
    submitToPayU() {
      _form.textContent = ""; // clear form
      createPayuFormInputs();
      _form.submit();
    }
  };
};
