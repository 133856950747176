import React, { useContext } from "react";

import AuthContext from "../../../context/AuthContext";
import type { PaidInvoiceInfo } from "../../../types/responseTypes";

import PaidInvoicesListGroupItem from "./PaidInvoicesListGroupItem";

import styles from "./PaidInvoicesListGroup.module.scss";

interface PaidInvoicesListGroupProps {
  month: number;
  year: number;
  invoices: PaidInvoiceInfo[];
}

const PaidInvoicesListGroup: React.FC<PaidInvoicesListGroupProps> = ({
  month,
  year,
  invoices
}) => {
  const { langCtx } = useContext(AuthContext);

  const dateGroup = new Date(year, month).toLocaleDateString(langCtx, {
    year: "numeric",
    month: "long"
  });

  return (
    <div className={styles.group}>
      <p className={styles.title}>{dateGroup}</p>
      {invoices.map((invoice) => (
        <PaidInvoicesListGroupItem
          key={invoice.id}
          invoice={invoice}
          className={styles.item}
        />
      ))}
    </div>
  );
};

export default PaidInvoicesListGroup;
