import React from "react";
import { t } from "i18next";
import { alertCircleOutline } from "ionicons/icons";

import type { TextFieldTypes } from "@ionic/core/dist/types/interface";
import { IonIcon, IonInput, IonLabel } from "@ionic/react";

interface StandardInputProps {
  labelName?: string;
  inputValue?: string;
  inputType?: TextFieldTypes;
  errorMsg?: string;
  setInputValue?: (event: any) => void;
  notRequired?: boolean;
  className?: string;
}

const StandardInput: React.FC<StandardInputProps> = ({
  inputValue,
  setInputValue,
  labelName,
  errorMsg,
  notRequired,
  inputType,
  className
}) => {
  return (
    <div className={className ? className : null}>
      <IonLabel position={"stacked"}>
        {labelName}{" "}
        {!notRequired ? (
          <IonIcon color={"danger"} icon={alertCircleOutline} />
        ) : null}
      </IonLabel>
      <IonInput
        onIonChange={setInputValue}
        className={`${
          !notRequired ? (!inputValue && !!errorMsg ? "error" : "") : ""
        }`}
        value={inputValue}
        inputmode="text"
        placeholder={
          inputType === "date" ? "dd-mm-yyyy" : t("placeholder.addHere")
        }
        type={inputType}
      />
    </div>
  );
};

export default StandardInput;
