import React from "react";
import { useTranslation } from "react-i18next";

import { IonLabel } from "@ionic/react";

import type { Notification } from "../../../types/responseTypes";

import RecentNotificationsItem from "./RecentNotificationsItem";

import styles from "./RecentNotifications.module.scss";

interface RecentNotificationsProps {
  recentNotifications: Notification[];
}

const RecentNotifications: React.FC<RecentNotificationsProps> = ({
  recentNotifications
}) => {
  const { t } = useTranslation();

  return (
    <>
      {recentNotifications.length ? (
        <div className={styles.wrapper}>
          <IonLabel className={styles.title}>
            {t("notifications.summaryTitle")}
          </IonLabel>
          <div className={styles.notificationsBox}>
            {recentNotifications.map((notification) => (
              <RecentNotificationsItem
                key={notification?.id}
                notification={notification}
              />
            ))}
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};

export default RecentNotifications;
