import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonCheckbox, IonIcon, IonLabel } from "@ionic/react";

import { notificationActions } from "../../../../api/NotificationActions";
import { updateContextActions } from "../../../../api/UpdateContextActions";
import AuthContext from "../../../../context/AuthContext";
import UserContext from "../../../../context/UserContext";
import type { Notification } from "../../../../types/responseTypes";
import {
  getNotificationIcon,
  shortenText
} from "../../../../utils/globalFunctions";

import styles from "../Notifications.module.scss";

interface NotificationsListItemProps {
  notification: Notification;
  selectAll: boolean;
  checkedItems: number[];
  checkboxesHandler: (value: number) => void;
}

const NotificationsListItem: React.FC<NotificationsListItemProps> = ({
  notification,
  selectAll,
  checkedItems,
  checkboxesHandler
}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const { updateMenuCtx } = useContext(UserContext);
  const { langCtx } = useContext(AuthContext);

  const notificationClickHandler = async () => {
    history.push({
      pathname: `/notification/${notification?.id}`,
      state: notification
    });
    if (!notification?.readAt) {
      await notificationActions.markNotificationsAsRead([notification?.id]);
      await updateContextActions
        .getMenuDetails()
        .then((data) => updateMenuCtx(data));
    }
  };

  return (
    <div
      className={`${styles.notificationCard} ${
        !notification?.readAt ? styles.unread : ""
      }`}
    >
      <IonCheckbox
        slot="start"
        mode="md"
        color="tertiary"
        checked={
          selectAll
            ? selectAll
            : checkedItems
              ? checkedItems.includes(notification?.id)
              : false
        }
        onClick={() => checkboxesHandler(notification?.id)}
      />

      <div
        data-testid="notification"
        className={styles.notificationText}
        onClick={notificationClickHandler}
      >
        <IonLabel className={styles.notificationSubject}>
          {notification?.subject
            ? notification?.subject
            : t("notification.noSubject")}
        </IonLabel>
        <p className={styles.notificationContent}>
          {shortenText(notification?.content, 130)}
        </p>
        <p className={styles.notificationCreatedDate}>
          {notification?.createdAt
            ? new Date(
                notification?.createdAt.replace(" ", "T")
              ).toLocaleDateString(langCtx) +
              "   " +
              new Date(
                notification?.createdAt.replace(" ", "T")
              ).toLocaleTimeString(langCtx, {
                hour: "2-digit",
                minute: "2-digit"
              })
            : "-"}
        </p>
      </div>

      <div className={styles.notificationIcon}>
        <IonIcon icon={getNotificationIcon(notification)} />
      </div>
    </div>
  );
};
export default NotificationsListItem;
