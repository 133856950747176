import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import { Capacitor } from "@capacitor/core";
import {
  IonButtons,
  IonHeader,
  IonItem,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { aqmeterActions } from "../../../api/AqmeterActions";
import { userActions } from "../../../api/UserActions";
import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import LanguageSwitcher from "../../../components/LanguageSwitcher";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../components/UI/BackBtn";
import AuthContext from "../../../context/AuthContext";
import { useSettings } from "../../../context/SettingsContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { Settings } from "../../../types/responseTypes";

import Toggle2FA from "./2FAToggle";

import styles from "./UserPages.module.scss";

const SettingsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { settings } = useSettings();
  const { updateLangCtx } = useContext(AuthContext);
  const isMobile = Capacitor.isNativePlatform();

  const [has2FA, setHas2FA] = useState(null);
  const [isCheckIconShown, setIsCheckIconShown] = useState(false);
  const [isXIconShown, setIsXIconShown] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);
  const [mobileVersion, setMobileVersion] = useState(null);

  const { data: settingsResponse, error } =
    useFetchCaching<Settings>("/users/settings");

  useEffect(() => {
    if (settingsResponse) {
      updateLangCtx(settingsResponse.language);
    }
  }, [settingsResponse]);

  useEffect(() => {
    const mobileVersionHandler = async () => {
      await aqmeterActions.getMobileVersion().then((version) => {
        setMobileVersion(version);
      });
    };
    mobileVersionHandler();
  }, []);

  useEffect(() => {
    if (settingsResponse) {
      setHas2FA(settingsResponse?.has2Fa);
    }
  }, [settingsResponse?.has2Fa]);

  const appVersion = isMobile ? mobileVersion : settings?.version;

  const toggle2FA = async () => {
    setHas2FA((prev) => !prev);

    if (!has2FA) {
      setIsCheckIconShown(true);
    } else {
      setIsXIconShown(true);
    }
    setIsDisabled(true);

    await userActions
      .changeUserSettings({
        has2Fa: !has2FA
      })
      .then(() => {
        setTimeout(() => {
          if (!has2FA) {
            setIsCheckIconShown(false);
          } else {
            setIsXIconShown(false);
          }
          setIsDisabled(false);
        }, 500);
      });
  };

  return (
    <div>
      <IonPage className={`layout--${layout}`}>
        {layout === "phone" && (
          <IonHeader>
            <IonToolbar>
              <IonButtons slot="start">
                <BackBtn />
              </IonButtons>
              <IonButtons slot="end">
                <AqNotificationsBell />
              </IonButtons>
            </IonToolbar>
          </IonHeader>
        )}
        <SidebarLayout
          layoutType={"submenu"}
          url={"/more"}
          menuType={"profile"}
          menuSelected={"item3"}
        >
          <IonLabel className={styles.pageTitle}>{t("settings")}</IonLabel>

          <SkeletonList
            loading={!settingsResponse}
            error={error}
            type={"simple"}
          />
          {settingsResponse ? (
            <IonList lines="full">
              <IonItem>
                <IonLabel color="medium">{t("language")}</IonLabel>
                <LanguageSwitcher />
              </IonItem>

              <Toggle2FA
                has2FA={has2FA}
                toggle2FA={toggle2FA}
                isDisabled={isDisabled}
                isCheckIconShown={isCheckIconShown}
                isXIconShown={isXIconShown}
              />

              <IonItem>
                <IonLabel color="medium">{t("version")}</IonLabel>
                <IonLabel slot="end" color="medium">
                  {appVersion}
                </IonLabel>
              </IonItem>
            </IonList>
          ) : (
            ""
          )}
        </SidebarLayout>
      </IonPage>
    </div>
  );
};
export default SettingsPage;
