import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import {
  IonCheckbox,
  IonCol,
  IonGrid,
  IonItem,
  IonLabel,
  IonRow
} from "@ionic/react";

import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import AuthContext from "../../../context/AuthContext";
import type { Invoice } from "../../../types/responseTypes";

import NotPaidInvoicesItemActions from "./NotPaidInvoicesItemActions";

interface NotPaidInvoicesItemProps {
  invoice: Invoice;
  updateSelectionList: (value) => void;
  checked?: boolean;
  disabled: boolean;
}

const NotPaidInvoicesItem: React.FC<NotPaidInvoicesItemProps> = ({
  invoice,
  updateSelectionList,
  checked,
  disabled
}) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  const newPayHandler = () => {
    if (disabled) {
      toastAdd(t("invoice.flag.newPay"), "info");
    }
  };

  return (
    <IonItem
      className="card-widget-list-item contract-invoices-list"
      onClick={newPayHandler}
    >
      <IonCheckbox
        slot="start"
        mode="md"
        color="tertiary"
        checked={checked}
        disabled={disabled}
        onClick={() => updateSelectionList(invoice?.id)}
      />
      <IonGrid>
        {(invoice?.isInProcessing || invoice?.hasPaymentInitialized) && (
          <React.Fragment>
            {invoice?.isInProcessing && (
              <div key="isInProcessing">
                <IonLabel className="ag-number-description invoice-flag processing">
                  {t("paymentProcessing")}
                </IonLabel>
              </div>
            )}
            {invoice?.hasPaymentInitialized && (
              <div key="hasOnePaymentInitialized">
                <IonLabel className="ag-number-description invoice-flag pay-init">
                  {t("hasOnePaymentInitialized")}
                </IonLabel>
              </div>
            )}
          </React.Fragment>
        )}

        <IonRow>
          <IonCol className="invoice-information">
            {(invoice?.isInProcessing || invoice?.hasPaymentInitialized) && (
              <span className="extra-spacing"></span>
            )}
            <span className="ag-number-description gray">
              {t("invoice.title")}
            </span>
            <span className="ag-number-description black">
              <span>
                {invoice?.serial} {invoice?.number}{" "}
              </span>
              <span className="lowercase">
                {t("invoice.issuedAt")}{" "}
                {new Date(invoice?.invoiceDate).toLocaleDateString(langCtx)}
              </span>
            </span>
            <span>
              <span className="ag-number-description gray">
                {t("invoice.value")}
              </span>
              <span className="ag-number-description black">
                {invoice?.hasPartialPayment
                  ? invoice?.amount.toLocaleString(langCtx)
                  : invoice?.dueAmount.toLocaleString(langCtx)}{" "}
                {t("currency")}
              </span>
            </span>
            {invoice?.hasPartialPayment && (
              <span>
                <span className="ag-number-description gray">
                  {t("invoice.difference")}
                </span>
                <span className="ag-number-description black">
                  {invoice?.dueAmount.toLocaleString(langCtx)} {t("currency")}
                </span>
              </span>
            )}
            <span>
              <span className="ag-number-description gray">
                {t("invoice.dueDate")}
              </span>
              <span className="ag-number-description black">
                {new Date(invoice?.dueDate).toLocaleDateString(langCtx)}
              </span>
            </span>
          </IonCol>
        </IonRow>
        <NotPaidInvoicesItemActions invoice={invoice} />
      </IonGrid>
    </IonItem>
  );
};
export default NotPaidInvoicesItem;
