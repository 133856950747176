import React from "react";
import { Link } from "react-router-dom";

export const TermsContentEN: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <h5>PLEASE READ THIS DOCUMENT CAREFULLY BEFORE USING OUR SERVICES.</h5>

      <p className="help-last-update">Last modified: 5 April 2018</p>
      <h6>Acceptance of Terms of Use</h6>
      <p className="help-strong">
        Welcome to the Aqmeter platform or website{" "}
        <Link
          to="https://app.aqmeter.com"
          target="_blank"
          rel="noopener noreferrer"
        >
          https://app.aqmeter.com
        </Link>
        , operated by the Company (as defined below in this term). By accessing
        this website or using the Services offered by the Company, you
        unconditionally agree to the terms and conditions listed below
        ("Terms").
      </p>
      <ol className="help-nested-list">
        <li>
          <span className="help-strong">Definitions. Purpose</span>

          <ol>
            <li>
              This document contains the terms and conditions for the provision
              of online services via the Aqmeter platform and the website{" "}
              <Link
                to="https://app.aqmeter.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                https://app.aqmeter.com
              </Link>
              .
            </li>

            <li>
              The following capitalized terms shall have the meanings set forth
              below unless otherwise expressly provided:
            </li>

            <span className="definitions">
              <label>"Company" or "Provider" </label>
              <span>
                means Set Mobile S.R.L., a limited liability company,
                incorporated and operating in accordance with the laws of
                Romania, having its registered office in Bucharest, str. Rovine,
                nr.3, Sc.A, Ap.21, registration number in the Trade Register
                J40/16520/2003, unique identification code RO15967256.
              </span>
            </span>

            <span className="definitions">
              <label>"Utility Operator" or "Operator" </label>
              <span>
                according to art. 2 lit. g) and h) of Law no. 51/2006,
                republished, means the Romanian or foreign legal person which
                has the competence and capacity recognised by the license to
                supply/provide, in accordance with the regulations in force, a
                public utility service and which directly manages and operates
                the public utility system related to it.
              </span>
            </span>

            <span className="definitions">
              <label>"Aqmeter App" or "App" </label>
              <span>
                means the software application developed by the Company and
                marketed by the Company under this name, through which Users can
                record water meter self-readings and make online payments for
                utility bills provided by the Utility Operator, accessible at
                the website described in the preamble and possibly for download
                by Users through the Apple App Store and Google Play.
              </span>
            </span>

            <span className="definitions">
              <label>"Order" </label>
              <span>
                means an order placed by the User through the Platform by which
                the User expresses his/her commitment to purchase one or more
                Services, under the conditions set forth herein and/or agreed
                with Aqmeter through a remote means of communication.
              </span>
            </span>

            <span className="definitions">
              <label>"Services" </label>
              <span>
                means any services that are offered to the User for purchase,
                whether for a fee or free of charge via the Platform/App.
              </span>
            </span>

            <span className="definitions">
              <label>"Aqmeter Platform" or "Platform" </label>
              <span>
                means the totality of the Applications and Services owned by the
                Company, published through the aqmeter.com domain and using
                Aqmeter logos,through which the Company offers services on
                behalf of the Operator. Mainly, through the Platform, water
                meter readings are recorded, respectively payments related to
                the service invoices that the Operator issues to Users are
                processed.
              </span>
            </span>

            <span className="definitions">
              <label>"User" </label>
              <span>
                means the natural person who accesses the Platform, registers an
                Account and uses the Applications and Services offered by the
                Platform.
              </span>
            </span>

            <span className="definitions">
              <label>"Account" </label>
              <span>
                means the section of the Platform accessible to the User through
                the use of an e- mail address and password for identification
                purposes, which allows the User to submit water meter readings
                and pay a service bill to the Operator and which contains
                information about the User and the User's history on the
                Platform (e.g., previous readings, tax bills, etc.).
              </span>
            </span>

            <span className="definitions">
              <label>“Contract” </label>
              <span>
                means the agreement entered into between the Operator and the
                User for the purpose of supplying/providing services, under the
                conditions provided for by the legislation in force,
                respectively the utility service regulation.
              </span>
            </span>

            <span className="definitions">
              <label>"Agreed Supplier" </label>
              <span>
                means a legal entity included in the Aqmeter Application or the
                aqmeter.com website as a result of a contract between one or
                more water suppliers and the Company.
              </span>
            </span>

            <span className="definitions">
              <label>"Confidential Information" </label>
              <span>
                means any information relating to the User or the Company, their
                associates and affiliates or the Contract and made available
                either before or after the Signing Date.
              </span>
            </span>

            <span className="definitions">
              <label>“Newsletter” </label>
              <span>
                means a means of periodic, exclusively electronic, information,
                i.e. electronic mail or other form of remote communication
                (e.g., e-mail, SMS) regarding the Services and/or promotions
                carried out by the Provider or its affiliates during a given
                period, without any commitment on the part of the Provider with
                reference to the information contained therein.
              </span>
            </span>

            <span className="definitions">
              <label>"Specifications" </label>
              <span>
                means any details of the features of the Services as specified
                in the description available on the Platform.
              </span>
            </span>

            <span className="definitions">
              <label>"Transaction" </label>
              <span>
                means the payment operation carried out by the User, i.e. the
                collection via the Platform on behalf of and for the Operator,
                of the consideration for a Service Invoice following a previous
                provision of Services by the Operator for the benefit of the
                User.
              </span>
            </span>

            <li>
              This document sets out the terms and conditions applicable to the
              relationship between Aqmeter and each User using the Platform for,
              on the occasion of or after placing an Order.
            </li>

            <li>
              Headings are included in this document for convenience and will
              not affect its interpretation.
            </li>

            <li>
              For the avoidance of doubt, in accordance with the business policy
              established independently and unilaterally by Aqmeter, the
              provision in the online environment (i.e., via the Platform) of
              the Services normally provided by Aqmeter is made both to
              individual consumers and to legal entities (Utility Companies -
              Operators).
            </li>

            <li>
              Aqmeter recommends that all potential Customers carefully read
              this document containing the terms and conditions applicable to
              the online provision of Aqmeter Services.
            </li>

            <li>
              Privacy and personal data processing issues related to the use, in
              any form, of the Platform are governed by the privacy policy
              ("Privacy Policy"), which supplements these Terms and Conditions.
              Please review our full <Link to="/privacy">Privacy Policy</Link>.
            </li>

            <li>
              Use of the Platform implies the express acceptance by any User of
              these terms and conditions and the Privacy Policy.
            </li>

            <li>
              The Provider reserves the right to modify the terms and conditions
              at any time, and the updated form of these terms and conditions
              will be available to Customers on the Platform in the "Terms and
              Conditions" section.
            </li>

            <li>
              These terms and conditions applicable to the online provision of
              services are available to the User for storage and reproduction.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Platform Policy. Main features of the online provision of services
            by Aqmeter
          </span>
          <ol>
            <li>
              Access to the Platform for the purpose of placing an Order is
              permitted to any potential User acting with a legitimate purpose
              and intending to purchase one or more Services from the Provider,
              subject to these terms and conditions.
            </li>

            <li>
              Misuse of the Platform is strictly prohibited. Misuse is any use
              of the Platform in a manner that is contrary to fair business
              practices, applicable law or in any other manner that is likely to
              harm Aqmeter or its affiliates in any way.
            </li>

            <li>
              Aqmeter reserves the right to deny Customers access to some or all
              of the Platform's functions and to restrict the processing and/or
              delivery of an Order if, in Aqmeter's reasonable opinion, there is
              a suspicion of fraud on the part of the User, if the User engages
              in conduct that may harm the interests of Aqmeter or its
              affiliates, or if the User misuses the Platform.
            </li>

            <li>
              The Provider reserves the right to refuse to provide the Services
              for unlawful and immoral purposes. In this regard, if the Service
              Provider considers that the request for Services may have an
              immoral or unlawful purpose, it will inform the User of the
              refusal to provide the Services and will return the consideration
              for the Services (if paid) without additional costs.
            </li>

            <li>
              The Provider may also refuse to provide the Services if the User
              abuses the way of completing the Services as explained on the
              Website and does not follow the Provider's instructions on the
              Website on how to submit an order.
            </li>

            <li>
              By using the Platform, the User accepts and agrees to the form of
              remote communication (e.g., telephone or e-mail) by which the
              Provider carries out its operations.
            </li>

            <li>
              Any information used to describe the Services (including, but not
              limited to, static or dynamic images, descriptive text, graphical
              or video presentations) does not impose any obligation on the
              Service Provider and is for presentation and information purposes
              only. The Service Provider shall use all reasonable efforts to
              ensure the accuracy of the pricing information, Services and their
              Specifications presented on the Platform.
            </li>

            <li>
              If prices or any other details in relation to the Service
              Specifications have been erroneously displayed on the Platform for
              any reason, the Service Provider shall inform the User who has
              registered on the Platform by e-mail as soon as possible of such
              error.
            </li>

            <li>
              The Company may publish on the Platform information about Services
              and/or promotions offered by it or by any other third party with
              which the Company has entered into partnership agreements during a
              given period of time.
            </li>

            <li>
              Services that are the subject of a promotion or campaign of any
              kind will also be subject to the terms and conditions applicable
              to that campaign or promotion.
            </li>

            <li>
              Any promotions featured on the Platform are valid for the duration
              mentioned. If no duration is indicated for the running of
              promotions, they will be valid within the limits of available
              stocks.
            </li>

            <li>
              The order shall be deemed accepted by Aqmeter at the time of
              payment by the User to the Agreed Provider via electronic means of
              payment (i.e. via credit/debit card or via bank transfer, in the
              latter case the order shall be deemed accepted at the time of
              confirmation by the Agreed Provider of the debiting of the bank
              account by the User).
            </li>

            <li>
              For justified reasons (including but not limited to suspected
              fraud on the part of the User, non-payment or technical
              impossibility of the Platform to provide the respective Services),
              the Provider reserves the right to cancel the Order placed by the
              User. In such a case, Aqmeter shall immediately notify the User of
              the change and the User may accept or refuse the change to the
              Order (if the change is temporary in terms of the delivery of the
              Services). If the User refuses, the Order shall be deemed canceled
              and the parties shall be returned to the situation prior to the
              Order (including reimbursement by the Provider of any amounts
              received, if applicable) and without any liability on the part of
              the Provider to the User.
            </li>

            <li>
              The order is accepted and the Contract is deemed to be concluded
              between the Provider and the User when the User receives from the
              Provider, via e-mail and/or SMS, the notification of successful
              payment for the Services. Payment for the Services may only be
              made in accordance with the instructions posted on the Website,
              including but not limited to payment by online cards or bank
              transfer. These terms and conditions as well as any subsequent
              agreements between the User and the Service Provider in respect of
              an Order, whether or not contained in an electronic or physical
              writing (including, but not limited to, any agreement between
              Aqmeter and the User in respect of Products made by Aqmeter to the
              User's specifications) form part of the Contract.
            </li>

            <li>
              The Provider provides the User with an e-mail address in the
              "Contact" section of the Platform for identifying and correcting
              errors that occur during data entry. As a rule, communication with
              the Service Provider will be via the addresses mentioned in the
              "Contact" section of the Platform.
            </li>

            <li>
              The Service Provider may at any time adhere to codes of conduct
              concerning the online provision of the Services and shall inform
              the Customers of this fact and ensure that the Customers are able
              to consult these codes effectively.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Orders</span>
          <ol>
            <li>
              The User can place Orders through the Platform, exclusively by
              registering an Account, by selecting and adding the desired
              Products to the shopping cart.
            </li>

            <li>
              The User will register an Account in the dedicated section of the
              Platform. By registering an Account, the User will be able to
              manage more efficiently the Orders placed on the Platform, having
              the possibility to view in the Account information about previous
              Orders, tax invoices, etc. Registering an Account will be possible
              by providing an e-mail address and a password or by logging in
              with different types of social networks (including but not limited
              to Facebook, Linkedin, Google, etc.).Aqmeter reserves the right to
              validate Orders prior to their fulfillment by contacting Customers
              by telephone or email, and Customers expressly declare that they
              accept this right of the Provider.
            </li>

            <li>
              Adding a Service to the shopping cart (or any other form by which
              a Service may be purchased through the Aqmeter App or the
              Platform), without completing the Order, does not result in an
              Order being registered.
            </li>

            <li>
              The Order will be considered completed upon full payment by the
              User of the price of the Products in the Order, by one of the
              payment methods accepted by the Provider as expressly indicated on
              the Platform and which can be consulted by the User at the latest
              at the beginning of the Order process.
            </li>

            <li>
              By completing the Order, the User confirms that all data he/she
              has provided is correct, complete and true at the time of placing
              the Order. By placing an Order the User explicitly acknowledges
              that such Order implies his/her firm obligation to pay the "total
              payment amount" indicated.
            </li>

            <li>
              By creating an Account and, where applicable, completing the
              Order, the User consents that the Provider may contact the User,
              by any means available, i.e. automated calling system without
              human intervention, fax, e-mail, in any situation where it is
              necessary to contact the User.
            </li>

            <li>
              The Service Provider may cancel the Order placed by the User, with
              the concomitant or subsequent notification of the User, and
              without such cancellation giving rise to any liability of Aqmeter
              towards the User, in the following cases:
            </li>

            <ul>
              <li>
                non-acceptance of the Transaction by the User's card-issuing
                bank in the case of online payment;
              </li>
              <li>
                invalidation of the Transaction by Aqmeter's approved card
                processor in case of online payment;
              </li>
              <li>
                the data provided by the User when accessing the Platform is
                incomplete or incorrect;
              </li>
              <li>
                the Provider reasonably believes that by accessing the Platform
                and placing Orders the User is pursuing an unlawful purpose or
                may cause any harm to Aqmeter or its affiliates;
              </li>
              <li>
                any of the terms and conditions herein have not been strictly
                observed.
              </li>
            </ul>
          </ol>
        </li>

        <li>
          <span className="help-strong">Prices. Billing. Payment. Return</span>
          <ol>
            <li>
              The prices of the Services displayed on the Platform are expressed
              in Lei and do not include VAT according to the legislation in
              force. The valid prices for the Services are those displayed on
              the Platform at the time an Order is completed.
            </li>

            <li>
              Payment for Services displayed on the Platform/App is made by the
              User directly to the account of the Agreed Provider (i.e. the
              utility company), the Aqmeter Platform/App being not responsible
              for the correctness of the Transactions made by the User to the
              Agreed Provider.
            </li>

            <li>
              Aqmeter may update the prices of the Services at any time, and any
              such update shall supersede any previously posted prices for such
              Services.
            </li>

            <li>
              Depending on the existing relationship between the User and the
              Agreed Provider (i.e. existence of a relationship or not), the
              User will find in the Application/Platform the contract concluded
              between the User and the Agreed Provider (e.g. water company,
              sanitation company), as well as the due invoices according to the
              legal situation between the two. The User can also make payment
              only on the basis of invoices issued by the Agreed Provider which
              are in his possession.
            </li>

            <li>
              After the successful completion of the Transaction, the User will
              find in the Platform/Application the history of the Transactions
              carried out, as well as the proof of completion of the
              Transactions, as communicated by the agreed Provider via the
              Platform/Application.
            </li>

            <li>
              According to the Romanian legislation in force, for those Orders
              for which the Provider will accept payment by bank card, the
              Provider will not request additional payments.
            </li>

            <li>
              In the case of online payments, the Provider is not and cannot be
              held liable for any other costs incurred by the User in addition
              to the price of the Service purchased including, but not limited
              to, transfer or currency conversion fees applied by the User's
              card issuing bank, if the currency of issue of the card differs
              from the currency in which the sale is made.
            </li>

            <li>
              If the User requests the return of the consideration for the
              Services, the User shall submit a request to the agreed Provider,
              and the Provider shall not be responsible for the return of any
              monies received as a result of the Transaction through the
              Platform/Application. Any request from the User regarding the
              prices displayed or the execution of Transactions through one of
              the methods provided for by the Platform/Application shall be
              dealt with by the agreed Provider, the Provider being absolved of
              any liability.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Services offered</span>
          <ol>
            <li>
              The Company offers the User free access to the Aqmeter Application
              through which he can pay his bills to the utility companies
              (Operators) and make other payments. The services provided through
              the Aqmeter Application are only for Users who accept these terms
              and conditions.
            </li>

            <li>
              Through the Aqmeter App/Platform Users are put in direct contact
              with the approved Providers (i.e. water companies, sanitation
              companies), with payment for Services being made directly to their
              bank account.
            </li>

            <li>
              The Company also offers Approved Providers, for a fee, access to
              the Aqmeter Application/Platform to connect them with Users
              registered through the Aqmeter Application/Platform.
            </li>

            <li>
              For the avoidance of doubt, the Company cannot be held liable for
              any use inconsistent with the purpose of the Aqmeter App or the
              aqmeter.com website by persons other than Users/Operators as
              defined in these terms and conditions, given that the Aqmeter App
              and the aqmeter.com website are developed by the Company with
              Users and their general profile in mind, including the level of
              responsibility expected of an average user of an application
              similar to the Aqmeter App or the aqmeter.com website.
            </li>

            <li>
              Through the Aqmeter App and/or the aqmeter.com website, the
              Company offers users access to the following services:
            </li>

            <ul>
              <li>
                for Operators:
                <ul>
                  <li>management contractual data subscribers;</li>
                  <li>card management;</li>
                  <li>work order management;</li>
                  <li>view meter readings;</li>
                  <li>view subscriber invoices;</li>
                  <li>
                    integration with reading solutions using smart meters;
                  </li>
                </ul>
              </li>
              <li>
                for Users:
                <ul>
                  <li>auto quote counter index</li>
                  <li>view and pay invoices online</li>
                  <li>registration of requests for authorized reading</li>
                  <li>update contractual data</li>
                  <li>real-time notification of service delivery incidents</li>
                </ul>
              </li>
            </ul>
          </ol>
        </li>

        <li>
          <span className="help-strong">Guarantees</span>
          <ol>
            <li>
              The Company assumes no liability, under any circumstances, for any
              direct or indirect damages and any direct or indirect loss of
              profits suffered as a result of the use or interruption of the use
              of the services offered by the Aqmeter App and/or the aqmeter.com
              website.
            </li>

            <li>
              The Company shall not be liable for any loss or damage that may
              arise from late payments made through the Aqmeter App and/or the
              aqmeter.com website or as a result of improper operation of the
              Aqmeter App and/or the aqmeter.com website.
            </li>

            <li>
              The Company is not responsible for the information transmitted by
              the Approved Provider to the User via the Platform/App, this being
              a separate relationship between the two parties, nor is the
              Company responsible for the Transactions made by the User to the
              Approved Provider, whether they are due to the Approved Provider
              or the online payment processor.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Intellectual Property</span>
          <ol>
            <li>
              Trademarks, brands, copyrights and any other intellectual property
              rights registered or pending registration relating to the Services
              owned or used by Aqmeter are and shall remain the exclusive
              property of Aqmeter or, where applicable, Aqmeter's licensors. The
              User shall have no rights or claims with respect thereto.
            </li>

            <li>
              The User shall not act in any way that may prejudice the rights
              set out in Article 8.1 above. The User undertakes not to use in
              his activity any sign or name similar or identical to the
              trademarks, trade names of the Services, etc., either as part of a
              name or in any other way.
            </li>

            <li>
              All information available on the Platform (including, but not
              limited to, images, text, logos, symbols) that can be viewed or
              accessed in any way through the use of electronic equipment, the
              content of emails sent to the User by the Provider, any
              information communicated to the User (including, but not limited
              to, data relating to the Provider, its activity, etc.) by any
              means by a representative of the Provider are and remain the
              exclusive property of Aqmeter. The User may copy, transfer and/or
              use such data only for personal or non-business purposes and only
              if it does not conflict with these Terms and Conditions.
            </li>

            <li>
              The Provider shall exclusively own all intellectual property
              rights (including but not limited to copyright, trademark rights,
              industrial design rights) in the Deliverables or Services and all
              rights (including but not limited to the right to use, modify,
              disclose or publish) in the ideas, concepts, know-how, methods,
              solutions, techniques, processes and skills and adaptations of the
              Deliverables throughout the performance of its business.
            </li>

            <li>
              The User acknowledges and agrees that no transfer of any
              intellectual or industrial property rights in the ideas, names,
              designs, trademarks, concepts, know-how, methods, solutions,
              techniques, scripts, codes, processes, skills and adaptations
              contained in the Deliverable or related to the Services occurs
              through the provision of the Services.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">User's right of withdrawal</span>
          <ol>
            <li>
              According to the legislation in force applicable to contracts
              concluded at a distance, the{" "}
              <span className="help-strong">
                right of withdrawal is not guaranteed in the case of contracts
                for the provision of services, after the services have been
                fully
              </span>{" "}
              performed, if performance has begun with the express prior consent
              of the User and after the User has confirmed that he is aware that
              he will lose his right of withdrawal after full performance of the
              Contract by the Provider;
            </li>

            <li>
              Also, given the relationship between the Platform/Application, the
              Agreed Provider and the User, any complaint/suggestion, request
              for refund of money on Transactions made will be settled directly
              between the User and the Agreed Provider, the Platform/Application
              acting exclusively as an interface between the two Parties.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Advertising</span>
          <ol>
            <li>
              This procedure shall be mentioned in all commercial communications
              sent by the Provider to Users.
            </li>

            <li>
              Aqmeter newsletters are delivered through Aqmeter's specialist and
              approved partners.
            </li>

            <li>
              The User's opt-out of receiving Newsletters does not imply a
              waiver of acceptance of the rest of the terms and conditions for
              online sales by Aqmeter.
            </li>

            <li>
              The User may consent to receive commercial communications by
              e-mail, allowing the Provider and its collaborators to carry out
              such communications, by ticking the specific option at the end of
              these terms and conditions. The subject of electronic messages
              sent by e-mail will begin with the word "ADVERTISEMENT" written in
              capital letters.
            </li>

            <li>
              The User may revoke consent to such commercial communications at
              any time by:
              <ul>
                <li>
                  sending a written request to the Seller to the e-mail address
                  in the "Contact" section of the Platform; in this case, the
                  revocation will take effect no later than 48 (forty- eight)
                  hours after the initiation of the procedure;
                </li>
                <li>
                  unsubscribe from receiving Newsletters at any time by clicking
                  on the dedicated link in any Newsletter;
                </li>
                <li>
                  ticking the option to withdraw consent in the dedicated
                  section of the Platform.
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Reply</span>
          <ol>
            <li>
              The Service Provider shall not be liable for any damage caused to
              the User/Operator or any third party as a result of the Service
              Provider's performance of any of its obligations under the Order,
              nor for damages resulting from the improper use of the Services
              offered.
            </li>

            <li>
              Provider shall in no event be liable for any loss of use,
              contracts, data, goodwill, revenues or profits (whether or not
              considered direct claims) or any consequential, special, indirect,
              incidental, punitive or exemplary loss, damage or expense under or
              in connection with this Agreement or the Services.
            </li>

            <li>
              The User will keep the username and password of the Account safe
              and will be solely responsible in case of fraudulent use by a
              third party.
            </li>

            <li>
              Aqmeter shall not be liable for any damage caused by any technical
              malfunction of the Platform (e.g., inability to access any link on
              the Platform).
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Complaints and enquiries</span>
          <ol>
            <li>
              For complaints or claims related to the purchased Service, the
              User can use the complaint form available on the Platform.
            </li>

            <li>
              The User may make complaints about the Orders, which will be sent
              to the e-mail address mentioned in the "Contact" section of the
              Platform. Complaints received in this way will be dealt with by
              the Provider within 30 (thirty) calendar days of receipt.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Use and processing of personal data of natural persons
          </span>
          <ol>
            <li>
              By using the Platform, the User understands and agrees to submit
              personal data to Aqmeter, which data will be processed in
              accordance with and for the purposes set out in the Privacy
              Policy, which complements these Terms and Conditions.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Privacy</span>
          <ol>
            <li>
              Both the Provider and the User undertake and warrant to keep the
              Confidential Information confidential, to prevent its disclosure
              to third parties and not to use it for purposes other than those
              contemplated by this Agreement, except as authorized in advance in
              writing by the Provider/User and subject to the terms and
              conditions imposed by it.
            </li>

            <li>
              Confidential Information may be made available either in writing,
              in machine-readable form or electronically, including by facsimile
              or other electronic form of transmission or orally, and may be
              marked as confidential or not.
            </li>

            <li>
              The following information is not considered Confidential
              Information: (i) is or has become public (including without
              limitation any information submitted to any government agency and
              available to the public) other than as a result of a disclosure by
              Provider in violation of this Section (ii) is made available to
              Provider on a non-confidential basis from a source other than User
              that Provider believes is not prohibited from disclosing such
              information to Provider (iii) is known to Provider prior to
              receipt from User without any obligation of confidentiality or
              (iii) is developed by Provider independently of the Confidential
              Information disclosed by User.
            </li>

            <li>
              The User shall not disclose to any third party the Confidential
              Information received from the Provider, with the exception of the
              Deliverables and only under the conditions set out in these terms
              and conditions and unless required to do so by applicable law. In
              the event that the User discloses Confidential Information to any
              person (public or private) other than those required to perform
              the contracted Service (ie. Commercial Registry, courts of law,
              police authorities, banking institutions, national tax
              administration agencies or commercial partners), the Provider
              reserves the right to claim against the User for any damages that
              may result from such disclosure.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Notifications</span>
          <ol>
            <li>
              These Terms and Conditions together with the Registration Form
              constitute the entire Agreement between the parties and supersede
              any prior agreements between the parties. The parties agree that
              no communication between the parties may modify this Agreement
              unless in writing and accepted by signature of both parties.
            </li>

            <li>
              The User agrees that all communications made under this Agreement
              shall be made by electronic mail to the address communicated by
              the User on the Platform, and agrees that such communication shall
              be valid upon simple proof by the Provider that the communication
              has been sent. The Service Provider is entitled to use other
              methods of transmission (by post, courier or bailiffs) of its
              communications to the User.
            </li>

            <li>
              The parties agree that all communications in connection with this
              Agreement shall be made to the following addresses:
              <ul>
                <li>
                  For the User/Operator - to the postal address mentioned in via
                  the Platform;
                </li>
                <li>
                  For the Company - at the following e-mail address:{" "}
                  <Link to="mailto:office@setmobile.ro">
                    office@setmobile.ro
                  </Link>{" "}
                  .
                </li>
              </ul>
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">Force majeure</span>
          <ol>
            <li>
              Neither the Provider nor the User shall be liable for
              non-performance of its contractual obligations if such
              non-performance on time and/or properly, in whole or in part, is
              due to an event of force majeure as defined by the Romanian Civil
              Code.
            </li>

            <li>
              If within 15 (fifteen) days from the date of the occurrence of the
              force majeure event, the said event does not cease, either the
              Provider or the User shall be entitled to notify the other party
              of the termination of the contract by operation of law without
              either party being entitled to claim damages from the other.
            </li>
          </ol>
        </li>

        <li>
          <span className="help-strong">
            Applicable law and dispute resolution
          </span>

          <ul className="local-law">
            <li>This contract is subject to Romanian law.</li>
            <li>
              Aqmeter and the User will attempt to resolve amicably any disputes
              or disagreements that may arise. To the extent that amicable
              settlement is not possible, disputes shall be settled by the
              competent Romanian courts according to law.
            </li>
            <li>
              Details on alternative dispute resolution methods are available at
              the EU's{" "}
              <Link
                to="https://ec.europa.eu/consumers/odr/main/?event=main.home2.show"
                target="_blank"
                rel="noopener noreferrer"
              >
                Online Dispute Resolution
              </Link>{" "}
              platform.
            </li>
          </ul>
        </li>
      </ol>
    </div>
  );
};
