import React from "react";
import { useHistory } from "react-router";

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardSubtitle,
  IonCardTitle
} from "@ionic/react";

import { userActions } from "../../../../../api/UserActions";

import styles from "../SignaturePage.module.scss";

interface RecapProps {
  trimmedDataSig: string;
  requestData: any;
}

const RecappPage: React.FC<RecapProps> = ({ trimmedDataSig, requestData }) => {
  const history = useHistory();
  const date = new Date();
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  // This arrangement can be altered based on how we want the date's format to appear.
  const currentDate = `${day}.${month}.${year}`;

  const handleSendData = async () => {
    try {
      const specifications = JSON.parse(
        sessionStorage.getItem("specification")
      );
      const CI_DATA = JSON.parse(sessionStorage.getItem("CI_DATA"));
      const signatureFile = JSON.parse(
        sessionStorage.getItem("signatureFileName")
      );
      const ciFile = JSON.parse(sessionStorage.getItem("ciFileName"));
      const companyId = JSON.parse(
        sessionStorage.getItem("selectedCompanyFromLocationObj")
      );

      await userActions.lastFrontierPost({
        firstName: CI_DATA.ocr.firstName,
        lastName: CI_DATA.ocr.lastName,
        specification: { ...specifications },
        destinationAddress: { ...CI_DATA.ocr.address },
        ciData: { ...CI_DATA.ocr.ciData },
        ciFileName: ciFile.ciFileName,
        signatureFileName: signatureFile.signatureName,
        companyId: companyId.id
      });
    } catch (err) {
      console.error(err);
    } finally {
      history.push("/demands/list");
      localStorage.removeItem("step");
      localStorage.removeItem("requestData");
      localStorage.removeItem("specifications");
      localStorage.removeItem("CI_DATA");
      localStorage.removeItem("signatureFileName");
      localStorage.removeItem("ciFileName");
      localStorage.removeItem("selectedCompanyFromLocationObj");
      sessionStorage.removeItem("step");
      sessionStorage.removeItem("requestData");
      sessionStorage.removeItem("specifications");
      sessionStorage.removeItem("CI_DATA");
      sessionStorage.removeItem("signatureFileName");
      sessionStorage.removeItem("ciFileName");
      sessionStorage.removeItem("selectedCompanyFromLocationObj");
      sessionStorage.removeItem("enableStep3&4");
    }
  };

  return (
    <div>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={styles.title}>
            {`Cerere pentru Întocmirea Contractului - Sumar`}
          </IonCardTitle>
          <IonCardSubtitle className={styles.title}>
            PERSOANĂ FIZICĂ
          </IonCardSubtitle>
        </IonCardHeader>
        <IonCardContent>
          {/* <IonCardTitle className={styles.header}>
            {`\xa0\xa0\xa0I. Date Personale`}
          </IonCardTitle> */}
          Subsemnatul(a){" "}
          <strong>
            <em>{requestData?.firstName}</em>
          </strong>{" "}
          <strong>
            <em>{requestData?.lastName}</em>
          </strong>
          , posesor al CI/BI seria{" "}
          <strong>
            <em>{requestData.ciData.series}</em>
          </strong>
          , nr.{" "}
          <strong>
            <em>{requestData.ciData.number}</em>
          </strong>
          , eliberat la data de{" "}
          <strong>
            <em>{requestData.ciData.issueDate}</em>
          </strong>
          , CNP{" "}
          <strong>
            <em>{requestData.ciData.cnp}</em>
          </strong>{" "}
          , domiciliat(ă) în:
          {/*judeţul{" "}*/}
          <strong>
            <em>{requestData.address.county}</em>
          </strong>{" "}
          {/*localitatea{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.city}</em>*/}
          {/*</strong>*/}
          {/*, str.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.street}</em>*/}
          {/*</strong>{" "}*/}
          {/*nr.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.number}</em>*/}
          {/*</strong>{" "}*/}
          {/*bl.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.building}</em>*/}
          {/*</strong>{" "}*/}
          {/*sc.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.entrance}</em>*/}
          {/*</strong>{" "}*/}
          {/*et.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.floor}</em>*/}
          {/*</strong>{" "}*/}
          {/*ap.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.ap}</em>*/}
          {/*</strong>*/}, telefon{" "}
          <strong>
            <em>{requestData.contact.phone}</em>
          </strong>{" "}
          e-mail{" "}
          <strong>
            <em>{requestData.contact.email}</em>
          </strong>
          , solicit întocmirea contractului de furnizare/prestare a serviciului
          de alimentare cu apă şi de canalizare pentru imobilul situat în:
          {/*judeţul{" "}*/}
          <strong>
            <em>{requestData.address.county}</em>
          </strong>{" "}
          {/*localitatea{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.place}</em>*/}
          {/*</strong>{" "}*/}
          {/*str.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.street}</em>*/}
          {/*</strong>{" "}*/}
          {/*nr.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.number}</em>*/}
          {/*</strong>{" "}*/}
          {/*bl.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.building}</em>*/}
          {/*</strong>{" "}*/}
          {/*sc.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.entrance}</em>*/}
          {/*</strong>*/}
          {/*et.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.floor}</em>*/}
          {/*</strong>{" "}*/}
          {/*ap.{" "}*/}
          {/*<strong>*/}
          {/*  <em>{requestData.address.ap}</em>*/}
          {/*</strong>{" "}*/}.
        </IonCardContent>
        <IonCardContent>
          Adresa de corespondenţă: - aceeaşi cu adresa locului de consum? Da Nu
        </IonCardContent>
        <IonCardContent>
          Client: nou - (aviz)recontractarereziliere-preluare
        </IonCardContent>
        <IonCardContent>
          Menţionez următoarele: - branşamentul deserveşte un număr de persoane
          `nr de personae` (cu baie fara baie) - canalizare Da Nu
        </IonCardContent>
        <IonCardContent>
          La finalizarea contractului doresc să fiu contactat(ă) (completaţi o
          singură opţiune) telefonic pe e-mail
        </IonCardContent>
        <IonCardContent>
          Mă angajez ca, în maximum 30 de zile de la data anunţării, să mă
          prezint în vederea semnării şi însuşirii acestuia, în caz contrar,
          intervenind acceptarea tacită a contractului, ce rezultă cu
          certitudine din acţiunea şi intenţia mea de încheiere a contractului
          de furnizare/prestare a serviciului, cuprinse în prezenta cerere.
        </IonCardContent>
        <IonCardContent>
          Declar pe propria răspundere că dețin/ocup în mod legal spațiul, că am
          dreptul să solicit și să închei contractul de furnizare a serviciului
          de alimentare cu apă și de canalizare la adresa mai sus menționată.
          Având în vedere dispozițiile art.326 din Codul penal care sancționează
          falsul în declarații, confirm că datele din cerere și documentele
          depuse în scopul contractării sunt corecte, reale și neschimbate.
        </IonCardContent>
        <IonCardContent>
          Prezenta cerere înregistrată de operator ţine loc de contract de
          furnizare a serviciilor de alimentare cu apă şi de canalizare, până la
          perfectarea acestuia în conformitate cu dispoziţiile Legii nr.
          241/2006 republicată, dispoziţiile acestui act normativ fiind
          aplicabile şi în perioada cuprinsă între înregistrarea cererii şi
          încheierea contractului.
        </IonCardContent>
        <IonCardContent>
          Menționez că am luat la cunoștință de{" "}
          <strong>Informațiile precontractuale </strong>.
        </IonCardContent>
        <div className={styles.signatureFields}>
          <IonCardContent>Data: {currentDate}</IonCardContent>
          <div>
            <IonCardContent>Semnătură</IonCardContent>
            <img
              className={styles.sigImage}
              src={trimmedDataSig}
              alt="signature"
            />
          </div>
        </div>

        <div>
          <IonCardTitle className={styles.title}>
            Informare privind prelucrarea datelor cu caracter personal
          </IonCardTitle>
          <IonCardSubtitle className={styles.title}>
            Conform legislației europene și naționale privind protecția datelor
            cu caracter personal, am fost informat(ă) că:
          </IonCardSubtitle>
          <IonCardContent>
            * operatorul Aqmeter colectează și prelucrează datele cu caracter
            personal din prezentul document în scopul încheierii raportului
            contractual de prestări servicii și că datele mele personale sunt
            securizate și păstrate până la expirarea obligațiilor contractuale
            și a termenelor legale de arhivare. * beneficiez de dreptul de
            informare, acces, de modificare, de limitare a prelucrărilor
            datelor, dreptul de a nu fi supus unei decizii individuale sau
            automatizate de prelucrare și le pot exercita prin solicitare scrisă
            datată și semnată la adresa de e-mail: office@setmobile.ro
          </IonCardContent>
          <IonCardContent>
            Datele mele cu caracter personal NU vor putea fi transferate către
            alte entități private, fără acordul meu scris.
          </IonCardContent>
        </div>
        <div className={styles.signatureFields}>
          <IonCardContent>Data: {currentDate}</IonCardContent>
          <div>
            <IonCardContent>Semnătură</IonCardContent>
            <img
              className={styles.sigImage}
              src={trimmedDataSig}
              alt="signature"
            />
          </div>
        </div>
      </IonCard>
      <div>
        <IonButton
          onClick={() => {
            handleSendData();
          }}
          className={styles.sigButton}
        >
          Trimite
        </IonButton>
      </div>
    </div>
  );
};

export default RecappPage;
