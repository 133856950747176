import React from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";

import { IonButton, IonLabel } from "@ionic/react";

import { useWindowSize } from "../../../hooks/useWindowSize";

import styles from "./RegisterPage.module.scss";

export const RegisterSuccess: React.FC = () => {
  const { t } = useTranslation();
  const history = useHistory();
  const { layout } = useWindowSize();

  return (
    <section
      className={`${styles.successWrapper} ${
        layout === "phone" ? styles.mobile : ""
      }`}
    >
      <img
        src="/assets/img/aqmeter-small.png"
        alt="Aqmeter"
        className={styles.logo}
      />

      <IonLabel data-testid="titlu" className={styles.successTitle}>
        {t("register.title")}
      </IonLabel>
      <div>
        <p data-testid="descriere">{t("register.desc")}</p>
        <p>{t("register.confirm")}</p>
      </div>

      <IonButton
        data-testid="backToLogin"
        fill="solid"
        onClick={() => history.replace("/login")}
      >
        {t("backToLogin")}
      </IonButton>

      <img src="./assets/img/register.jpg" />
    </section>
  );
};
