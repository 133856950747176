import React, { useState } from "react";
import { useHistory } from "react-router";

import { IonButton, IonText } from "@ionic/react";

import { useWindowSize } from "../../../hooks/useWindowSize";
import DeleteProfileModal from "../../../pages/5.More/User/DeleteProfileModal";

import styles from "./SecondaryMenu.module.scss";

interface SecondaryMenuItemProps {
  text: string;
  path?: string;
  active: boolean;
  type?: "item" | "delete";
  disable?: boolean;
}

const SecondaryMenuItem: React.FC<SecondaryMenuItemProps> = ({
  text,
  path,
  active,
  type,
  disable
}) => {
  const history = useHistory();
  const { layout } = useWindowSize();

  const [showDeleteAccountModal, setShowDeleteAccountModal] = useState(false);

  const clickHandler = () => {
    if (type === "delete") {
      setShowDeleteAccountModal(true);
    } else {
      history.push(path);
    }
  };

  return (
    <>
      {layout !== "phone" && type === "delete" ? (
        <hr className={styles.deleteBtn} />
      ) : (
        ""
      )}
      <IonButton
        data-testid="delete"
        disabled={disable}
        onClick={clickHandler}
        color={active ? "tertiary" : type === "delete" ? "danger" : ""}
      >
        <IonText>{text}</IonText>
      </IonButton>

      <DeleteProfileModal
        showModal={showDeleteAccountModal}
        updateShowModal={setShowDeleteAccountModal}
      />
    </>
  );
};

export default SecondaryMenuItem;
