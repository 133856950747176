import React, { useContext, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { userActions } from "../../../api/UserActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import AuthContext from "../../../context/AuthContext";
import type { ExceptionDto } from "../../../types/schema";

interface DeleteProfileModalProps {
  showModal: boolean;
  updateShowModal: (value: boolean) => void;
}

const DeleteProfileModal: React.FC<DeleteProfileModalProps> = ({
  showModal,
  updateShowModal
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { userId, updateProfileCtx, logoutCtx } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);

  const deleteProfileHandler = async () => {
    setIsLoading(true);

    if (!userId) {
      await userActions
        .getUserProfile()
        .then((response) => updateProfileCtx(response));
    } else {
      try {
        await userActions.deleteAccount(userId);
        setIsLoading(false);
        toastAdd(t("profileDeleted"), "success");
        updateShowModal(false);
        await logoutCtx();
        history.replace("/");
      } catch (err: any) {
        setIsLoading(false);
        const ex: ExceptionDto = err.response.data;
        if (ex) {
          toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
        }
      }
    }
  };

  return (
    <IonModal isOpen={showModal} onDidDismiss={() => updateShowModal(false)}>
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("confirmation")}</IonTitle>
          <IonButtons slot="end">
            <IonButton onClick={() => updateShowModal(false)}>
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonText className="confirm-deletion">
          {t("removeProfileDetails")}
        </IonText>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                fill="clear"
                color="dark"
                expand="block"
                onClick={() => updateShowModal(false)}
              >
                {t("cancel")}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="danger"
                expand="block"
                onClick={deleteProfileHandler}
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <IonSpinner name="circles" /> {t("deleting")}
                  </>
                ) : (
                  t("delete")
                )}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default DeleteProfileModal;
