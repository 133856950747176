import axios from "axios";

const BASE_URL = process.env.REACT_APP_API_URL;

const axiosBasic = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json"
  }
});

export default axiosBasic;

// asta e pt interceptors
export const axiosPrivate = axios.create({
  baseURL: BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true
});
