import React, { useContext } from "react";

import { IonCol, IonGrid, IonLabel, IonRow } from "@ionic/react";

import UserContext from "../context/UserContext";
import { useWindowSize } from "../hooks/useWindowSize";
import type { Contract } from "../types/responseTypes";

interface ContractHeaderProps {
  contract: Contract;
}

export const ContractHeader: React.FC<ContractHeaderProps> = ({ contract }) => {
  const { layout } = useWindowSize();
  const { companiesCtx } = useContext(UserContext);

  const company = companiesCtx?.filter(
    (company) => company?.id === contract?.companyId
  )[0];

  return (
    <>
      {contract && company && (
        <IonGrid className="contract-header">
          <IonRow className="ion-align-items-center">
            <IonCol size="12" className="ion-text-center">
              <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size="12" className="ion-text-center">
              <IonLabel className="ag-h1 black multiline">
                {layout === "phone" ? company?.shortName : company?.name}
              </IonLabel>
            </IonCol>
          </IonRow>
          <IonRow className="ion-align-items-center">
            <IonCol size="12" className="ion-text-center gray">
              <span>{contract?.name}</span>
              <span>{contract?.deliveryAddress}</span>
            </IonCol>
          </IonRow>
        </IonGrid>
      )}
    </>
  );
};
