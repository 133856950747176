import React, { useContext } from "react";

import AuthContext from "../../../context/AuthContext";
import type { ReadingHistoryInfo } from "../../../types/responseTypes";

import ReadingsHistoryItem from "./ReadingsHistoryItem";

import styles from "./ReadingsHistoryPage.module.scss";

interface ReadingsHistoryGroupProps {
  month: number;
  year: number;
  readings: ReadingHistoryInfo[];
}

const ReadingsHistoryGroup: React.FC<ReadingsHistoryGroupProps> = ({
  month,
  year,
  readings
}) => {
  const { langCtx } = useContext(AuthContext);

  const dateGroup = new Date(year, month).toLocaleDateString(langCtx, {
    year: "numeric",
    month: "long"
  });

  return (
    <div className={styles.group}>
      <p className={styles.groupTitle}>{dateGroup}</p>
      {readings.map((reading) => (
        <ReadingsHistoryItem
          key={`${reading?.contractNumber} ${reading?.meterSerial} ${Math.random()}`}
          reading={reading}
        />
      ))}
    </div>
  );
};

export default ReadingsHistoryGroup;
