import React, { useContext, useEffect, useState } from "react";
import {
  chevronForward,
  documentTextOutline,
  helpCircle,
  logOutOutline,
  mail,
  notifications,
  personCircle
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  IonBadge,
  IonButtons,
  IonHeader,
  IonIcon,
  IonItem,
  IonItemDivider,
  IonLabel,
  IonList,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../components/AqNotificationsBell";
import { toastAdd } from "../../components/CustomToast/CustomToasts";
import SidebarLayout from "../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../components/UI/BackBtn";
import AuthContext from "../../context/AuthContext";
import useBubbleTabsInfoUpdate from "../../hooks/useBubbleTabsInfoUpdate";
import useLogout from "../../hooks/useLogout";
import { useWindowSize } from "../../hooks/useWindowSize";
import type { LogoutData } from "../../types/requestTypes";
import type { ExceptionDto } from "../../types/schema";

import styles from "./MorePage.module.scss";

const MorePage: React.FC = () => {
  const { t } = useTranslation();
  const logout = useLogout();
  const history = useHistory();
  const { layout } = useWindowSize();
  const { nameCtx, mailCtx } = useContext(AuthContext);

  const [fcmToken, setFcmToken] = useState<string>("");
  const [, , , totalUnreadNotifs, numberOfDemands] = useBubbleTabsInfoUpdate();

  // useEffect(() => {
  //   if (menuInfo) {
  //     updateMenuCtx(menuInfo);
  //   }
  //   if (contracts) {
  //     updateContractsCtx(contracts);
  //   }
  //   if (demands){
  //     updateNumberOfDemands(demands.length);
  //   }
  // }, [menuInfo, contracts, demands]);

  useEffect(() => {
    const storedFcm = localStorage.getItem("fcm");
    if (storedFcm) {
      setFcmToken(storedFcm);
    }
  }, []);

  const onClickLogout = async () => {
    const data: LogoutData = {
      userEmail: mailCtx,
      fcmToken: fcmToken
    };
    try {
      await logout(data);
      history.replace("/login");
    } catch (err: any) {
      const ex: ExceptionDto = err.response.data;
      toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
    }
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        className="others-page"
        layoutType={"back"}
        url={"/dashboard"}
      >
        {/* <AqCodulQR />*/}
        <IonList className="others-menu" lines="none">
          {layout === "phone" ? (
            <>
              <IonItem
                key="demands"
                className="cursor-hover next-is-divider"
                routerLink="/demands/list"
                detail={false}
              >
                <div className="others-icon-bg demands-btn">
                  <IonIcon icon={documentTextOutline} />
                </div>
                <IonLabel>
                  <span className="ag-cta black">Cereri</span>
                </IonLabel>
                {numberOfDemands > 0 ? (
                  <IonBadge className={styles.badge} color={"danger"}>
                    {numberOfDemands}
                  </IonBadge>
                ) : (
                  ""
                )}
                <IonIcon className="chevron" icon={chevronForward} />
              </IonItem>
              <IonItemDivider mode={"md"}></IonItemDivider>
            </>
          ) : null}

          <IonItem
            key="profile"
            className="cursor-hover"
            routerLink="/user/profile"
            detail={false}
          >
            <div className="others-icon-bg">
              <IonIcon icon={personCircle} />
            </div>
            <IonLabel>
              <span className="ag-cta black">
                {nameCtx ? nameCtx : "Profil"}
              </span>
              <br />
              <span className="ag-number-description black">
                {mailCtx ? mailCtx : ""}
              </span>
            </IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          <IonItem
            key="notificari"
            className="cursor-hover"
            routerLink="/notifications"
            detail={false}
          >
            <div className="others-icon-bg">
              <IonIcon icon={notifications} />
            </div>
            <IonLabel className="ag-cta black">
              {t("notification")}{" "}
              {totalUnreadNotifs ? <span>({totalUnreadNotifs})</span> : ""}
            </IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          <IonItem
            key="contact"
            className="cursor-hover"
            routerLink="/contact"
            detail={false}
          >
            <div className="others-icon-bg">
              <IonIcon icon={mail} />
            </div>
            <IonLabel className="ag-cta black">{t("contact")}</IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          <IonItem
            key="help"
            className="next-is-divider"
            routerLink="/help"
            detail={false}
          >
            <div className="others-icon-bg">
              <IonIcon icon={helpCircle} />
            </div>
            <IonLabel className="ag-cta black">{t("help")}</IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>

          <IonItemDivider mode={"md"}></IonItemDivider>

          {/* <IonItem key='bonusare' className='cursor-hover' onClick={() => history.push("/bonus")}>
           <IonAvatar slot='start'>
           <img src='./assets/aqmeter/components/bonusare.svg' />
           </IonAvatar>
           <IonLabel className='ag-cta black'>{t("bonus")}</IonLabel>
           <IonIcon className="chevron" icon={chevronForward} />
           </IonItem> */}

          {/* <IonItem key='scanare-cod' className='cursor-hover' onClick={() => history.push("/scan")}>
           <IonAvatar slot='start'>
           <img src='./assets/aqmeter/components/scanare-cod.svg' />
           </IonAvatar>
           <IonLabel className='ag-cta black'>{t("qrScan")}</IonLabel>
           <IonIcon className="chevron" icon={chevronForward}/>
           </IonItem> */}

          <IonItem
            key="logout"
            className="cursor-hover"
            routerLink="#"
            detail={false}
            onClick={() => {
              onClickLogout();
              sessionStorage.removeItem("step");
              sessionStorage.removeItem("requestData");
              sessionStorage.removeItem("specifications");
              sessionStorage.removeItem("CI_DATA");
              sessionStorage.removeItem("signatureFileName");
              sessionStorage.removeItem("ciFileName");
              sessionStorage.removeItem("selectedCompanyFromLocationObj");
            }}
          >
            <div className="others-icon-bg logout-btn">
              <IonIcon icon={logOutOutline} />
            </div>
            <IonLabel className="ag-cta black">{t("logout")}</IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>
        </IonList>
      </SidebarLayout>
    </IonPage>
  );
};

export default MorePage;
