import React, { useContext, useState } from "react";
import { closeOutline } from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import {
  IonButton,
  IonButtons,
  IonCol,
  IonContent,
  IonGrid,
  IonHeader,
  IonIcon,
  IonModal,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToolbar
} from "@ionic/react";

import { contractActions } from "../../../api/ContractActions";
import { updateContextActions } from "../../../api/UpdateContextActions";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import UserContext from "../../../context/UserContext";
import type { ExceptionDto } from "../../../types/schema";

interface EditContractDeleteModalProps {
  contractId: number;
  showModal: boolean;
  updateShowModal: (value: boolean) => void;
  deleteDemandsHandler?: () => void;
  type: "demands" | "contracts";
}

const EditContractDeleteModal: React.FC<EditContractDeleteModalProps> = ({
  contractId,
  showModal,
  updateShowModal,
  deleteDemandsHandler,
  type
}) => {
  const { t } = useTranslation();
  const history = useHistory();
  const { updateMenuCtx } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState(false);

  const deleteContractHandler = async () => {
    setIsLoading(true);
    try {
      await contractActions.remove(contractId);
      setIsLoading(false);
      updateContextActions.getMenuDetails().then((data) => updateMenuCtx(data));
      toastAdd(t("contract.deleted"), "success");
      updateShowModal(false);
      history.replace("/contracts");
    } catch (err: any) {
      setIsLoading(false);
      const ex: ExceptionDto = err.response.data;
      toastAdd(t([`err.${ex.message}`, "err.__"]), "error");
    }
  };

  return (
    <IonModal
      isOpen={showModal}
      onDidDismiss={() => updateShowModal(false)}
      backdropDismiss={false}
    >
      <IonHeader>
        <IonToolbar>
          <IonTitle>{t("confirmation")}</IonTitle>
          <IonButtons slot="end">
            <IonButton
              onClick={(event) => {
                event.stopPropagation();
                updateShowModal(false);
              }}
            >
              <IonIcon slot="icon-only" icon={closeOutline} />
            </IonButton>
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <IonContent>
        <IonText className="confirm-deletion">
          {type === "demands"
            ? "Sigur dorești să ștergi cererea din contul tău?"
            : type === "contracts"
              ? t("contract.associationRemove")
              : null}
        </IonText>

        <IonGrid>
          <IonRow>
            <IonCol>
              <IonButton
                fill="clear"
                color="dark"
                expand="block"
                onClick={(event) => {
                  event.stopPropagation();
                  updateShowModal(false);
                }}
              >
                {t("cancel")}
              </IonButton>
            </IonCol>
            <IonCol>
              <IonButton
                color="danger"
                expand="block"
                onClick={
                  type === "demands"
                    ? (event) => {
                        event.stopPropagation();
                        updateShowModal(false);
                        setTimeout(() => {
                          deleteDemandsHandler();
                        }, 1);
                      }
                    : type === "contracts"
                      ? deleteContractHandler
                      : null
                }
                disabled={isLoading}
              >
                {isLoading ? (
                  <>
                    <IonSpinner name="circles" /> {t("deleting")}
                  </>
                ) : (
                  t("delete")
                )}
              </IonButton>
            </IonCol>
          </IonRow>
        </IonGrid>
      </IonContent>
    </IonModal>
  );
};

export default EditContractDeleteModal;
