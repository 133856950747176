import { Capacitor } from "@capacitor/core";

import type {
  LastFrontierDataObj,
  SignatureUploadedFile
} from "../types/contractDemandsTypes";
import type { ChangePassword, RegisterData } from "../types/requestTypes";
import type {
  EmailAvailability,
  LoginResponse,
  Profile,
  Settings
} from "../types/responseTypes";

import axiosBasic, { axiosPrivate } from "./customAxios";
import { doMobileGet } from "./customHttp";

class UserActions {
  async checkEmailAvailability(email: string): Promise<EmailAvailability> {
    try {
      const response = await axiosPrivate.get(
        `/users/available?email=${email}`
      );
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async registerAccount(data: RegisterData): Promise<void> {
    try {
      const response = await axiosPrivate.post("/users", data);
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async confirmAccount(confirmationToken: string): Promise<LoginResponse> {
    const isMobile = Capacitor.isNativePlatform();
    let response: LoginResponse;
    try {
      if (isMobile) {
        const mobileResponse = await doMobileGet(
          `/users/confirmation?token=${confirmationToken}`
        );
        response = mobileResponse?.data;
      } else {
        const webResponse = await axiosBasic.get(
          `/users/confirmation?token=${confirmationToken}`,
          {
            withCredentials: true
          }
        );
        response = webResponse?.data;
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async resendEmailConfirmation(url: string): Promise<boolean> {
    try {
      const response = await axiosPrivate.get(url);
      return response.status === 200;
    } catch (error) {
      console.log(error);
    }
  }

  async confirmNewPassword(confirmationToken: string): Promise<LoginResponse> {
    const isMobile = Capacitor.isNativePlatform();
    let response: LoginResponse;
    try {
      if (isMobile) {
        const mobileResponse = await doMobileGet(
          `/users/reset-password/confirmation?token=${confirmationToken}`
        );
        response = mobileResponse?.data;
      } else {
        const webResponse = await axiosBasic.get(
          `/users/reset-password/confirmation?token=${confirmationToken}`,
          {
            withCredentials: true
          }
        );
        response = webResponse?.data;
      }
      return response;
    } catch (error) {
      console.log(error);
    }
  }

  async getUserProfile(): Promise<Profile> {
    try {
      const response = await axiosPrivate.get("/users/profile");
      return response.data;
    } catch (error) {
      console.log(error);
    }
  }

  async updateUserProfile(data: FormData): Promise<void> {
    try {
      await axiosPrivate.post("/users/profile", data);
    } catch (error) {
      console.log({ error });
      throw new Error(error);
    }
  }

  async changeUserPass(data: ChangePassword): Promise<void> {
    try {
      await axiosPrivate.post("/users/reset-password", data);
    } catch (error) {
      console.log(error);
    }
  }

  async changeUserSettings(data: Settings): Promise<void> {
    try {
      await axiosPrivate.patch("/users/settings", data);
    } catch (error) {
      console.log(error);
    }
  }

  async deleteAccount(userId: number): Promise<boolean> {
    try {
      const response = await axiosPrivate.delete(`/users/${userId}`);
      return response.status === 200;
    } catch (error) {
      console.log(error);
    }
  }

  async uploadCiFile(formData: FormData): Promise<boolean> {
    const response = await axiosPrivate.post(
      `/contract-demands/ci-upload`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
    return response.data;
  }

  async downloadDocuments(fileId: number) {
    return await axiosPrivate.get(`/contract-demands/file/${fileId}`, {
      responseType: "blob"
    });
  }

  async uploadSignatureFile(
    formData: FormData
  ): Promise<SignatureUploadedFile> {
    const response = await axiosPrivate.post(
      `/contract-demands/signature-upload`,
      formData,
      {
        headers: { "Content-Type": "multipart/form-data" }
      }
    );
    return response.data;
  }

  async lastFrontierPost(
    dataObj: LastFrontierDataObj
  ): Promise<SignatureUploadedFile> {
    const response = await axiosPrivate.post(`/contract-demands`, dataObj, {});
    return response.data;
  }
}

export const userActions = new UserActions();
