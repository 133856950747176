import { useContext } from "react";

import { Capacitor } from "@capacitor/core";

import axiosBasic from "../api/customAxios";
import { doMobilePost } from "../api/customHttp";
import AuthContext from "../context/AuthContext";
import type { RefreshData } from "../types/requestTypes";
import type { LoginResponse } from "../types/responseTypes";

const useRefreshToken = () => {
  const { updateAuthCtx } = useContext(AuthContext);
  const isMobile = Capacitor.isNativePlatform();

  return async (data: RefreshData): Promise<string> => {
    try {
      let response: LoginResponse;
      if (isMobile) {
        const mobileRefreshResponse = await doMobilePost("/auth/refresh", data);
        response = mobileRefreshResponse?.data;
      } else {
        const webRefreshResponse = await axiosBasic.post(
          "/auth/refresh",
          data,
          { withCredentials: true }
        );
        response = webRefreshResponse?.data;
      }

      const accessToken = response?.accessToken;

      updateAuthCtx(response);
      return accessToken;
    } catch (err) {
      throw new Error(err);
    }
  };
};

export default useRefreshToken;
