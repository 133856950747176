import React from "react";
import { useTranslation } from "react-i18next";

import type { Contract } from "../../../types/responseTypes";

import styles from "./InvoicesPage.module.scss";

interface InvoicesLegendProps {
  filteredContracts: Contract[];
}

const countUserPaymentsInProcessing = (userContracts) => {
  let counter = 0;
  if (!userContracts?.length) return 0;
  for (const contract of userContracts) {
    if (contract?.customPaymentInProcessingAmount > 0) {
      counter++;
    }
    if (contract?.hasInvoicePaymentProcessing) {
      counter++;
    }
  }
  return counter;
};

const countUserPaymentsInitialized = (userContracts) => {
  let counter = 0;
  if (!userContracts?.length) return 0;
  for (const contract of userContracts) {
    if (contract?.hasCustomPaymentInitialized) {
      counter++;
    }
    if (contract?.hasInvoicePaymentInitialized) {
      counter++;
    }
  }
  return counter;
};

const InvoicesLegend: React.FC<InvoicesLegendProps> = ({
  filteredContracts
}) => {
  const { t } = useTranslation();

  const paymentsProcessing = countUserPaymentsInProcessing(filteredContracts);
  const paymentsInitialized = countUserPaymentsInitialized(filteredContracts);

  return (
    <>
      {filteredContracts &&
        (paymentsProcessing > 0 || paymentsInitialized > 0) && (
          <div className={styles.paymentLegendBox}>
            <p className={styles.legendTitle}>{t("invoice.legend.title")}</p>
            {paymentsProcessing > 0 && (
              <div>
                <span className={`${styles.flagItem} ${styles.processing}`}>
                  {t("paymentProcessing")}
                </span>
                <span className={styles.legendText}>
                  {t("invoice.legend.processing")}
                </span>
              </div>
            )}
            {paymentsInitialized > 0 && (
              <div>
                <span className={`${styles.flagItem} ${styles.initialized}`}>
                  {t("hasOnePaymentInitialized")}
                </span>
                <span className={styles.legendText}>
                  {t("invoice.legend.payInit")}
                </span>
              </div>
            )}
          </div>
        )}
    </>
  );
};

export default InvoicesLegend;
