import React from "react";
import { useTranslation } from "react-i18next";
import { Keyboard, Navigation } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";

import { useWindowSize } from "../../../hooks/useWindowSize";
import type {
  SecondaryMenuItemsType,
  SecondaryMenuType
} from "../../../types/otherTypes";

import SecondaryMenuItem from "./SecondaryMenuItem";

import "./SecondaryMenuMobileSwiper.scss";
import styles from "./SecondaryMenu.module.scss";

interface SecondaryMenuProps {
  type: SecondaryMenuType;
  selected: SecondaryMenuItemsType;
}

const SecondaryMenu: React.FC<SecondaryMenuProps> = ({ type, selected }) => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();

  const arrayWhichDisablesDemandsMenu = () => {
    let menuArray = [1, 0, 0, 0];

    if (selected === "item1") {
      menuArray = [...menuArray];
    } else if (selected === "item2") {
      menuArray = [1, 1, 0, 0];
    } else if (selected === "item3") {
      menuArray = [1, 1, 1, 0];
    } else if (selected === "item4") {
      menuArray = [1, 1, 1, 1];
    }
    return menuArray;
  };

  const getSelectedSlideNr = () => {
    switch (selected) {
      case "item1":
        return 0;
      case "item2":
        return 1;
      case "item3":
        return 2;
      case "item4":
        return 3;
      case "delete":
        return 4;
      default:
        return 0;
    }
  };

  const initialSlideNr = getSelectedSlideNr();

  return (
    <div
      className={`${styles.menu} ${layout === "phone" ? styles.mobile : ""}`}
    >
      {type === "invoices" ? (
        <>
          <SecondaryMenuItem
            text={t("submenu.invoices.notPaid")}
            path={"/invoices"}
            active={selected === "item1"}
          />
          <SecondaryMenuItem
            text={t("submenu.invoices.paid")}
            path={"/invoices/paid"}
            active={selected === "item2"}
          />
        </>
      ) : type === "readings" ? (
        <>
          <SecondaryMenuItem
            text={t("submenu.readings.add")}
            path={"/readings"}
            active={selected === "item1"}
          />
          <SecondaryMenuItem
            text={t("submenu.readings.history")}
            path={"/readings/history"}
            active={selected === "item2"}
          />
        </>
      ) : type === "demands" ? (
        layout === "phone" ? (
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={20}
            centeredSlides={true}
            keyboard={{
              enabled: true
            }}
            initialSlide={initialSlideNr}
            navigation={true}
            modules={[Keyboard, Navigation]}
            className="mobileSwiper"
          >
            <SwiperSlide>
              <SecondaryMenuItem
                text={t("submenu.demands.list")}
                path={"/demands/list"}
                active={selected === "item1"}
              />
            </SwiperSlide>
            <SwiperSlide>
              <SecondaryMenuItem
                text={t("submenu.demands.pickCompany")}
                path={"/demands/company"}
                active={selected === "item2"}
                disable={!arrayWhichDisablesDemandsMenu()[1]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <SecondaryMenuItem
                text={t("submenu.demands.dataGather")}
                path={"/demands/create"}
                active={selected === "item3"}
                disable={!arrayWhichDisablesDemandsMenu()[2]}
              />
            </SwiperSlide>
            <SwiperSlide>
              <SecondaryMenuItem
                text={t("submenu.demands.sign")}
                path={"/demands/signature"}
                active={selected === "item4"}
                disable={!arrayWhichDisablesDemandsMenu()[3]}
              />
            </SwiperSlide>
          </Swiper>
        ) : (
          <>
            <SecondaryMenuItem
              text={t("submenu.demands.list")}
              path={"/demands/list"}
              // disable={true}
              active={selected === "item1"}
            />
            <SecondaryMenuItem
              text={t("submenu.demands.pickCompany")}
              path={"/demands/company"}
              active={selected === "item2"}
              disable={!arrayWhichDisablesDemandsMenu()[1]}
            />
            <SecondaryMenuItem
              text={t("submenu.demands.dataGather")}
              path={"/demands/create"}
              active={selected === "item3"}
              disable={!arrayWhichDisablesDemandsMenu()[2]}
            />
            <SecondaryMenuItem
              text={t("submenu.demands.sign")}
              path={"/demands/signature"}
              // disable={true}
              active={selected === "item4"}
              disable={!arrayWhichDisablesDemandsMenu()[3]}
            />
          </>
        )
      ) : layout === "phone" ? (
        <Swiper
          slidesPerView={"auto"}
          spaceBetween={20}
          centeredSlides={true}
          keyboard={{
            enabled: true
          }}
          initialSlide={initialSlideNr}
          navigation={true}
          modules={[Keyboard, Navigation]}
          className="mobileSwiper"
        >
          <SwiperSlide>
            <SecondaryMenuItem
              text={t("profile")}
              path={"/user/profile"}
              active={selected === "item1"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SecondaryMenuItem
              text={t("profile.changePass")}
              path={"/user/password"}
              active={selected === "item2"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SecondaryMenuItem
              text={t("settings")}
              path={"/user/settings"}
              active={selected === "item3"}
            />
          </SwiperSlide>
          <SwiperSlide>
            <SecondaryMenuItem
              text={t("removeProfile")}
              active={selected === "delete"}
              type={"delete"}
            />
          </SwiperSlide>
        </Swiper>
      ) : (
        <>
          <SecondaryMenuItem
            text={t("profile")}
            path={"/user/profile"}
            active={selected === "item1"}
          />
          <SecondaryMenuItem
            text={t("profile.changePass")}
            path={"/user/password"}
            active={selected === "item2"}
          />
          <SecondaryMenuItem
            text={t("settings")}
            path={"/user/settings"}
            active={selected === "item3"}
          />

          <SecondaryMenuItem
            text={t("removeProfile")}
            active={selected === "delete"}
            type={"delete"}
          />
        </>
      )}
    </div>
  );
};

export default SecondaryMenu;
