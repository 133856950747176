import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { notificationActions } from "../../../../api/NotificationActions";
import { updateContextActions } from "../../../../api/UpdateContextActions";
import { AqNotificationsBell } from "../../../../components/AqNotificationsBell";
import { toastAdd } from "../../../../components/CustomToast/CustomToasts";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../../components/UI/BackBtn";
import UserContext from "../../../../context/UserContext";
import useFetchCaching from "../../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import type {
  NotificationsPagination,
  NotificationType
} from "../../../../types/responseTypes";
import ConfirmActionModal from "../ConfirmActionModal";

import NotificationsActions from "./NotificationsActions";
import NotificationsList from "./NotificationsList";

import styles from "../Notifications.module.scss";

export const NotificationsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { updateMenuCtx } = useContext(UserContext);

  const [notificationsPagination, setNotificationsPagination] =
    useState<NotificationsPagination>();
  const [typeFilter, setTypeFilter] = useState<NotificationType>();
  const [checkedItems, setCheckedItems] = useState<number[]>(null);
  const [selectAll, setSelectAll] = useState<boolean>(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showMarkAsReadModal, setShowMarkAsReadModal] = useState(false);
  const [successfulAction, setSuccessfulAction] = useState<number>(0);

  const { data, error } =
    useFetchCaching<NotificationsPagination>("/notifications");

  useEffect(() => {
    if (data) {
      setNotificationsPagination(data);
    }
  }, [data]);

  const filterNotifications = (notifications, type) => {
    if (!notifications?.length) return [];
    if (!type) {
      return notifications;
    }
    return notifications.filter((notification) => notification.type === type);
  };

  const checkboxesHandler = (notificationId) => {
    if (selectAll) {
      setSelectAll(false);
    }
    setCheckedItems((prevState) => {
      if (!prevState) {
        return [notificationId];
      } else if (prevState.includes(notificationId)) {
        return prevState.filter((id) => id !== notificationId);
      } else {
        return [...prevState, notificationId];
      }
    });
  };

  const filteredNotifications = filterNotifications(
    notificationsPagination?.content,
    typeFilter
  );

  const selectAllHandler = () => {
    if (!selectAll) {
      setSelectAll(true);
      setCheckedItems(
        filteredNotifications.map((notification) => notification.id)
      );
    } else {
      setSelectAll(false);
      setCheckedItems(null);
    }
  };

  const deleteModalHandler = () => {
    if (!checkedItems?.length) {
      toastAdd(t("notif.noSelection"), "error");
    } else {
      setShowDeleteModal(true);
    }
  };

  const markAsReadModalHandler = () => {
    if (!checkedItems?.length) {
      toastAdd(t("notif.noSelection"), "error");
    } else {
      setShowMarkAsReadModal(true);
    }
  };

  useEffect(() => {
    if (successfulAction) {
      notificationActions
        .getAllNotifications()
        .then((data) => setNotificationsPagination(data));
      updateContextActions.getMenuDetails().then((data) => updateMenuCtx(data));
      setCheckedItems(null);
    }
  }, [successfulAction]);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}

      <SidebarLayout
        className="notifications-page"
        layoutType={"back"}
        url={"/more"}
      >
        <IonLabel className={styles.pageTitle}>{t("notification")}</IonLabel>
        <SkeletonList loading={!notificationsPagination} error={error} />

        {!error && notificationsPagination ? (
          <>
            {notificationsPagination?.content.length ? (
              <NotificationsActions
                notifications={notificationsPagination?.content}
                typeFilter={typeFilter}
                setTypeFilter={setTypeFilter}
                checkedItems={checkedItems}
                selectAll={selectAll}
                selectAllHandler={selectAllHandler}
                markAsReadModalHandler={markAsReadModalHandler}
                deleteModalHandler={deleteModalHandler}
              />
            ) : (
              ""
            )}
            <NotificationsList
              filteredNotifications={filteredNotifications}
              selectAll={selectAll}
              checkedItems={checkedItems}
              checkboxesHandler={checkboxesHandler}
            />
          </>
        ) : (
          ""
        )}
      </SidebarLayout>

      <ConfirmActionModal
        view="list"
        action="delete"
        notificationIds={checkedItems}
        showModal={showDeleteModal}
        updateShowModal={setShowDeleteModal}
        setSuccessfulAction={setSuccessfulAction}
      />

      <ConfirmActionModal
        view="list"
        action="read"
        notificationIds={checkedItems}
        showModal={showMarkAsReadModal}
        updateShowModal={setShowMarkAsReadModal}
        setSuccessfulAction={setSuccessfulAction}
      />
    </IonPage>
  );
};
