import React from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonPage,
  IonToolbar,
  isPlatform
} from "@ionic/react";

import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from "../../components/UI/BackBtn";
import { useWindowSize } from "../../hooks/useWindowSize";
import i18n from "../../i18n";
import ImageCardLayout from "../../layout/ImageCardLayout/ImageCardLayout";
import type { Language } from "../../types/otherTypes";

import styles from "./UpdatePage.module.scss";

export const UpdatePage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const lang = i18n.resolvedLanguage as Language;

  return (
    <IonPage className={`layout--${layout}`}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <BackBtn />
          </IonButtons>
          <IonButtons slot={"end"}>
            <LanguageSwitcher />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ImageCardLayout
        title={t("update.title")}
        imgUrl={"./assets/img/update.jpg"}
        logo={false}
      >
        <p>{t("update.desc")}</p>
        <div className={styles.updateAppLinks}>
          {isPlatform("ios") ? (
            <a
              href="https://itunes.apple.com/us/app/aqmeter/id1332042499"
              target="_blank"
              rel="noopener noreferrer"
            >
              {lang === "en" ? (
                <img
                  src="/assets/img/apple-store-en.png"
                  alt="Apple App Store"
                />
              ) : (
                <img
                  src="/assets/img/apple-store-ro.png"
                  alt="Apple App Store"
                />
              )}
            </a>
          ) : (
            <>
              <a
                href="https://play.google.com/store/apps/details?id=ro.setmobile.aqmeter"
                target="_blank"
                rel="noopener noreferrer"
              >
                {lang === "en" ? (
                  <img
                    src="/assets/img/google-store-en.png"
                    alt="Google Play Store"
                  />
                ) : (
                  <img
                    src="/assets/img/google-store-ro.png"
                    alt="Google Play Store"
                  />
                )}
              </a>
              <a
                href="https://appgallery.huawei.com/app/C104127187"
                target="_blank"
                rel="noopener noreferrer"
              >
                {lang === "en" ? (
                  <img
                    src="/assets/img/huawei-store-en.png"
                    alt="Huawei App Gallery"
                  />
                ) : (
                  <img
                    src="/assets/img/huawei-store-ro.png"
                    alt="Huawei App Gallery"
                  />
                )}
              </a>
            </>
          )}
        </div>
      </ImageCardLayout>
    </IonPage>
  );
};
