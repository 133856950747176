import React, { useState } from "react";
import { useLocation } from "react-router";

import { IonButtons, IonHeader, IonPage, IonToolbar } from "@ionic/react";

import { AqNotificationsBell } from "../../../../components/AqNotificationsBell";
import SidebarLayout from "../../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../../components/UI/BackBtn";
import FormStepsContractare from "../../../../components/UI/Forms/FormStepsContractare";
import { useWindowSize } from "../../../../hooks/useWindowSize";

import RecapPage from "./PagesSignature/RecapPage";
import Signature from "./PagesSignature/SignatureCanvasPage";

import styles from "./SignaturePage.module.scss";

const SignaturePage: React.FC = () => {
  const { layout } = useWindowSize();
  const locationObj = useLocation()?.state;

  const [step, setStep] = useState<number>(1);
  const [trimmedDataSig, setTrimmedDataSig] = useState(null);
  const [selectedRequestDataFromLocationObj] = useState(locationObj);

  // Do something on step change

  const renderStep = (step) => {
    if (step === 1)
      return (
        <Signature
          setStep={setStep}
          step={step}
          trimmedDataSig={trimmedDataSig}
          setTrimmedDataSig={setTrimmedDataSig}
        />
      );
    if (step === 2)
      return (
        <RecapPage
          trimmedDataSig={trimmedDataSig}
          requestData={selectedRequestDataFromLocationObj}
        />
      );
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"demands"}
        menuSelected={"item4"}
      >
        {step >= 1 && step <= 2 ? (
          <IonButtons slot="end" className={styles.navButtons}>
            <FormStepsContractare
              formStepsType={"signature"}
              steps={2}
              currentStep={step}
              setCurrentStep={setStep}
            />
          </IonButtons>
        ) : (
          ""
        )}

        {renderStep(step)}
      </SidebarLayout>
    </IonPage>
  );
};

export default SignaturePage;
