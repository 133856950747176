import { useContext } from "react";

import { Capacitor } from "@capacitor/core";

import axiosBasic from "../api/customAxios";
import { doMobilePost } from "../api/customHttp";
import AuthContext from "../context/AuthContext";
import type { LogoutData } from "../types/requestTypes";

const useLogout = () => {
  const { logoutCtx } = useContext(AuthContext);
  const isMobile = Capacitor.isNativePlatform();

  return async (data: LogoutData): Promise<void> => {
    if (isMobile) {
      await doMobilePost("/auth/logout", data);
    } else {
      await axiosBasic.post("/auth/logout", data, { withCredentials: true });
    }
    logoutCtx();
    // folosite la login cu social media
    localStorage.removeItem("isMobile");
    localStorage.removeItem("provider");
  };
};

export default useLogout;
