import { Capacitor } from "@capacitor/core";

import axiosBasic from "../api/customAxios";
import { doMobilePost } from "../api/customHttp";
import type { SSOLogin } from "../types/requestTypes";
import type { LoginResponse } from "../types/responseTypes";

export const useSSOLogin = () => {
  const isMobile = Capacitor.isNativePlatform();

  return async (data: SSOLogin): Promise<LoginResponse> => {
    let response: LoginResponse;
    if (isMobile) {
      const mobileResponse = await doMobilePost("/auth/login/sso", data);
      response = mobileResponse?.data;
    } else {
      const webResponse = await axiosBasic.post("/auth/login/sso", data, {
        withCredentials: true
      });
      response = webResponse?.data;
    }
    return response;
  };
};
