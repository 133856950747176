import React, { useMemo } from "react";
import { mailOpenOutline, trashSharp } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonCheckbox,
  IonIcon,
  IonItem,
  IonSelect,
  IonSelectOption
} from "@ionic/react";

import type {
  Notification,
  NotificationType
} from "../../../../types/responseTypes";

import styles from "./NotificationsActions.module.scss";

interface NotificationsFilterProps {
  notifications: Notification[];
  typeFilter: NotificationType;
  checkedItems: number[];
  selectAll: boolean;
  setTypeFilter: (value: NotificationType) => void;
  selectAllHandler: () => void;
  markAsReadModalHandler: () => void;
  deleteModalHandler: () => void;
}

const NotificationsActions: React.FC<NotificationsFilterProps> = ({
  notifications,
  typeFilter,
  checkedItems,
  selectAll,
  setTypeFilter,
  selectAllHandler,
  markAsReadModalHandler,
  deleteModalHandler
}) => {
  const { t } = useTranslation();

  const types = useMemo(
    () =>
      notifications?.reduce((accum, elem) => {
        if (!accum.includes(elem.type)) {
          accum.push(elem.type);
        }
        return accum;
      }, []),
    [notifications]
  );

  const filterHandler = (event) => {
    const selectedOption = event.target.value;
    if (selectedOption === "0") {
      setTypeFilter(null);
    } else {
      setTypeFilter(selectedOption);
    }
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.actions}>
        <div
          className={`${styles.selectAll} ${
            checkedItems?.length ? styles.active : ""
          }`}
        >
          <IonCheckbox
            mode="md"
            color="medium"
            checked={selectAll}
            onClick={selectAllHandler}
          />
        </div>

        <div
          data-testid="markAsRead"
          className={`${styles.markRead} ${
            checkedItems?.length ? styles.active : ""
          }`}
          onClick={markAsReadModalHandler}
        >
          <IonIcon icon={mailOpenOutline} />
        </div>

        <div
          data-testid="delete"
          className={`${styles.delete} ${
            checkedItems?.length ? styles.active : ""
          }`}
          onClick={deleteModalHandler}
        >
          <IonIcon icon={trashSharp} />
        </div>
      </div>

      {types?.length ? (
        <IonItem className={styles.filterSelector} lines="none">
          <IonSelect
            placeholder={t("notif.filter")}
            value={typeFilter}
            onIonChange={filterHandler}
            disabled={notifications?.length === 1}
            interface="popover"
            mode="md"
          >
            <IonSelectOption key={0} value={0}>
              {t("all")}
            </IonSelectOption>
            {types.map((type, idx) => (
              <IonSelectOption key={idx} value={type}>
                {t(`server.notification.${type}`)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
      ) : (
        ""
      )}
    </div>
  );
};

export default NotificationsActions;
