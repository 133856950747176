import React, { useContext } from "react";
import { useTranslation } from "react-i18next";

import { IonCol, IonGrid, IonItem, IonRow } from "@ionic/react";

import AuthContext from "../../../context/AuthContext";
import type { InvoiceService } from "../../../types/responseTypes";

interface InvoiceDetailsServicesListItemProps {
  service: InvoiceService;
}

const InvoiceDetailsServicesListItem: React.FC<
  InvoiceDetailsServicesListItemProps
> = ({ service }) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  return (
    <IonItem>
      <IonGrid>
        <IonRow>
          <IonCol className="invoice-information">
            <span className="ag-number-description">
              <span className="gray">{t("invoice.moreInfo.name")}</span>
              <span className="black capitalize">
                {service?.serviceName?.toLowerCase()}
              </span>
            </span>
            <span className="ag-number-description">
              <span className="gray">{t("invoice.moreInfo.desc")}</span>
              <span className="black capitalize">
                {service?.description?.toLowerCase()}
              </span>
            </span>
            <span className="ag-number-description">
              <span className="gray">{t("invoice.moreInfo.unitPrice")}</span>
              <span className="black lowercase">
                {service?.price} {t("currency")} /{" "}
                {(service?.um === "MC" && (
                  <span className="black lowercase">
                    m<sup>3</sup>
                  </span>
                )) ||
                  service?.um}
              </span>
            </span>
            <span className="ag-number-description">
              <span className="gray">{t("invoice.moreInfo.consumption")}</span>
              <span className="black lowercase">
                {service?.consumption}{" "}
                {(service?.um === "MC" && (
                  <span className="black lowercase">
                    m<sup>3</sup>
                  </span>
                )) ||
                  service?.um}
              </span>
            </span>
          </IonCol>
        </IonRow>
        <IonRow>
          <IonCol className="invoice-subtotals ag-number-description">
            <span>
              {t("invoice.moreInfo.netPrice")}
              {service?.itemAmount.toLocaleString(langCtx)} {t("currency")}
            </span>
            <span>
              {t("invoice.moreInfo.vat")}
              {service?.vat.toLocaleString(langCtx)} {t("currency")}
            </span>
            <span>
              {t("invoice.moreInfo.subtotal")}
              {parseFloat(
                (service?.itemAmount + service?.vat).toFixed(2)
              ).toLocaleString(langCtx)}{" "}
              {t("currency")}
            </span>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonItem>
  );
};

export default InvoiceDetailsServicesListItem;
