import { useEffect, useState } from "react";

// Define general type for useWindowSize hook, which includes width and height
interface Size {
  width: number | undefined;
  height: number | undefined;
  layout: "phone" | "tablet" | "desktop";
}

// Hook
export const useWindowSize = (): Size => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
    layout: "phone"
  });
  useEffect(() => {
    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      const aspect = window.innerWidth / window.innerHeight;
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
        /* tablet resolution [768..1366] */
        layout:
          window.innerWidth < 768
            ? "phone"
            : window.innerWidth <= 1366 && aspect < 0.9
              ? "tablet"
              : "desktop"
      });
    }
    // Add event listener
    window.addEventListener("resize", handleResize);
    // Call handler right away so state gets updated with initial window size
    handleResize();
    // Remove event listener on cleanup
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Empty array ensures that effect is only run on mount
  return windowSize;
};
