import React from "react";
import { useTranslation } from "react-i18next";

import { IonAvatar, IonItem, IonLabel, IonList } from "@ionic/react";

import type { Contract } from "../../../types/responseTypes";

import ContractsListItem from "./ContractsListItem";

interface ContractsListProps {
  filteredContracts: Contract[];
}

const ContractsList: React.FC<ContractsListProps> = ({ filteredContracts }) => {
  const { t } = useTranslation();

  return (
    <IonList lines="none">
      {filteredContracts && !filteredContracts?.length ? (
        <IonItem className="card-widget-list-item">
          <IonAvatar slot="start" />
          <IonLabel>
            <span className="ag-body-text">{t("contractsEmpty")}</span>
          </IonLabel>
        </IonItem>
      ) : (
        filteredContracts.map((contract) => (
          <ContractsListItem key={contract?.id} contract={contract} />
        ))
      )}
    </IonList>
  );
};

export default ContractsList;
