import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  logoApple,
  logoFacebook,
  logoGoogle,
  logoLinkedin
} from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  getPlatforms,
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonPage,
  IonSpinner,
  IonToolbar
} from "@ionic/react";

import LanguageSwitcher from "../../../../components/LanguageSwitcher";
import BackBtn from "../../../../components/UI/BackBtn";
import FormFeedback from "../../../../components/UI/FormFeedback";
import AuthContext from "../../../../context/AuthContext";
import AcceptTermsAndPrivacy from "../../../../features/AcceptTermsAndPrivacy";
import { startFirebase } from "../../../../firebaseInit";
import { useSSOLogin } from "../../../../hooks/useSSOLogin";
import { useWindowSize } from "../../../../hooks/useWindowSize";
import i18n from "../../../../i18n";
import ImageCardLayout from "../../../../layout/ImageCardLayout/ImageCardLayout";
import type { Language, SSOProvider } from "../../../../types/otherTypes";
import type { SSOLogin } from "../../../../types/requestTypes";
import type { ExceptionDto } from "../../../../types/schema";
import { getValueOfSearchedUrlQueryParameter } from "../../../../utils/globalFunctions";
import TwoFALogin from "../TwoFALogin/TwoFALogin";

import styles from "./SSOFinish.module.scss";

const SSOFinish: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const ssoLogin = useSSOLogin();
  const { updateAuthCtx } = useContext(AuthContext);

  const [fcmToken, setFcmToken] = useState<string>(null);
  const [accept, setAccept] = useState<boolean>(false);
  const [isLoading, setLoading] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>();
  const [has2FA, setHas2FA] = useState<boolean>(false);

  const ssoSuccess = getValueOfSearchedUrlQueryParameter("ssoSuccess");
  const ssoToken = getValueOfSearchedUrlQueryParameter("token");
  const isNewUser = getValueOfSearchedUrlQueryParameter("isNewUser");
  const isMobile = localStorage.getItem("isMobile");
  const provider =
    (getValueOfSearchedUrlQueryParameter("provider") as SSOProvider) ??
    (localStorage.getItem("provider") as SSOProvider);
  const lang = i18n.resolvedLanguage as Language;

  useEffect(() => {
    const storedFcm = localStorage.getItem("fcm");
    if (storedFcm) {
      setFcmToken(storedFcm);
    } else {
      startFirebase().then((currentToken) => setFcmToken(currentToken));
    }
  }, [fcmToken]);

  const getProviderIcon = (provider: SSOProvider) => {
    switch (provider) {
      case "facebook":
        return logoFacebook;
      case "google":
        return logoGoogle;
      case "apple":
        return logoApple;
      case "linkedin":
        return logoLinkedin;
    }
  };

  const providerIcon = useMemo(() => getProviderIcon(provider), [provider]);

  const ssoData: SSOLogin = {
    token: ssoToken,
    fcmToken: fcmToken,
    platforms: getPlatforms()
  };

  const responseErrorHandler = (exception) => {
    switch (exception.message) {
      case "INVALID_TOKEN":
        setErrorMsg(t("err.INVALID_TOKEN"));
        break;
      case "EXPIRED_TOKEN":
        setErrorMsg(t("err.EXPIRED_TOKEN"));
        break;
      case "INCORRECT_PIN":
        setErrorMsg(t("err.INCORRECT_PIN"));
        break;
      case "EXPIRED_PIN":
        setErrorMsg(t("err.EXPIRED_PIN"));
        break;
      case "INVALID_LOGIN":
        setErrorMsg(t("err.INVALID_LOGIN"));
        break;
      default:
        setErrorMsg(t("err.GENERIC_ERROR"));
        break;
    }
    return;
  };

  const doSSOLogin = async () => {
    setLoading(true);
    await ssoLogin(ssoData)
      .then((result) => {
        setLoading(false);
        if (!result.has2FA) {
          updateAuthCtx(result);
        } else {
          setHas2FA(result?.has2FA);
        }
      })
      .catch((err) => {
        setLoading(false);
        const exception: ExceptionDto = err.response?.data;
        responseErrorHandler(exception);
      });
  };

  const redirectOrPerformLogin = async () => {
    // daca e mobil, fortam redirect in aceeasi pagina pentru a inchide webview-ul
    if (isMobile === "true") {
      // isMobile exista doar in localStorage-ul webview-ului
      if (ssoSuccess === "false") {
        window.location.replace(
          `aqmeter:login/sso/finish?ssoSuccess=${ssoSuccess}&provider=${provider}`
        );
      } else {
        window.location.replace(
          `aqmeter:login/sso/finish?token=${ssoToken}&isNewUser=${isNewUser}&provider=${provider}`
        );
      }
    } else {
      // daca e mobile, a fost deja redirectat in app prin conditia de mai sus
      // nu mai exista isMobile in localStorage-ul aplicatiei
      // daca e web, intra direct pe conditia asta
      if (ssoSuccess !== "false" && isNewUser !== "true") {
        // facem login direct numai daca NU este user nou
        await doSSOLogin();
      }
    }
  };

  useEffect(() => {
    redirectOrPerformLogin().then();
  }, []);

  const submitHandler = async () => {
    // daca e user nou, tre sa isi dea acordul pt inregistrare
    if (!accept) {
      setErrorMsg(t("err.MANDATORY_TERMS"));
      return;
    }
    await doSSOLogin();
  };

  useEffect(() => {
    if (errorMsg) {
      setErrorMsg(null);
    }
  }, [accept]);

  return (
    <IonPage className={`layout--${layout}`}>
      {/* loginul cu social media a esuat */}
      {ssoSuccess === "false" ? (
        <>
          <IonHeader>
            <IonToolbar>
              <IonButtons slot={"start"}>
                <BackBtn url={"/login"} />
              </IonButtons>
              <IonButtons slot={"end"}>
                <LanguageSwitcher />
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <ImageCardLayout imgUrl={"./assets/img/register.jpg"} logo={true}>
            <FormFeedback type={"error"}>
              {t("sso.loginFailed")}{" "}
              <span className={styles.capitalized}>{provider}</span>{" "}
              {t("sso.loginFailed2")}
            </FormFeedback>
            <IonButton color="dark" href={"/login"}>
              {t("backToLogin")}
            </IonButton>
          </ImageCardLayout>
        </>
      ) : isNewUser === "true" ? (
        <>
          {/* daca loginul a functionat, dar este user nou, ii cerem acord GDPR */}
          <IonHeader>
            <IonToolbar>
              <IonButtons slot={"start"}>
                <BackBtn url={"/login"} />
              </IonButtons>
              <IonButtons slot={"end"}>
                <LanguageSwitcher />
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <ImageCardLayout
            data-testid="ssoFinishTitle"
            title={t("sso.registerTitle")}
            imgUrl={"./assets/img/register.jpg"}
            logo={true}
          >
            <p>{t("sso.registerInfo")}</p>
            <AcceptTermsAndPrivacy
              accept={accept}
              setAccept={setAccept}
              lang={lang}
            />

            <IonButton
              className={styles.providerBtn}
              strong={true}
              color="primary"
              expand="block"
              disabled={isLoading}
              onClick={submitHandler}
            >
              {isLoading ? (
                <>
                  <IonSpinner name="circles" />
                  {t("sso.registerSubmitting")}
                </>
              ) : (
                <>
                  <IonIcon icon={providerIcon} />
                  {t("sso.registerSubmit")}
                </>
              )}
            </IonButton>

            {errorMsg ? (
              <FormFeedback type={"error"}>{errorMsg}</FormFeedback>
            ) : (
              ""
            )}
          </ImageCardLayout>
        </>
      ) : has2FA ? (
        <>
          {/* daca NU este user nou, verificam daca are sau nu 2FA */}
          <IonHeader>
            <IonToolbar>
              <IonButtons slot={"start"}>
                <BackBtn url={"/login"} />
              </IonButtons>
              <IonButtons slot={"end"}>
                <LanguageSwitcher />
              </IonButtons>
            </IonToolbar>
          </IonHeader>

          <ImageCardLayout
            title={t("sso.registerTitle")}
            imgUrl={"./assets/img/register.jpg"}
            logo={true}
          >
            <TwoFALogin ssoData={ssoData} source={"sso"} />
          </ImageCardLayout>
          {/* daca nu este user nou si nu are 2FA, va fi logat direct in aplicatie si impins in dashboard */}
        </>
      ) : null}
    </IonPage>
  );
};

export default SSOFinish;
