import React from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import BackBtn from "../../../components/UI/BackBtn";
import { useWindowSize } from "../../../hooks/useWindowSize";

import { ContactContent } from "./ContactContent";

const ContactPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout layoutType={"back"}>
        <IonLabel className="ag-h1 black">{t("contact.form")}</IonLabel>
        <ContactContent />
      </SidebarLayout>
    </IonPage>
  );
};
export default ContactPage;
