import React, { useContext, useEffect } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import BackBtn from "../../../components/UI/BackBtn";
import AuthContext from "../../../context/AuthContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { Profile } from "../../../types/responseTypes";

import ProfilePageForm from "./ProfilePageForm";

import styles from "./UserPages.module.scss";

const ProfilePage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { updateProfileCtx } = useContext(AuthContext);

  const { data: profile, error } = useFetchCaching<Profile>("/users/profile");

  useEffect(() => {
    if (profile) {
      updateProfileCtx(profile);
    }
  }, [profile]);

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start">
              <BackBtn />
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        url={"/more"}
        menuType={"profile"}
        menuSelected={"item1"}
      >
        <IonLabel className={styles.pageTitle}>{t("profile")}</IonLabel>
        <SkeletonList loading={!profile} error={error} type={"simple"} />
        {profile ? <ProfilePageForm /> : ""}
      </SidebarLayout>
    </IonPage>
  );
};

export default ProfilePage;
