import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import PinInput from "react-pin-input";

import { getPlatforms, IonButton, IonSpinner } from "@ionic/react";

import FormFeedback from "../../../../components/UI/FormFeedback";
import AuthContext from "../../../../context/AuthContext";
import useLogin from "../../../../hooks/useLogin";
import { useSSOLogin } from "../../../../hooks/useSSOLogin";
import type {
  LoginData,
  LoginRequest,
  SSOLogin
} from "../../../../types/requestTypes";

import styles from "./TwoFALogin.module.scss";

interface TwoFALoginProps {
  credentials?: LoginData;
  ssoData?: SSOLogin;
  source: "login" | "sso";
}

const TwoFALogin: React.FC<TwoFALoginProps> = ({
  credentials,
  ssoData,
  source
}) => {
  const { t } = useTranslation();
  const login = useLogin();
  const ssoLogin = useSSOLogin();
  const { updateAuthCtx } = useContext(AuthContext);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [alertVisible, setAlertVisible] = useState(false);
  const [resent, setResent] = useState(false);
  const [progressBarAppearance, setProgressBarAppearance] = useState(true);
  const [pin, setPin] = useState("");
  let pinRef;

  const resendHandler = async (e) => {
    e.preventDefault();

    pinRef.clear();
    setPin("");

    try {
      if (source === "login") {
        // logica Bogdan
        const data: LoginRequest = {
          email: credentials.email,
          password: credentials.password,
          fcmToken: credentials.fcmToken,
          pin: null,
          platforms: getPlatforms()
        };
        await login(data);
      } else {
        // vine din sso
        await ssoLogin(ssoData);
      }
    } catch (err) {
      setErrorMessage(`err.${err.response?.data.message}`);
      setAlertVisible(true);
      setPin("");
    } finally {
      // logica Bogdan
      setResent(true);
      setTimeout(() => setProgressBarAppearance(false), 1);
      setTimeout(() => setProgressBarAppearance(true), 2);
      setTimeout(() => setResent(false), 6000);
      setAlertVisible(false);
    }
  };

  const submitHandler = async (event) => {
    event.preventDefault();

    pinRef?.clear();
    setIsLoading(true);

    try {
      if (source === "login" && credentials) {
        // logica Bogdan
        const data: LoginRequest = {
          email: credentials.email,
          password: credentials.password,
          fcmToken: credentials.fcmToken,
          pin: +pin,
          platforms: getPlatforms()
        };
        await login(data).then((result) => {
          setIsLoading(false);
          updateAuthCtx(result);
        });
      } else {
        // vine din sso
        await ssoLogin({ ...ssoData, pin: +pin }).then((result) => {
          setIsLoading(false);
          updateAuthCtx(result);
        });
      }
    } catch (err) {
      setIsLoading(false);
      if (err.response?.data.message === "INCORRECT_PIN") {
        setErrorMessage("err.INCORRECT_PIN");
        setAlertVisible(true);
        setPin("");
      } else if (err.response?.data.message === "EXPIRED_PIN") {
        setAlertVisible(true);
        setErrorMessage("err.EXPIRED_PIN");
        setPin("");
      } else {
        setAlertVisible(true);
        setErrorMessage("err.GENERIC_ERROR");
        setPin("");
      }
    }
  };

  return (
    <form onSubmit={submitHandler} className={styles.twoFactorForm}>
      <div className={styles.titleContainer}>
        <h1>{t("2FA.verifyTitle")}</h1>
        <p>{t("2FA.subtitle")}</p>
      </div>

      <div className={styles.twoFactorInputs}>
        <PinInput
          data-testid="pin-input"
          length={4}
          initialValue=""
          type="numeric"
          inputMode="number"
          style={{
            padding: "10px",
            width: "100%",
            textAlign: "center",
            fontWeight: "500"
          }}
          inputStyle={{ borderColor: "red" }}
          inputFocusStyle={{ borderColor: "blue" }}
          autoSelect={true}
          regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
          onChange={(value) => {
            setResent(false);
            setPin(value);
            setAlertVisible(false);
          }}
          onComplete={(value) => {
            setPin(value);
          }}
          focus={true}
          ref={(value) => {
            return (pinRef = value);
          }}
        />

        {progressBarAppearance && (
          <div className={styles.loaderWrapper}>
            <div className={styles["loader-progressBar"]}></div>
          </div>
        )}

        <div className={` ${styles.loader}`}>
          <div className={styles.loaderText}>
            <p className={styles.validityText}>{t("2FA.validity")}</p>
            <IonButton
              className={styles.linkResend}
              fill="clear"
              color="primary"
              size="small"
              onClick={resendHandler}
            >
              {t("2FA.resend")}
            </IonButton>
          </div>
        </div>

        <IonButton
          className="ion-margin-top"
          type="submit"
          color="primary"
          expand="block"
          strong={true}
          disabled={isLoading || pin.length <= 3}
        >
          {isLoading ? (
            <>
              <IonSpinner name="circles" /> {t("2FA.loading")}
            </>
          ) : (
            <> {t("2FA.buttonText")}</>
          )}
        </IonButton>
      </div>
      {alertVisible && (
        <FormFeedback type={"error"}>{t(`${errorMessage}`)}</FormFeedback>
      )}
      {resent && (
        <FormFeedback type={"success"}>{t("2FA.resend.message")}</FormFeedback>
      )}

      <input
        type="submit"
        className="submit-enter"
        disabled={isLoading || pin.length <= 3}
      />
    </form>
  );
};

export default TwoFALogin;
