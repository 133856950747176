import React, { useContext, useState } from "react";
import { chevronDown, informationCircle } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonIcon, IonItem } from "@ionic/react";

import AuthContext from "../../../../context/AuthContext";
import type { DashboardContract } from "../../../../types/responseTypes";

import styles from "../PaymentCard.module.scss";

interface ContractsWithNegativeBalanceProps {
  contractsWithNegativeBalance: DashboardContract[];
}

const ContractsWithNegativeBalance: React.FC<
  ContractsWithNegativeBalanceProps
> = ({ contractsWithNegativeBalance }) => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);

  const [isNegOpen, setIsNegOpen] = useState(false);

  return (
    <div className={`${styles.contractInfo} ${isNegOpen ? styles.open : ""}`}>
      <IonItem
        className={styles.contractDetails}
        lines="none"
        detail={false}
        onClick={() => setIsNegOpen((prevState) => !prevState)}
      >
        <IonIcon icon={informationCircle} color="tertiary" slot="start" />
        <span>
          {t("customPayment.summary.info", {
            count: contractsWithNegativeBalance.length
          })}
        </span>
        <IonIcon icon={chevronDown} color="medium" slot="end" />
      </IonItem>
      <ul>
        {contractsWithNegativeBalance.map((contract) => (
          <li key={contract.number}>
            {contract?.companyShortName} {contract?.number}
            {" - "}
            {contract?.name}
            {" ("}
            {contract?.deliveryAddress}
            {"): "}
            <span className="tertiary-color">
              {contract?.balance.toLocaleString(langCtx)} {t("currency")}
            </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default ContractsWithNegativeBalance;
