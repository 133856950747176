import React, { useContext } from "react";
import {
  documentText,
  ellipsisHorizontalCircleSharp,
  library,
  menuSharp,
  notificationsOutline,
  receipt,
  speedometer
} from "ionicons/icons";
import { useTranslation } from "react-i18next";
import { Redirect, Route, useHistory } from "react-router";
import { Switch } from "react-router-dom";

import {
  IonAvatar,
  IonBadge,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs
} from "@ionic/react";

import AuthContext from "../context/AuthContext";
import useBubbleTabsInfoUpdate from "../hooks/useBubbleTabsInfoUpdate";
import { useWindowSize } from "../hooks/useWindowSize";
import animationBuilder from "../utils/animation-builder";

import InvoicesPage from "./1.Invoices/AllInvoices/InvoicesPage";
import InvoiceDetailsPage from "./1.Invoices/InvoiceDetails/InvoiceDetailsPage";
import NotPaidInvoicesPage from "./1.Invoices/NotPaidInvoices/NotPaidInvoicesPage";
import PaidInvoicesPage from "./1.Invoices/PaidInvoices/PaidInvoicesPage";
import { PayPage } from "./1.Invoices/Payments/Common/PayPage";
import CustomPaymentPage from "./1.Invoices/Payments/CustomPayment/CustomPaymentPage";
import InvoicesPaymentPage from "./1.Invoices/Payments/InvoicesPayment/InvoicesPaymentPage";
import { TransactionResultPage } from "./1.Invoices/Payments/TransactionResultPage";
import AddReadingPage from "./2.Readings/AddReading/AddReadingPage";
import ReadingsPage from "./2.Readings/AllReadings/ReadingsPage";
import ReadingsHistoryPage from "./2.Readings/ReadingsHistory/ReadingsHistoryPage";
import DashboardPage from "./3.Dashboard/DashboardPage";
import AddContractPage from "./4.Contracts/AddContract/AddContractPage";
import ContractsPage from "./4.Contracts/AllContracts/ContractsPage";
import EditContractPage from "./4.Contracts/EditContract/EditContractPage";
import ContactPage from "./5.More/Contact/ContactPage";
import CreateContractPage from "./5.More/Demands/Create/CreateContractPage";
import ListDemands from "./5.More/Demands/ListDemands/ListDemands";
import PickCompanyPage from "./5.More/Demands/PickCompany/PickCompanyPage";
import SignaturePage from "./5.More/Demands/Signature/SignaturePage";
import { CookiesPage } from "./5.More/Help/Cookies/CookiesPage";
import { FaqPage } from "./5.More/Help/FAQ/FaqPage";
import HelpPage from "./5.More/Help/HelpPage";
import { PrivacyPage } from "./5.More/Help/Privacy/PrivacyPage";
import { TermsPage } from "./5.More/Help/Terms/TermsPage";
import MorePage from "./5.More/MorePage";
import { NotificationsPage } from "./5.More/Notifications/AllNotifications/NotificationsPage";
import { NotificationDetailsPage } from "./5.More/Notifications/NotificationDetail/NotificationDetailsPage";
import ChangePasswordPage from "./5.More/User/ChangePasswordPage";
import ProfilePage from "./5.More/User/ProfilePage";
import SettingsPage from "./5.More/User/SettingsPage";
import EmailIsValidPage from "./Others/EmailIsValidPage";

import "./UserTabs.scss";
import styles from "./5.More/User/UserPages.module.scss";

const UserTabs: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const [
    unpaidInvoices,
    hasWaterContracts,
    meterReadings,
    unreadNotifs,
    numberOfDemands
  ] = useBubbleTabsInfoUpdate();
  const { profileAvatarCtx } = useContext(AuthContext);
  const history = useHistory();

  return (
    <IonTabs className={layout === "phone" ? "ionTabs-bottom" : "ionTabs-top"}>
      <IonRouterOutlet animation={animationBuilder}>
        <Switch>
          <Route exact path="/" component={DashboardPage} />
          <Route exact path="/dashboard" component={DashboardPage} />
          <Route exact path="/invoices" component={InvoicesPage} />
          <Route exact path="/invoices/paid" component={PaidInvoicesPage} />
          <Route
            exact
            path="/invoices/:contractId"
            component={NotPaidInvoicesPage}
          />
          <Route
            exact
            path="/invoices/detail/:invoiceId"
            component={InvoiceDetailsPage}
          />
          <Route
            exact
            path="/payment/invoices"
            component={InvoicesPaymentPage}
          />
          <Route
            exact
            path="/payment/custom/:contractId"
            component={CustomPaymentPage}
          />
          <Route exact path="/contracts" component={ContractsPage} />
          <Route exact path="/contracts/add" component={AddContractPage} />
          <Route
            exact
            path="/contracts/create"
            component={CreateContractPage}
          />
          <Route exact path="/contracts/:id" component={EditContractPage} />
          <Route exact path="/readings" component={ReadingsPage} />
          <Route
            exact
            path="/readings/history"
            component={ReadingsHistoryPage}
          />
          <Route exact path="/readings/:id" component={AddReadingPage} />
          <Route exact path="/more" component={MorePage} />
          <Route exact path="/demands/company" component={PickCompanyPage} />
          <Route exact path="/demands/create" component={CreateContractPage} />
          <Route exact path="/demands/signature" component={SignaturePage} />
          <Route exact path="/demands/list" component={ListDemands} />

          <Route exact path="/user/profile" component={ProfilePage} />
          <Route exact path="/user/password" component={ChangePasswordPage} />
          <Route exact path="/user/settings" component={SettingsPage} />
          <Route exact path="/notifications" component={NotificationsPage} />
          <Route
            exact
            path="/notification/:id"
            component={NotificationDetailsPage}
          />

          <Route exact path="/validation" component={EmailIsValidPage} />

          <Route exact path="/help" component={HelpPage} />
          <Route exact path="/FAQ" component={FaqPage} />
          <Route exact path="/contact" component={ContactPage} />
          <Route exact path="/terms" component={TermsPage} />
          <Route exact path="/privacy" component={PrivacyPage} />
          <Route exact path="/cookies" component={CookiesPage} />
          <Route
            exact
            path="/pay/:contractId/:paymentData"
            component={PayPage}
          />
          <Route
            exact
            path="/payment/transaction/result"
            component={TransactionResultPage}
          />

          <Route
            exact
            path="/FCM_PLUGIN_ACTIVITY"
            render={() => <Redirect to="/notifications" />}
          />
          <Route exact path="/home" render={() => <Redirect to="/" />} />
          <Route render={() => <Redirect to="/dashboard" />} />
        </Switch>
      </IonRouterOutlet>

      {history.location.pathname !== "/validation" ? (
        layout === "phone" ? (
          <IonTabBar slot={"bottom"}>
            <IonTabButton tab="invoices" href="/invoices">
              <div className="icon-bg">
                <IonIcon icon={receipt} />
              </div>
              <IonLabel>{t("tabs.invoices")}</IonLabel>
              {unpaidInvoices > 0 && (
                <IonBadge color="danger">{unpaidInvoices}</IonBadge>
              )}
            </IonTabButton>
            {hasWaterContracts && (
              <IonTabButton tab="readings" href="/readings">
                <div className="icon-bg">
                  <IonIcon icon={speedometer} />
                </div>
                <IonLabel>{t("tabs.index")}</IonLabel>
                {meterReadings > 0 && (
                  <IonBadge color="danger">{meterReadings}</IonBadge>
                )}
              </IonTabButton>
            )}
            <IonTabButton tab="dashboard" href="/dashboard">
              <div className="icon-bg">
                <IonIcon icon={library} />
              </div>
              <IonLabel>{t("tabs.dashboard")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="contracts" href="/contracts">
              <div className="icon-bg">
                <IonIcon icon={documentText} />
              </div>
              <IonLabel>{t("tabs.contracts")}</IonLabel>
            </IonTabButton>
            <IonTabButton tab="more" href="/more">
              <div className="icon-bg">
                <IonIcon icon={ellipsisHorizontalCircleSharp} />
              </div>
              <IonLabel>{t("tabs.others")}</IonLabel>
              {numberOfDemands > 0 ? (
                <IonBadge color={"danger"}>{numberOfDemands}</IonBadge>
              ) : (
                ""
              )}
            </IonTabButton>
          </IonTabBar>
        ) : (
          <IonTabBar slot={"top"}>
            <IonTabButton
              tab="logo"
              href="/home"
              className="main-menu-logo-btn"
            >
              <img src="/assets/img/aqmeter-small.png" alt="Aqmeter" />
            </IonTabButton>

            <IonTabButton
              tab="dashboard"
              href="/dashboard"
              className="main-menu-links"
            >
              <IonLabel>{t("tabs.dashboard")}</IonLabel>
            </IonTabButton>
            <IonTabButton
              tab="invoices"
              href="/invoices"
              className="main-menu-links"
            >
              <IonLabel>{t("tabs.invoices")}</IonLabel>
              {unpaidInvoices > 0 && (
                <IonBadge color="danger">{unpaidInvoices}</IonBadge>
              )}
            </IonTabButton>
            {hasWaterContracts && (
              <IonTabButton
                tab="readings"
                href="/readings"
                className="main-menu-links"
              >
                <IonLabel>{t("tabs.index")}</IonLabel>
                {meterReadings > 0 && (
                  <IonBadge color="danger">{meterReadings}</IonBadge>
                )}
              </IonTabButton>
            )}
            <IonTabButton
              tab="contracts"
              href="/contracts"
              className="main-menu-links"
            >
              <IonLabel>{t("tabs.contracts")}</IonLabel>
            </IonTabButton>

            <IonTabButton
              tab="demands"
              href="/demands/list"
              className="main-menu-links"
            >
              <IonLabel>{t("tabs.demands")}</IonLabel>
              {numberOfDemands > 0 ? (
                <IonBadge color={"danger"}>{numberOfDemands}</IonBadge>
              ) : (
                ""
              )}
            </IonTabButton>

            <IonTabButton
              tab="notifications"
              href="/notifications"
              className="main-menu-bell-btn small-btns"
            >
              {unreadNotifs > 0 && <div className="new-notification"></div>}
              <IonIcon icon={notificationsOutline} />
            </IonTabButton>
            <IonTabButton
              tab="more"
              href="/more"
              className="main-menu-more-btn small-btns"
            >
              <IonIcon icon={menuSharp} />
            </IonTabButton>
            <IonTabButton
              tab="profilePicture"
              href="/user/profile"
              className="profileAvatar-btn"
            >
              <IonAvatar className={styles.avatarImage}>
                <img
                  alt="Silhouette of a person's head"
                  src={profileAvatarCtx}
                />
              </IonAvatar>
            </IonTabButton>
          </IonTabBar>
        )
      ) : (
        <IonTabBar></IonTabBar>
      )}
    </IonTabs>
  );
};

export default UserTabs;
