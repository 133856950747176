import React, { useContext, useEffect, useMemo } from "react";
import { chevronForward } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import { IonAvatar, IonIcon, IonItem, IonLabel, IonList } from "@ionic/react";

import UserContext from "../../context/UserContext";
import { useWindowSize } from "../../hooks/useWindowSize";
import type { Company } from "../../types/responseTypes";
import { getValueOfSearchedUrlQueryParameter } from "../../utils/globalFunctions";

import styles from "../../pages/4.Contracts/AddContract/AddContractPage.module.scss";

interface AddContractChooseCompanyProps {
  setSelectedCompany?: (value: Company) => void;
  setStep?: (value) => void;
}

const AddContractChooseCompany: React.FC<AddContractChooseCompanyProps> = ({
  setSelectedCompany,
  setStep
}) => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { companiesCtx } = useContext(UserContext);

  const restrictedToCompany = getValueOfSearchedUrlQueryParameter("ca");
  const preselectedCompany = useMemo(
    () =>
      companiesCtx?.filter(
        (company) => company.landingCode === restrictedToCompany
      )[0],
    [restrictedToCompany, companiesCtx]
  );

  useEffect(() => {
    if (restrictedToCompany) {
      setSelectedCompany(preselectedCompany);
      if (setStep) {
        setStep(2);
      }
    }
  }, [restrictedToCompany, preselectedCompany]);

  const selectionHandler = (company) => {
    setSelectedCompany(company);
    if (setStep) {
      setStep((prevState) => prevState + 1);
    }
  };

  return (
    <>
      <IonLabel className={styles.title} data-testid="titleAddCompany">
        {t("contract.addContract")}
      </IonLabel>
      <IonLabel className={styles.subtitle} data-testid="subtitle">
        {t("contract.addContractSubTitle")}
      </IonLabel>

      <IonList lines="none" data-testid="companyList">
        {companiesCtx?.map((company) => (
          <IonItem
            data-testid={company.id}
            key={company?.id}
            className={styles.companyItem}
            onClick={() => selectionHandler(company)}
          >
            <IonAvatar slot="start">
              <img src={company?.logoUrl} alt={`Logo ${company?.shortName}`} />
            </IonAvatar>
            <IonLabel className={styles.companyName}>
              {layout === "phone" ? company?.shortName : company?.name}
            </IonLabel>
            <IonIcon className="chevron" icon={chevronForward} />
          </IonItem>
        ))}
      </IonList>
    </>
  );
};

export default AddContractChooseCompany;
