import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { PieChart } from "react-minimal-pie-chart";

import AuthContext from "../../../../context/AuthContext";
import UserContext from "../../../../context/UserContext";
import type { DashboardCompany } from "../../../../types/responseTypes";

import styles from "../PaymentCard.module.scss";

interface ChartProps {
  dashboardCompanies: DashboardCompany[];
  filteredCompany: number;
}

const Chart: React.FC<ChartProps> = ({
  dashboardCompanies,
  filteredCompany
}: ChartProps): JSX.Element => {
  const { t } = useTranslation();
  const { langCtx } = useContext(AuthContext);
  const { contractsCtx } = useContext(UserContext);

  const numberOfContracts: number = useMemo(
    () => contractsCtx?.length,
    [contractsCtx]
  );

  const pieColor = (idx: number) => {
    const pallet = [
      "#50c8ff",
      "#007cef",
      "#00c27c",
      "#4854e0",
      "#ffca22",
      "#63768b",
      "#ed576b"
    ];
    return pallet[idx % pallet.length];
  };

  const pieData = dashboardCompanies?.map((company, idx) => {
    return {
      key: company.companyId,
      title: company.companyShortName,
      value: company.totalDueAmount,
      color: pieColor(idx)
    };
  });

  const emptyPieData = [
    {
      value: 100,
      color: pieColor(0)
    }
  ];

  const totalChartValue = dashboardCompanies.reduce(
    (res, x) => res + x.totalDueAmount,
    0
  );

  return (
    <div className={styles.chartBox}>
      {dashboardCompanies.length ? (
        filteredCompany === 0 ? (
          <>
            <PieChart
              className={styles.chart}
              lineWidth={7}
              totalValue={totalChartValue}
              data={pieData}
              animate={true}
              startAngle={180}
              labelPosition={80}
              segmentsStyle={{ transition: "stroke .3s", cursor: "pointer" }}
            />
            <span className={styles.chartLabel}>
              {totalChartValue.toLocaleString(langCtx)} {t("currency")}
            </span>
          </>
        ) : (
          <PieChart
            className={styles.chart}
            lineWidth={7}
            totalValue={
              pieData.filter((chart) => filteredCompany === chart.key)[0].value
            }
            data={pieData.filter((chart) => filteredCompany === chart.key)}
            animate={true}
            animationDuration={4000}
            animationEasing={"ease-in"}
            startAngle={180}
            label={({ dataEntry }) =>
              `${dataEntry.value.toLocaleString(langCtx)} ${t("currency")}`
            }
            labelPosition={0}
            labelStyle={{
              fontSize: "12px",
              fontFamily: "sans-serif",
              fontWeight: "600",
              transition: "stroke .3s"
            }}
          />
        )
      ) : numberOfContracts === 0 ? (
        <>
          <PieChart
            className={styles.chart}
            lineWidth={7}
            totalValue={0}
            data={emptyPieData}
            animate={true}
          />
          <span className={styles.chartLabelEmpty}>
            {t("dashboard.graph.noContract")}
          </span>
        </>
      ) : (
        <>
          <PieChart
            className={styles.chart}
            lineWidth={7}
            totalValue={0}
            data={emptyPieData}
            animate={true}
          />
          <span className={styles.chartLabel}>0 {t("currency")}</span>
        </>
      )}
    </div>
  );
};

export default Chart;
