import { t } from "i18next";

import { IonList } from "@ionic/react";

import StandardInput from "../StandardInput";

import styles from "../../CreateContractPage.module.scss";

interface CiDataProps {
  setCiData: (ciData) => void;
  ciData: any;
  errorMsg?: string;
}

const CiData: React.FC<CiDataProps> = ({ setCiData, ciData, errorMsg }) => {
  return (
    <>
      <IonList>
        <div className={styles.spacedInputs}>
          <StandardInput
            setInputValue={(event) =>
              setCiData((prev) => ({
                ...prev,
                series: event.target.value as string
              }))
            }
            inputValue={ciData.series}
            labelName={t("demands.requestFormPage.ciData.seriesCI")}
            errorMsg={errorMsg}
          />
          <StandardInput
            setInputValue={(event) =>
              setCiData((prev) => ({
                ...prev,
                number: event.target.value as string
              }))
            }
            inputValue={ciData?.number}
            labelName={t("demands.requestFormPage.ciData.number")}
            errorMsg={errorMsg}
          />
        </div>
        <div className={styles.spacedInputs}>
          <StandardInput
            setInputValue={(event) =>
              setCiData((prev) => ({
                ...prev,
                issueDate: event.target.value as string
              }))
            }
            inputValue={ciData?.issueDate}
            labelName={t("demands.requestFormPage.ciData.issuedDate")}
            // inputType={"date"}
            errorMsg={errorMsg}
          />
          <StandardInput
            setInputValue={(event) =>
              setCiData((prev) => ({
                ...prev,
                cnp: event.target.value as string
              }))
            }
            inputValue={ciData?.cnp}
            labelName={t("demands.requestFormPage.ciData.CNP")}
            errorMsg={errorMsg}
          />
        </div>
      </IonList>
    </>
  );
};

export default CiData;
