import React, { useContext, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";

import { IonButton, IonLabel } from "@ionic/react";

import AuthContext from "../../context/AuthContext";
import UserContext from "../../context/UserContext";
import type { InfoSliderProps } from "../../types/otherTypes";

import InfoSlider from "./InfoSlider/InfoSlider";

import styles from "./DashboardPage.module.scss";

const DashboardHeader: React.FC<InfoSliderProps> = ({ info }) => {
  const { t } = useTranslation();
  const { nameCtx } = useContext(AuthContext);
  const { menuCtx, contractsCtx } = useContext(UserContext);

  const numberOfContracts: number = useMemo(
    () => contractsCtx?.length,
    [contractsCtx]
  );

  const history = useHistory();

  return (
    <div className={styles.header}>
      <div className={styles.greeting}>
        {nameCtx ? (
          <IonLabel className="ag-h2 black capitalize">
            {t("hiUser", { name: nameCtx?.toLowerCase() })}
          </IonLabel>
        ) : (
          <IonLabel className="ag-h2 black">{t("hiNoUser")}</IonLabel>
        )}

        {numberOfContracts ? (
          !menuCtx?.totalUnpaidInvoices ? (
            <IonLabel className="ag-body-text gray">
              {t("invoiceToPayCountZero")}
            </IonLabel>
          ) : (
            <IonLabel className="ag-body-text gray">
              {t("invoiceToPayCount", {
                count: menuCtx?.totalUnpaidInvoices
              })}
            </IonLabel>
          )
        ) : (
          <IonLabel className="ag-body-text gray">
            {t("dashboard.header.noContract")}
          </IonLabel>
        )}
      </div>

      {info?.length ? <InfoSlider info={info} /> : ""}

      {numberOfContracts !== 0 && (
        <IonButton
          color="tertiary"
          onClick={() => history.push("/contracts/add")}
        >
          {t("contract.addContract")}
        </IonButton>
      )}
    </div>
  );
};

export default DashboardHeader;
