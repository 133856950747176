import React, { useEffect, useState } from "react";
import { refresh } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonButton,
  IonButtons,
  IonHeader,
  IonIcon,
  IonLabel,
  IonPage,
  IonSpinner,
  IonToolbar
} from "@ionic/react";

import { aqmeterActions } from "../../api/AqmeterActions";
import LanguageSwitcher from "../../components/LanguageSwitcher";
import BackBtn from "../../components/UI/BackBtn";
import { useSettings } from "../../context/SettingsContext";
import { useWindowSize } from "../../hooks/useWindowSize";
import ImageCardLayout from "../../layout/ImageCardLayout/ImageCardLayout";
import type { AppStatus } from "../../types/responseTypes";

const MaintenancePage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { settings } = useSettings();

  const [isLoading, setIsLoading] = useState(false);
  const [status, setStatus] = useState<AppStatus>({
    health: "DOWN",
    version: settings.version
  });

  const retryAppStatusCheck = async () => {
    setIsLoading(true);
    try {
      const appStatusResponse = await aqmeterActions.getAppStatus();
      setStatus(appStatusResponse);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (status.health === "UP") {
      window.location.reload();
    }
  }, [status]);

  return (
    <IonPage className={`layout--${layout}`}>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot={"start"}>
            <BackBtn />
          </IonButtons>
          <IonButtons slot={"end"}>
            <LanguageSwitcher />
          </IonButtons>
        </IonToolbar>
      </IonHeader>

      <ImageCardLayout
        title={t("maintenance.title")}
        imgUrl={"./assets/img/maintenance.jpg"}
        logo={true}
      >
        <p>{t("maintenance.desc")}</p>

        <IonButton onClick={retryAppStatusCheck} disabled={isLoading}>
          {isLoading ? (
            <IonSpinner slot="start" name="circles" />
          ) : (
            <IonIcon size="small" slot="start" icon={refresh} />
          )}
          <IonLabel>{t("maintenance.retry")}</IonLabel>
        </IonButton>
      </ImageCardLayout>
    </IonPage>
  );
};
export default MaintenancePage;
