import { useEffect, useState } from "react";
import { chevronBack } from "ionicons/icons";
import { useTranslation } from "react-i18next";

import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonIcon
} from "@ionic/react";

import styles from "../CreateContractPage.module.scss";

const ClientTypePage = ({
  setStep,
  setScenario,
  scenario,
  setSpecification,
  specification
}) => {
  const { t } = useTranslation();

  const [client, setClient] = useState({
    physical: { active: false, name: "P.F." },
    juridical: { active: false, name: "P.J." },
    asociation: { active: false, name: "A.P." }
  });

  const initialStateContract = {
    overTaken: { active: false, name: "Preluare" },
    updated: { active: false, name: "Reînnoire" }
  };

  const initialStateClientType = {
    owner: { active: false, name: "Proprietar" },
    tenant: { active: false, name: "Chiriaș" }
  };

  const [contractType, setContractType] = useState(initialStateContract);

  const [clientType, setClientType] = useState(initialStateClientType);

  useEffect(() => {
    if (
      !client.physical.active &&
      !client.juridical.active &&
      !client.asociation.active
    ) {
      setContractType(initialStateContract);
    }

    if (!contractType.overTaken.active && !contractType.updated.active) {
      setClientType(initialStateClientType);
    }
  }, [
    client.physical.active,
    client.juridical.active,
    client.asociation.active,
    contractType.overTaken.active,
    contractType.updated.active
  ]);

  useEffect(() => {
    if (scenario) {
      setScenario("");
    }
    if (specification) {
      setSpecification("");
    }
    if (
      (clientType.owner.active && client.juridical.active) ||
      (clientType.owner.active && client.physical.active) ||
      (clientType.tenant.active && client.juridical.active) ||
      (clientType.tenant.active && client.physical.active) ||
      (client.asociation.active && contractType.overTaken.active) ||
      (client.asociation.active && contractType.updated.active)
    ) {
      setScenario(assembleLabelText());
      setSpecification(toBeStoredLocal());

      setStep((prev) => prev + 1);
    }
  }, [
    clientType.owner.active,
    clientType.tenant.active,
    contractType.overTaken.active,
    contractType.updated.active
  ]);

  const assembleLabelText = () => {
    const labelText = `${
      client.juridical.active
        ? client.juridical.name
        : client.physical.active
          ? client.physical.name
          : client.asociation.active
            ? client.asociation.name
            : ""
    }/${
      contractType.overTaken.active
        ? contractType.overTaken.name
        : contractType.updated.active
          ? contractType.updated.name
          : ""
    }${
      clientType.owner.active
        ? `/ ${clientType.owner.name}`
        : clientType.tenant.active
          ? `/ ${clientType.tenant.name}`
          : ""
    }`;

    return labelText;
  };

  const toBeStoredLocal = () => {
    return {
      clientType: client.juridical.active
        ? "Persoană juridică"
        : client.physical.active
          ? "Persoană fizică"
          : client.asociation.active
            ? "Asociație proprietari"
            : "",
      target: contractType.overTaken.active
        ? "Preluare"
        : contractType.updated.active
          ? "Reînnoire"
          : "",
      propertyOwnership: clientType.owner.active
        ? "Proprietar"
        : clientType.tenant.active
          ? "Chiriaș"
          : ""
    };
  };

  return (
    <>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle className={styles.title}>
            {t("demands.clientType.title")}
          </IonCardTitle>
        </IonCardHeader>
        <IonCardContent>{t("demands.clientType.content")}</IonCardContent>
        <div
          className={`${
            !(
              client.juridical.active ||
              client.asociation.active ||
              client.physical.active
            )
              ? styles.clientTypeButtons
              : null
          }`}
        >
          {/* Afisare buton persoana fizica daca cel de asociatie sau de juridic NU e apasat */}
          {!(client.juridical.active || client.asociation.active) ? (
            !client.physical.active ? (
              <IonButton
                onClick={() =>
                  setClient((prevClient) => ({
                    ...prevClient,
                    physical: {
                      ...prevClient.physical,
                      active: !prevClient.physical.active
                    }
                  }))
                }
                color={"tertiary"}
              >
                Persoană fizică
              </IonButton>
            ) : (
              <div className={styles.wrapperLabelClient}>
                <IonIcon className={styles.chevronIcon} icon={chevronBack} />
                <IonCardContent
                  className={styles.labelClient}
                  onClick={() =>
                    setClient((prevClient) => ({
                      ...prevClient,
                      physical: {
                        ...prevClient.physical,
                        active: !prevClient.physical.active
                      }
                    }))
                  }
                >
                  Persoană fizică
                </IonCardContent>
              </div>
            )
          ) : null}
          {!(client.physical.active || client.asociation.active) ? (
            !client.juridical.active ? (
              <IonButton
                onClick={() =>
                  setClient((prevClient) => ({
                    ...prevClient,
                    juridical: {
                      ...prevClient.juridical,
                      active: !prevClient.juridical.active
                    }
                  }))
                }
                color={"tertiary"}
              >
                Persoană juridică
              </IonButton>
            ) : (
              <div className={styles.wrapperLabelClient}>
                <IonIcon className={styles.chevronIcon} icon={chevronBack} />
                <IonCardContent
                  className={styles.labelClient}
                  onClick={() =>
                    setClient((prevClient) => ({
                      ...prevClient,
                      juridical: {
                        ...prevClient.juridical,
                        active: !prevClient.juridical.active
                      }
                    }))
                  }
                >
                  Persoană juridică
                </IonCardContent>
              </div>
            )
          ) : null}
          {!(client.juridical.active || client.physical.active) ? (
            !client.asociation.active ? (
              <IonButton
                color={"tertiary"}
                onClick={() =>
                  setClient((prevClient) => ({
                    ...prevClient,
                    asociation: {
                      ...prevClient.asociation,
                      active: !prevClient.asociation.active
                    }
                  }))
                }
              >
                Asociație proprietari
              </IonButton>
            ) : (
              <div className={styles.wrapperLabelClient}>
                <IonIcon className={styles.chevronIcon} icon={chevronBack} />
                <IonCardContent
                  className={styles.labelClient}
                  onClick={() =>
                    setClient((prevClient) => ({
                      ...prevClient,
                      asociation: {
                        ...prevClient.asociation,
                        active: !prevClient.asociation.active
                      }
                    }))
                  }
                >
                  Asociație proprietari
                </IonCardContent>
              </div>
            )
          ) : null}
        </div>

        {/* Daca unul dintre cele 3 butoane: persoana fizica/juridica/asociatie este apasat, se afiseaza butoanele de contractType */}

        {client.juridical.active ||
        client.asociation.active ||
        client.physical.active ? (
          <div
            className={`${
              !(contractType.updated.active || contractType.overTaken.active)
                ? styles.contractTypeButtons
                : null
            }`}
          >
            {!contractType.updated.active ? (
              !contractType.overTaken.active ? (
                <IonButton
                  onClick={() =>
                    setContractType((prevContractType) => ({
                      ...prevContractType,
                      overTaken: {
                        ...prevContractType.overTaken,
                        active: !prevContractType.overTaken.active
                      }
                    }))
                  }
                  color={"tertiary"}
                >
                  Preluare
                </IonButton>
              ) : (
                <div className={styles.wrapperLabelClient}>
                  <IonIcon className={styles.chevronIcon} icon={chevronBack} />
                  <IonCardContent
                    className={styles.labelClient}
                    onClick={() =>
                      setContractType((prevContractType) => ({
                        ...prevContractType,
                        overTaken: {
                          ...prevContractType.overTaken,
                          active: !prevContractType.overTaken.active
                        }
                      }))
                    }
                  >
                    Preluare
                  </IonCardContent>
                </div>
              )
            ) : null}
            {!contractType.overTaken.active ? (
              !contractType.updated.active ? (
                <IonButton
                  onClick={() =>
                    setContractType((prevContractType) => ({
                      ...prevContractType,
                      updated: {
                        ...prevContractType.updated,
                        active: !prevContractType.updated.active
                      }
                    }))
                  }
                  color={"tertiary"}
                >
                  Reînnoire
                </IonButton>
              ) : (
                <div className={styles.wrapperLabelClient}>
                  <IonIcon className={styles.chevronIcon} icon={chevronBack} />
                  <IonCardContent
                    className={styles.labelClient}
                    onClick={() =>
                      setContractType((prevContractType) => ({
                        ...prevContractType,
                        updated: {
                          ...prevContractType.updated,
                          active: !prevContractType.updated.active
                        }
                      }))
                    }
                  >
                    Reînnoire
                  </IonCardContent>
                </div>
              )
            ) : null}
          </div>
        ) : null}

        {((contractType.overTaken.active || contractType.updated.active) &&
          client.physical.active) ||
        ((contractType.overTaken.active || contractType.updated.active) &&
          client.juridical.active) ? (
          <div className={styles.contractTypeButtons}>
            <IonButton
              onClick={() => {
                setClientType((prevClient) => ({
                  ...prevClient,
                  owner: {
                    ...prevClient.owner,
                    active: !prevClient.owner.active
                  }
                }));
              }}
              color={"tertiary"}
            >
              Proprietar
            </IonButton>
            <IonButton
              onClick={() => {
                setClientType((prevClient) => ({
                  ...prevClient,
                  tenant: {
                    ...prevClient.tenant,
                    active: !prevClient.tenant.active
                  }
                }));
              }}
              color={"tertiary"}
            >
              Chiriaș
            </IonButton>
          </div>
        ) : null}
      </IonCard>
    </>
  );
};

export default ClientTypePage;
