import React from "react";
import { Link } from "react-router-dom";

import { IonAccordion, IonAccordionGroup, IonItem } from "@ionic/react";

const FaqContentEN: React.FC = () => {
  return (
    <div className="help-page-text-wrapper">
      <IonAccordionGroup multiple={true}>
        <IonAccordion value="ask1">
          <IonItem slot="header">
            <h6>What is Aqmeter?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Aqmeter is a web and mobile portal for customers of utility
              companies.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask2">
          <IonItem slot="header">
            <h6>
              What are the app's functionalities and what the user's benefits?
            </h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              The portal offers the following functionalities on behalf of the
              utility companies for its customers: account registration,
              updating contractual details (phone, email, etc.), self-reading
              water indexes, viewing bill history, online bill payments,
              requesting an authorized meter reading , sending alerts and
              notifications to customers.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask3">
          <IonItem slot="header">
            <h6>How do I register as an Aqmeter user?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              You can register a new account by accessing the{" "}
              <Link to="/register">Create an account</Link> section and filling
              in the required details. Registration is possible for users who
              have a contract with a utility provider that has implemented
              Aqmeter portal.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask4">
          <IonItem slot="header">
            <h6>How much does it cost me to be an Aqmeter user?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              It costs you nothing. Using the application is free of charge.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask5">
          <IonItem slot="header">
            <h6>Can I pay my invoice online?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Yes, you can pay your invoice online using a card by accessing the{" "}
              <Link to="/invoices">Invoices</Link> section.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask6">
          <IonItem slot="header">
            <h6>
              Where can I find the bank accounts where I can pay my invoice via
              wire transfer?
            </h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              If you wish to pay your invoice using the wire transfer method,
              you will find the bank account details on the printed invoice
              you've received at your desired destination or, if you've chosen
              electronic invoice, in the .PDF file you can download from your
              account, by accessing the <Link to="/invoices">Invoices</Link>{" "}
              section.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask7">
          <IonItem slot="header">
            <h6>Is my data safe?</h6>
          </IonItem>
          <IonItem slot="content">
            <p>
              Yes. The processing of personal data is subject to national data
              protection legislation and the user has the right to access,
              inform and refuse the collection of the data.
            </p>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask8">
          <IonItem slot="header">
            <h6>What notifications can I receive from the platform?</h6>
          </IonItem>
          <IonItem slot="content">
            <div>
              <p>
                The Aqmeter platform can send the following notification types,
                according to the settings on your account, found on each{" "}
                <Link to="/contracts">Contract</Link>:
              </p>
              <ul>
                <li>
                  Card payment confirmation for invoices paid using the
                  platform.
                </li>
                <li>
                  If you opt for the electronic invoice, you will receive an
                  email with a .PDF file attached.
                </li>
                <li>
                  If you have a contract with a water provider and have the
                  self-reading function active, we can remind you when to send
                  your self-reading.
                </li>
                <li>We can send you alerts when an invoice is overdue.</li>
              </ul>
            </div>
          </IonItem>
        </IonAccordion>

        <IonAccordion value="ask9">
          <IonItem slot="header">
            <h6>
              What can I do if I receive my electronic invoice late or not at
              all?
            </h6>
          </IonItem>
          <IonItem slot="content" lines="none">
            <p>
              Please report the situation, giving as many details as possible,
              using our <Link to="/contact">contact form</Link>. If the
              situation is due to your utility provider, we will contact them.
              In either case, you will be updated on the resolution.
            </p>
          </IonItem>
        </IonAccordion>
      </IonAccordionGroup>
    </div>
  );
};

export default FaqContentEN;
