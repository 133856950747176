import React from "react";

import i18n from "../../../i18n";
import type { Language } from "../../../types/otherTypes";

import styles from "./Login.module.scss";

const LoginAppStoreButtons: React.FC = () => {
  const lang = i18n.resolvedLanguage as Language;

  return (
    <div className={styles.storeLinks}>
      <a
        href="https://play.google.com/store/apps/details?id=ro.setmobile.aqmeter"
        target="_blank"
        rel="noopener noreferrer"
      >
        {(lang === "en" && (
          <img src="/assets/img/google-store-en.png" alt="Google Play Store" />
        )) || (
          <img src="/assets/img/google-store-ro.png" alt="Google Play Store" />
        )}
      </a>
      <a
        href="https://itunes.apple.com/us/app/aqmeter/id1332042499"
        target="_blank"
        rel="noopener noreferrer"
      >
        {(lang === "en" && (
          <img src="/assets/img/apple-store-en.png" alt="Apple App Store" />
        )) || (
          <img src="/assets/img/apple-store-ro.png" alt="Apple App Store" />
        )}
      </a>
      <a
        href="https://appgallery.huawei.com/#/app/C104127187"
        target="_blank"
        rel="noopener noreferrer"
      >
        {(lang === "en" && (
          <img src="/assets/img/huawei-store-en.png" alt="Huawei AppGallery" />
        )) || (
          <img src="/assets/img/huawei-store-ro.png" alt="Huawei AppGallery" />
        )}
      </a>
    </div>
  );
};

export default LoginAppStoreButtons;
