/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useState } from "react";
import { useTranslation } from "react-i18next";

import {
  IonButtons,
  IonHeader,
  IonLabel,
  IonPage,
  IonToolbar
} from "@ionic/react";

import { AqNotificationsBell } from "../../../components/AqNotificationsBell";
import CompanyFilter from "../../../components/Filters/CompanyFilter";
import SidebarLayout from "../../../components/SidebarLayout/SidebarLayout";
import SkeletonList from "../../../components/Skeleton/SkeletonList";
import UserContext from "../../../context/UserContext";
import useFetchCaching from "../../../hooks/useFetchCaching";
import { useWindowSize } from "../../../hooks/useWindowSize";
import type { ReadingSummary } from "../../../types/responseTypes";
import { filterItems } from "../../../utils/globalFunctions";

import ReadingsList from "./ReadingsList";

const mergeReadingContractDetails = (contractsCtx, readingSummaries) => {
  if (!contractsCtx?.length || !readingSummaries?.length) {
    return [];
  }
  return readingSummaries.map((summary) => {
    const currentContract = contractsCtx.find(
      (contract) => contract.id === summary.contractId
    );
    const mergedItem = { ...summary, ...currentContract };
    const { contractId, ...finalItem } = mergedItem;
    return finalItem;
  });
};

const ReadingsPage: React.FC = () => {
  const { t } = useTranslation();
  const { layout } = useWindowSize();
  const { menuCtx, contractsCtx } = useContext(UserContext);

  const [companyFilter, setCompanyFilter] = useState(0);

  const { data: readingSummaries, error } = useFetchCaching<ReadingSummary[]>(
    "/contracts/meters-summary"
  );
  const readingsToSend = menuCtx?.totalReadingsToSend;
  const readingContracts = mergeReadingContractDetails(
    contractsCtx,
    readingSummaries
  );
  const filteredContracts = filterItems(readingContracts, companyFilter);

  const companyFilterChangeHandler = (selectedCompany) => {
    setCompanyFilter(selectedCompany);
  };

  return (
    <IonPage className={`layout--${layout}`}>
      {layout === "phone" && (
        <IonHeader>
          <IonToolbar>
            <IonButtons slot="start" style={{ flexDirection: "column" }}>
              <IonLabel className="ag-h2 black">{t("tabs.index")}</IonLabel>
              {readingsToSend === 0 ? (
                <IonLabel className="ag-body-text gray">
                  {t("indexToReadCountZero")}
                </IonLabel>
              ) : (
                <IonLabel className="ag-body-text gray">
                  {t("indexToReadCount", {
                    count: readingsToSend
                  })}
                </IonLabel>
              )}
            </IonButtons>
            <IonButtons slot="end">
              <AqNotificationsBell />
            </IonButtons>
          </IonToolbar>
        </IonHeader>
      )}
      <SidebarLayout
        layoutType={"submenu"}
        menuType={"readings"}
        menuSelected={"item1"}
      >
        <SkeletonList loading={!readingSummaries} error={error} />

        {!error && readingSummaries ? (
          <>
            <CompanyFilter
              type={"readings"}
              readingContracts={readingContracts}
              onChangeCompanyFilter={companyFilterChangeHandler}
            />
            <ReadingsList filteredContracts={filteredContracts} />
          </>
        ) : (
          ""
        )}
      </SidebarLayout>
    </IonPage>
  );
};
export default ReadingsPage;
