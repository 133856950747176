import React, { Fragment, useContext, useRef, useState } from "react";
import type { AxiosResponse } from "axios";
import { addCircle, closeCircle } from "ionicons/icons";
import type { KeyboardEvent, MutableRefObject } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router";
import validator from "validator";

import type { InputChangeEventDetail } from "@ionic/react";
import {
  IonCheckbox,
  IonChip,
  IonIcon,
  IonInput,
  IonItem,
  IonLabel,
  IonText
} from "@ionic/react";

import { axiosPrivate } from "../../../api/customAxios";
import { toastAdd } from "../../../components/CustomToast/CustomToasts";
import InfoBox from "../../../components/UI/InfoBox";
import UserContext from "../../../context/UserContext";
import type { Company } from "../../../types/responseTypes";

import EditContractValidateEmailModal from "./EditContractValidateEmailModal";

import styles from "./EditContractPage.module.scss";

type ElectronicInvoiceEmails = {
  email: string;
  confirmedEmail: boolean;
  contractId: string;
};

interface EditContractInvoiceTypeProps {
  isRevertToPhysicalInvoicePermitted: boolean;
  electronicInvoiceEmails: ElectronicInvoiceEmails[];
  setElectronicInvoiceEmails: (value) => void;
  isElectronicInvoice: boolean;
  setIsElectronicInvoice: (value: boolean) => void;
  setNotificationSettings: (value) => void;
  electronicInvoiceEmailHasError: boolean;
  setElectronicInvoiceEmailHasError: (value: boolean) => void;
  newElectronicInvoiceEmail: ElectronicInvoiceEmails;
  setNewElectronicInvoiceEmail: (emails: ElectronicInvoiceEmails) => void;
  companyId: number;
  isElectronicInvoiceDisabled: boolean;
}

const EditContractInvoiceType: React.FC<EditContractInvoiceTypeProps> = ({
  isRevertToPhysicalInvoicePermitted,
  electronicInvoiceEmails,
  setElectronicInvoiceEmails,
  isElectronicInvoice,
  setIsElectronicInvoice,
  setNotificationSettings,
  electronicInvoiceEmailHasError,
  setElectronicInvoiceEmailHasError,
  newElectronicInvoiceEmail,
  setNewElectronicInvoiceEmail,
  companyId,
  isElectronicInvoiceDisabled
}: EditContractInvoiceTypeProps): JSX.Element => {
  const { t } = useTranslation();
  const { id }: { id: string } = useParams();

  const inputRef: MutableRefObject<HTMLIonInputElement> =
    useRef<HTMLIonInputElement>(null);

  const { companiesCtx } = useContext(UserContext);

  const [showModal, setShowModal] = useState<boolean>(false);

  const [selectedEmailForValidation, setSelectedEmailForValidation] =
    useState<ElectronicInvoiceEmails>(null);

  const hasValidationEnabled: boolean = companiesCtx?.filter(
    (company: Company): boolean => company.id === companyId
  )[0]?.isValidationEmailActive;

  const electronicInvoiceEmailsHandler = (
    updateEmail: ElectronicInvoiceEmails,
    action: "add" | "remove"
  ) => {
    if (!updateEmail || !action) {
      return;
    }
    if (action === "remove") {
      setElectronicInvoiceEmails(
        (prevState: ElectronicInvoiceEmails[]): ElectronicInvoiceEmails[] =>
          prevState.filter(
            (email: ElectronicInvoiceEmails): boolean =>
              email.email !== updateEmail?.email
          )
      );
    } else {
      if (!validator.isEmail(updateEmail.email.trim())) {
        setElectronicInvoiceEmailHasError(true);
        toastAdd(t([`err.EMAIL_NOT_VALID`, "err.__"]), "error");
        return;
      }

      axiosPrivate
        .get(`/confirm-contract-email/is-confirmed?email=${updateEmail.email}`)
        .then((response: AxiosResponse) => {
          if (response.data) {
            updateEmail.confirmedEmail = true;
            setElectronicInvoiceEmails(
              (
                prevState: ElectronicInvoiceEmails[]
              ): ElectronicInvoiceEmails[] => [...prevState, updateEmail]
            );
            setNewElectronicInvoiceEmail(null);
          } else {
            setElectronicInvoiceEmails(
              (
                prevState: ElectronicInvoiceEmails[]
              ): ElectronicInvoiceEmails[] => [...prevState, updateEmail]
            );
            setNewElectronicInvoiceEmail(null);
          }
        })
        .catch((err: any) => {
          console.log(err);
        });
    }
  };

  const invoiceTypeHandler = () => {
    if (isRevertToPhysicalInvoicePermitted) {
      if (isElectronicInvoice) {
        setIsElectronicInvoice(false);
        setElectronicInvoiceEmails([]);
        setNotificationSettings((prevState) => {
          return { ...prevState, generateInvoiceEmail: false };
        });
        setNotificationSettings((prevState) => {
          return { ...prevState, generateInvoiceEmail: false };
        });
      } else if (!isElectronicInvoiceDisabled) {
        setIsElectronicInvoice(true);
        setNotificationSettings((prevState) => {
          return { ...prevState, generateInvoiceEmail: true };
        });
      }
    }
  };

  const handleShowModal = async (selectedEmail: ElectronicInvoiceEmails) => {
    setSelectedEmailForValidation(selectedEmail);
    setShowModal(true);

    try {
      await axiosPrivate.post("/confirm-contract-email/send-validation", {
        contractId: id,
        email: selectedEmail.email,
        isConfirmedEmail: false
      });
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div className={styles.invoiceType}>
      <h3>{t("contract.edit.invoiceType")}</h3>

      <div className={styles.electronicInvoiceBox}>
        <IonItem lines="none">
          <IonCheckbox
            slot="start"
            mode="md"
            disabled={
              !isRevertToPhysicalInvoicePermitted || isElectronicInvoiceDisabled
            }
            color="tertiary"
            checked={isElectronicInvoice}
            onClick={invoiceTypeHandler}
          />
          <IonLabel className={isElectronicInvoice ? styles.checked : ""}>
            {t("contract.edit.electronicInvoice")}
          </IonLabel>
        </IonItem>
        <IonItem lines="none">
          <IonCheckbox
            slot="start"
            mode="md"
            disabled={!isRevertToPhysicalInvoicePermitted}
            color="tertiary"
            checked={!isElectronicInvoice}
            onClick={invoiceTypeHandler}
          />
          <IonLabel className={!isElectronicInvoice ? styles.checked : ""}>
            {t("contract.edit.courierInvoice")}
          </IonLabel>
        </IonItem>
        {!isRevertToPhysicalInvoicePermitted && (
          <IonItem lines="none" className={styles.notice}>
            {t("contract.edit.changeNotice")}
          </IonItem>
        )}
      </div>

      {isElectronicInvoiceDisabled && (
        <InfoBox color="warning">{t("contract.edit.warning.DAE")}</InfoBox>
      )}

      {isElectronicInvoice && (
        <div className={styles.electronicInvoiceEmails}>
          <h4>{t("contract.edit.electronicInvoiceEmails")}</h4>

          {electronicInvoiceEmails && (
            <>
              {!electronicInvoiceEmails.length ? (
                <p>{t("contract.edit.electronicInvoiceEmailDesc.empty")}</p>
              ) : electronicInvoiceEmails.length === 1 ? (
                <p className="gray">
                  {t("contract.edit.electronicInvoiceEmailDesc.one")}
                </p>
              ) : (
                <p className="gray">
                  {t("contract.edit.electronicInvoiceEmailDesc.multiple")}
                </p>
              )}

              {electronicInvoiceEmails
                .sort(
                  (
                    a: ElectronicInvoiceEmails,
                    b: ElectronicInvoiceEmails
                  ): number => {
                    if (a.confirmedEmail && !b.confirmedEmail) {
                      return -1;
                    } else if (!a.confirmedEmail && b.confirmedEmail) {
                      return 1;
                    } else if (a.confirmedEmail && b.confirmedEmail) {
                      return a.email.localeCompare(b.email);
                    } else {
                      return 0;
                    }
                  }
                )
                .map((selectedEmail: ElectronicInvoiceEmails): JSX.Element => {
                  const colorAll: string =
                    hasValidationEnabled && !selectedEmail.confirmedEmail
                      ? "danger"
                      : !hasValidationEnabled
                        ? "medium"
                        : "primary";
                  const colorLabel: string =
                    hasValidationEnabled && !selectedEmail.confirmedEmail
                      ? "danger"
                      : "medium";

                  return (
                    <Fragment key={selectedEmail.email}>
                      <IonChip
                        outline={true}
                        className={
                          (selectedEmail.confirmedEmail && styles.margin) ||
                          (!hasValidationEnabled && styles.margin)
                        }
                        color={colorAll}
                      >
                        <IonLabel color={colorLabel}>
                          {selectedEmail.email}
                        </IonLabel>

                        {hasValidationEnabled &&
                        selectedEmail.confirmedEmail ? (
                          <div
                            className={`cursor-hover ${styles.anchorValidate}`}
                          >
                            <IonChip
                              outline={true}
                              color="primary"
                              className={styles.validate}
                            >
                              <IonLabel>Validat</IonLabel>
                            </IonChip>
                          </div>
                        ) : (
                          <div
                            className={`cursor-hover ${styles.anchorValidate}`}
                          >
                            <IonChip
                              outline={true}
                              color="danger"
                              className={styles.validate}
                              onClick={() => handleShowModal(selectedEmail)}
                            >
                              <IonLabel>
                                {t(
                                  "contract.edit.electronicInvoiceEmails.validate"
                                )}
                              </IonLabel>
                            </IonChip>
                          </div>
                        )}
                        <div
                          className="cursor-hover"
                          onKeyDown={(e: KeyboardEvent<HTMLDivElement>) => {
                            if (e.key === "Enter") {
                              e.preventDefault();
                              electronicInvoiceEmailsHandler(
                                selectedEmail,
                                "remove"
                              );
                            }
                          }}
                          onClick={() =>
                            electronicInvoiceEmailsHandler(
                              selectedEmail,
                              "remove"
                            )
                          }
                        >
                          <IonIcon icon={closeCircle} color={colorAll} />
                        </div>
                      </IonChip>
                      {hasValidationEnabled &&
                        !selectedEmail.confirmedEmail && (
                          <IonText className={styles.notice} color="danger">
                            {t(
                              "contract.edit.electronicInvoiceEmails.warningValidate"
                            )}
                          </IonText>
                        )}
                      <EditContractValidateEmailModal
                        electronicInvoice={selectedEmailForValidation}
                        electronicInvoiceEmails={electronicInvoiceEmails}
                        setElectronicInvoiceEmails={setElectronicInvoiceEmails}
                        showModal={showModal}
                        updateShowModal={setShowModal}
                      />
                    </Fragment>
                  );
                })}
            </>
          )}

          <IonChip
            outline={true}
            color={
              electronicInvoiceEmailHasError
                ? "danger"
                : !hasValidationEnabled
                  ? "primary"
                  : "medium"
            }
          >
            <IonInput
              ref={inputRef}
              onKeyDown={(e: KeyboardEvent<HTMLIonInputElement>) => {
                if (e.key === "Enter") {
                  e.preventDefault();
                  electronicInvoiceEmailsHandler(
                    newElectronicInvoiceEmail,
                    "add"
                  );
                }
              }}
              onIonChange={(e: CustomEvent<InputChangeEventDetail>) => {
                setNewElectronicInvoiceEmail({
                  ...newElectronicInvoiceEmail,
                  email: e.detail.value
                });
                setElectronicInvoiceEmailHasError(false);
              }}
              type="text"
              value={newElectronicInvoiceEmail?.email}
              inputmode="text"
              autocomplete="off"
              placeholder={t("contract.edit.electronicInvoiceEmailAdd")}
            />
            <div className="cursor-hover">
              <IonIcon
                icon={addCircle}
                color={electronicInvoiceEmailHasError ? "danger" : "medium"}
                onClick={() =>
                  electronicInvoiceEmailsHandler(
                    newElectronicInvoiceEmail,
                    "add"
                  )
                }
              />
            </div>
          </IonChip>
        </div>
      )}
    </div>
  );
};

export default EditContractInvoiceType;
